import * as XLSX from "xlsx";
export const getPath = (object, search) => {
  if (object.key === search) return [object.key];
  else if (object.children || Array.isArray(object)) {
    let children = Array.isArray(object) ? object : object.children;
    for (let child of children) {
      let result = getPath(child, search);
      if (result) {
        if (object.key) result.unshift(object.key);
        return result;
      }
    }
  }
};
export const download = (filepath, filename, mimeType) => {
  if (filepath) {
    const fileMetadata = {
      path: filepath,
      mime: mimeType,
      name: filename,
    };
    const url = fileMetadata.path;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileMetadata.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const convertExcelToArray = (data) => {
  const workbook = XLSX.read(data, { type: "binary" });
  const sheet_name_list = workbook.SheetNames;
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  if (sheet_name_list.length > 0) {
    const csv = XLSX.utils.sheet_to_csv(sheet, { header: 1 });
    let lines = csv.split("\n");
    let result = [];
    let headers = lines[0].split(",");
    lines.forEach((item) => {
      let obj = {};
      const currentline = item.split(",");
      headers.forEach((items, i) => {
        obj[items] = currentline[i];
      });
      result.push(obj);
    });
    return result;
  } else {
    return [];
  }
};
