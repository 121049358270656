import React from "react";
import { useTranslation } from "react-i18next";
import { Cross } from "../SVG";
import "react-notifications-component/dist/theme.css";
import "./style.scss";

const Notification = (props) => {
  const { t } = useTranslation();
  return (
    <div className={`notification-wrapper notification-wrapper-${props.type}`}>
      <div className="notification-text-wrapper">
        <p className={`notification-text notification-text-${props.type}`}>{props.content} {t(props.message)}</p>
      </div>
      <div className={`close-icon-wrapper close-icon-wrapper-${props.type}`}>
        <Cross />
      </div>
    </div>
  );
};

export default Notification;
