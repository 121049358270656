import {
  INIT_BULKRELEASEAI_EXCEL_VALIDATION,
  FETCHING_BULKRELEASEAI_EXCEL_VALIDATION,
  BULKRELEASEAI_EXCEL_VALIDATION_FETCHED,
  BULKRELEASEAI_EXCEL_VALIDATION_FETCH_COMPLETE,
  BULKRELEASEAI_EXCEL_VALIDATION_FETCH_FAIL,
  INIT_BULKRELEASE_DUPS_UPLOAD,
  FETCHING_BULKRELEASE_DUPS_UPLOAD,
  BULKRELEASE_DUPS_UPLOAD_FETCHED,
  BULKRELEASE_DUPS_UPLOAD_FETCH_COMPLETE,
  BULKRELEASE_DUPS_UPLOAD_FETCH_FAIL,
  INIT_BULKRELEASE_DUPS_UPLOAD_FILE,
  FETCHING_BULKRELEASE_DUPS_UPLOAD_FILE,
  BULKRELEASE_DUPS_UPLOAD_FILE_FETCHED,
  BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_COMPLETE,
  BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_FAIL,
  INIT_BULKRELEASE_DUPS_RELEASE_ALL,
  FETCHING_BULKRELEASE_DUPS_RELEASE_ALL,
  BULKRELEASE_DUPS_RELEASE_ALL_FETCHED,
  BULKRELEASE_DUPS_RELEASE_ALL_FETCH_COMPLETE,
  BULKRELEASE_DUPS_RELEASE_ALL_FETCH_FAIL,
  INIT_BULKRELEASE_FNE_UPLOAD,
  FETCHING_BULKRELEASE_FNE_UPLOAD,
  BULKRELEASE_FNE_UPLOAD_FETCHED,
  BULKRELEASE_FNE_UPLOAD_FETCH_COMPLETE,
  BULKRELEASE_FNE_UPLOAD_FETCH_FAIL,
  INIT_BULKRELEASE_FNE_UPLOAD_FILE,
  FETCHING_BULKRELEASE_FNE_UPLOAD_FILE,
  BULKRELEASE_FNE_UPLOAD_FILE_FETCHED,
  BULKRELEASE_FNE_UPLOAD_FILE_FETCH_COMPLETE,
  BULKRELEASE_FNE_UPLOAD_FILE_FETCH_FAIL,
  INIT_BULKRELEASE_FNE_RELEASE_ALL,
  FETCHING_BULKRELEASE_FNE_RELEASE_ALL,
  BULKRELEASE_FNE_RELEASE_ALL_FETCHED,
  BULKRELEASE_FNE_RELEASE_ALL_FETCH_COMPLETE,
  BULKRELEASE_FNE_RELEASE_ALL_FETCH_FAIL,
} from "./actionTypes";

export const initBulkReleaseExcelValidation = (payload, callback) => {
  return {
    type: INIT_BULKRELEASEAI_EXCEL_VALIDATION,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseExcelValidation = () => {
  return {
    type: FETCHING_BULKRELEASEAI_EXCEL_VALIDATION,
  };
};
export const fetchBulkReleaseExcelValidation = (data) => {
  return {
    type: BULKRELEASEAI_EXCEL_VALIDATION_FETCHED,
    data,
  };
};
export const bulkReleaseExcelValidationComplete = () => {
  return {
    type: BULKRELEASEAI_EXCEL_VALIDATION_FETCH_COMPLETE,
  };
};
export const bulkReleaseExcelValidationFail = () => {
  return {
    type: BULKRELEASEAI_EXCEL_VALIDATION_FETCH_FAIL,
  };
};

export const initBulkReleaseUpload = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_DUPS_UPLOAD,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseUpload = () => {
  return {
    type: FETCHING_BULKRELEASE_DUPS_UPLOAD,
  };
};
export const fetchBulkReleaseUpload = (data) => {
  return {
    type: BULKRELEASE_DUPS_UPLOAD_FETCHED,
    data,
  };
};
export const bulkReleaseUploadComplete = () => {
  return {
    type: BULKRELEASE_DUPS_UPLOAD_FETCH_COMPLETE,
  };
};
export const bulkReleaseUploadFail = () => {
  return {
    type: BULKRELEASE_DUPS_UPLOAD_FETCH_FAIL,
  };
};

export const initBulkReleaseUploadFile = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_DUPS_UPLOAD_FILE,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseUploadFile = () => {
  return {
    type: FETCHING_BULKRELEASE_DUPS_UPLOAD_FILE,
  };
};
export const fetchBulkReleaseUploadFile = (data) => {
  return {
    type: BULKRELEASE_DUPS_UPLOAD_FILE_FETCHED,
    data,
  };
};
export const bulkReleaseUploadFileComplete = () => {
  return {
    type: BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_COMPLETE,
  };
};
export const bulkReleaseUploadFileFail = () => {
  return {
    type: BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_FAIL,
  };
};

export const initBulkReleaseReleaseAll = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_DUPS_RELEASE_ALL,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseReleaseAll = () => {
  return {
    type: FETCHING_BULKRELEASE_DUPS_RELEASE_ALL,
  };
};
export const fetchBulkReleaseReleaseAll = (data) => {
  return {
    type: BULKRELEASE_DUPS_RELEASE_ALL_FETCHED,
    data,
  };
};
export const bulkReleaseReleaseAllComplete = () => {
  return {
    type: BULKRELEASE_DUPS_RELEASE_ALL_FETCH_COMPLETE,
  };
};
export const bulkReleaseReleaseAllFail = () => {
  return {
    type: BULKRELEASE_DUPS_RELEASE_ALL_FETCH_FAIL,
  };
};
export const initBulkReleaseFneUpload = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_FNE_UPLOAD,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseFneUpload = () => {
  return {
    type: FETCHING_BULKRELEASE_FNE_UPLOAD,
  };
};
export const fetchBulkReleaseFneUpload = (data) => {
  return {
    type: BULKRELEASE_FNE_UPLOAD_FETCHED,
    data,
  };
};
export const bulkReleaseFneUploadComplete = () => {
  return {
    type: BULKRELEASE_FNE_UPLOAD_FETCH_COMPLETE,
  };
};
export const bulkReleaseFneUploadFail = () => {
  return {
    type: BULKRELEASE_FNE_UPLOAD_FETCH_FAIL,
  };
};

export const initBulkReleaseFneUploadFile = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_FNE_UPLOAD_FILE,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseFneUploadFile = () => {
  return {
    type: FETCHING_BULKRELEASE_FNE_UPLOAD_FILE,
  };
};
export const fetchBulkReleaseFneUploadFile = (data) => {
  return {
    type: BULKRELEASE_FNE_UPLOAD_FILE_FETCHED,
    data,
  };
};
export const bulkReleaseFneUploadFileComplete = () => {
  return {
    type: BULKRELEASE_FNE_UPLOAD_FILE_FETCH_COMPLETE,
  };
};
export const bulkReleaseFneUploadFileFail = () => {
  return {
    type: BULKRELEASE_FNE_UPLOAD_FILE_FETCH_FAIL,
  };
};

export const initBulkReleaseFneReleaseAll = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_FNE_RELEASE_ALL,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseFneReleaseAll = () => {
  return {
    type: FETCHING_BULKRELEASE_FNE_RELEASE_ALL,
  };
};
export const fetchBulkReleaseFneReleaseAll = (data) => {
  return {
    type: BULKRELEASE_FNE_RELEASE_ALL_FETCHED,
    data,
  };
};
export const bulkReleaseFneReleaseAllComplete = () => {
  return {
    type: BULKRELEASE_FNE_RELEASE_ALL_FETCH_COMPLETE,
  };
};
export const bulkReleaseFneReleaseAllFail = () => {
  return {
    type: BULKRELEASE_FNE_RELEASE_ALL_FETCH_FAIL,
  };
};
