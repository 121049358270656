import { fromJS } from "immutable";
import {
  FETCH_UDLMAINTENANCEDETAILS,
  FETCH_UDLMAINTENANCE,
  FETCH_UDLMAINTENANCEDETAILS_DATA,
  FETCH_UDLMAINTENANCE_LIST,
  INIT_UDLMAINTENANCE_DETAILS,
} from "./actionTypes";

export const initialState = fromJS({
  udlMaintenanceList: [],
  udlMaintenanceDetails: "",
  udlMaintenanceDetailsData: {},
  fetchingUdlList: false,
  fetchingUdlDetails: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UDLMAINTENANCEDETAILS: {
      return state.set("udlMaintenanceDetails", action.data);
    }
    case FETCH_UDLMAINTENANCEDETAILS_DATA: {
      return state
        .set("udlMaintenanceDetailsData", action.data)
        .set("fetchingUdlDetails", false);
    }
    case FETCH_UDLMAINTENANCE_LIST: {
      return state
        .set("udlMaintenanceList", action.data)
        .set("fetchingUdlList", false);
    }
    case FETCH_UDLMAINTENANCE: {
      return state.set("fetchingUdlList", true);
    }
    case INIT_UDLMAINTENANCE_DETAILS: {
      return state.set("fetchingUdlDetails", true);
    }
    default:
      return state;
  }
};

export default reducer;
