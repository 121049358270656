import { fromJS } from "immutable";
import {
  INIT_VENDOR_GROUP_LIST,
  FETCH_VENDOR_GROUP_LIST,
  INIT_VENDOR_GROUP_DETAILS,
  FETCH_VENDOR_GROUP_LIST_ROLE_BASE_ACTION,
  FETCH_VENDOR_GROUP_DETAILS,
  APPLY_VARIABLE_INITAL_VALUE,
  INIT_VENDOR_GROUP_ASSIGN_VENDOR,
  INIT_VENDOR_GROUP_FILTER_DROPDOWN,
  FETCH_VENDOR_GROUP_ASSIGN_VENDOR,
  FETCH_VENDOR_GROUP_FILTER_DROPDOWN,
} from "./actionTypes";
export const initialState = fromJS({
  userActions: "",
  vendorGroupList: "",
  initLoader: false,
  vendorDetails: "",
  assignVendorList: [],
  vendorFilterOption: [],
});
const reduer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_VENDOR_GROUP_LIST: {
      return state.set("initLoader", true);
    }
    case INIT_VENDOR_GROUP_DETAILS: {
      return state.set("initLoader", true);
    }
    case FETCH_VENDOR_GROUP_LIST: {
      return state.set("vendorGroupList", action.data).set("initLoader", false);
    }
    case FETCH_VENDOR_GROUP_LIST_ROLE_BASE_ACTION: {
      return state.set("userActions", action.data);
    }
    case FETCH_VENDOR_GROUP_DETAILS: {
      return state.set("vendorDetails", action.data).set("initLoader", false);
    }
    case APPLY_VARIABLE_INITAL_VALUE: {
      return state
        .set("userActions", action.data.userAction)
        .set("vendorGroupList", action.data.vendorGroupList)
        .set("vendorDetails", action.data.vendorDetails);
    }
    case INIT_VENDOR_GROUP_ASSIGN_VENDOR: {
      return state.set("initLoader", true).set("assignVendorList", []);
    }
    case INIT_VENDOR_GROUP_FILTER_DROPDOWN: {
      return state.set("vendorFilterOption", []);
    }
    case FETCH_VENDOR_GROUP_ASSIGN_VENDOR: {
      return state
        .set("assignVendorList", action.data)
        .set("initLoader", false);
    }
    case FETCH_VENDOR_GROUP_FILTER_DROPDOWN: {
      return state.set("vendorFilterOption", action.data);
    }
    default:
      return state;
  }
};
export default reduer;
