export const INIT_BULKRELEASE_GET_TIME = "INIT_BULKRELEASE_GET_TIME";
export const FETCHING_BULKRELEASE_GET_TIME = "FETCHING_BULKRELEASE_GET_TIME";
export const BULKRELEASE_GET_TIME_FETCHED = "BULKRELEASE_GET_TIME_FETCHED";
export const BULKRELEASE_GET_TIME_FETCH_COMPLETE =
  "BULKRELEASE_GET_TIME_FETCH_COMPLETE";
export const BULKRELEASE_GET_TIME_FETCH_FAIL =
  "BULKRELEASE_GET_TIME_FETCH_FAIL";

export const INIT_BULKRELEASE_SUBMIT_TIME = "INIT_BULKRELEASE_SUBMIT_TIME";
export const FETCHING_BULKRELEASE_SUBMIT_TIME =
  "FETCHING_BULKRELEASE_SUBMIT_TIME";
export const BULKRELEASE_SUBMIT_TIME_FETCHED =
  "BULKRELEASE_SUBMIT_TIME_FETCHED";
export const BULKRELEASE_SUBMIT_TIME_FETCH_COMPLETE =
  "BULKRELEASE_SUBMIT_TIME_FETCH_COMPLETE";
export const BULKRELEASE_SUBMIT_TIME_FETCH_FAIL =
  "BULKRELEASE_SUBMIT_TIME_FETCH_FAIL";
