import PropTypes from "prop-types";
import React from "react";
import plusIcon from "../../assets/images/common/Accordionplus.svg";
import minusIcon from "../../assets/images/common/Accordionminus.svg";
import { useTranslation } from "react-i18next";
import "./style.scss";

const AccordionItem = (props) => {
  const { t } = useTranslation();
  return (
    <div className="accordionItem-container">
      <div className="accordionItem-header">
        <button
          aria-label={t("atcat.clickToToggleAccordionItem")}
          onClick={(e) => {
            e.stopPropagation();
            props.setActive({ id: props.id, active: !props.active });
          }}
        >
          <p>{props.title}</p>
          <img
            src={props.active ? minusIcon : plusIcon}
            alt={t("atcat.clickToToggleAccordionItem")}
          />
        </button>
      </div>
      <div
        className={`accordionItem-body ${
          props.active ? "accordionItem-body-active" : ""
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  active: PropTypes.any,
  id: PropTypes.any,
  setActive: PropTypes.func,
  title: PropTypes.any,
};

export default AccordionItem;
