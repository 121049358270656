import React from "react";
import "./style.scss";

const Cross = (props) => {
  return (
    <svg
      className="icons"
      viewBox="0 0 15 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-881.000000, -140.000000)">
          <g transform="translate(433.000000, 116.000000)">
            <path d="M457.090938,31.5 L461.898438,26.6925 L462.889844,25.7010938 C463.036094,25.5548438 463.036094,25.3171875 462.889844,25.1709375 L461.829063,24.1101563 C461.682813,23.9639062 461.445156,23.9639062 461.298906,24.1101563 L455.5,29.9090625 L449.701094,24.1096875 C449.554844,23.9634375 449.317188,23.9634375 449.170938,24.1096875 L448.109688,25.1704688 C447.963437,25.3167188 447.963437,25.554375 448.109688,25.700625 L453.909063,31.5 L448.109688,37.2989063 C447.963437,37.4451563 447.963437,37.6828126 448.109688,37.8290626 L449.170469,38.8898438 C449.316719,39.0360938 449.554375,39.0360938 449.700625,38.8898438 L455.5,33.0909375 L460.3075,37.8984376 L461.298906,38.8898438 C461.445156,39.0360938 461.682813,39.0360938 461.829063,38.8898438 L462.889844,37.8290626 C463.036094,37.6828126 463.036094,37.4451563 462.889844,37.2989063 L457.090938,31.5 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Cross;
