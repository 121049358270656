export const INIT_BULKRELEASE_EXCEL_VALIDATION =
  "INIT_BULKRELEASE_EXCEL_VALIDATION";
export const FETCHING_BULKRELEASE_EXCEL_VALIDATION =
  "FETCHING_BULKRELEASE_EXCEL_VALIDATION";
export const BULKRELEASE_EXCEL_VALIDATION_FETCHED =
  "BULKRELEASE_EXCEL_VALIDATION_FETCHED";
export const BULKRELEASE_EXCEL_VALIDATION_FETCH_COMPLETE =
  "BULKRELEASE_EXCEL_VALIDATION_FETCH_COMPLETE";
export const BULKRELEASE_EXCEL_VALIDATION_FETCH_FAIL =
  "BULKRELEASE_EXCEL_VALIDATION_FETCH_FAIL";

export const INIT_BULKRELEASE_UPLOAD = "INIT_BULKRELEASE_UPLOAD";
export const FETCHING_BULKRELEASE_UPLOAD = "FETCHING_BULKRELEASE_UPLOAD";
export const BULKRELEASE_UPLOAD_FETCHED = "BULKRELEASE_UPLOAD_FETCHED";
export const BULKRELEASE_UPLOAD_FETCH_COMPLETE =
  "BULKRELEASE_UPLOAD_FETCH_COMPLETE";
export const BULKRELEASE_UPLOAD_FETCH_FAIL = "BULKRELEASE_UPLOAD_FETCH_FAIL";

export const INIT_BULKRELEASE_UPLOAD_FILE = "INIT_BULKRELEASE_UPLOAD_FILE";
export const FETCHING_BULKRELEASE_UPLOAD_FILE =
  "FETCHING_BULKRELEASE_UPLOAD_FILE";
export const BULKRELEASE_UPLOAD_FILE_FETCHED =
  "BULKRELEASE_UPLOAD_FILE_FETCHED";
export const BULKRELEASE_UPLOAD_FILE_FETCH_COMPLETE =
  "BULKRELEASE_UPLOAD_FILE_FETCH_COMPLETE";
export const BULKRELEASE_UPLOAD_FILE_FETCH_FAIL =
  "BULKRELEASE_UPLOAD_FILE_FETCH_FAIL";

export const INIT_BULKRELEASE_RELEASE_ALL = "INIT_BULKRELEASE_RELEASE_ALL";
export const FETCHING_BULKRELEASE_RELEASE_ALL =
  "FETCHING_BULKRELEASE_RELEASE_ALL";
export const BULKRELEASE_RELEASE_ALL_FETCHED =
  "BULKRELEASE_RELEASE_ALL_FETCHED";
export const BULKRELEASE_RELEASE_ALL_FETCH_COMPLETE =
  "BULKRELEASE_RELEASE_ALL_FETCH_COMPLETE";
export const BULKRELEASE_RELEASE_ALL_FETCH_FAIL =
  "BULKRELEASE_RELEASE_ALL_FETCH_FAIL";
