export const INIT_VENDOR_GROUP_LIST_ROLE_BASE_ACTION =
  "INIT_VENDOR_GROUP_LIST_ROLE_BASE_ACTION";
export const INIT_VENDOR_GROUP_LIST = "INIT_VENDOR_GROUP_LIST";
export const INIT_VENDOR_GROUP_DETAILS = "INIT_VENDOR_GROUP_DETAILS";
export const INIT_VENDOR_GROUP_FILTER_DROPDOWN =
  "INIT_VENDOR_GROUP_FILTER_DROPDOWN";
export const INIT_VENDOR_GROUP_ASSIGN_VENDOR =
  "INIT_VENDOR_GROUP_ASSIGN_VENDOR";
export const INIT_UNASSIGN_VENDOR = "INIT_UNASSIGN_VENDOR";
export const INIT_DELETE_VENDOR_GROUP = "INIT_DELETE_VENDOR_GROUP";
export const INIT_SAVE_VENDOR_DETAILS = "INIT_SAVE_VENDOR_DETAILS";
export const INIT_HANDLE_ASSIGN_VENDOR_TO_GROUP =
  "INIT_HANDLE_ASSIGN_VENDOR_TO_GROUP";
export const INIT_CREATE_NEW_VENDOR_GROUP = "INIT_CREATE_NEW_VENDOR_GROUP";
export const FETCH_VENDOR_GROUP_LIST_ROLE_BASE_ACTION =
  "FETCH_VENDOR_GROUP_LIST_ROLE_BASE_ACTION";
export const FETCH_VENDOR_GROUP_LIST = "FETCH_VENDOR_GROUP_LIST";
export const FETCH_VENDOR_GROUP_DETAILS = "FETCH_VENDOR_GROUP_DETAILS";
export const FETCH_VENDOR_GROUP_FILTER_DROPDOWN =
  "FETCH_VENDOR_GROUP_FILTER_DROPDOWN";
export const FETCH_VENDOR_GROUP_ASSIGN_VENDOR =
  "FETCH_VENDOR_GROUP_ASSIGN_VENDOR";
export const APPLY_VARIABLE_INITAL_VALUE = "APPLY_VARIABLE_INITAL_VALUE";
