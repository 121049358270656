import {
  INIT_SAVE_USER_DATA,
  USER_DATA_SAVE_FAILED,
  USER_DATA_SAVE_SUCCESS,
  INIT_GET_TOTAL_USERS,
  FETCHING_TOTAL_USERS,
  FETCH_TOTAL_USERS,
  FETCH_FILTERED_TOTAL_USERS,
  TOTAL_USERS_FETCHING_COMPLETE,
  INIT_GET_ACTIVE_USERS,
  FETCHING_ACTIVE_USERS,
  FETCH_ACTIVE_USERS,
  ACTIVE_USERS_FETCHING_COMPLETE,
  INIT_GET_USER_LANGUAGES,
  FETCHING_USER_LANGUAGES,
  FETCH_USER_LANGUAGES,
  USER_LANGUAGES_FETCH_COMPLETE,
  INIT_GET_INACTIVE_USERS,
  FETCHING_INACTIVE_USERS,
  FETCH_INACTIVE_USERS,
  INACTIVE_USERS_FETCHING_COMPLETE,
  INIT_GET_USERS_LIST,
  FETCHING_USERS_LIST,
  FETCH_USERS_LIST,
  USERS_LIST_FETCHING_COMPLETE,
  INIT_USER_ACTION,
  USER_ACTION_SUCCESS,
  USER_ACTION_FAILED,
  INIT_GET_COUNTRY_LIST,
  FETCHING_COUNTRY_LIST,
  FETCH_COUNTRY_LIST,
  COUNTRY_LIST_FETCH_COMPLETE,
  INIT_GET_CLIENT_LIST,
  FETCHING_CLIENT_LIST,
  FETCH_CLIENT_LIST,
  CLIENT_LIST_FETCHING_COMPLETE,
  INIT_GET_EXPORT_TO_EXCEL,
  FETCHING_EXPORT_TO_EXCEL,
  FETCH_EXPORT_TO_EXCEL,
  EXPORT_TO_EXCEL_FETCHING_COMPLETE,
  INIT_DELETE_FILTER,
  INIT_FETCH_FILTER_NAMES,
  INIT_FETCH_USER_FILTER_COL_NAMES,
  FETCHING_USER_FILTER_COL_NAMES,
  USER_FILTER_COL_NAMES_FETCHED,
  USER_FILTER_COL_NAMES_FETCH_COMPLETE,
  USER_FILTER_COL_NAMES_FETCH_FAIL,
  INIT_FETCH_USER_FILTER_DETAILS,
  INIT_SAVE_USER_FILTER,
  INIT_USERMANAGEMENT_HEADER_LIST,
  FETCH_USERMANAGEMENT_HEADER_LIST,
} from "./actionTypes";

export const initGetExportToExcel = (payload) => {
  return {
    type: INIT_GET_EXPORT_TO_EXCEL,
    payload,
  };
};

export function fetchingExportToExcel() {
  return {
    type: FETCHING_EXPORT_TO_EXCEL,
  };
}

export function fetchExportToExcel(data) {
  return {
    type: FETCH_EXPORT_TO_EXCEL,
    data,
  };
}

export function exportToExcelFetchingComplete() {
  return {
    type: EXPORT_TO_EXCEL_FETCHING_COMPLETE,
  };
}

export const initDeleteFilter = (payload, callback) => {
  return {
    type: INIT_DELETE_FILTER,
    payload,
    callback,
  };
};

export const initGetFilterNames = (payload, callback) => {
  return {
    type: INIT_FETCH_FILTER_NAMES,
    payload,
    callback,
  };
};

export const initFetchUserColNames = (payload) => {
  return {
    type: INIT_FETCH_USER_FILTER_COL_NAMES,
    payload,
  };
};
export const fetchingUserColNames = () => {
  return {
    type: FETCHING_USER_FILTER_COL_NAMES,
  };
};

export const fetchUserColNames = (data) => {
  return {
    type: USER_FILTER_COL_NAMES_FETCHED,
    data,
  };
};

export const userColNameFetchComplete = () => {
  return {
    type: USER_FILTER_COL_NAMES_FETCH_COMPLETE,
  };
};
export const userColNameFetchFailed = () => {
  return {
    type: USER_FILTER_COL_NAMES_FETCH_FAIL,
  };
};

export const initFecthUserFilterDetails = (payload, callback) => {
  return {
    type: INIT_FETCH_USER_FILTER_DETAILS,
    payload,
    callback,
  };
};

export const initSaveUserFilter = (payload, callback) => {
  return {
    type: INIT_SAVE_USER_FILTER,
    payload,
    callback,
  };
};

export const initSaveUserData = (payload, callback) => {
  return {
    type: INIT_SAVE_USER_DATA,
    payload,
    callback,
  };
};

export const userDataSaveSuccess = () => {
  return {
    type: USER_DATA_SAVE_SUCCESS,
  };
};

export const userDataSaveFailed = () => {
  return {
    type: USER_DATA_SAVE_FAILED,
  };
};

export function initGetTotalUsers(payload, callback) {
  return {
    type: INIT_GET_TOTAL_USERS,
    payload,
    callback,
  };
}

export function fetchingTotalUsers() {
  return {
    type: FETCHING_TOTAL_USERS,
  };
}

export function fetchTotalUsers(data) {
  return {
    type: FETCH_TOTAL_USERS,
    data,
  };
}

export function fetchFilteredTotalUsers(data) {
  return {
    type: FETCH_FILTERED_TOTAL_USERS,
    data,
  };
}

export function totalUsersFetchingComplete() {
  return {
    type: TOTAL_USERS_FETCHING_COMPLETE,
  };
}

export function initGetActiveUsers(payload) {
  return {
    type: INIT_GET_ACTIVE_USERS,
    payload,
  };
}

export function fetchingActiveUsers() {
  return {
    type: FETCHING_ACTIVE_USERS,
  };
}

export function fetchActiveUsers(data) {
  return {
    type: FETCH_ACTIVE_USERS,
    data,
  };
}

export function activeUsersFetchingComplete() {
  return {
    type: ACTIVE_USERS_FETCHING_COMPLETE,
  };
}

export const initGetUserLanguages = () => {
  return {
    type: INIT_GET_USER_LANGUAGES,
  };
};

export const userLanguagesFetching = () => {
  return {
    type: FETCHING_USER_LANGUAGES,
  };
};

export const fetchUserLanguages = (data) => {
  return {
    type: FETCH_USER_LANGUAGES,
    data,
  };
};

export const userLanguagesFetchComplete = () => {
  return {
    type: USER_LANGUAGES_FETCH_COMPLETE,
  };
};
export function initGetInactiveUsers(payload) {
  return {
    type: INIT_GET_INACTIVE_USERS,
    payload,
  };
}

export function fetchingInactiveUsers() {
  return {
    type: FETCHING_INACTIVE_USERS,
  };
}

export function fetchInactiveUsers(data) {
  return {
    type: FETCH_INACTIVE_USERS,
    data,
  };
}

export function inactiveUsersFetchingComplete() {
  return {
    type: INACTIVE_USERS_FETCHING_COMPLETE,
  };
}

export function initGetUsersList(payload) {
  return {
    type: INIT_GET_USERS_LIST,
    payload,
  };
}

export function fetchingUsersList() {
  return {
    type: FETCHING_USERS_LIST,
  };
}

export function fetchUsersList(data) {
  return {
    type: FETCH_USERS_LIST,
    data,
  };
}

export function usersListFetchingComplete() {
  return {
    type: USERS_LIST_FETCHING_COMPLETE,
  };
}

export const initUserAction = (payload, callback) => {
  return {
    type: INIT_USER_ACTION,
    payload,
    callback,
  };
};

export const userActionSuccess = () => {
  return {
    type: USER_ACTION_SUCCESS,
  };
};

export const userActionFailed = () => {
  return {
    type: USER_ACTION_FAILED,
  };
};

export const initGetCountryList = (payload) => {
  return {
    type: INIT_GET_COUNTRY_LIST,
    payload,
  };
};

export const fetchingCountryList = () => {
  return {
    type: FETCHING_COUNTRY_LIST,
  };
};

export const fetchCountryList = (data) => {
  return {
    type: FETCH_COUNTRY_LIST,
    data,
  };
};

export const countryListFetchingComplete = () => {
  return {
    type: COUNTRY_LIST_FETCH_COMPLETE,
  };
};

export function initGetClientList(payload) {
  return {
    type: INIT_GET_CLIENT_LIST,
    payload,
  };
}

export function fetchingClientList() {
  return {
    type: FETCHING_CLIENT_LIST,
  };
}

export function fetchClientList(data) {
  return {
    type: FETCH_CLIENT_LIST,
    data,
  };
}

export function clientListFetchingComplete() {
  return {
    type: CLIENT_LIST_FETCHING_COMPLETE,
  };
}

export const initUserManagementHeaderList = (payload,callback) => {
  return{
    type: INIT_USERMANAGEMENT_HEADER_LIST,
    payload,
    callback
  }
}

export const getUserManagementHeaderList = (data) => {
  return{
    type: FETCH_USERMANAGEMENT_HEADER_LIST,
    data
  }
}