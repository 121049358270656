import React from "react";
import "./style.scss";

const Loader = (props) => {
  return (
    <svg
      className="icons"
      viewBox="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <style type="text/css"></style>
      <g>
        <g transform="translate(18 18)">
          <animateTransform
            accumulate="none"
            additive="replace"
            attributeName="transform"
            calcMode="discrete"
            dur="10s"
            fill="freeze"
            keyTimes="0;.10299;.103;.23099;.231;.35899;.359;.48699;.487;.61499;.615;.74399;.744;.87199;.872;1"
            restart="always"
            type="translate"
            values="50,50;50,50;50.021,50.002;50.021,50.002;50.051,50;50.051,50;50.116,50.024;50.116,50.024;50.051,50.153;50.051,50.153;49.976,50.237;49.976,50.237;49.845,50.153;49.845,50.153;49.795,50.061;49.795,50.061"
          ></animateTransform>

          <animateTransform
            accumulate="none"
            additive="sum"
            attributeName="transform"
            calcMode="discrete"
            dur="10s"
            fill="freeze"
            keyTimes="0;.10299;.103;.23099;.231;.35899;.359;.48699;.487;.61499;.615;.74399;.744;.87199;.872;1"
            restart="always"
            type="rotate"
            values="0,0,0;0,0,0;45,0,0;45,0,0;89.998,0,0;89.998,0,0;134.997,0,0;134.997,0,0;179.998,0,0;179.998,0,0;-135.002,0,0;-135.002,0,0;-90.002,0,0;-90.002,0,0;-45.003,0,0;-45.003,0,0"
          ></animateTransform>

          <animateTransform
            accumulate="none"
            additive="sum"
            attributeName="transform"
            calcMode="discrete"
            dur="10s"
            fill="freeze"
            keyTimes="0;.35899;.359;.48699;.487;.61499;.615;.74399;.744;.87199;.872;1"
            restart="always"
            type="scale"
            values="1,1;1,1;.998,.998;.998,.998;1,1;1,1;.998,.998;.998,.998;1,1;1,1;.998,.998;.998,.998"
          ></animateTransform>

          <animateTransform
            accumulate="none"
            additive="sum"
            attributeName="transform"
            calcMode="discrete"
            dur="10s"
            fill="freeze"
            keyTimes="0;.10299;.103;.23099;.231;.35899;.359;.48699;.487;.61499;.615;.87199;.872;1"
            restart="always"
            type="translate"
            values="-32,-32;-32,-32;-32.05,-31.95;-32.05,-31.95;-32,-31.95;-32,-31.95;-31.9,-31.9;-31.9,-31.9;-31.95,-31.85;-31.95,-31.85;-31.85,-31.85;-31.85,-31.85;-31.85,-31.9;-31.85,-31.9"
          ></animateTransform>
          <g transform="translate(406.65 -229)">
            <path
              className="st0"
              d="M-354.6,261c0,1.6,0.6,3,1.8,4.2c1.2,1.2,2.6,1.8,4.2,1.8c1.6,0,3-0.6,4.2-1.8c1.2-1.1,1.8-2.5,1.8-4.2
				s-0.6-3.1-1.8-4.2c-1.1-1.2-2.5-1.8-4.2-1.8c-1.7,0-3.1,0.6-4.2,1.8C-354,257.9-354.6,259.3-354.6,261z"
              fill="#4A2A8A"
            />
            <path
              className="st1"
              d="M-406.6,261c0,1.6,0.6,3,1.8,4.2c1.2,1.2,2.6,1.8,4.2,1.8s3.1-0.6,4.2-1.8c1.2-1.2,1.8-2.6,1.8-4.2
				c0-1.7-0.6-3.1-1.8-4.2c-1.2-1.2-2.6-1.8-4.2-1.8s-3.1,0.6-4.2,1.8C-406.1,257.9-406.6,259.3-406.6,261z"
              fill="#D3D7DD"
            />
            <path
              className="st2"
              d="M-374.6,240.9c1.7,0,3.1-0.6,4.2-1.8s1.8-2.6,1.8-4.2c0-1.7-0.6-3.1-1.8-4.2c-1.2-1.2-2.6-1.8-4.2-1.8
				s-3.1,0.6-4.2,1.8c-1.2,1.2-1.8,2.6-1.8,4.2c0,1.7,0.6,3.1,1.8,4.2C-377.7,240.4-376.3,240.9-374.6,240.9z"
              fill="#3A8FF7"
            />
            <path
              className="st1"
              d="M-374.6,293c1.7,0,3.1-0.6,4.2-1.8s1.8-2.6,1.8-4.2s-0.6-3.1-1.8-4.2s-2.6-1.8-4.2-1.8c-1.6,0-3,0.6-4.2,1.8
				c-1.2,1.2-1.8,2.6-1.8,4.2s0.6,3.1,1.8,4.2C-377.7,292.4-376.3,293-374.6,293z"
              fill="#D3D7DD"
            />
            <path
              className="st3"
              d="M-360.4,246.8c1.2,1.2,2.6,1.8,4.2,1.8c1.7,0,3.1-0.6,4.2-1.8c1.2-1.2,1.8-2.6,1.8-4.2
				c0-1.7-0.6-3.1-1.8-4.2c-1.2-1.2-2.6-1.8-4.2-1.8c-1.6,0-3,0.6-4.2,1.8c-1.2,1.2-1.8,2.6-1.8,4.2
				C-362.2,244.2-361.6,245.6-360.4,246.8z"
              fill="#922EF5"
            />
            <path
              className="st1"
              d="M-388.8,275.1c-1.2-1.2-2.6-1.8-4.2-1.8c-1.6,0-3,0.6-4.2,1.8c-1.2,1.2-1.8,2.6-1.8,4.2c0,1.6,0.6,3,1.8,4.2
				c1.2,1.2,2.6,1.8,4.2,1.8c1.7,0,3.1-0.6,4.2-1.8c1.2-1.2,1.8-2.6,1.8-4.2C-387,277.7-387.6,276.3-388.8,275.1z"
              fill="#D3D7DD"
            />
            <path
              className="st4"
              d="M-397.2,246.8c1.2,1.2,2.6,1.8,4.2,1.8c1.7,0,3.1-0.6,4.2-1.8c1.2-1.2,1.8-2.6,1.8-4.2
				c0-1.7-0.6-3.1-1.8-4.2c-1.2-1.2-2.6-1.8-4.2-1.8c-1.6,0-3,0.6-4.2,1.8c-1.2,1.2-1.8,2.6-1.8,4.2
				C-399,244.2-398.4,245.6-397.2,246.8z"
              fill="#97D6FB"
            />
            <path
              className="st1"
              d="M-352,275.1c-1.2-1.2-2.6-1.8-4.2-1.8c-1.6,0-3,0.6-4.2,1.8c-1.2,1.2-1.8,2.6-1.8,4.2c0,1.6,0.6,3,1.8,4.2
				c1.2,1.2,2.6,1.8,4.2,1.8c1.7,0,3.1-0.6,4.2-1.8c1.2-1.2,1.8-2.6,1.8-4.2C-350.2,277.7-350.8,276.3-352,275.1z"
              fill="#D3D7DD"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Loader;
