import {
  INIT_GET_PH_RECORDS_EXPORT,
  FETCHING_PH_RECORDS_EXPORT,
  FETCH_PH_RECORDS_EXPORT,
  PH_RECORDS_EXPORT_FETCHING_COMPLETE,
  INIT_GET_PH_RECORDS_EXPORT_ALL,
  FETCHING_PH_RECORDS_EXPORT_ALL,
  FETCH_PH_RECORDS_EXPORT_ALL,
  PH_RECORDS_EXPORT_ALL_FETCHING_COMPLETE,
  INIT_GET_PH_COUNT,
  FETCHING_PH_COUNT,
  FETCH_PH_COUNT,
  PH_COUNT_FETCHING_COMPLETE,
  INIT_FETCH_PH_FILTER_COL_NAMES,
  FETCHING_PH_FILTER_COL_NAMES,
  PH_FILTER_COL_NAMES_FETCHED,
  PH_FILTER_COL_NAMES_FETCH_COMPLETE,
  PH_FILTER_COL_NAMES_FETCH_FAIL,
  INIT_SAVE_PH_FILTER,
  ASSIGN_INITAL_DATA,
  INIT_FETCH_PH_FILTERS,
  INIT_FETCH_PH_LIST_HEADER_NAMES,
  FETCHING_PH_LIST_HEADER_NAMES,
  PH_LIST_HEADER_NAMES_FETCHED,
  PH_LIST_HEADER_NAMES_FETCH_COMPLETE,
  PH_LIST_HEADER_NAMES_FETCH_FAIL,
  INIT_FETCH_PH_ROLE_BASED_ACTIONS,
  FETCHING_PH_ROLE_BASED_ACTIONS,
  PH_ROLE_BASED_ACTIONS_FETCHED,
  PH_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
  PH_ROLE_BASED_ACTIONS_FETCH_FAIL,
  INIT_DELETE_PH_FILTERS,
  INIT_PH_FILTER_FETCH,
  INIT_FETCH_PH_LIST,
  FETCHING_PH_LIST,
  PH_LIST_FETCHED,
  PH_LIST_FETCH_COMPLETE,
  PH_LIST_FETCH_FAIL,
} from "./actionTypes";
export const initGetPhRecordsExport = (payload) => {
  return {
    type: INIT_GET_PH_RECORDS_EXPORT,
    payload,
  };
};

export function fetchingPhRecodsExport() {
  return {
    type: FETCHING_PH_RECORDS_EXPORT,
  };
}

export function fetchPhRecordsExport(data) {
  return {
    type: FETCH_PH_RECORDS_EXPORT,
    data,
  };
}

export function phRecordsExportFetchingComplete() {
  return {
    type: PH_RECORDS_EXPORT_FETCHING_COMPLETE,
  };
}

export const initGetPhRecordsExportAll = (payload) => {
  return {
    type: INIT_GET_PH_RECORDS_EXPORT_ALL,
    payload,
  };
};

export function fetchingPhRecodsExportAll() {
  return {
    type: FETCHING_PH_RECORDS_EXPORT_ALL,
  };
}

export function fetchPhRecordsExportAll(data) {
  return {
    type: FETCH_PH_RECORDS_EXPORT_ALL,
    data,
  };
}

export function phRecordsExportAllFetchingComplete() {
  return {
    type: PH_RECORDS_EXPORT_ALL_FETCHING_COMPLETE,
  };
}
export const initFetchPhList = (payload) => {
  return {
    type: INIT_FETCH_PH_LIST,
    payload,
  };
};

export const fetchingPhList = () => {
  return {
    type: FETCHING_PH_LIST,
  };
};

export const fetchPhList = (data) => {
  return {
    type: PH_LIST_FETCHED,
    data,
  };
};

export const phListFetchComplete = () => {
  return {
    type: PH_LIST_FETCH_COMPLETE,
  };
};
export const phListFetchFailed = () => {
  return {
    type: PH_LIST_FETCH_FAIL,
  };
};

export const initGetPhCount = (payload) => {
  return {
    type: INIT_GET_PH_COUNT,
    payload,
  };
};

export function fetchingPhCount() {
  return {
    type: FETCHING_PH_COUNT,
  };
}

export function fetchPhCount(data) {
  return {
    type: FETCH_PH_COUNT,
    data,
  };
}

export function phCountFetchingComplete() {
  return {
    type: PH_COUNT_FETCHING_COMPLETE,
  };
}

export function assignInitalData() {
  return {
    type: ASSIGN_INITAL_DATA,
  };
}
export const initSavePHFilter = (payload, callback) => {
  return {
    type: INIT_SAVE_PH_FILTER,
    payload,
    callback,
  };
};

export const initFetchPhColNames = (payload) => {
  return {
    type: INIT_FETCH_PH_FILTER_COL_NAMES,
    payload,
  };
};
export const fetchingPhColNames = () => {
  return {
    type: FETCHING_PH_FILTER_COL_NAMES,
  };
};

export const fetchColNames = (data) => {
  return {
    type: PH_FILTER_COL_NAMES_FETCHED,
    data,
  };
};

export const phColNameFetchComplete = () => {
  return {
    type: PH_FILTER_COL_NAMES_FETCH_COMPLETE,
  };
};
export const phColNameFetchFailed = () => {
  return {
    type: PH_FILTER_COL_NAMES_FETCH_FAIL,
  };
};

export const initGetPhFilters = (payload, callback) => {
  return {
    type: INIT_FETCH_PH_FILTERS,
    payload,
    callback,
  };
};

export const initFetchPhListHeaderNames = (payload) => {
  return {
    type: INIT_FETCH_PH_LIST_HEADER_NAMES,
    payload,
  };
};
export const fetchingPhListHeaderNames = () => {
  return {
    type: FETCHING_PH_LIST_HEADER_NAMES,
  };
};

export const initDeletePhFilter = (payload, callback) => {
  return {
    type: INIT_DELETE_PH_FILTERS,
    payload,
    callback,
  };
};

export const fetchPhListHeaderNames = (data) => {
  return {
    type: PH_LIST_HEADER_NAMES_FETCHED,
    data,
  };
};

export const phListHeaderNameFetchComplete = () => {
  return {
    type: PH_LIST_HEADER_NAMES_FETCH_COMPLETE,
  };
};
export const phListHeaderNameFetchFailed = () => {
  return {
    type: PH_LIST_HEADER_NAMES_FETCH_FAIL,
  };
};

export const initFetchPhRoleBasedActions = (payload) => {
  return {
    type: INIT_FETCH_PH_ROLE_BASED_ACTIONS,
    payload,
  };
};
export const fetchingPhRoleBasedActions = () => {
  return {
    type: FETCHING_PH_ROLE_BASED_ACTIONS,
  };
};

export const fetchPhRoleBasedActions = (data) => {
  return {
    type: PH_ROLE_BASED_ACTIONS_FETCHED,
    data,
  };
};

export const phRoleBasedActionsFetchComplete = () => {
  return {
    type: PH_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
  };
};
export const phRoleBasedActionsFetchFailed = () => {
  return {
    type: PH_ROLE_BASED_ACTIONS_FETCH_FAIL,
  };
};

export const initFecthPhFilter = (payload, callback) => {
  return {
    type: INIT_PH_FILTER_FETCH,
    payload,
    callback,
  };
};
