import { fromJS } from "immutable";
import {FETCH_GRID_CATEGORY,FETCH_GRID_CATEGORY_LISTING_LIST,FETCH_GRID_DETAILS} from './actionsTypes';
export const initialState = fromJS({
  gridCategory: '',
  gridList:'',
  gridDetails:''
});

const reducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_GRID_CATEGORY:
            return state.set("gridCategory",action.data);
        case FETCH_GRID_CATEGORY_LISTING_LIST:
            return state.set("gridList",action.data);
        case FETCH_GRID_DETAILS:
            return state.set("gridDetails",action.data);
        default:
            return state;
    }
};

export default reducer;
