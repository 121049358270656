import {
  FETCH_UDLMAINTENANCE,
  FETCH_UDLMAINTENANCEDETAILS,
  FETCH_UDLMAINTENANCE_LIST,
  FETCH_UDLMAINTENANCEDETAILS_DATA,
  INIT_UDLMAINTENANCE_DETAILS,
  SAVE_UDLMAINTENANCEDETAILS,
} from "./actionTypes";

export const fetchUDLMaintenance = (payload) => {
  return {
    type: FETCH_UDLMAINTENANCE,
    payload,
  };
};
export const fetchUDLMaintenanceList = (data) => {
  return {
    type: FETCH_UDLMAINTENANCE_LIST,
    data,
  };
};
export const initUDLMaintenanceDetails = (payload, callback) => {
  return {
    type: INIT_UDLMAINTENANCE_DETAILS,
    payload,
    callback,
  };
};
export const fetchUDLMaintenanceDetailsdData = (data) => {
  return {
    type: FETCH_UDLMAINTENANCEDETAILS_DATA,
    data,
  };
};
export const fetchUDLMaintenanceDetails = (data) => {
  return {
    type: FETCH_UDLMAINTENANCEDETAILS,
    data,
  };
};

export const saveUDLDetails = (payload, callback) => {
  return {
    type: SAVE_UDLMAINTENANCEDETAILS,
    payload,
    callback,
  };
};
