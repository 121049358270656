import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { storage } from "../../config/storage";
import * as workerTimers from "worker-timers";
import { initLogOut } from "../../containers/Login/store/actions";
import { RedirectURL, Tenant } from "../../config";
let handleIdleTimer = null;
let setTimeoutFunc = workerTimers.setTimeout;
let clearTimeoutFunc = workerTimers.clearTimeout;
let setIntervalFunc = workerTimers.setInterval;
let clearIntervalFunc = workerTimers.clearInterval;

const Timer = (props) => {
  const [idleTime, setIdleTime] = useState(120);
  const [sessionTime, setSessionTime] = useState(-1);

  useEffect(() => {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails"));
    const userLogoutTimeInSecs = sessionDetails.tokenExpireTime * 60;
    setSessionTime(userLogoutTimeInSecs);
    const handleIdleUser = () => {
      setIdleTime(120);
      setSessionTime(userLogoutTimeInSecs);
    };
    document.addEventListener("click", handleIdleUser);
    document.addEventListener("keypress", handleIdleUser);
    document.addEventListener("mouseover", handleIdleUser);
    handleIdleUser();
    return () => {
      document.removeEventListener("click", handleIdleUser);
      document.removeEventListener("keypress", handleIdleUser);
      document.removeEventListener("mouseover", handleIdleUser);
    };
  }, []);

  useEffect(() => {
    if (handleIdleTimer) {
      handleIdleTimer = clearTimeoutFunc(handleIdleTimer);
    }
    if (idleTime > 0) {
      handleIdleTimer = setTimeoutFunc(() => {
        setIdleTime(idleTime - 1);
      }, 1000);
    }
    if (sessionTime === 0) {
      const ssoLoginDetails =
        JSON.parse(storage.getItem("ssoLoginDetails")) || "NON-SSO";
      const SSOClientUser = sessionStorage.getItem("SSOClientUser");
      let redirectUri = "";
      props.logout(() => {
        if (ssoLoginDetails.loginType === "SSO" && SSOClientUser !== "Client") {
          redirectUri =
            "https://login.microsoftonline.com/" +
            Tenant +
            "/oauth2/logout?post_logout_redirect_uri=" +
            RedirectURL;
        }
        storage.removeItem("ssoLoginDetails");
        sessionStorage.setItem("SSOClientUser", "");
        window.open(redirectUri, "_self");
      });
    }
    if (idleTime === 0) {
      if (sessionTime > -1) {
        const handleSessionTime = setIntervalFunc(() => {
          setSessionTime(sessionTime - 1);
        }, 1000);
        return () => clearIntervalFunc(handleSessionTime);
      }
    }
  }, [idleTime, sessionTime, props]);

  return <></>;
};

Timer.propTypes = {
  logout: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (callback) => {
      dispatch(initLogOut(callback));
    },
  };
};
const withConnect = connect(null, mapDispatchToProps);

export default withConnect(Timer);
