import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { store } from "react-notifications-component";
import { postRequestDetails } from "../../../config/requestHeaders";
import generateKey from "../../../config/generateKey";
import {
  AlertGetAlertList,
  AlertGetClientAlertList,
  AlertGetAlertDetails,
  AlertGetClientAlertDetails,
  AlertSaveAlertDetails,
  AlertSaveClientAlertDetails,
} from "../../../config/APINames";
import {
  FETCH_ALERT_LIST,
  FETCH_ALERT_DETAILS,
  SAVE_ALERT_DETAILS,
} from "./actionTypes";
import { buildNotification } from "../../../config/notification";
import { AtcatAdminName } from "../../../config/index";
import { loginFailed } from "../../Login/store/actions";
import { fetchStatus } from "./actions";
export default function* watchAlertListing() {
  yield all([
    takeLatest(FETCH_ALERT_LIST, initGetAlertList),
    takeLatest(FETCH_ALERT_DETAILS, initGetAlertDetails),
    takeLatest(SAVE_ALERT_DETAILS, initSaveAlertDetails),
  ]);
}
function* initGetAlertList(action) {
  const { payload, callback } = action || {};
  try {
    yield put(fetchStatus(true));
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      payload.clientName.toUpperCase() === AtcatAdminName
        ? AlertGetAlertList
        : AlertGetClientAlertList,
      requestDetails
    );
    response = response.data;
    yield put(fetchStatus(false));
    if(response.length >=0){
      callback(response)
    }
    else{
      const notification = buildNotification({
        message: generateKey(response),
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    yield put(fetchStatus(false));
    yield put(loginFailed(error));
  }
}

function* initGetAlertDetails(action) {
  const { payload, callback } = action || {};
  try {
    yield put(fetchStatus(true));
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      payload.clientName.toUpperCase() === AtcatAdminName
        ? AlertGetAlertDetails
        : AlertGetClientAlertDetails,
      requestDetails
    );
    response = response.data;
    yield put(fetchStatus(false));
    callback(response);
  } catch (error) {
    yield put(fetchStatus(false));
    yield put(loginFailed(error));
  }
}

function* initSaveAlertDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      payload.clientName.toUpperCase() === AtcatAdminName
        ? AlertSaveAlertDetails
        : AlertSaveClientAlertDetails,
      requestDetails
    );
    response = response.data;
    if (response === "Alert details saved successfully") {
      const notification = buildNotification({
        message: generateKey(response),
        type: "success",
      });
      store.addNotification({
        ...notification,
      });
      callback();
    } else {
      const notification = buildNotification({
        message: generateKey(response),
        type: "danger",
      });
      store.addNotification({
        ...notification,
      });
    }
  } catch (error) {
    yield put(loginFailed(error));
  }
}
