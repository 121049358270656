import {
  INIT_SAVE_CLIENT,
  CLIENT_SAVE_SUCCESS,
  CLIENT_SAVE_FAILED,
  SET_TEMP_DATA,
} from "./actionTypes";

export const initSaveClient = (payload) => {
  return {
    type: INIT_SAVE_CLIENT,
    payload,
  };
};

export const clientSaveSuccess = () => {
  return {
    type: CLIENT_SAVE_SUCCESS,
  };
};

export const clientSaveFailed = () => {
  return {
    type: CLIENT_SAVE_FAILED,
  };
};

export const setTempData = () => {
  return {
    type: SET_TEMP_DATA,
  };
};
