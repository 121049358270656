import React from "react";
import "./style.scss";

const Radio = (props) => {
  return (
    <>
      <input
        className="radio"
        type="radio"
        id={props.id}
        key={props.key}
        name={props.name}
        checked={props.checked}
        value={props.value}
        onChange={props.handleCheck}
        aria-label={props.label}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </>
  );
};

export default Radio;
