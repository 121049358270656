import { fromJS } from "immutable";
import {
  INIT_VENDOR_ANALYSIS_REPORT_LIST,
  FETCH_VENDOR_ANALYSIS_REPORT_LIST,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingVendorAnalysisReportList: false,
  vendorAnalysisReportList: {},
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_VENDOR_ANALYSIS_REPORT_LIST: {
      return state.set("fetchingVendorAnalysisReportList", true);
    }
    case FETCH_VENDOR_ANALYSIS_REPORT_LIST: {
      return state
        .set("vendorAnalysisReportList", action.data)
        .set("fetchingVendorAnalysisReportList", false);
    }

    default:
      return state;
  }
};

export default reducer;
