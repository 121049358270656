export const INIT_GET_CLIENTS_LIST = "INIT_GET_CLIENTS_LIST";
export const FETCHING_CLIENTS_LIST = "FETCHING_CLIENTS_LIST";
export const FETCH_CLIENTS_LIST = "FETCH_CLIENTS_LIST";
export const CLIENTS_LIST_FETCHING_COMPLETE = "CLIENTS_LIST_FETCHING_COMPLETE";

export const INIT_GET_TOTAL_CLIENTS = "INIT_GET_TOTAL_CLIENTS";
export const FETCH_TOTAL_CLIENTS = "FETCH_TOTAL_CLIENTS";
export const FETCH_FILTERED_TOTAL_CLIENTS = "FETCH_FILTERED_TOTAL_CLIENTS";
export const TOTAL_CLIENTS_FETCHING_COMPLETE =
  "TOTAL_CLIENTS_FETCHING_COMPLETE";

export const INIT_GET_ACTIVE_CLIENTS = "INIT_GET_ACTIVE_CLIENTS";
export const FETCH_ACTIVE_CLIENTS = "FETCH_ACTIVE_CLIENTS";

export const INIT_GET_INACTIVE_CLIENTS = "INIT_GET_INACTIVE_CLIENTS";
export const FETCH_INACTIVE_CLIENTS = "FETCH_INACTIVE_CLIENTS";

export const INIT_CLIENT_ACTION = "INIT_CLIENT_ACTION";
export const CLIENT_ACTION_SUCCESS = "CLIENT_ACTION_SUCCESS";
export const CLIENT_ACTION_FAILED = "CLIENT_ACTION_FAILED";

export const INIT_DELETE_CLIENT = "INIT_DELETE_CLIENT";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_DELETE_FAILED = "CLIENT_DELETE_FAILED";

export const INIT_GET_COUNTRIES = "INIT_GET_COUNTRIES";
export const FETCHING_COUNTRIES = "FETCHING_COUNTRIES";
export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const COUNTRIES_FETCH_COMPLETE = "COUNTRIES_FETCH_COMPLETE";

export const INIT_GET_UDL_DETAILS = "INIT_GET_UDL_DETAILS";
export const FETCHING_UDL_DETAILS = "FETCHING_UDL_DETAILS";
export const FETCH_UDL_DETAILS = "FETCH_UDL_DETAILS";
export const UDL_DETAILS_FETCH_COMPLETE = "UDL_DETAILS_FETCH_COMPLETE";

export const INIT_GET_ATCAT_VERSION = "INIT_GET_ATCAT_VERSION";
export const FETCHING_ATCAT_VERSION = "FETCHING_ATCAT_VERSION";
export const FETCH_ATCAT_VERSION = "FETCH_ATCAT_VERSION";
export const ATCAT_VERSION_FETCH_COMPLETE = "ATCAT_VERSION_FETCH_COMPLETE";

export const INIT_GET_DEFAULT_VALUES = "INIT_GET_DEFAULT_VALUES";
export const FETCHING_DEFAULT_VALUES = "FETCHING_DEFAULT_VALUES";
export const DEFAULT_VALUES_FETCH_COMPLETE = "DEFAULT_VALUES_FETCH_COMPLETE";

export const INIT_GET_CLIENT_BY_NAME = "INIT_GET_CLIENT_BY_NAME";
export const FETCHING_CLIENT_BY_NAME = "FETCHING_CLIENT_BY_NAME";
export const CLIENT_BY_NAME_FETCH_COMPLETE = "CLIENT_BY_NAME_FETCH_COMPLETE";

export const INIT_SAVE_CLIENT_DATA = "INIT_SAVE_CLIENT_DATA";
export const CLIENT_DATA_SAVE_SUCCESS = "CLIENT_DATA_SAVE_SUCCESS";
export const CLIENT_DATA_SAVE_FAILED = "CLIENT_DATA_SAVE_FAILED";

export const INIT_GET_CLIENT_LANGUAGES = "INIT_GET_CLIENT_LANGUAGES";

export const INIT_GET_SAVED_REPORTS_LIST = "INIT_GET_SAVED_REPORTS_LIST";
export const FETCHING_SAVED_REPORTS_LIST = "FETCHING_SAVED_REPORTS_LIST";
export const SAVED_REPORTS_LIST_FETCHING_COMPLETE =
  "SAVED_REPORTS_LIST_FETCHING_COMPLETE";

export const INIT_UPLOAD_REPORTS = "INIT_UPLOAD_REPORTS";
export const REPORTS_UPLOAD_SAVE_SUCCESS = "REPORTS_UPLOAD_SAVE_SUCCESS";
export const REPORTS_UPLOAD_SAVE_FAILED = "REPORTS_UPLOAD_SAVE_FAILED";
