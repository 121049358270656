import React, { Suspense } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { storage } from "../../../config/storage";
import { getComponentByPath } from "../../../helper/componentGetter";
import { selectRoute } from "../../../store/selectors";
import Loader from "../../SVG/Loader";
const MainContent = (props) => {
  const currentPath = storage.getItem("currPath");
  let path = currentPath ? JSON.parse(currentPath).pathname : false;

  const Component = getComponentByPath(path, props.isAuthenticated);

  return (
    <section
      className={`main-content ${
        props.isAuthenticated ? "main-content-authenticated" : ""
      }`}
      style={{
        maxWidth: `calc(100vw - ${props.navWidth}px)`,
        marginLeft: props.navWidth,
      }}
    >
      <div className="full-container">
        <Suspense fallback={<Loader />}>
          <Component {...props.ccProps} />
        </Suspense>
      </div>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentRoute: selectRoute(),
});
const mapDispatchToProps = (dispatch) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MainContent);
