import { fromJS } from "immutable";
import {
  INIT_GET_GENERAL_REPORTS_LIST,
  FETCH_GENERAL_REPORTS_LIST,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingGeneralReportsList: false,
  generalReportsList: {},
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_GET_GENERAL_REPORTS_LIST: {
      return state.set("fetchingGeneralReportsList", true);
    }
    case FETCH_GENERAL_REPORTS_LIST: {
      return state
        .set("generalReportsList", action.data)
        .set("fetchingGeneralReportsList", false);
    }

    default:
      return state;
  }
};

export default reducer;
