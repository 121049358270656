import {
    INIT_RENEW_TOKEN,
} from "./actionTypes";

export const initRenewToken = (payload, callback) => {
    return {
        type: INIT_RENEW_TOKEN,
        payload,
        callback,
    };
};
