import React from "react";
import synopsLogo from "../../../assets/images/layout/footer/synops.svg";
import acclogo from "../../../assets/images/layout/footer/acc-logo.png";
import Modal from "../../Modal";
import { useTranslation } from "react-i18next";
import generateKey from "../../../config/generateKey";
import { AppVersion,PackageReleaseVersion } from "../../../config";

const Footer = (props) => {
    const { t } = useTranslation();
    const releaseVersion = PackageReleaseVersion!==undefined && PackageReleaseVersion!=="" ?`${AppVersion}.${PackageReleaseVersion}`:AppVersion; 
    return (
        <>
            <Modal
                title={
                    props.children !== undefined && t(generateKey(props.children.title))
                }
                centered
                scrollable
                maxWidth={"30rem"}
                show={props.modalFlag}
                onClose={props.handleModal}
                children={props.children !== undefined && props.children.content}
                preventOutterClickClose
            />
            <footer className="footer">
                <div className="acc-logo">
                    <img src={acclogo}
                        alt={t("atcat.accentureLogo")} />
                </div>
                <p className="copyright">
                    {t("atcat.accentureAllRightsReserved", { latestVersion: releaseVersion,year:props.footerValue })}
                </p>

                <ul className="footer-links">
                    <li
                        onClick={() => {
                            props.handleOnClick("Terms of Use");
                        }}
                    >
                        {t("atcat.termsOfUse")}
                    </li>
                    <li
                        onClick={() => {
                            props.handleOnClick("Privacy Statement");
                        }}
                    >
                        {t("atcat.privacyStatement")}
                    </li>
                    <li
                        onClick={() => {
                            props.handleOnClick("Contact Support");
                        }}
                    >
                        {t("atcat.contactSupport")}
                    </li>
                </ul>
                <div className="synops">
                    <img src={synopsLogo} className="synops-logo" alt="synops logo" />
                    <span className="synops-name">SynOps</span>
                    <span className="synops-desp">For Finance and Accounting</span>
                </div>
            </footer>
        </>
    );
};

export default Footer;
