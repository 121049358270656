import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, RadioButton, Select, Button } from "../../../../../components";
import { AtcatAdminName } from "../../../../../config/index";

const SwitchClient = (props) => {
  const { t } = useTranslation();
  const [active, setActive] = useState("Switch to ATCAT");
  const [selectedOption, setSelectedOption] = useState("");
  let option = [];
  props.clientList.length > 0 &&
    props.clientList.forEach((item) => {
      option.push({ id: item, value: item });
    });

  useEffect(() => {
    if (
      props.authDetails.clientName !== undefined &&
      props.authDetails.clientName.toUpperCase() !== AtcatAdminName
    ) {
      setSelectedOption(props.authDetails.clientName);
      setActive("Switch to ATCAT");
    }
    if (props.authDetails.clientName.toUpperCase() === AtcatAdminName) {
      setActive("Switch to Client");
    }
  }, []);
  return (
    <>
      <Modal
        title={t("atcat.switchClient")}
        centered
        scrollable
        maxWidth={"30em"}
        show={props.modalFlag}
        preventOutterClickClose={true}
        onClose={() => {
          props.handleClose();
        }}
      >
        <div role="group" className="switch-client" aria-label="Switch Client">
          <RadioButton
            name="Switch Client"
            label={t("atcat.switchAtcat")}
            id={"Switch to ATCAT"}
            checked={active === "Switch to ATCAT" ? true : false}
            handleCheck={() => {
              setActive("Switch to ATCAT");
            }}
          />
          <RadioButton
            name="Switch Client"
            label={t("atcat.switchToClient")}
            id={"Switch to Client"}
            checked={active === "Switch to Client" ? true : false}
            handleCheck={() => {
              setActive("Switch to Client");
            }}
          />
          <div className="switch-client-select">
            <Select
              options={option}
              onChangeHandler={(item) => setSelectedOption(item.target.value)}
              isSearchable
              value={selectedOption}
              disabled={active === "Switch to Client" ? false : true}
            />
          </div>
        </div>
        <div className="modal-button">
          <Button
            customClass="primary-button"
            handleClick={() => {
              props.handleClose();
            }}
            ariaLabel={t("atcat.cancel")}
            label={t("atcat.cancel")}
          />
          <Button
            customClass="default-button"
            handleClick={() => {
              props.handleClose();
              props.handleSwitchClient({
                clientName: selectedOption,
                option: active,
              });
            }}
            ariaLabel={t("atcat.switch")}
            label={t("atcat.switch")}
          />
        </div>
      </Modal>
    </>
  );
};
export default SwitchClient;
