import { all, takeLatest, put } from "redux-saga/effects";
import { setRoute } from "./actions";
import { INIT_SET_CURRENT_ROUTE } from "./actionTypes";
import { storage } from "../config/storage";
export default function* watchCommonAPI() {
  yield all([takeLatest(INIT_SET_CURRENT_ROUTE, initSetCurrentRoute)]);
}

function* initSetCurrentRoute(action) {
  const { payload } = action;

  try {
    yield storage.setItem("currPath", JSON.stringify(payload));
    yield put(setRoute(payload));
  } catch (error) {
  }
}
