import { all, call, put, takeLatest } from "redux-saga/effects";
import { postRequestDetails } from "../../../config/requestHeaders";
import axios from "../../../config/axiosCall";
import { Error_Msg } from "../../../config/index";
import {
  INIT_DELETE_JOB,
  INIT_GET_JOBS_LIST,
  INIT_GET_LOGS_FOR_DUPS_AND_DATALOAD,
  INIT_RUN_JOB,
  INIT_GET_JOB_DETAILS,
  INIT_GET_SERVER_DATE_TIME,
  INIT_SAVE_ADD_JOB,
  INIT_UPDATE_JOB_DETAILS,
} from "./actionTypes";
import { storage } from "../../../config/storage";
import {
  fetchingJobsList,
  fetchJobsList,
  JobsListFetchingComplete,
  jobDeleteFailed,
  jobDeleteSuccess,
  jobRunSuccess,
  jobRunFailed,
  fetchingLogsForDupsAndDataLoad,
  LogsForDupsAndDataLoadFetchingComplete,
  fetchLogsForDupsAndDataLoad,
  fetchingJobDetails,
  jobDetailsFetchingComplete,
  fetchServerDateTime,
  serverDateTimeFetchingComplete,
  addJobSuccess,
  addJobFailed,
  updateJobSuccess,
  updateJobFailed,
} from "./actions";
import {
  JobSchedularListofAllScheduleJob,
  JobSchedularDeleteScheduledJob,
  JobSchedularSubmitFunctionalityDups,
  JobSchedularLogsForDupsAndDataload,
  JobSchedularGetScheduledJobDetails,
  JobSchedularServerTime,
  JobSchedularAddScheduledJob,
  JobSchedularUpdateScheduledJobDetails,
} from "../../../config/APINames";
import generateKey from "../../../config/generateKey";
import { DeleteJobMsg, RunJobMsg } from "../../../config";
import { generateNotification } from "../../../helper/generateNotification";

export default function* watchLogin() {
  yield all([
    takeLatest(INIT_GET_JOBS_LIST, initGetJobsList),
    takeLatest(INIT_DELETE_JOB, initDeleteJobSaga),
    takeLatest(INIT_RUN_JOB, initRunJobSaga),
    takeLatest(
      INIT_GET_LOGS_FOR_DUPS_AND_DATALOAD,
      initGetLogsForDupsAndDataloadSaga
    ),
    takeLatest(INIT_GET_JOB_DETAILS, initGetJobDetails),
    takeLatest(INIT_GET_SERVER_DATE_TIME, initGetServerDateTime),
    takeLatest(INIT_SAVE_ADD_JOB, initSaveAddJob),
    takeLatest(INIT_UPDATE_JOB_DETAILS, initUpdateJobDetails),
  ]);
}

function* initGetJobsList(action) {
  const { payload } = action || {};
  yield put(fetchingJobsList());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      JobSchedularListofAllScheduleJob,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(fetchJobsList(response.data));
    }
    response = response.data;
    yield put(JobsListFetchingComplete());
  } catch (error) {
    yield put(JobsListFetchingComplete());
  }
}

function* initDeleteJobSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      JobSchedularDeleteScheduledJob,
      requestDetails
    );
    if (response.data === DeleteJobMsg) {
      yield put(jobDeleteSuccess());
      generateNotification(generateKey(response.data), "success");
      callback && callback();
    } else {
      generateNotification(generateKey(response.data), "danger");
    }
  } catch (error) {
    yield put(jobDeleteFailed());

    generateNotification(generateKey(error), "danger");
  }
}

function* initRunJobSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      JobSchedularSubmitFunctionalityDups,
      requestDetails
    );
    if (response.data === RunJobMsg) {
      yield put(jobRunSuccess());
      generateNotification(generateKey(response.data), "success");
      callback && callback();
    } else {
      generateNotification(generateKey(response.data), "danger");
    }
  } catch (error) {
    yield put(jobRunFailed());
    generateNotification(generateKey(error), "danger");
  }
}

function* initGetLogsForDupsAndDataloadSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName } = sessionDetails || {};
  const { payload } = action || {};
  yield put(fetchingLogsForDupsAndDataLoad());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userName,
    });
    const response = yield call(
      axios,
      JobSchedularLogsForDupsAndDataload,
      requestDetails
    );
    if (response && response.status === 200) {
      if (typeof response.data === "object")
        yield put(fetchLogsForDupsAndDataLoad(response.data));
      else if (response.data === Error_Msg) {
        generateNotification(generateKey(response.data), "warning");
      }
    }
    yield put(LogsForDupsAndDataLoadFetchingComplete());
  } catch (error) {
    yield put(LogsForDupsAndDataLoadFetchingComplete());
  }
}

function* initGetJobDetails(action) {
  const { payload, callback } = action || {};
  yield put(fetchingJobDetails());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      JobSchedularGetScheduledJobDetails,
      requestDetails
    );
    if (response && response.status === 200) {
      callback && callback(response.data.getJobDetailsOfScheduleID[0]);
      yield put(jobDetailsFetchingComplete());
    }
  } catch (error) {
    yield put(jobDetailsFetchingComplete());
  }
}

function* initGetServerDateTime(action) {
  const { callback } = action || {};
  yield put(fetchServerDateTime());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {});
    let response = yield call(axios, JobSchedularServerTime, requestDetails);
    if (response && response.status === 200) {
      callback && callback(response.data);
      yield put(fetchServerDateTime(response.data));
    }
    response = response.data;
    yield put(serverDateTimeFetchingComplete());
  } catch (error) {
    yield put(serverDateTimeFetchingComplete());
  }
}

function* initSaveAddJob(action) {
  const { payload, callback } = action || {};
  yield put(addJobSuccess());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      JobSchedularAddScheduledJob,
      requestDetails
    );
    if (response.data === "Job added successfully") {
      yield put(addJobSuccess());
      callback && callback(response);
    } else {
      generateNotification(generateKey(response.data), "danger");
    }
  } catch (error) {
    yield put(addJobFailed());
  }
}

function* initUpdateJobDetails(action) {
  const { payload, callback } = action || {};
  yield put(updateJobSuccess());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      JobSchedularUpdateScheduledJobDetails,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(updateJobSuccess());
      callback && callback(response);
    }
  } catch (error) {
    yield put(updateJobFailed());
  }
}
