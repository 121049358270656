import { store } from "react-notifications-component";
import { buildNotification } from "../config/notification";

export const generateNotification = (message, alertType) => {
  const notification = buildNotification({
    message: message,
    type: alertType,
    id: "Alert",
  });

  store.addNotification({
    ...notification,
  });
};
