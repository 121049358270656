import { fromJS } from "immutable";
import {
  INIT_SAVE_CLIENT,
  CLIENT_SAVE_SUCCESS,
  CLIENT_SAVE_FAILED,
  SET_TEMP_DATA,
} from "./actionTypes";

export const initialState = fromJS({
  savingClient: false,
  tempOptions: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_SAVE_CLIENT: {
      return state.set("savingClient", true);
    }
    case CLIENT_SAVE_SUCCESS: {
      return state.set("savingClient", false);
    }
    case CLIENT_SAVE_FAILED: {
      return state.set("savingClient", false);
    }
    case SET_TEMP_DATA: {
      return state.set("tempOptions", [
        { id: "1", value: "EMEA" },
        { id: "2", value: "Growth Markets" },
        { id: "3", value: "North America" },
      ]);
    }
    default:
      return state;
  }
};

export default reducer;
