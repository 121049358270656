import {
    INIT_GET_CLIENT_QUEUE_SUMMARY, INIT_GET_CLIENT_QUEUE_SUMMARY_FAILED, FETCH_GET_CLIENT_QUEUE_SUMMARY_LIST
} from "./actionTypes";

export const initGetClientQueueSummary = (payload) => {
  return {
      type: INIT_GET_CLIENT_QUEUE_SUMMARY,
    payload,    
  };
};
export const initGetClientQueueSummaryFailed = () => {
    return {
        type: INIT_GET_CLIENT_QUEUE_SUMMARY_FAILED
    };
}
export const fetchClientQueueSummaryList = (data) => {
    return {
        type: FETCH_GET_CLIENT_QUEUE_SUMMARY_LIST,
        data
    };
}
