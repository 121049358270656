import {
  FETCH_CLIENTS_LIST,
  INIT_GET_QUEUED_JOB_LIST,
  FETCH_QUEUED_JOB_LIST,
  INIT_GET_QUEUE_SUSPEND_STATE,
  FETCH_QUEUE_SUSPEND_STATE,
  INIT_GET_SUSPENDED_QUEUE,
  INIT_GET_RESUMED_QUEUE,
  INIT_DELETE_QUEUE,
  INIT_SAVE_REORDERED_JOBS,
  FETCH_SAVE_REORDERED_JOBS_COMPLETE,
  FETCH_RESUMEQUEUE_COMPLETE,
  FETCH_SUSPENDQUEUE_COMPLETE,
  INIT_GET_CLIENT_QUEUEDJOB_LISTING,
  FETCH_CLIENT_QUEUEDJOB_LISTING
} from "./actionTypes";

export function fetchClientsList(data) {
  return {
    type: FETCH_CLIENTS_LIST,
    data,
  };
}

export function initGetQueuedJobListing(payload, callback) {
  return {
    type: INIT_GET_QUEUED_JOB_LIST,
    payload,
    callback,
  };
}

export function fetchQueuedJobList(data) {
  return {
    type: FETCH_QUEUED_JOB_LIST,
    data,
  };
}

export function initGetQueueSuspendState(payload) {
  return {
    type: INIT_GET_QUEUE_SUSPEND_STATE,
    payload,
  };
}

export function fetchQueueSuspendState(data) {
  return {
    type: FETCH_QUEUE_SUSPEND_STATE,
    data,
  };
}

export function initGetSuspendedQueue(payload, callback) {
  return {
    type: INIT_GET_SUSPENDED_QUEUE,
    payload,
    callback,
  };
}

export function initGetResumedQueue(payload, callback) {
  return {
    type: INIT_GET_RESUMED_QUEUE,
    payload,
    callback,
  };
}

export function initDeleteQueue(payload, callback) {
  return {
    type: INIT_DELETE_QUEUE,
    payload,
    callback,
  };
}

export function initSaveReorderedJobs(payload, callback) {
  return {
    type: INIT_SAVE_REORDERED_JOBS,
    payload,
    callback,
  };
}

export function fetchSaveReorderComplete() {
  return {
    type: FETCH_SAVE_REORDERED_JOBS_COMPLETE,
  };
}

export function fetchResumedQueueComplete() {
  return {
    type: FETCH_RESUMEQUEUE_COMPLETE,
  };
}

export function fetchSuspendQueueComplete() {
  return {
    type: FETCH_SUSPENDQUEUE_COMPLETE,
  };
}

export function initGetClientQueuedJobListing(payload,callback) {
  return {
    type: INIT_GET_CLIENT_QUEUEDJOB_LISTING,
    payload,
    callback
  };
}


export function fetchClientQueuedJobList(data) {
  return {
    type: FETCH_CLIENT_QUEUEDJOB_LISTING,
    data,
  };
}
