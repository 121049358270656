import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { GDPRMessage } from "../../../config/APINames";
import { INIT_GDPRMSG } from "./actionTypes";
import { loginFailed } from "../../Login/store/actions";
export default function* watchGDPR() {
  yield all([
      takeLatest(INIT_GDPRMSG, initGDPRSaga)
  ]);
}
function* initGDPRSaga(action) {
  const { payload, callback } = action || {};
  try {
      const requestDetails = postRequestDetails();
      yield (requestDetails.data = {
          ...payload
    });
      let response = yield call(axios, GDPRMessage, requestDetails);
    response = response.data;
    callback(response);
  } catch (error) {
    yield put(loginFailed(error));
  }
}

