export const INIT_FETCH_EXCEPTION_FILTER_COL_NAMES =
  "INIT_FETCH_EXCEPTION_FILTER_COL_NAMES";
export const FETCHING_EXCEPTION_FILTER_COL_NAMES =
  "FETCHING_EXCEPTION_FILTER_COL_NAMES";
export const EXCEPTION_FILTER_COL_NAMES_FETCHED =
  "EXCEPTION_FILTER_COL_NAMES_FETCHED";
export const EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE =
  "EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE";
export const EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL =
  "EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL";

export const INIT_SAVE_EXCEPTION_FILTER = "INIT_SAVE_EXCEPTION_FILTER";

export const ASSIGN_INITAL_DATA = "ASSIGN_INITAL_DATA";

export const INIT_FETCH_EXCEPTION_FILTER_NAMES =
  "INIT_FETCH_EXCEPTION_FILTER_NAMES";

export const INIT_GET_EXCEPTION_COUNT = "INIT_GET_EXCEPTION_COUNT";
export const FETCHING_EXCEPTION_COUNT = "FETCHING_EXCEPTION_COUNT";
export const FETCH_EXCEPTION_COUNT = "FETCH_EXCEPTION_COUNT";
export const EXCEPTION_COUNT_FETCHING_COMPLETE =
  "EXCEPTION_COUNT_FETCHING_COMPLETE";

export const INIT_FETCH_EXCEPTION_LIST = "INIT_FETCH_EXCEPTION_LIST";
export const FETCHING_EXCEPTION_LIST = "FETCHING_EXCEPTION_LIST";
export const EXCEPTION_LIST_FETCHED = "EXCEPTION_LIST_FETCHED";
export const EXCEPTION_LIST_FETCH_COMPLETE = "EXCEPTION_LIST_FETCH_COMPLETE";
export const EXCEPTION_LIST_FETCH_FAIL = "EXCEPTION_LIST_FETCH_FAIL";

export const INIT_FETCH_EXCEPTION_FILTER_DETAILS =
  "INIT_FETCH_EXCEPTION_FILTER_DETAILS";

export const INIT_DELETE_EXCEPTION_FILTER = "INIT_DELETE_EXCEPTION_FILTER";

export const INIT_GET_EXCEPTION_RECORDS_EXPORT =
  "INIT_GET_EXCEPTION_RECORDS_EXPORT";
export const FETCHING_EXCEPTION_RECORDS_EXPORT =
  "FETCHING_EXCEPTION_RECORDS_EXPORT";
export const FETCH_EXCEPTION_RECORDS_EXPORT = "FETCH_EXCEPTION_RECORDS_EXPORT";
export const EXCEPTION_RECORDS_EXPORT_FETCHING_COMPLETE =
  "EXCEPTION_RECORDS_EXPORT_FETCHING_COMPLETE";

export const INIT_GET_EXCEPTION_RECORDS_EXPORT_ALL =
  "INIT_GET_EXCEPTION_RECORDS_EXPORT_ALL";
export const FETCHING_EXCEPTION_RECORDS_EXPORT_ALL =
  "FETCHING_EXCEPTION_RECORDS_EXPORT_ALL";
export const FETCH_EXCEPTION_RECORDS_EXPORT_ALL =
  "FETCH_EXCEPTION_RECORDS_EXPORT_ALL";
export const EXCEPTION_RECORDS_EXPORT_ALL_FETCHING_COMPLETE =
  "EXCEPTION_RECORDS_EXPORT_ALL_FETCHING_COMPLETE";

export const INIT_GET_RELEASE_EXCEPTION = "INIT_GET_RELEASE_EXCEPTION";
export const FETCHING_RELEASE_EXCEPTION = "FETCHING_RELEASE_EXCEPTION";
export const FETCH_RELEASE_EXCEPTION = "FETCH_RELEASE_EXCEPTION";
export const RELEASE_EXCEPTION_FETCHING_COMPLETE =
  "RELEASE_EXCEPTION_FETCHING_COMPLETE";

export const INIT_GET_INVESTIGATION_TYPE = "INIT_GET_INVESTIGATION_TYPE";
export const FETCHING_INVESTIGATION_TYPE = "FETCHING_INVESTIGATION_TYPE";
export const FETCH_INVESTIGATION_TYPE = "FETCH_INVESTIGATION_TYPE";
export const INVESTIGATION_TYPE_FETCHING_COMPLETE =
  "INVESTIGATION_TYPE_FETCHING_COMPLETE";

export const INIT_USERMANGEMENT_GET_USERS_LIST =
  "INIT_USERMANGEMENT_GET_USERS_LIST";
export const FETCH_USERMANGEMENT_GET_USERS_LIST =
  "FETCH_USERMANGEMENT_GET_USERS_LIST";

export const INIT_GET_DUPS_TYPE = "INIT_GET_DUPS_TYPE";
export const FETCHING_DUPS_TYPE = "FETCHING_DUPS_TYPE";
export const FETCH_DUPS_TYPE = "FETCH_DUPS_TYPE";
export const DUPS_TYPE_FETCHING_COMPLETE = "DUPS_TYPE_FETCHING_COMPLETE";

export const INIT_USER_ROLE_BASED_ACTION = "INIT_USER_ROLE_BASED_ACTION";
export const FETCH_USER_ROLE_BASED_ACTION = "FETCH_USER_ROLE_BASED_ACTION";

export const INIT_FETCH_EXCEPTION_RECORD_HEADER =
  "INIT_FETCH_EXCEPTION_RECORD_HEADER";
export const FETCHING_EXCEPTION_RECORD_HEADER =
  "FETCHING_EXCEPTION_RECORD_HEADER";
export const FETCH_EXCEPTION_RECORD_HEADER = "FETCH_EXCEPTION_RECORD_HEADER";
export const EXCEPTION_RECORD_HEADER_FETCHING_COMPLETE =
  "EXCEPTION_RECORD_HEADER_FETCHING_COMPLETE";

export const INIT_GET_EXCEPTION_RECORD_LIST = "INIT_GET_EXCEPTION_RECORD_LIST";
export const FETCHING_EXCEPTION_RECORD_LIST = "FETCHING_EXCEPTION_RECORD_LIST";
export const FETCH_EXCEPTION_RECORD_LIST = "FETCH_EXCEPTION_RECORD_LIST";
export const EXCEPTION_RECORD_LIST_FETCHING_COMPLETE =
  "EXCEPTION_RECORD_LIST_FETCHING_COMPLETE";

export const INIT_GET_DIARY_LIST = "INIT_GET_DIARY_LIST";
export const FETCHING_DIARY_LIST = "FETCHING_DIARY_LIST";
export const FETCH_DIARY_LIST = "FETCH_DIARY_LIST";
export const DIARY_LIST_FETCHING_COMPLETE = "DIARY_LIST_FETCHING_COMPLETE";

export const INIT_GET_DOCUMENTS_LIST = "INIT_GET_DOCUMENTS_LIST";
export const FETCH_DOCUMENTS_LIST = "FETCH_DOCUMENTS_LIST";

export const INIT_ADD_DOCUMENT = "INIT_ADD_DOCUMENT";
export const INIT_DOCUMENT_DETAILS = "INIT_DOCUMENT_DETAILS";
export const INIT_UPDATE_DOCUMENT = "INIT_UPDATE_DOCUMENT";

export const INIT_GET_DIARY_TYPE = "INIT_GET_DIARY_TYPE";
export const FETCHING_DIARY_TYPE = "FETCHING_DIARY_TYPE";
export const FETCH_DIARY_TYPE = "FETCH_DIARY_TYPE";
export const DIARY_TYPE_FETCHING_COMPLETE = "DIARY_TYPE_FETCHING_COMPLETE";

export const INIT_SAVE_ADD_DIARY = "INIT_SAVE_ADD_DIARY";
export const ADD_DIARY_SAVE_SUCCESS = "ADD_DIARY_SAVE_SUCCESS";
export const ADD_DIARY_SAVE_FAILED = "ADD_DIARY_SAVE_FAILED";

export const INIT_GET_DIARY_DETAILS = "INIT_GET_DIARY_DETAILS";
export const FETCHING_DIARY_DETAILS = "FETCHING_DIARY_DETAILS";
export const DIARY_DETAILS_FETCH_COMPLETE = "DIARY_DETAILS_FETCH_COMPLETE";

export const INIT_DELETE_DOCUMENT = "INIT_DELETE_DOCUMENT";
export const FETCHING_DELETE_DOCUMENT = "FETCHING_DELETE_DOCUMENT";
export const DELETE_DOCUMENT_FETCH_COMPLETE = "DELETE_DOCUMENT_FETCH_COMPLETE";

export const INIT_UPDATE_DIARY_DETAILS = "INIT_UPDATE_DIARY_DETAILS";
export const DIARY_DETAILS_UPDATE_SUCCESS = "DIARY_DETAILS_UPDATE_SUCCESS";
export const DIARY_DETAILS_UPDATE_FAILED = " DIARY_DETAILS_UPDATE_FAILED";

export const INIT_HISTORY_COUNT = "INIT_HISTORY_COUNT";
export const FETCH_HISTORY_COUNT = "FETCH_HISTORY_COUNT";

export const INIT_HISTORY_DATA = "INIT_HISTORY_DATA";
export const FETCH_HISTORY_DATA = "FETCH_HISTORY_DATA";

export const INIT_DUPLICATE_EXCEPTIONS_POST = "INIT_DUPLICATE_EXCEPTIONS_POST";

export const INIT_MOVE_AND_COPY_TO_CLAIM = "INIT_MOVE_AND_COPY_TO_CLAIM";
export const FETCH_MOVE_AND_COPY_TO_CLAIM = "FETCH_MOVE_AND_COPY_TO_CLAIM";

export const INIT_MARK_DIARY_COMPLETE = "INIT_MARK_DIARY_COMPLETE";
export const MARK_DIARY_COMPLETE_SUCCESS = "MARK_DIARY_COMPLETE_SUCCESS";
export const MARK_DIARY_COMPLETE_FAILED = "MARK_DIARY_COMPLETE_FAILED";

export const INIT_UNMARK_DIARY_COMPLETE = "INIT_UNMARK_DIARY_COMPLETE";
export const UNMARK_DIARY_COMPLETE_SUCCESS = "UNMARK_DIARY_COMPLETE_SUCCESS";
export const UNMARK_DIARY_COMPLETE_FAILED = "UNMARK_DIARY_COMPLETE_FAILED";

export const INIT_SAVE_DUPLICATE_EXCEPTIONS_DETAILS =
  "INIT_SAVE_DUPLICATE_EXCEPTIONS_DETAILS";

export const INIT_GET_PRIMUS_DFM_URL_DETAILS =
  "INIT_GET_PRIMUS_DFM_URL_DETAILS";
export const FETCH_BULK_RELEASE_REASON_TYPE = "FETCH_BULK_RELEASE_REASON_TYPE";
export const APPLY_INITAL_DUPLICATE_EXCEPTION_VALUES =
  "APPLY_INITAL_DUPLICATE_EXCEPTION_VALUES";
