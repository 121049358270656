export const INIT_GET_TOTAL_USERS = "INIT_GET_TOTAL_USERS";
export const FETCHING_TOTAL_USERS = "FETCHING_TOTAL_USERS";
export const FETCH_TOTAL_USERS = "FETCH_TOTAL_USERS";
export const FETCH_FILTERED_TOTAL_USERS = "FETCH_FILTERED_TOTAL_USERS";
export const TOTAL_USERS_FETCHING_COMPLETE = "TOTAL_USERS_FETCHING_COMPLETE";

export const INIT_GET_ACTIVE_USERS = "INIT_GET_ACTIVE_USERS";
export const FETCHING_ACTIVE_USERS = "FETCHING_ACTIVE_USERS";
export const FETCH_ACTIVE_USERS = "FETCH_ACTIVE_USERS";
export const ACTIVE_USERS_FETCHING_COMPLETE = "ACTIVE_USERS_FETCHING_COMPLETE";

export const INIT_GET_USER_LANGUAGES = "INIT_GET_USER_LANGUAGES";
export const FETCHING_USER_LANGUAGES = "FETCHING_USER_LANGUAGES";
export const FETCH_USER_LANGUAGES = "FETCH_USER_LANGUAGES";
export const USER_LANGUAGES_FETCH_COMPLETE = "USER_LANGUAGES_FETCH_COMPLETE";

export const INIT_GET_INACTIVE_USERS = "INIT_GET_INACTIVE_USERS";
export const FETCHING_INACTIVE_USERS = "FETCHING_INACTIVE_USERS";
export const FETCH_INACTIVE_USERS = "FETCH_INACTIVE_USERS";
export const INACTIVE_USERS_FETCHING_COMPLETE =
  "INACTIVE_USERS_FETCHING_COMPLETE";

export const INIT_GET_USERS_LIST = "INIT_GET_USERS_LIST";
export const FETCHING_USERS_LIST = "FETCHING_USERS_LIST";
export const FETCH_USERS_LIST = "FETCH_USERS_LIST";
export const USERS_LIST_FETCHING_COMPLETE = "USERS_LIST_FETCHING_COMPLETE";

export const INIT_USER_ACTION = "INIT_USER_ACTION";
export const USER_ACTION_SUCCESS = "USER_ACTION_SUCCESS";
export const USER_ACTION_FAILED = "USER_ACTION_FAILED";

export const INIT_SAVE_USER_DATA= "INIT_SAVE_USER_DATA";
export const USER_DATA_SAVE_SUCCESS = "USER_DATA_SAVE_SUCCESS";
export const USER_DATA_SAVE_FAILED = "USER_DATA_SAVE_FAILED";

export const INIT_GET_COUNTRY_LIST = "INIT_GET_COUNTRY_LIST";
export const FETCHING_COUNTRY_LIST = "FETCHING_COUNTRY_LIST";
export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST";
export const COUNTRY_LIST_FETCH_COMPLETE = "COUNTRY_LIST_FETCH_COMPLETE";

export const INIT_GET_CLIENT_LIST = "INIT_GET_CLIENT_LIST";
export const FETCHING_CLIENT_LIST = "FETCHING_CLIENT_LIST";
export const FETCH_CLIENT_LIST = "FETCH_CLIENT_LIST";
export const CLIENT_LIST_FETCHING_COMPLETE = "CLIENT_LIST_FETCHING_COMPLETE";

export const INIT_GET_EXPORT_TO_EXCEL = "INIT_GET_EXPORT_TO_EXCEL";
export const FETCHING_EXPORT_TO_EXCEL = "FETCHING_EXPORT_TO_EXCEL";
export const FETCH_EXPORT_TO_EXCEL = "FETCH_EXPORT_TO_EXCEL";
export const EXPORT_TO_EXCEL_FETCHING_COMPLETE = "EXPORT_TO_EXCEL_FETCHING_COMPLETE";

export const INIT_DELETE_FILTER = "INIT_DELETE_FILTER";
export const INIT_FETCH_FILTER_NAMES = "INIT_FETCH_FILTER_NAMES";

export const INIT_FETCH_USER_FILTER_COL_NAMES = "INIT_FETCH_USER_FILTER_COL_NAMES";
export const FETCHING_USER_FILTER_COL_NAMES = "FETCHING_USER_FILTER_COL_NAMES";
export const USER_FILTER_COL_NAMES_FETCHED = "USER_FILTER_COL_NAMES_FETCHED";
export const USER_FILTER_COL_NAMES_FETCH_COMPLETE = "USER_FILTER_COL_NAMES_FETCH_COMPLETE";
export const USER_FILTER_COL_NAMES_FETCH_FAIL = "USER_FILTER_COL_NAMES_FETCH_FAIL";

export const INIT_FETCH_USER_FILTER_DETAILS = "INIT_FETCH_USER_FILTER_DETAILS";

export const INIT_SAVE_USER_FILTER = "INIT_SAVE_USER_FILTER";

export const INIT_USERMANAGEMENT_HEADER_LIST = "INIT_USERMANAGEMENT_HEADER_LIST";
export const FETCH_USERMANAGEMENT_HEADER_LIST = "FETCH_USERMANAGEMENT_HEADER_LIST";