export const INIT_FETCH_APPH_FILTER_COL_NAMES =
  "INIT_FETCH_APPH_FILTER_COL_NAMES";
export const FETCHING_APPH_FILTER_COL_NAMES = "FETCHING_APPH_FILTER_COL_NAMES";
export const APPH_FILTER_COL_NAMES_FETCHED = "APPH_FILTER_COL_NAMES_FETCHED";
export const APPH_FILTER_COL_NAMES_FETCH_COMPLETE =
  "APPH_FILTER_COL_NAMES_FETCH_COMPLETE";
export const APPH_FILTER_COL_NAMES_FETCH_FAIL =
  "APPH_FILTER_COL_NAMES_FETCH_FAIL";

export const INIT_SAVE_APPH_FILTERS = "INIT_SAVE_APPH_FILTERS";
export const ASSIGN_INITAL_DATA = "ASSIGN_INITAL_DATA";
export const INIT_FETCH_APPH_LIST_HEADER_NAMES =
  "INIT_FETCH_APPH_LIST_HEADER_NAMES";
export const FETCHING_APPH_LIST_HEADER_NAMES =
  "FETCHING_APPH_LIST_HEADER_NAMES";
export const APPH_LIST_HEADER_NAMES_FETCHED = "APPH_LIST_HEADER_NAMES_FETCHED";
export const APPH_LIST_HEADER_NAMES_FETCH_COMPLETE =
  "APPH_LIST_HEADER_NAMES_FETCH_COMPLETE";
export const APPH_LIST_HEADER_NAMES_FETCH_FAIL =
  "APPH_LIST_HEADER_NAMES_FETCH_FAIL";

export const INIT_FETCH_APPH_ROLE_BASED_ACTIONS =
  "INIT_FETCH_APPH_ROLE_BASED_ACTIONS";
export const FETCHING_APPH_ROLE_BASED_ACTIONS =
  "FETCHING_APPH_ROLE_BASED_ACTIONS";
export const APPH_ROLE_BASED_ACTIONS_FETCHED =
  "APPH_ROLE_BASED_ACTIONS_FETCHED";
export const APPH_ROLE_BASED_ACTIONS_FETCH_COMPLETE =
  "APPH_ROLE_BASED_ACTIONS_FETCH_COMPLETE";
export const APPH_ROLE_BASED_ACTIONS_FETCH_FAIL =
  "APPH_ROLE_BASED_ACTIONS_FETCH_FAIL";

export const INIT_FETCH_APPH_FILTER_NAMES = "INIT_FETCH_APPH_FILTER_NAMES";
export const INIT_GET_APPH_COUNT = "INIT_GET_APPH_COUNT";
export const FETCHING_APPH_COUNT = "FETCHING_APPH_COUNT";
export const FETCH_APPH_COUNT = "FETCH_APPH_COUNT";
export const APPH_COUNT_FETCHING_COMPLETE = "APPH_COUNT_FETCHING_COMPLETE";
export const INIT_FETCH_APPH_LIST = "INIT_FETCH_APPH_LIST";
export const FETCHING_APPH_LIST = "FETCHING_APPH_LIST";
export const APPH_LIST_FETCHED = "APPH_LIST_FETCHED";
export const APPH_LIST_FETCH_COMPLETE = "APPH_LIST_FETCH_COMPLETE";
export const APPH_LIST_FETCH_FAIL = "APPH_LIST_FETCH_FAIL";
export const INIT_FETCH_APPH_FILTER_DETAILS = "INIT_FETCH_APPH_FILTER_DETAILS";
export const INIT_DELETE_APPH_FILTERS = "INIT_DELETE_APPH_FILTERS";

export const INIT_GET_APPH_RIGHT_ALIGNED_FIELDS =
  "INIT_GET_APPH_RIGHT_ALIGNED_FIELDS";
export const FETCHING_APPH_RIGHT_ALIGNED_FIELDS =
  "FETCHING_APPH_RIGHT_ALIGNED_FIELDS";
export const FETCH_APPH_RIGHT_ALIGNED_FIELDS =
  "FETCH_APPH_RIGHT_ALIGNED_FIELDS";
export const APPH_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE =
  "APPH_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE";

export const INIT_GET_APPH_EXPORT_FIELDS_LIST =
  "INIT_GET_APPH_EXPORT_FIELDS_LIST";
export const FETCHING_APPH_EXPORT_FIELDS_LIST =
  "FETCHING_APPH_EXPORT_FIELDS_LIST";
export const FETCH_APPH_EXPORT_FIELDS_LIST = "FETCH_APPH_EXPORT_FIELDS_LIST";
export const APPH_EXPORT_FIELDS_LIST_FETCHING_COMPLETE =
  "APPH_EXPORT_FIELDS_LIST_FETCHING_COMPLETE";

export const INIT_GET_APPH_EXPORT_RECORDS =
  "INIT_GET_APPH_EXPORT_RECORDS";
export const FETCHING_APPH_EXPORT_RECORDS =
  "FETCHING_APPH_EXPORT_RECORDS";
export const FETCH_APPH_EXPORT_RECORDS = "FETCH_APPH_EXPORT_RECORDS";
export const APPH_EXPORT_RECORDS_FETCHING_COMPLETE =
  "APPH_EXPORT_RECORDS_FETCHING_COMPLETE";

export const INIT_GET_APPH_EXPORT_ALL_RECORDS =
"INIT_GET_APPH_EXPORT_ALL_RECORDS";
export const FETCHING_APPH_EXPORT_ALL_RECORDS =
"FETCHING_APPH_EXPORT_ALL_RECORDS";
export const FETCH_APPH_EXPORT_ALL_RECORDS = "FETCH_APPH_EXPORT_ALL_RECORDS";
export const APPH_EXPORT_ALL_RECORDS_FETCHING_COMPLETE =
"APPH_EXPORT_ALL_RECORDS_FETCHING_COMPLETE";
