import React from "react";
import "./style.scss";

const LabeledTextarea = (props) => {
  const handleChange = (e) => {
    let value = e.target.value;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    props.onChangeHandler && props.onChangeHandler(e);
  };
  const handleBlur = (e) => {
    let value = e.target.value;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    props.onBlurHandler && props.onBlurHandler(e);
  };
  const labelclass = `default-label ${props.required ? `required` : ``}`;
  const textareaclass = `default-textarea ${props.disabled ? `disabled` : ``} ${
    props.showError ? `error` : ``
  }`;
  return (
    <div className={textareaclass}>
      <label htmlFor={props.id} className={labelclass}>
        {props.label}
      </label>
      <textarea
        className={props.className}
        id={props.id}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        aria-label={props.label}
        rows={props.rows || 4}
        disabled={props.disabled}
        maxLength={props.inputLength || 500}
      />
    </div>
  );
};

export default LabeledTextarea;
