import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { storage } from "../../../config/storage";
import { generateNotification } from "../../../helper/generateNotification";
import exportToExcel from "../../../helper/exportToExcel";
import {
  APGetFilterFields,
  APAddAdvanceFilter,
  ReVisitedDupsUnattendedDupsExceptionListHeaders,
  UserActionAuthenticationGetRoleBasedActions,
  APGetFilterName,
  APListCount,
  APGetAccountPayableList,
  APGetEditFilterData,
  APDeleteAdvanceFilter,
  ReVisitedDupsUnattendedRightAlignedFields,
  ReVisitedDupsUnattendedExportFieldsList,
  APExportToExcel,
  APExportAllToExcel,
  ReportGetParam,
} from "../../../config/APINames";
import {
  INIT_FETCH_AP_FILTER_COL_NAMES,
  INIT_SAVE_AP_FILTER,
  INIT_FETCH_AP_LIST_HEADER_NAMES,
  INIT_FETCH_AP_ROLE_BASED_ACTIONS,
  INIT_FETCH_AP_FILTER_NAMES,
  INIT_GET_AP_COUNT,
  INIT_FETCH_AP_LIST,
  INIT_FETCH_AP_FILTER_DETAILS,
  INIT_DELETE_AP_FILTER,
  INIT_GET_AP_RIGHT_ALIGNED_FIELDS,
  INIT_GET_AP_EXPORT_FIELDS_LIST,
  INIT_GET_AP_RECORDS_EXPORT,
  INIT_GET_AP_RECORDS_EXPORT_ALL,
  INIT_GET_REPORT,
} from "./actionTypes";
import { Error_Msg } from "../../../config/index";
import {
  fetchApColNames,
  fetchingApColNames,
  apColNameFetchComplete,
  apColNameFetchFailed,
  fetchApListHeaderNames,
  fetchingApListHeaderNames,
  apListHeaderNameFetchComplete,
  apListHeaderNameFetchFailed,
  fetchApRoleBasedActions,
  fetchingApRoleBasedActions,
  apRoleBasedActionsFetchComplete,
  apRoleBasedActionsFetchFailed,
  fetchingApCount,
  fetchApCount,
  apCountFetchingComplete,
  fetchApList,
  fetchingApList,
  apListFetchComplete,
  apListFetchFailed,
  fetchApRightAlignedFields,
  fetchingApRightAlignedFields,
  apRightAlignedFieldsFetchingComplete,
  fetchApExportFieldsList,
  fetchingApExportFieldsList,
  apExportFieldsListFetchingComplete,
  fetchingApRecodsExport,
  fetchApRecordsExport,
  apRecordsExportFetchingComplete,
  fetchingApRecodsExportAll,
  fetchApRecordsExportAll,
  apRecordsExportAllFetchingComplete,
  fetchReport,
  fetchingReport,
  reportFetchingComplete,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(INIT_FETCH_AP_FILTER_COL_NAMES, initGetApColNamesSaga),
    takeLatest(INIT_SAVE_AP_FILTER, initSaveApFilterSaga),
    takeLatest(INIT_FETCH_AP_LIST_HEADER_NAMES, initGetApListHeaderNamesSaga),
    takeLatest(INIT_FETCH_AP_ROLE_BASED_ACTIONS, initGetRoleBasedActionsSaga),
    takeLatest(INIT_FETCH_AP_FILTER_NAMES, initGetApFilterNamesSaga),
    takeLatest(INIT_GET_AP_COUNT, initGetApCountSaga),
    takeLatest(INIT_FETCH_AP_LIST, initApListFetchSaga),
    takeLatest(INIT_FETCH_AP_FILTER_DETAILS, initGetApFilterDetailsSaga),
    takeLatest(INIT_DELETE_AP_FILTER, initDeleteApFilterSaga),
    takeLatest(
      INIT_GET_AP_RIGHT_ALIGNED_FIELDS,
      initGetApRightAlignedFieldsSaga
    ),
    takeLatest(INIT_GET_AP_EXPORT_FIELDS_LIST, initGetApExportFieldsListSaga),
    takeLatest(INIT_GET_AP_RECORDS_EXPORT, initGetApRecordsExportSaga),
    takeLatest(INIT_GET_AP_RECORDS_EXPORT_ALL, initGetApRecordsExportAllSaga),
    takeLatest(INIT_GET_REPORT, initGetReportSaga),
  ]);
}
function* initGetApColNamesSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApColNames());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APGetFilterFields, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchApColNames(response.data));
      yield put(apColNameFetchComplete());
    } else {
      yield put(apColNameFetchFailed());
    }
  } catch (error) {
    yield put(apColNameFetchFailed());
  }
}

function* initSaveApFilterSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APAddAdvanceFilter, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetApListHeaderNamesSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApListHeaderNames());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedDupsExceptionListHeaders,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchApListHeaderNames(response.data));
      yield put(apListHeaderNameFetchComplete());
    } else {
      yield put(apListHeaderNameFetchFailed());
    }
  } catch (error) {
    yield put(apListHeaderNameFetchFailed());
  }
}

function* initGetRoleBasedActionsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName } = sessionDetails || {};
  const { payload } = action || {};
  yield put(fetchingApRoleBasedActions());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      clientName,
      ...payload,
    });
    const response = yield call(
      axios,
      UserActionAuthenticationGetRoleBasedActions,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApRoleBasedActions(response.data));
    }
    yield put(apRoleBasedActionsFetchComplete());
  } catch (error) {
    yield put(apRoleBasedActionsFetchFailed());
  }
}

function* initGetApFilterNamesSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, sourceDomain, clientName, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userId,
      userName,
      sourceDomain,
      clientName,
    });
    const response = yield call(axios, APGetFilterName, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetApCountSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName } = sessionDetails || {};
  yield put(fetchingApCount());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userName,
      clientName: clientName.toLowerCase(),
    });
    const response = yield call(axios, APListCount, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApCount(response.data));
    }
    yield put(apCountFetchingComplete());
  } catch (error) {
    yield put(apCountFetchingComplete());
  }
}

function* initApListFetchSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName, clientId } = sessionDetails || {};
  yield put(fetchingApList());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      userName,
      ClientId: clientId,
    });
    const response = yield call(axios, APGetAccountPayableList, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApList(response.data));
      yield put(apListFetchComplete());
    } else {
      yield put(apListFetchFailed());
    }
  } catch (error) {
    yield put(apListFetchFailed());
  }
}

function* initGetApFilterDetailsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, sourceDomain, clientName, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      sourceDomain,
      userId,
      userName,
    });
    const response = yield call(axios, APGetEditFilterData, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initDeleteApFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userName,
      clientName,
    });
    const response = yield call(axios, APDeleteAdvanceFilter, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetApRightAlignedFieldsSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName } = sessionDetails || {};
  yield put(fetchingApRightAlignedFields());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName: clientName.toLowerCase(),
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedRightAlignedFields,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApRightAlignedFields(response.data));
    }
    yield put(apRightAlignedFieldsFetchingComplete());
  } catch (error) {
    yield put(apRightAlignedFieldsFetchingComplete());
  }
}

function* initGetApExportFieldsListSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName } = sessionDetails || {};
  yield put(fetchingApExportFieldsList());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName: clientName.toLowerCase(),
      userName,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedExportFieldsList,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApExportFieldsList(response.data));
    }
    yield put(apExportFieldsListFetchingComplete());
  } catch (error) {
    yield put(apExportFieldsListFetchingComplete());
  }
}

function* initGetApRecordsExportSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName } = sessionDetails || {};
  yield put(fetchingApRecodsExport());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientname: clientName.toLowerCase(),
      userName,
    });
    const response = yield call(axios, APExportToExcel, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApRecordsExport(response.data));
      exportToExcel(
        response.data.dt,
        "AccountPayables",
        response.data.exportfileName
      );
    }

    yield put(apRecordsExportFetchingComplete());
  } catch (error) {
    yield put(apRecordsExportFetchingComplete());
  }
}

function* initGetApRecordsExportAllSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName } = sessionDetails || {};
  yield put(fetchingApRecodsExportAll());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientname: clientName.toLowerCase(),
      userName,
    });
    const response = yield call(axios, APExportAllToExcel, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApRecordsExportAll(response.data));
      exportToExcel(
        response.data.dt,
        "AccountPayables",
        response.data.exportfileName
      );
    }
    yield put(apRecordsExportAllFetchingComplete());
  } catch (error) {
    yield put(apRecordsExportAllFetchingComplete());
  }
}

function* initGetReportSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName, selectedLanguage } = sessionDetails || {};
  yield put(fetchingReport());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName: clientName.toLowerCase(),
      userName,
      item3: selectedLanguage.toLowerCase(),
    });
    const response = yield call(axios, ReportGetParam, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchReport(response.data));
      if (action.callback) action.callback(response.data);
    }
    yield put(reportFetchingComplete());
  } catch (error) {
    yield put(reportFetchingComplete());
    if (action.callback) action.callback(error);
  }
}
