import { fromJS } from "immutable";
import {
  INIT_DATA_VALIDATION_REPORT_LIST,
  FETCH_DATA_VALIDATION_REPORT_LIST,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingDataValidationReportList: false,
  dataValidationReportList: {},
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_DATA_VALIDATION_REPORT_LIST: {
      return state.set("fetchingDataValidationReportList", true);
    }
    case FETCH_DATA_VALIDATION_REPORT_LIST: {
      return state
        .set("dataValidationReportList", action.data)
        .set("fetchingDataValidationReportList", false);
    }

    default:
      return state;
  }
};

export default reducer;
