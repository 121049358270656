import React from "react";
import { useTranslation } from "react-i18next";
import currUserLogo from "../../../../assets/images/layout/header/acc-logo.png";
import { AtcatAdminName } from "../../../../config/index";

const CurrentUser = (props) => {
  const { t } = useTranslation();
  let logo =
    props.authDetails.clientName.toUpperCase() === AtcatAdminName
      ? currUserLogo
      : props.authDetails.logo !== "" ||
        props.authDetails.logo !== null ||
        props.authDetails.logo !== undefined
      ? props.authDetails.logo
      : "";
  return (
    <>
      <li tabIndex={0} className="cols current-user">
        <h2 className="current-user-name">
          {props.authDetails.clientName !== undefined
            ? props.authDetails.clientName.toUpperCase()
            : "ATCAT"}
        </h2>
        {logo !== "" && (
          <img
            className="current-user-logo"
            src={logo}
            alt={`${t("atcat.loggedIn")} ${
              props.authDetails.clientName !== undefined
                ? props.authDetails.clientName.toUpperCase()
                : "ATCAT"
            }`}
          />
        )}
      </li>
    </>
  );
};

export default CurrentUser;
