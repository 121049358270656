import { fromJS } from "immutable";
import {
  FETCHING_BULKRELEASEAI_EXCEL_VALIDATION,
  BULKRELEASEAI_EXCEL_VALIDATION_FETCHED,
  BULKRELEASEAI_EXCEL_VALIDATION_FETCH_COMPLETE,
  BULKRELEASEAI_EXCEL_VALIDATION_FETCH_FAIL,
  FETCHING_BULKRELEASE_DUPS_UPLOAD,
  BULKRELEASE_DUPS_UPLOAD_FETCHED,
  BULKRELEASE_DUPS_UPLOAD_FETCH_COMPLETE,
  BULKRELEASE_DUPS_UPLOAD_FETCH_FAIL,
  FETCHING_BULKRELEASE_DUPS_UPLOAD_FILE,
  BULKRELEASE_DUPS_UPLOAD_FILE_FETCHED,
  BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_COMPLETE,
  BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_FAIL,
  FETCHING_BULKRELEASE_DUPS_RELEASE_ALL,
  BULKRELEASE_DUPS_RELEASE_ALL_FETCHED,
  BULKRELEASE_DUPS_RELEASE_ALL_FETCH_COMPLETE,
  BULKRELEASE_DUPS_RELEASE_ALL_FETCH_FAIL,
  FETCHING_BULKRELEASE_FNE_UPLOAD,
  BULKRELEASE_FNE_UPLOAD_FETCHED,
  BULKRELEASE_FNE_UPLOAD_FETCH_COMPLETE,
  BULKRELEASE_FNE_UPLOAD_FETCH_FAIL,
  FETCHING_BULKRELEASE_FNE_UPLOAD_FILE,
  BULKRELEASE_FNE_UPLOAD_FILE_FETCHED,
  BULKRELEASE_FNE_UPLOAD_FILE_FETCH_COMPLETE,
  BULKRELEASE_FNE_UPLOAD_FILE_FETCH_FAIL,
  FETCHING_BULKRELEASE_FNE_RELEASE_ALL,
  BULKRELEASE_FNE_RELEASE_ALL_FETCHED,
  BULKRELEASE_FNE_RELEASE_ALL_FETCH_COMPLETE,
  BULKRELEASE_FNE_RELEASE_ALL_FETCH_FAIL,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingExcelValidation: false,
  fetchingUploadFile: false,
  fetchingUpload: false,
  fetchingBulkReleaseAll: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_BULKRELEASEAI_EXCEL_VALIDATION: {
      return state.set("fetchingExcelValidation", true);
    }
    case BULKRELEASEAI_EXCEL_VALIDATION_FETCHED: {
      return state.set("fetchedExcelValidation", action.data);
    }
    case BULKRELEASEAI_EXCEL_VALIDATION_FETCH_COMPLETE:
    case BULKRELEASEAI_EXCEL_VALIDATION_FETCH_FAIL: {
      return state.set("fetchingExcelValidation", false);
    }

    case FETCHING_BULKRELEASE_DUPS_UPLOAD: {
      return state.set("fetchingUpload", true);
    }
    case BULKRELEASE_DUPS_UPLOAD_FETCHED: {
      return state.set("fetchedUpload", action.data);
    }
    case BULKRELEASE_DUPS_UPLOAD_FETCH_COMPLETE:
    case BULKRELEASE_DUPS_UPLOAD_FETCH_FAIL: {
      return state.set("fetchingUpload", false);
    }

    case FETCHING_BULKRELEASE_DUPS_UPLOAD_FILE: {
      return state.set("fetchingUploadFile", true);
    }
    case BULKRELEASE_DUPS_UPLOAD_FILE_FETCHED: {
      return state.set("fetchedUploadFile", action.data);
    }
    case BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_COMPLETE:
    case BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_FAIL: {
      return state.set("fetchedUploadFile", false);
    }

    case FETCHING_BULKRELEASE_DUPS_RELEASE_ALL: {
      return state.set("fetchingBulkReleaseAll", true);
    }
    case BULKRELEASE_DUPS_RELEASE_ALL_FETCHED: {
      return state.set("fetchedBulkReleaseAll", action.data);
    }
    case BULKRELEASE_DUPS_RELEASE_ALL_FETCH_COMPLETE:
    case BULKRELEASE_DUPS_RELEASE_ALL_FETCH_FAIL: {
      return state.set("fetchingBulkReleaseAll", false);
    }

    case FETCHING_BULKRELEASE_FNE_UPLOAD: {
      return state.set("fetchingFneUpload", true);
    }
    case BULKRELEASE_FNE_UPLOAD_FETCHED: {
      return state.set("fetchedFneUpload", action.data);
    }
    case BULKRELEASE_FNE_UPLOAD_FETCH_COMPLETE:
    case BULKRELEASE_FNE_UPLOAD_FETCH_FAIL: {
      return state.set("fetchingFneUpload", false);
    }

    case FETCHING_BULKRELEASE_FNE_UPLOAD_FILE: {
      return state.set("fetchingFneUploadFile", true);
    }
    case BULKRELEASE_FNE_UPLOAD_FILE_FETCHED: {
      return state.set("fetchedFneUploadFile", action.data);
    }
    case BULKRELEASE_FNE_UPLOAD_FILE_FETCH_COMPLETE:
    case BULKRELEASE_FNE_UPLOAD_FILE_FETCH_FAIL: {
      return state.set("fetchedFneUploadFile", false);
    }

    case FETCHING_BULKRELEASE_FNE_RELEASE_ALL: {
      return state.set("fetchingFneBulkReleaseAll", true);
    }
    case BULKRELEASE_FNE_RELEASE_ALL_FETCHED: {
      return state.set("fetchedFneBulkReleaseAll", action.data);
    }
    case BULKRELEASE_FNE_RELEASE_ALL_FETCH_COMPLETE:
    case BULKRELEASE_FNE_RELEASE_ALL_FETCH_FAIL: {
      return state.set("fetchingFneBulkReleaseAll", false);
    }

    default:
      return state;
  }
};

export default reducer;
