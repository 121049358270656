import React, { useEffect, useRef } from "react";
import { Cross } from "../SVG";
import { useTranslation } from "react-i18next";
import "./style.scss";

const Modal = (props) => {
  const { t } = useTranslation();
  const closeModal = (e) => props.onClose && props.onClose(e);
  const useOverlayClickAlerter = (ref) => {
    useEffect(() => {
      if (!props.preventOutterClickClose) {
        const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
            closeModal(event);
          }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    });
  };
  const wrapperRef = useRef(null);
  useOverlayClickAlerter(wrapperRef);

  return props.show ? (
    <div className="overlay">
      <div
        className={`dialogbox ${props.centered ? `centered` : ``} ${
          props.scrollable ? `scrollable` : ``
        }`}
        ref={wrapperRef}
      >
        <div className={`rows modal-container `}>
          {props.noHeader ? null : (
            <div className="modal-header">
              <h1 className="heading">{props.title}</h1>
              <button aria-label={t("atcat.close")} className="icon-wrapper close-modal" onClick={closeModal}>
                <Cross />
              </button>
            </div>
          )}
          {props.children}
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
