import React from "react";

const Help = (props) => {
  return (
    <svg
      className="icons"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-27.000000, -187.000000)">
          <g transform="translate(10.000000, 80.000000)">
            <g transform="translate(0.000000, 96.000000)">
              <g transform="translate(17.000000, 11.000000)">
                <path d="M9,11.953125 C8.45634375,11.953125 8.015625,12.3938438 8.015625,12.9375 C8.015625,13.4811563 8.45634375,13.921875 9,13.921875 C9.54365625,13.921875 9.984375,13.4811563 9.984375,12.9375 C9.984375,12.3938438 9.54365625,11.953125 9,11.953125 Z M9.26964844,11.109375 L8.70714844,11.109375 C8.47416797,11.109375 8.28527344,10.9204805 8.28527344,10.6875 L8.28527344,10.6741055 C8.28527344,8.20110937 11.0077734,8.4375 11.0077734,6.89804296 C11.0077734,6.19435546 10.3833633,5.48437499 8.98839844,5.48437499 C7.96380468,5.48437499 7.43220703,5.82359765 6.90676171,6.49307812 C6.76937109,6.66815624 6.51814453,6.70383984 6.33554296,6.57660937 L5.87380077,6.25492968 C5.67604687,6.11715234 5.6326289,5.84110546 5.78081249,5.65105077 C6.52703906,4.69385155 7.41241406,4.07812499 8.98839844,4.07812499 C10.8277734,4.07812499 12.4140234,5.12405858 12.4140234,6.89804296 C12.4140234,9.26806641 9.69152344,9.14273438 9.69152344,10.6741055 L9.69152344,10.6875 C9.69152344,10.9204805 9.50262891,11.109375 9.26964844,11.109375 Z M9,1.40624998 C13.1702695,1.40624998 16.59375,4.78388671 16.59375,9 C16.59375,13.1938242 13.1973047,16.59375 9,16.59375 C4.80782811,16.59375 1.40624998,13.1989922 1.40624998,9 C1.40624998,4.80926952 4.80241405,1.40624998 9,1.40624998 M9,0.281249978 C4.18510546,0.281249978 0.281249978,4.18651171 0.281249978,9 C0.281249978,13.8163008 4.18510546,17.71875 9,17.71875 C13.8148945,17.71875 17.71875,13.8163008 17.71875,9 C17.71875,4.18651171 13.8148945,0.281249978 9,0.281249978 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Help;
