import { fromJS } from "immutable";
import {
  FETCH_EXCEPTION_LIST_HEADER_NAMES,
  INIT_EXCEPTION_LIST,
  FETCH_EXCEPTION_LIST,
  FETCH_EXCEPTION_FILTER_COLUMNS,
  FETCH_EXCEPTION_TOTAL_COUNT,
  FETCH_BULK_RELEASE_UDL_LIST,
  INITAL_EXCEPTION_DATA,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingColumns: false,
  exceptionHeaderList: [],
  exceptionList: {},
  exceptionFilterColumns: [],
  exceptionTotalCount: 0,
  bulkReleaseReasonTypes: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXCEPTION_LIST_HEADER_NAMES: {
      return state.set("exceptionHeaderList", action.data);
    }
    case INIT_EXCEPTION_LIST: {
      return state.set("loader", true);
    }
    case FETCH_EXCEPTION_LIST: {
      return state.set("exceptionList", action.data).set("loader", false);
    }
    case FETCH_EXCEPTION_FILTER_COLUMNS: {
      return state.set("exceptionFilterColumns", action.data);
    }
    case FETCH_EXCEPTION_TOTAL_COUNT: {
      return state.set("exceptionTotalCount", action.data);
    }
    case FETCH_BULK_RELEASE_UDL_LIST: {
      return state.set("bulkReleaseReasonTypes", action.data.udlTypeDetail);
    }
    case INITAL_EXCEPTION_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
