import {
  INIT_GET_SYSTEM_PARAMETERS,
  SAVE_SYSTEM_PARAMETERS,
  SAVE_TERMS_OF_USE_FAILED,
  INIT_GET_TOU_PS,
  SAVE_TERMS_OF_USE,
  SAVE_TERMS_OF_USE_COMPLETE,
  SAVE_SYSTEM_PARAMETERS_COMPLETED,
  SAVE_SYSTEM_PARAMETERS_FAILED,
  SYSTEM_PARAMETER_FORMDATA,
  UPDATE_SYSTEM_PARAMETER_FORMDATA,
  TERMS_OF_USE_FORMDATA,
  UPDATE_TERMS_OF_USE_FORMDATA,
} from "./actionTypes";

export function initGetSystemParameters(payload) {
  return {
    type: INIT_GET_SYSTEM_PARAMETERS,
    payload,
  };
}

export function initGetToUandPS(payload) {
  return {
    type: INIT_GET_TOU_PS,
    payload,
  };
}

export function initSaveSystemParameters(payload) {
  return {
    type: SAVE_SYSTEM_PARAMETERS,
    payload,
  };
}

export function initSaveTermsOfUse(payload) {
  return {
    type: SAVE_TERMS_OF_USE,
    payload,
  };
}

export function saveTermsOfUseComplete(data) {
  return {
    type: SAVE_TERMS_OF_USE_COMPLETE,
    data
  };
}

export function saveTermsOfUseFailed() {
  return {
    type: SAVE_TERMS_OF_USE_FAILED,
  };
}

export function saveSystemParametersComplete(data) {
  return {
    type: SAVE_SYSTEM_PARAMETERS_COMPLETED,
    data
  };
}

export function saveSystemParametersFailed() {
  return {
    type: SAVE_SYSTEM_PARAMETERS_FAILED,
  };
}

export const getSystemParameterSuccess = (data) => {
  return {
    type: SYSTEM_PARAMETER_FORMDATA,
    data,
  };
};

export const updateSystemParameters = (payload) => {
  return {
    type: UPDATE_SYSTEM_PARAMETER_FORMDATA,
    payload,
  };
};

export const getTermsOfUseSuccess = (data) => {
  return {
    type: TERMS_OF_USE_FORMDATA,
    data,
  };
};

export const updateTermsOfUse = (payload) => {
  return {
    type: UPDATE_TERMS_OF_USE_FORMDATA,
    payload,
  };
};
