import { fromJS } from "immutable";
import {
    INIT_CLAIM_LIST,
    FETCH_CLAIM_LIST,
    FETCH_CLAIM_TOTAL_COUNT,
    FETCH_DUPS_CLAIM_DETAILS,
} from "./actionTypes";

export const initialState = fromJS({
    claimHeaderList: [],
    claimList: {},
    claimTotalCount: 0,
    dupsClaimDetails: {},
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_CLAIM_LIST: {
            return state.set("loader", true);
        }
        case FETCH_CLAIM_LIST: {
            return state.set("claimList", action.data).set("loader", false);
        }
        case FETCH_CLAIM_TOTAL_COUNT: {
            return state.set("claimTotalCount", action.data);
        }
        case FETCH_DUPS_CLAIM_DETAILS: {
            return state.set("dupsClaimDetails", action.data);
        }
        default:
            return state;
    }
};

export default reducer;
