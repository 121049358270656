import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from "../../../config/index";
import generateKey from "../../../config/generateKey";
import { storage } from "../../../config/storage";
import exportToExcel from "../../../helper/exportToExcel";
import {
    AISavedFilterFields,
    AISavedFilterName,
    AISavedAddAdvanceFilter,
    AIRevisitedDupsRightAlignedFilterField,
    AIFilterGetEditFilterPageData,
    AISavedFilterCount,
    AISavedRunWithFilterSort,
    AISavedDeleteAdvanceFilter,
    AISavedDuplicateExportToExcel,
    AISavedDuplicateExportAllToExcel,
    AISavedReleaseExceptionInExceptionQuery,
    AIRevisitedDupsExceptionRecord,
    AIRevisitedDupsMoveAndCopyToClaim,
    SaveExceptionDetails,
    AIRevisitedDupsExceptionListHeader,
} from "../../../config/APINames";
import {
    INIT_AI_EXCEPTION_FILTER_COL_NAMES,
    INIT_SAVE_AI_EXCEPTION_FILTER,
    INIT_FETCH_AI_EXCEPTION_FILTER_DETAILS,
    INIT_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS,
    INIT_FETCH_AI_EXCEPTION_FILTER_NAMES,
    INIT_GET_AI_EXCEPTION_COUNT,
    INIT_FETCH_AI_EXCEPTION_LIST,
    INIT_DELETE_AI_EXCEPTION_FILTER,
    INIT_GET_AI_EXCEPTION_RECORDS_EXPORT,
    INIT_GET_AI_EXCEPTION_RECORDS_EXPORT_ALL,
    INIT_GET_RELEASE_AI_EXCEPTION,
    INIT_GET_AI_EXCEPTION_RECORD_LIST,
    INIT_AI_MOVE_AND_COPY_TO_CLAIM,
    INIT_SAVE_AI_DUPLICATE_EXCEPTIONS_DETAILS,
    INIT_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES,
} from "./actionTypes";
import {
    fetchAIExceptionColNames,
    fetchingAIExceptionColNames,
    aiExceptionColNameFetchComplete,
    aiExceptionColNameFetchFailed,
    fetchAIExceptionRightAlignedFields,
    fetchAIExceptionCount,
    fetchingAIExceptionCount,
    aiExceptionCountFetchingComplete,
    fetchAIExceptionList,
    fetchingAIExceptionList,
    aiExceptionListFetchComplete,
    aiExceptionListFetchFailed,
    fetchingAIExceptionRecodsExport,
    fetchAIExceptionRecordsExport,
    aiExceptionRecordsExportFetchingComplete,
    fetchingAIExceptionRecodsExportAll,
    fetchAIExceptionRecordsExportAll,
    aiExceptionRecordsExportAllFetchingComplete,
    fetchReleaseAIException,
    fetchingReleaseAIException,
    releaseAIExceptionFetchingComplete,
    fetchingAIExceptionRecordList,
    fetchAIExceptionRecordList,
    aiExceptionRecordListFetchingComplete,
    fetchAIExceptionRecordListHeaderNames,
} from "./actions";

export default function* watchLogin() {
    yield all([
        takeLatest(INIT_AI_EXCEPTION_FILTER_COL_NAMES, initGetAIExceptionColNamesSaga),
        takeLatest(INIT_SAVE_AI_EXCEPTION_FILTER, initSaveAIExceptionFilterSaga),
        takeLatest(INIT_FETCH_AI_EXCEPTION_FILTER_DETAILS, initGetAIExceptionFilterDetailsSaga),
        takeLatest(INIT_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS, initGetAIExceptionRightAllignedFieldsSaga),
        takeLatest(INIT_FETCH_AI_EXCEPTION_FILTER_NAMES, initGetAIExceptionFilterNamesSaga),
        takeLatest(INIT_GET_AI_EXCEPTION_COUNT, initGetAIExceptionCountSaga),
        takeLatest(INIT_FETCH_AI_EXCEPTION_LIST, initAIExceptionListFetchSaga),
        takeLatest(INIT_DELETE_AI_EXCEPTION_FILTER, initDeleteAIExceptionFilterSaga),
        takeLatest(INIT_GET_AI_EXCEPTION_RECORDS_EXPORT, initGetAIExceptionRecordsExportSaga),
        takeLatest(INIT_GET_AI_EXCEPTION_RECORDS_EXPORT_ALL, initGetAIExceptionRecordsExportAllSaga),
        takeLatest(INIT_GET_RELEASE_AI_EXCEPTION, initGetReleaseAIExceptionSaga),
        takeLatest(INIT_GET_AI_EXCEPTION_RECORD_LIST, initGetAIExceptionRecordListSaga),
        takeLatest(INIT_SAVE_AI_DUPLICATE_EXCEPTIONS_DETAILS, initSaveAIDuplicateExceptionDetailsSaga),
        takeLatest(INIT_AI_MOVE_AND_COPY_TO_CLAIM, initAIMoveAndCopyToClaimSaga),
        takeLatest(INIT_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES, initGetAIExceptionRecordListHeaderNamesSaga),
    ]);
}

function* initGetAIExceptionColNamesSaga(action) {
    const { payload } = action || {};
    yield put(fetchingAIExceptionColNames());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, AISavedFilterFields, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            yield put(fetchAIExceptionColNames(response.data));
            yield put(aiExceptionColNameFetchComplete());
        } else {
            yield put(aiExceptionColNameFetchFailed());
        }
    } catch (error) {
        yield put(aiExceptionColNameFetchFailed());
    }
}

function* initSaveAIExceptionFilterSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            AISavedAddAdvanceFilter,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetAIExceptionFilterNamesSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, sourceDomain, clientName, userId } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            userId,
            userName,
            sourceDomain,
            clientName,
        });
        const response = yield call(axios, AISavedFilterName, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetAIExceptionCountSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName } = sessionDetails || {};
    yield put(fetchingAIExceptionCount());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            userName,
            clientName: clientName.toLowerCase(),
        });
        const response = yield call(axios, AISavedFilterCount, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchAIExceptionCount(response.data));
        }
        yield put(aiExceptionCountFetchingComplete());
    } catch (error) {
        yield put(aiExceptionCountFetchingComplete());
    }
}

function* initAIExceptionListFetchSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName, clientId } = sessionDetails || {};
    yield put(fetchingAIExceptionList());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName,
            userName,
            ClientId: clientId,
        });
        const response = yield call(
            axios,
            AISavedRunWithFilterSort,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchAIExceptionList(response.data));
            yield put(aiExceptionListFetchComplete());
        } else {
            yield put(aiExceptionListFetchFailed());
        }
    } catch (error) {
        yield put(aiExceptionListFetchFailed());
    }
}

function* initGetAIExceptionFilterDetailsSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, sourceDomain, clientName, userId } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName,
            sourceDomain,
            userId,
            userName,
        });
        const response = yield call(
            axios,
            AIFilterGetEditFilterPageData,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initDeleteAIExceptionFilterSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, clientName } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            userName,
            clientName,
        });
        const response = yield call(
            axios,
            AISavedDeleteAdvanceFilter,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetAIExceptionRecordsExportSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName, clientId, selectedLanguage } =
        sessionDetails || {};
    yield put(fetchingAIExceptionRecodsExport());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName: clientName.toLowerCase(),
            userName,
            ClientID: clientId,
            language: selectedLanguage,
        });
        const response = yield call(
            axios,
            AISavedDuplicateExportToExcel,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchAIExceptionRecordsExport(response.data));
            exportToExcel(
                response.data.duplicateExceptionListing,
                "DuplicateExceptionsListing",
                response.data.exportfileName
            );
        }

        yield put(aiExceptionRecordsExportFetchingComplete());
    } catch (error) {
        yield put(aiExceptionRecordsExportFetchingComplete());
    }
}

function* initGetAIExceptionRecordsExportAllSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName, clientId, selectedLanguage } =
        sessionDetails || {};
    yield put(fetchingAIExceptionRecodsExportAll());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientname: clientName.toLowerCase(),
            userName,
            ClientID: clientId,
            language: selectedLanguage,
        });
        const response = yield call(
            axios,
            AISavedDuplicateExportAllToExcel,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchAIExceptionRecordsExportAll(response.data));
            exportToExcel(
                response.data.duplicateExceptionListing,
                "DuplicateExceptionsListing",
                response.data.exportfileName
            );
        }
        yield put(aiExceptionRecordsExportAllFetchingComplete());
    } catch (error) {
        yield put(aiExceptionRecordsExportAllFetchingComplete());
    }
}

function* initGetReleaseAIExceptionSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName } = sessionDetails || {};
    yield put(fetchingReleaseAIException());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientname: clientName.toLowerCase(),
            userName,
        });
        const response = yield call(
            axios,
            AISavedReleaseExceptionInExceptionQuery,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (
            response.data ===
            "Cannot release the already released exception. Please try to release an active record."
        ) {
            yield put(fetchReleaseAIException(response.data));
            generateNotification(generateKey(response.data), "danger");
        } else if (response.data === "Selected records released successfully") {
            yield put(fetchReleaseAIException(response.data));
            generateNotification(generateKey(response.data), "success");
        }
        yield put(releaseAIExceptionFetchingComplete());
    } catch (error) {
        yield put(releaseAIExceptionFetchingComplete());
    }
}

function* initGetAIExceptionRecordListSaga(action) {
    const { payload } = action || {};
    yield put(fetchingAIExceptionRecordList());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            AIRevisitedDupsExceptionRecord,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            yield put(fetchAIExceptionRecordList(response.data));
        }
        yield put(aiExceptionRecordListFetchingComplete());
    } catch (error) {
        yield put(aiExceptionRecordListFetchingComplete());
    }
}

function* initAIMoveAndCopyToClaimSaga(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, AIRevisitedDupsMoveAndCopyToClaim, requestDetails);
        if (response.data.resultStatus) {
            callback && callback(response.data);
        } else {
            generateNotification(generateKey(response.data.message), "danger");
        }
    } catch (error) {
        generateNotification(generateKey(Error_Msg), "danger");
    }
}

function* initSaveAIDuplicateExceptionDetailsSaga(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            SaveExceptionDetails,
            requestDetails
        );
        if (response.data === "Exception details saved successfully") {
            generateNotification(generateKey(response.data), "success");
        } else {
            generateNotification(generateKey(response.data), "danger");
        }
        callback && callback();
    } catch (error) {
        generateNotification(generateKey(Error_Msg), "danger");
    }
}

function* initGetAIExceptionRightAllignedFieldsSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName } = sessionDetails || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName: clientName.toLowerCase(),
        });
        const response = yield call(
            axios,
            AIRevisitedDupsRightAlignedFilterField,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchAIExceptionRightAlignedFields(response.data));
        }
    } catch (error) {
        generateNotification(
            "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
            "danger"
        );
    }
}

function* initGetAIExceptionRecordListHeaderNamesSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            AIRevisitedDupsExceptionListHeader,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            yield put(fetchAIExceptionRecordListHeaderNames(response.data));
        }
    } catch (error) {
        generateNotification(
            "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
            "danger"
        );
    }
}
