import { fromJS } from "immutable";
import {
  INIT_SAVE_USER_DATA,
  USER_DATA_SAVE_SUCCESS,
  USER_DATA_SAVE_FAILED,
  FETCH_TOTAL_USERS,
  FETCH_FILTERED_TOTAL_USERS,
  TOTAL_USERS_FETCHING_COMPLETE,
  FETCH_ACTIVE_USERS,
  ACTIVE_USERS_FETCHING_COMPLETE,
  FETCHING_USER_LANGUAGES,
  FETCH_USER_LANGUAGES,
  USER_LANGUAGES_FETCH_COMPLETE,
  FETCH_INACTIVE_USERS,
  INACTIVE_USERS_FETCHING_COMPLETE,
  FETCHING_USERS_LIST,
  FETCH_USERS_LIST,
  USERS_LIST_FETCHING_COMPLETE,
  INIT_USER_ACTION,
  USER_ACTION_SUCCESS,
  USER_ACTION_FAILED,
  FETCHING_COUNTRY_LIST,
  FETCH_COUNTRY_LIST,
  COUNTRY_LIST_FETCH_COMPLETE,
  FETCHING_CLIENT_LIST,
  FETCH_CLIENT_LIST,
  CLIENT_LIST_FETCHING_COMPLETE,
  FETCHING_EXPORT_TO_EXCEL,
  FETCH_EXPORT_TO_EXCEL,
  EXPORT_TO_EXCEL_FETCHING_COMPLETE,
  FETCHING_USER_FILTER_COL_NAMES,
  USER_FILTER_COL_NAMES_FETCHED,
  USER_FILTER_COL_NAMES_FETCH_COMPLETE,
  USER_FILTER_COL_NAMES_FETCH_FAIL,
  FETCH_USERMANAGEMENT_HEADER_LIST,
} from "./actionTypes";

export const initialState = fromJS({
  totalUsers: "",
  fetchFilteredTotalUsers: "",
  fetchingTotalUsers: "",
  activeUsers: "",
  fetchingActiveUser: "",
  inactiveUsers: "",
  fetchingInactiveUser: "",
  fetchingUsersList: "",
  usersList: "",
  usersListFetchingComplete: "",
  fetchingLanguages: false,
  languages: [],
  savingUserData: false,
  fetchingCountryList: "",
  countryList: [],
  fetchingClientList: "",
  clientList: [],
  fetchingExportToExcel: false,
  exportToExcelFieldList: [],
  userColumnList: [],
  fetchingUserColumns: false,
  exportHeaders:[],
  tableHeaders:[],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_SAVE_USER_DATA: {
      return state.set("savingUserData", true);
    }

    case USER_DATA_SAVE_SUCCESS: {
      return state.set("savingUserData", false);
    }

    case USER_DATA_SAVE_FAILED: {
      return state.set("savingUserData", false);
    }

    case FETCH_TOTAL_USERS: {
      return state.set("totalUsers", action.data);
    }

    case FETCH_FILTERED_TOTAL_USERS: {
      return state.set("fetchFilteredTotalUsers", action.data);
    }
    case TOTAL_USERS_FETCHING_COMPLETE: {
      return state.set("fetchingTotalUsers", false);
    }

    case FETCH_ACTIVE_USERS: {
      return state.set("activeUsers", action.data);
    }

    case ACTIVE_USERS_FETCHING_COMPLETE: {
      return state.set("fetchingActiveUser", false);
    }

    case FETCH_INACTIVE_USERS: {
      return state.set("inactiveUsers", action.data);
    }

    case INACTIVE_USERS_FETCHING_COMPLETE: {
      return state.set("fetchingInactiveUser", false);
    }

    case FETCHING_USERS_LIST: {
      return state.set("fetchingUsersList", true);
    }

    case FETCH_USERS_LIST: {
      return state.set("usersList", action.data);
    }

    case USERS_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingUsersList", false);
    }

    case FETCHING_USER_LANGUAGES:
      return state.set("fetchingLanguages", true);

    case FETCH_USER_LANGUAGES:
      return state.set("languages", action.data);

    case USER_LANGUAGES_FETCH_COMPLETE:
      return state.set("fetchingLanguages", false);

    case INIT_USER_ACTION: {
      return state.set("userActionOnGoing", true);
    }

    case USER_ACTION_SUCCESS: {
      return state.set("userActionOnGoing", false);
    }

    case USER_ACTION_FAILED: {
      return state.set("userActionOnGoing", false);
    }

    case FETCHING_COUNTRY_LIST: {
      return state.set("fetchingCountryList", true);
    }
    case FETCH_COUNTRY_LIST: {
      return state.set("countryList", action.data);
    }
    case COUNTRY_LIST_FETCH_COMPLETE: {
      return state.set("fetchingCountryList", false);
    }

    case FETCHING_CLIENT_LIST: {
      return state.set("fetchingClientList", true);
    }
    case FETCH_CLIENT_LIST: {
      return state.set("clientList", action.data);
    }
    case CLIENT_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingClientList", false);
    }
    case FETCHING_EXPORT_TO_EXCEL: {
      return state.set("fetchingExportToExcel", true);
    }
    case FETCH_EXPORT_TO_EXCEL: {
      return state.set("exportToExcelFieldList", action.data);
    }

    case EXPORT_TO_EXCEL_FETCHING_COMPLETE: {
      return state.set("fetchingExportToExcel", false);
    }

    case FETCHING_USER_FILTER_COL_NAMES: {
      return state.set("fetchingUserColumns", true);
    }

    case USER_FILTER_COL_NAMES_FETCHED: {
      return state.set("userColumnList", action.data);
    }
    case USER_FILTER_COL_NAMES_FETCH_COMPLETE:
    case USER_FILTER_COL_NAMES_FETCH_FAIL: {
      return state.set("fetchingUserColumns", false);
    }
    case FETCH_USERMANAGEMENT_HEADER_LIST:{
      return state.set("exportHeaders",action.data.exportHeaders)
      .set("tableHeaders",action.data.tableHeaders);

    }
    default:
      return state;
  }
};

export default reducer;
