import {
  FETCHING_JOB_FILTER_COL_NAMES,
  FETCHING_JOB_LIST,
  INIT_DELETE_JOB_FILTERS,
  INIT_FETCH_JOB_FILTER_COL_NAMES,
  INIT_FETCH_JOB_FILTERS,
  INIT_FETCH_JOB_LIST,
  INIT_JOB_FILTER_FETCH,
  JOB_FILTER_COL_NAMES_FETCHED,
  JOB_FILTER_COL_NAMES_FETCH_COMPLETE,
  JOB_FILTER_COL_NAMES_FETCH_FAIL,
  JOB_LIST_FETCHED,
  JOB_LIST_FETCH_COMPLETE,
  JOB_LIST_FETCH_FAIL,
  INIT_SAVE_FILTER,
  INIT_GET_JOB_COUNT,
  FETCHING_JOB_COUNT,
  FETCH_JOB_COUNT,
  JOB_COUNT_FETCHING_COMPLETE,
  INIT_GET_COMPLETED_JOB_DETAILS,
  FETCHING_JOB_DETAILS,
  FETCH_JOB_DETAILS,
  JOB_DETAILS_FETCHING_COMPLETE,
  ASSIGN_INITAL_DATA,
  INIT_CLIENT_COMPLETED_JOB_LIST,
  INIT_CLIENT_JOB_COUNT,
} from "./actionTypes";

export const initFetchJobList = (payload) => {
  return {
    type: INIT_FETCH_JOB_LIST,
    payload,
  };
};

export const fetchingJobList = () => {
  return {
    type: FETCHING_JOB_LIST,
  };
};

export const fetchJobList = (data) => {
  return {
    type: JOB_LIST_FETCHED,
    data,
  };
};

export const jobListFetchComplete = () => {
  return {
    type: JOB_LIST_FETCH_COMPLETE,
  };
};
export const jobListFetchFailed = () => {
  return {
    type: JOB_LIST_FETCH_FAIL,
  };
};

export const initFetchJobColNames = (payload) => {
  return {
    type: INIT_FETCH_JOB_FILTER_COL_NAMES,
    payload,
  };
};

export const fetchingJobColNames = () => {
  return {
    type: FETCHING_JOB_FILTER_COL_NAMES,
  };
};

export const fetchColNames = (data) => {
  return {
    type: JOB_FILTER_COL_NAMES_FETCHED,
    data,
  };
};

export const jobColNameFetchComplete = () => {
  return {
    type: JOB_FILTER_COL_NAMES_FETCH_COMPLETE,
  };
};
export const jobColNameFetchFailed = () => {
  return {
    type: JOB_FILTER_COL_NAMES_FETCH_FAIL,
  };
};

export const initGetJobFilters = (payload, callback) => {
  return {
    type: INIT_FETCH_JOB_FILTERS,
    payload,
    callback,
  };
};

export const initFecthJobFilter = (payload, callback) => {
  return {
    type: INIT_JOB_FILTER_FETCH,
    payload,
    callback,
  };
};

export const initDeleteJobFilter = (payload, callback) => {
  return {
    type: INIT_DELETE_JOB_FILTERS,
    payload,
    callback,
  };
};

export const initSaveFilter = (payload, callback) => {
  return {
    type: INIT_SAVE_FILTER,
    payload,
    callback,
  };
};

export const initGetJobCount = (payload) => {
  return {
    type: INIT_GET_JOB_COUNT,
    payload,
  };
};

export function fetchingJobCount() {
  return {
    type: FETCHING_JOB_COUNT,
  };
}

export function fetchJobCount(data) {
  return {
    type: FETCH_JOB_COUNT,
    data,
  };
}

export function jobCountFetchingComplete() {
  return {
    type: JOB_COUNT_FETCHING_COMPLETE,
  };
}

export const initGetJobDetails = (payload) => {
  return {
    type: INIT_GET_COMPLETED_JOB_DETAILS,
    payload,
  };
};

export function fetchingJobDetails() {
  return {
    type: FETCHING_JOB_DETAILS,
  };
}

export function fetchJobDetails(data) {
  return {
    type: FETCH_JOB_DETAILS,
    data,
  };
}

export function jobDetailsFetchingComplete() {
  return {
    type: JOB_DETAILS_FETCHING_COMPLETE,
  };
}
export function assignInitalData() {
  return {
    type: ASSIGN_INITAL_DATA,
  };
}

export const initClientCompletedJobList = (payload) => {
  return {
    type: INIT_CLIENT_COMPLETED_JOB_LIST,
    payload,
  };
};

export const initClientJobCount = (payload) => {
  return {
    type: INIT_CLIENT_JOB_COUNT,
    payload,
  };
};
