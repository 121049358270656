import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from "../../../config/index";
import {
  AdhocExcelValidation,
  BulkReleaseUpload,
  BulkReleaseReleaseAll,
  BulkReleaseUploadFile,
} from "../../../config/APINames";
import {
  INIT_BULKRELEASE_EXCEL_VALIDATION,
  INIT_BULKRELEASE_UPLOAD,
  INIT_BULKRELEASE_UPLOAD_FILE,
  INIT_BULKRELEASE_RELEASE_ALL,
} from "./actionTypes";
import {
  fetchingBulkReleaseExcelValidation,
  fetchBulkReleaseExcelValidation,
  bulkReleaseExcelValidationComplete,
  bulkReleaseExcelValidationFail,
  fetchingBulkReleaseUpload,
  fetchBulkReleaseUpload,
  bulkReleaseUploadComplete,
  bulkReleaseUploadFail,
  fetchingBulkReleaseUploadFile,
  fetchBulkReleaseUploadFile,
  bulkReleaseUploadFileComplete,
  bulkReleaseUploadFileFail,
  fetchingBulkReleaseReleaseAll,
  fetchBulkReleaseReleaseAll,
  bulkReleaseReleaseAllComplete,
  bulkReleaseReleaseAllFail,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(
      INIT_BULKRELEASE_EXCEL_VALIDATION,
      initBulkReleaseExcelValidationSaga
    ),
    takeLatest(INIT_BULKRELEASE_UPLOAD, initBulkReleaseUploadSaga),
    takeLatest(INIT_BULKRELEASE_UPLOAD_FILE, initBulkReleaseUploadFileSaga),
    takeLatest(INIT_BULKRELEASE_RELEASE_ALL, initBulkReleaseRelaseAllSaga),
  ]);
}

function* initBulkReleaseUploadSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseUpload());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, BulkReleaseUpload, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseUpload(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseUploadComplete());
    } else {
      yield put(bulkReleaseUploadFail());
    }
  } catch (error) {
    yield put(bulkReleaseUploadFail());
  }
}

function* initBulkReleaseExcelValidationSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseExcelValidation());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, AdhocExcelValidation, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseExcelValidation(response.data.resultStatus));
      callback && callback(response.data);
      yield put(bulkReleaseExcelValidationComplete());
    } else {
      yield put(bulkReleaseExcelValidationFail());
    }
  } catch (error) {
    yield put(bulkReleaseExcelValidationFail());
  }
}

function* initBulkReleaseUploadFileSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseUploadFile());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, BulkReleaseUploadFile, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseUploadFile(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseUploadFileComplete());
    } else {
      yield put(bulkReleaseUploadFileFail());
    }
  } catch (error) {
    yield put(bulkReleaseUploadFileFail());
  }
}

function* initBulkReleaseRelaseAllSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseReleaseAll());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, BulkReleaseReleaseAll, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseReleaseAll(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseReleaseAllComplete());
    } else {
      yield put(bulkReleaseReleaseAllFail());
    }
  } catch (error) {
    yield put(bulkReleaseReleaseAllFail());
  }
}
