import React from "react";

const ArrowDown = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 10"
      focusable="false"
      id={props.id}
      className="icons"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1260.000000, -703.000000)" fill="#A2B9C4">
          <g transform="translate(1256.000000, 696.000000)">
            <path d="M11.6015625,16.303125 L4.66406248,9.42187499 C4.44374998,9.20156249 4.44374998,8.84531249 4.66406248,8.62499999 L5.59218748,7.69687499 C5.81249998,7.47656249 6.16874999,7.47656249 6.38906249,7.69687499 L12,13.2515625 L17.6109375,7.69687499 C17.83125,7.47656249 18.1875,7.47656249 18.4078125,7.69687499 L19.3359375,8.62499999 C19.55625,8.84531249 19.55625,9.20156249 19.3359375,9.42187499 L12.3984375,16.303125 C12.178125,16.5234375 11.821875,16.5234375 11.6015625,16.303125 Z"></path>
          </g>
        </g>
      </g>{" "}
    </svg>
  );
};

export default ArrowDown;
