import { FETCH_STATEMENTS,INIT_FOOTER,FETCH_FOOTER } from "./actionTypes";

export const fetchStatements = (payload, callback) => {
  return {
    type: FETCH_STATEMENTS,
    payload,
    callback,
  };
};

export const initFooter = () => {
  return {
    type: INIT_FOOTER,
  };
};

export const fetchFooter = (data) => {
  return {
    type: FETCH_FOOTER,
    data,
  };
};
  
