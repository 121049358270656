import React from "react";
import PropTypes from "prop-types";
import AccordionItem from "./AccordionItem";

const Accordion = (props) => {
  return (
    <div className="accordion-container">
      {props.items && props.items.length
        ? props.items.map((item, idx) => (
            <AccordionItem
              key={item.id || idx}
              id={item.id || idx}
              title={item.title}
              active={
                props.activeItem &&
                props.activeItem.id === (item.id ? item.id : idx) &&
                props.activeItem.active
              }
              setActive={props.handleSetActiveItem}
            >
              {item.content}
            </AccordionItem>
          ))
        : null}
    </div>
  );
};

Accordion.propTypes = {
  activeItem: PropTypes.any,
  handleSetActiveItem: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.any.isRequired,
    })
  ),
};

export default Accordion;
