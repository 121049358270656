import React, { useState } from "react";
import profileIcon from "../../../../assets/images/layout/header/Profile-Icon.svg";
import switchIcon from "../../../../assets/images/layout/header/switch_client.svg";
import logoutIcon from "../../../../assets/images/layout/header/logout.svg";
import { clientConfig } from "./config/index";
import SwitchClient from "./SwitchClient";
import { useTranslation } from "react-i18next";
const MyProfile = (props) => {
  const {t}= useTranslation();
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <li tabIndex={0} className="dropdown my-profile">
        <img
          className="profile-icon"
          src={profileIcon}
          alt={t('atcat.displayProfile')}
        />
        <ul className="dropdown-content">
          <li className="dropdown-item  profile-info ">
            <p className="profile-name">
              {props.authDetails.userName !== undefined
                ? props.authDetails.userName.toLowerCase()
                : ""}
            </p>
            <small className="profile-role">
              {props.authDetails.role !== undefined
                ? props.authDetails.role
                : ""}
            </small>
            <br></br>
            <small className="last-login">
              {props.authDetails.loginTime !== undefined
                ? `${t('atcat.lastLogin')}: ${props.authDetails.loginTime}${props.authDetails.timezone !== undefined ? `, ${props.authDetails.timezone}` : ""}`
                : ""}
            </small>
          </li>
          {clientConfig.sourceDomain.includes(props.authDetails.sourceDomain) &&
            clientConfig.role.includes(props.authDetails.role) &&
            clientConfig.version.includes(props.authDetails.atcatVersion) && (
              <>
                <li className="dropdown-item">
                  <button
                    className="action-item"
                    onClick={() => {
                      props.fetchClientList();
                      setOpenModal((prevState) => !prevState);
                    }}
                  >
                    <img src={switchIcon} alt={t('atcat.switchClient')} />
                    <span>{t('atcat.switchClient')}</span>
                  </button>
                </li>
              </>
            )}
          <li className="dropdown-item">
            <button className="action-item" onClick={()=>{props.handleLogOut();}}>
              <img src={logoutIcon} alt={t('atcat.logout')} />
              <span>{t('atcat.logout')}</span>
            </button>
          </li>
        </ul>
        {openModal && (
          <SwitchClient
            modalFlag={openModal}
            {...props}
            handleClose={() => {
              setOpenModal((prevState) => !prevState);
            }}
          />
        )}
      </li>
    </>
  );
};

export default MyProfile;
