import { fromJS } from "immutable";
import {
    FETCH_AI_EXCEPTION_LIST_HEADER_NAMES,
    FETCH_AI_EXCEPTION_EXPORT_FIELD_LIST,
    FETCH_AI_EXCEPTION_TOTAL_COUNT,
    FETCH_AI_EXCEPTION_LIST,
    FETCH_AI_EXCEPTION_FILTER_COLUMNS,
    INIT_AI_EXCEPTION_LIST,
} from "./actionTypes";

export const initialState = fromJS({
    fetchingColumns: false,
    aiExceptionHeaderList: [],
    aIExceptionList: {},
    aIExceptionExportFieldList: {},
    aIExceptionTotalCount: 0,
    aIExceptionFilterColumns: [],
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AI_EXCEPTION_LIST_HEADER_NAMES: {
            return state.set("aiExceptionHeaderList", action.data);
        }
        case INIT_AI_EXCEPTION_LIST: {
            return state.set("loader", true);
        }
        case FETCH_AI_EXCEPTION_LIST: {
            return state.set("aIExceptionList", action.data).set("loader", false);;
        }
        case FETCH_AI_EXCEPTION_FILTER_COLUMNS: {
            return state.set("aIExceptionFilterColumns", action.data);
        }
        case FETCH_AI_EXCEPTION_TOTAL_COUNT: {
            return state.set("aIExceptionTotalCount", action.data);
        }
        case FETCH_AI_EXCEPTION_EXPORT_FIELD_LIST: {
            return state.set("aIExceptionExportFieldList", action.data);
        }
        default:
            return state;
    }
};

export default reducer;
