import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from "../../../config";
import {
  BulkReleaseGetTime,
  BulkReleaseSubmitTime,
} from "../../../config/APINames";
import {
  INIT_BULKRELEASE_GET_TIME,
  INIT_BULKRELEASE_SUBMIT_TIME,
} from "./actionTypes";
import {
  fetchingBulkReleaseGetTime,
  fetchBulkReleaseGetTime,
  bulkReleaseGetTimeComplete,
  bulkReleaseGetTimeFail,
  fetchingBulkReleaseSubmitTime,
  fetchBulkReleaseSubmitTime,
  bulkReleaseSubmitTimeComplete,
  bulkReleaseSubmitTimeFail,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(INIT_BULKRELEASE_GET_TIME, initBulkReleaseGetTimeSaga),
    takeLatest(INIT_BULKRELEASE_SUBMIT_TIME, initBulkReleaseSubmitTimeSaga),
  ]);
}

function* initBulkReleaseGetTimeSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseGetTime());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, BulkReleaseGetTime, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseGetTime(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseGetTimeComplete());
    } else {
      yield put(bulkReleaseGetTimeFail());
    }
  } catch (error) {
    yield put(bulkReleaseGetTimeFail());
  }
}

function* initBulkReleaseSubmitTimeSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseSubmitTime());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, BulkReleaseSubmitTime, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseSubmitTime(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseSubmitTimeComplete());
    } else {
      yield put(bulkReleaseSubmitTimeFail());
    }
  } catch (error) {
    yield put(bulkReleaseSubmitTimeFail());
  }
}
