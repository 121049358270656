import React from "react";
import { useTranslation } from "react-i18next";
import atcatLogo from "../../../../assets/images/layout/header/AtcatLogo.png";
const Logo = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="separator"></div>
      <div tabIndex="0" className=" cols site-info">
        <h1 className="site-title">ATCAT</h1>
        <img className="site-logo" 
        src={atcatLogo} 
        alt={t("atcat.atcatLogo")} />
      </div>
    </>
  );
};

export default Logo;
