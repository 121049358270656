import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import generateKey from "../../../config/generateKey";
import {
  RunningJobsQueuedJobsList,
  RunningJobsCheckSuspendState,
  RunningJobsSuspendQueue,
  RunningJobsResumeQueue,
  RunningJobsDeleteQueue,
  RunningJobsReorderJobRun,
  RunningJobsClientQueuedJobsList,
} from "../../../config/APINames";
import {
  INIT_GET_QUEUED_JOB_LIST,
  INIT_GET_QUEUE_SUSPEND_STATE,
  INIT_GET_SUSPENDED_QUEUE,
  INIT_GET_RESUMED_QUEUE,
  INIT_DELETE_QUEUE,
  INIT_SAVE_REORDERED_JOBS,
  INIT_GET_CLIENT_QUEUEDJOB_LISTING,
} from "./actionTypes";
import {
  fetchQueuedJobList,
  fetchQueueSuspendState,
  fetchSaveReorderComplete,
  fetchResumedQueueComplete,
  fetchSuspendQueueComplete,
  fetchClientQueuedJobList,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(INIT_GET_QUEUED_JOB_LIST, initGetQueuedJobListingSaga),
    takeLatest(INIT_GET_QUEUE_SUSPEND_STATE, initGetQueueSuspendStateSaga),
    takeLatest(INIT_GET_SUSPENDED_QUEUE, initGetSuspendedQueueSaga),
    takeLatest(INIT_GET_RESUMED_QUEUE, initGetResumedQueueSaga),
    takeLatest(INIT_DELETE_QUEUE, initDeleteQueueSaga),
    takeLatest(INIT_SAVE_REORDERED_JOBS, initSaveReorderedSaga),
    takeLatest(
      INIT_GET_CLIENT_QUEUEDJOB_LISTING,
      initGetClientQueuedJobListingSaga
    ),
  ]);
}

function* initGetQueuedJobListingSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RunningJobsQueuedJobsList,
      requestDetails
    );
    if (response && response.status === 200) {
      if (response.data.resultStatus === true) {
        yield put(fetchQueuedJobList(response.data.resultSet));
      } else {
        generateNotification(generateKey(response.data.message), "danger");
      }
      callback && callback();
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initGetQueueSuspendStateSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RunningJobsCheckSuspendState,
      requestDetails
    );
    if (response && response.status === 200) {
      if (response.data.resultStatus === true) {
        yield put(
          fetchQueueSuspendState(response.data.resultSet.queuestate[0])
        );
      } else {
        const msg = response.data.message
          ? generateKey(response.data.message)
          : "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam";
        generateNotification(msg, "danger");
      }
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initGetSuspendedQueueSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, RunningJobsSuspendQueue, requestDetails);
    if (response && response.status === 200) {
      callback && callback();
      if (response.data.resultStatus === true) {
        generateNotification(generateKey(response.data.message), "success");
      } else {
        generateNotification(generateKey(response.data.message), "danger");
      }
    }
    yield put(fetchSuspendQueueComplete());
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initGetResumedQueueSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, RunningJobsResumeQueue, requestDetails);
    if (response && response.status === 200) {
      callback && callback();
      if (response.data.resultStatus === true) {
        generateNotification(generateKey(response.data.message), "success");
      } else {
        generateNotification(generateKey(response.data.message), "danger");
      }
    }
    yield put(fetchResumedQueueComplete());
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initDeleteQueueSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, RunningJobsDeleteQueue, requestDetails);
    if (response && response.status === 200) {
      if (response.data.resultStatus === true) {
        generateNotification(generateKey(response.data.message), "success");
      } else {
        generateNotification(generateKey(response.data.message), "danger");
      }
      callback && callback();
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initSaveReorderedSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RunningJobsReorderJobRun,
      requestDetails
    );
    if (response && response.status === 200) {
      if (response.data.resultStatus === true) {
        generateNotification(generateKey(response.data.message), "success");
      } else {
        generateNotification(generateKey(response.data.message), "danger");
      }
      callback && callback();
    }
    yield put(fetchSaveReorderComplete());
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initGetClientQueuedJobListingSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RunningJobsClientQueuedJobsList,
      requestDetails
    );
    if (response && response.status === 200) {
      if (response.data.resultStatus === true) {
        yield put(fetchClientQueuedJobList(response.data.resultSet));
        callback && callback();
      } else {
        generateNotification(generateKey(response.data.message), "danger");
      }
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
