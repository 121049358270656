import {
  INIT_FRAUD_AND_ERROR_DATA,
  INIT_FRAUD_AND_ERROR_EXPORT_FIELDS,
  INIT_FRAUD_AND_ERROR_FILTER_NAME,
  INIT_FRAUD_AND_ERROR_HEADER_FIELDS,
  INIT_FRAUD_AND_ERROR_PAGES_COUNT,
  INIT_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS,
  INIT_FRAUD_AND_ERROR_SEARCH_RECORD,
  INIT_FRAUD_AND_ERROR_SAVE_RECORD_DETAILS,
  INIT_DELETE_FILTER_OPTION,
  INIT_FRAUD_AND_ERROR_EXPORT_RECORDS,
  INIT_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS,
  INIT_FRAUD_AND_ERROR_RECORD_DETAILS,
  INIT_FRAUD_AND_ERROR_RUN_FILTER,
  INIT_FRAUD_AND_ERROR_FILTER_FIELDS,
  INIT_FRAUD_AND_ERROR_EDIT_FILTER_DETAILS,
  INIT_FRAUD_AND_ERROR_SAVE_FILTER_DETAILS,
  FETCH_FRAUD_AND_ERROR_DATA,
  FETCH_FRAUD_AND_ERROR_EXPORT_FIELDS,
  FETCH_FRAUD_AND_ERROR_FILTER_NAME,
  FETCH_FRAUD_AND_ERROR_HEADER_FIELDS,
  FETCH_FRAUD_AND_ERROR_PAGES_COUNT,
  FETCH_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS,
  FETCH_FRAUD_AND_ERROR_SEARCH_RECORD,
  FETCH_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS,
  FETCH_FRAUD_AND_ERROR_FILTER_FIELDS,
  INIT_FRAUD_AND_ERROR_PAGE_EXIT,
} from "./actionTypes";

export const initTableData = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_DATA,
    payload,
    callback,
  };
};
export const fetchTableData = (data) => {
  return {
    type: FETCH_FRAUD_AND_ERROR_DATA,
    data,
  };
};
export const initExportFields = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_EXPORT_FIELDS,
    payload,
    callback,
  };
};
export const fetchExportFields = (data) => {
  return {
    type: FETCH_FRAUD_AND_ERROR_EXPORT_FIELDS,
    data,
  };
};
export const initFilterName = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_FILTER_NAME,
    payload,
    callback,
  };
};
export const fetchFilterName = (data) => {
  return {
    type: FETCH_FRAUD_AND_ERROR_FILTER_NAME,
    data,
  };
};
export const initHeaderFieldsList = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_HEADER_FIELDS,
    payload,
    callback,
  };
};
export const fetchHeaderFieldsList = (data) => {
  return {
    type: FETCH_FRAUD_AND_ERROR_HEADER_FIELDS,
    data,
  };
};
export const initPageCount = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_PAGES_COUNT,
    payload,
    callback,
  };
};
export const fetchPageCount = (data) => {
  return {
    type: FETCH_FRAUD_AND_ERROR_PAGES_COUNT,
    data,
  };
};
export const initRoleBasedActions = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS,
    payload,
    callback,
  };
};
export const fetchRoleBasedActions = (data) => {
  return {
    type: FETCH_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS,
    data,
  };
};
export const initRecordSearch = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_SEARCH_RECORD,
    payload,
    callback,
  };
};
export const fetchRecordSeach = (data) => {
  return {
    type: FETCH_FRAUD_AND_ERROR_SEARCH_RECORD,
    data,
  };
};
export const initSaveRecord = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_SAVE_RECORD_DETAILS,
    payload,
    callback,
  };
};
export const initDeleteFilter = (payload, callback) => {
  return {
    type: INIT_DELETE_FILTER_OPTION,
    payload,
    callback,
  };
};
export const initExportRecord = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_EXPORT_RECORDS,
    payload,
    callback,
  };
};
export const initDropDownFields = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS,
    payload,
    callback,
  };
};
export const fetchDropDownFields = (data) => {
  return {
    type: FETCH_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS,
    data,
  };
};
export const initRecordDetails = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_RECORD_DETAILS,
    payload,
    callback,
  };
};
export const initRunFilter = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_RUN_FILTER,
    payload,
    callback,
  };
};
export const initFilterFields = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_FILTER_FIELDS,
    payload,
    callback,
  };
};
export const fetchFilterFields = (data) => {
  return {
    type: FETCH_FRAUD_AND_ERROR_FILTER_FIELDS,
    data,
  };
};
export const initEditFilterDetails = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_EDIT_FILTER_DETAILS,
    payload,
    callback,
  };
};
export const initSaveFilterDetails = (payload, callback) => {
  return {
    type: INIT_FRAUD_AND_ERROR_SAVE_FILTER_DETAILS,
    payload,
    callback,
  };
};
export const initExitPage = () => {
  return {
    type: INIT_FRAUD_AND_ERROR_PAGE_EXIT,
  };
};
