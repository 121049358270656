import React from "react";
import "./style.scss";
import { LabeledInput, IconInput } from "../..";
import Calendar from "../../SVG/Calendar";

const DateInput = React.forwardRef((props, _ref) => {
  return (
    <div className="dateinput">
      {props.type === "default" ? (
        <IconInput
          {...props}
          value={props.value}
          label={props.label}
          onChange={props.onChange}
          iconRight={<Calendar />}
          handleClick={props.setDatePicker}
          onIconRightClick={props.setDatePicker}
        />
      ) : (
        <LabeledInput
          {...props}
          value={props.value}
          label={props.label}
          onChange={props.onChange}
          iconRight={<Calendar />}
          handleClick={props.setDatePicker}
          onIconRightClick={props.setDatePicker}
        />
      )}
    </div>
  );
});

export default DateInput;
