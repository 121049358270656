import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import LabeledInput from "../Inputs/LabeledInput";
import { ReactComponent as Clock } from "../../assets/images/common/clock.svg";

const TimePicker = (props) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [visited, setVisited] = useState(false);

  const useOutsideHandler = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
          setVisited(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideHandler(wrapperRef);
  let hours = [];
  for (let hour = 1; hour <= 12; hour++) {
    let hourStr = String(hour).padStart(2, "0");
    hours.push(<option value={hourStr}>{hourStr}</option>);
  }
  let mins = [];
  if (props.noMin) {
    // for (let min = 0; min <= 59; min++) {
      let minStr = String(0).padStart(2, "0");
      mins.push(<option value={minStr}>{minStr}</option>);
    // }
  } else {
    for (let min = 0; min <= 59; min++) {
      let minStr = String(min).padStart(2, "0");
      mins.push(<option value={minStr}>{minStr}</option>);
    }
  }

  return (
    <div
      className={`time-picker ${props.display ? `display` : ``}`}
      ref={wrapperRef}
    >
      <LabeledInput
        aria-label={t("atcat.timeInput")}
        type="text"
        id={props.id || "timeInput"}
        maxLength="100"
        label={props.label}
        required={props.required}
        value={
          props.selectedHour +
          ":" +
          props.selectedMin +
          " " +
          props.selectedPeriod
        }
        readOnly
        handleClick={() => {
          setShowDropdown(!showDropdown);
          setVisited(!visited);
        }}
        onIconRightClick={() => {
          setShowDropdown(!showDropdown);
          setVisited(!visited);
        }}
        iconRight={<Clock />}
        rightIconAriaLabel={t("atcat.openMenu")}
      />
      {showDropdown ? (
        <div className="options-wrapper">
          <select
            size={6}
            onChange={props.handleHourChange}
            value={props.selectedHour}
          >
            {hours}
          </select>
          <select
            size={6}
            value={props.selectedMin}
            onChange={props.handleMinChange}
          >
            {mins}
          </select>
          <select
            size={6}
            value={props.selectedPeriod}
            onChange={props.handlePeriodChange}
          >
            <option>AM</option>
            <option>PM</option>
          </select>
        </div>
      ) : null}
    </div>
  );
};

export default TimePicker;
