import { fromJS } from "immutable";
import {
  INIT_DASHBOARD_DATA,
  FETCH_DASHBOARD_DATA,
  FETCH_CLAIM_AGEING,
  FETCH_DASHBOARD_AGEING,
  FETCH_STARTDATE_ENDDATE_UI,
  FETCH_SUPPLIER_DATA,
  FETCH_AUDITOR_LIST,
  FETCH_SUPPLIER_CLAIM_PRODUCTIVITY,
  FETCH_CLAIM_AGEING_NOTIFICATION,
  FETCH_EXCEPTION_AGEING_NOTIFICATION,
  FETCH_SUPPLIER_DATA_BY_COUNT,
  FETCH_SUPPLIER_CLAIM_PRODUCTIVITY_BY_COUNT,
  ASSIGN_VARIABLE_INITAL_STATE,
} from "./actionTypes";
export const initialState = fromJS({
  loader: false,
  dashboardData: {},
  startEndDate: "",
  claimAgeing: {},
  dashboardAgeing: {},
  supplierData: {},
  auditorList: [],
  supplierProductivity: {},
  claimAgeingNotifications: {},
  exceptionAgeingNotifications: {},
  supplierDataByCount: {},
  supplierProductivityByCount: {},
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_DASHBOARD_DATA:
      return state.set("loader", true);
    case FETCH_DASHBOARD_DATA:
      return state.set("loader", false).set("dashboardData", action.data);
    case FETCH_CLAIM_AGEING:
      return state.set("claimAgeing", action.data);
    case FETCH_DASHBOARD_AGEING:
      return state.set("dashboardAgeing", action.data);
    case FETCH_STARTDATE_ENDDATE_UI:
      return state.set("startEndDate", action.data);
    case FETCH_SUPPLIER_DATA:
      return state.set("supplierData", action.data);
    case FETCH_AUDITOR_LIST:
      return state.set("auditorList", action.data);
    case FETCH_SUPPLIER_CLAIM_PRODUCTIVITY:
      return state.set("supplierProductivity", action.data);
    case FETCH_CLAIM_AGEING_NOTIFICATION:
      return state.set("claimAgeingNotifications", action.data);
    case FETCH_EXCEPTION_AGEING_NOTIFICATION:
      return state.set("exceptionAgeingNotifications", action.data);
    case FETCH_SUPPLIER_DATA_BY_COUNT:
      return state.set("supplierDataByCount", action.data);
    case FETCH_SUPPLIER_CLAIM_PRODUCTIVITY_BY_COUNT:
      return state.set("supplierProductivityByCount", action.data);
    case ASSIGN_VARIABLE_INITAL_STATE:
      return state
        .set("loader", false)
        .set("dashboardData", {})
        .set("startEndDate", "")
        .set("claimAgeing", {})
        .set("dashboardAgeing", {})
        .set("supplierData", {})
        .set("auditorList", [])
        .set("supplierProductivity", {})
        .set("claimAgeingNotifications", {})
        .set("exceptionAgeingNotifications", {})
        .set("supplierDataByCount", {})
        .set("supplierProductivityByCount", {});
    default:
      return state;
  }
};
export default reducer;
