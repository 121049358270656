import { fromJS } from "immutable";
import {
  FETCH_CLIENT_SYSTEM_PARAMETER,
  INIT_CLIENT_SYSTEM_PARAMETER,
  FETCH_CLIENT_SYSTEM_PARAMETER_DATA,
  INIT_SAVE_CLIENT_SYSTEM_PARAMETER,
  FETCHING_SAVE_CLIENT_SYSTEM_PARAMETER,
} from "./actionTypes";

export const initialState = fromJS({
  clientSystemParameterDetails: {},
  fetchingClientSysytemParameter: false,
  clientSystemParameterData: {},
  fetchingSaveClientSystemParameter: {},
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_CLIENT_SYSTEM_PARAMETER: {
      return state.set("fetchingClientSysytemParameter", true);
    }
    case FETCH_CLIENT_SYSTEM_PARAMETER: {
      return state
        .set("clientSystemParameterDetails", action.data)
        .set("fetchingClientSysytemParameter", false);
    }
    case FETCH_CLIENT_SYSTEM_PARAMETER_DATA: {
      return state.set("clientSystemParameterData", action.data);
    }
    case INIT_SAVE_CLIENT_SYSTEM_PARAMETER: {
      return state.set("fetchingSaveClientSystemParameter", true);
    }
    case FETCHING_SAVE_CLIENT_SYSTEM_PARAMETER: {
      return state.set("fetchingSaveClientSystemParameter", false);
    }
    default:
      return state;
  }
};

export default reducer;
