import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import exportToExcel from "../../../helper/exportToExcel";
import {
  APPHGetFilterFields,
  APPHAddAdvanceFilter,
  ReVisitedDupsUnattendedDupsExceptionListHeaders,
  UserActionAuthenticationGetRoleBasedActions,
  APPHGetFilterName,
  APPHListCount,
  APPHList,
  APPHEditFilterPageData,
  APPHDeleteAdvanceFilter,
  ReVisitedDupsUnattendedRightAlignedFields,
  ReVisitedDupsUnattendedExportFieldsList,
  APPHExportToExcel,
  APPHExportAllToExcel,
} from "../../../config/APINames";
import {
  INIT_FETCH_APPH_FILTER_COL_NAMES,
  INIT_SAVE_APPH_FILTERS,
  INIT_FETCH_APPH_LIST_HEADER_NAMES,
  INIT_FETCH_APPH_ROLE_BASED_ACTIONS,
  INIT_FETCH_APPH_FILTER_NAMES,
  INIT_GET_APPH_COUNT,
  INIT_FETCH_APPH_LIST,
  INIT_FETCH_APPH_FILTER_DETAILS,
  INIT_DELETE_APPH_FILTERS,
  INIT_GET_APPH_RIGHT_ALIGNED_FIELDS,
  INIT_GET_APPH_EXPORT_FIELDS_LIST,
  INIT_GET_APPH_EXPORT_RECORDS,
  INIT_GET_APPH_EXPORT_ALL_RECORDS,
} from "./actionTypes";
import { Error_Msg } from "../../../config/index";
import {
  fetchApPhColNames,
  fetchingApPhColNames,
  apPhColNameFetchComplete,
  apPhColNameFetchFailed,
  fetchApPhListHeaderNames,
  fetchingApPhListHeaderNames,
  apPhListHeaderNameFetchComplete,
  apPhListHeaderNameFetchFailed,
  fetchApPhRoleBasedActions,
  fetchingApPhRoleBasedActions,
  apPhRoleBasedActionsFetchComplete,
  apPhRoleBasedActionsFetchFailed,
  fetchingApPhCount,
  fetchApPhCount,
  apPhCountFetchingComplete,
  fetchApPhList,
  fetchingApPhList,
  apPhListFetchComplete,
  apPhListFetchFailed,
  fetchApPhRightAlignedFields,
  fetchingApPhRightAlignedFields,
  apPhRightAlignedFieldsFetchingComplete,
  fetchApPhExportFieldsList,
  fetchingApPhExportFieldsList,
  apPhExportFieldsListFetchingComplete,
  fetchingApPhExportRecords,
  fetchApPhExportRecords,
  apPhExportRecordsFetchingComplete,
  fetchingApPhExportAllRecords,
  fetchApPhExportAllRecords,
  apPhExportAllRecordsFetchingComplete,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(INIT_FETCH_APPH_FILTER_COL_NAMES, initGetApPhColNamesSaga),
    takeLatest(INIT_SAVE_APPH_FILTERS, initSaveApPhFilterSaga),
    takeLatest(
      INIT_FETCH_APPH_LIST_HEADER_NAMES,
      initGetApPhListHeaderNamesSaga
    ),
    takeLatest(INIT_FETCH_APPH_ROLE_BASED_ACTIONS, initGetRoleBasedActionsSaga),
    takeLatest(INIT_FETCH_APPH_FILTER_NAMES, initGetApPhFilterNamesSaga),
    takeLatest(INIT_GET_APPH_COUNT, initGetApPhCountSaga),
    takeLatest(INIT_FETCH_APPH_LIST, initApPhListFetchSaga),
    takeLatest(INIT_FETCH_APPH_FILTER_DETAILS, initGetApPhFilterDetailsSaga),
    takeLatest(INIT_DELETE_APPH_FILTERS, initDeleteApPhFilterSaga),
    takeLatest(
      INIT_GET_APPH_RIGHT_ALIGNED_FIELDS,
      initGetApPhRightAlignedFieldsSaga
    ),
    takeLatest(
      INIT_GET_APPH_EXPORT_FIELDS_LIST,
      initGetApPhExportFieldsListSaga
    ),
    takeLatest(INIT_GET_APPH_EXPORT_RECORDS, initGetApPhExportRecordsSaga),
    takeLatest(INIT_GET_APPH_EXPORT_ALL_RECORDS, initGetApPhExportAllRecordsSaga),
  ]);
}
function* initGetApPhColNamesSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApPhColNames());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APPHGetFilterFields, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchApPhColNames(response.data));
      yield put(apPhColNameFetchComplete());
    } else {
      yield put(apPhColNameFetchFailed());
    }
  } catch (error) {
    yield put(apPhColNameFetchFailed());
  }
}

function* initSaveApPhFilterSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APPHAddAdvanceFilter, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetApPhListHeaderNamesSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApPhListHeaderNames());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedDupsExceptionListHeaders,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchApPhListHeaderNames(response.data));
      yield put(apPhListHeaderNameFetchComplete());
    } else {
      yield put(apPhListHeaderNameFetchFailed());
    }
  } catch (error) {
    yield put(apPhListHeaderNameFetchFailed());
  }
}

function* initGetRoleBasedActionsSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApPhRoleBasedActions());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      UserActionAuthenticationGetRoleBasedActions,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApPhRoleBasedActions(response.data));
    }
    yield put(apPhRoleBasedActionsFetchComplete());
  } catch (error) {
    yield put(apPhRoleBasedActionsFetchFailed());
  }
}

function* initGetApPhFilterNamesSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APPHGetFilterName, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetApPhCountSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApPhCount());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APPHListCount, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApPhCount(response.data));
    }
    yield put(apPhCountFetchingComplete());
  } catch (error) {
    yield put(apPhCountFetchingComplete());
  }
}

function* initApPhListFetchSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApPhList());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APPHList, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApPhList(response.data));
      yield put(apPhListFetchComplete());
    } else {
      yield put(apPhListFetchFailed());
    }
  } catch (error) {
    yield put(apPhListFetchFailed());
  }
}

function* initGetApPhFilterDetailsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APPHEditFilterPageData, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initDeleteApPhFilterSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APPHDeleteAdvanceFilter, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetApPhRightAlignedFieldsSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApPhRightAlignedFields());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedRightAlignedFields,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApPhRightAlignedFields(response.data));
    }
    yield put(apPhRightAlignedFieldsFetchingComplete());
  } catch (error) {
    yield put(apPhRightAlignedFieldsFetchingComplete());
  }
}

function* initGetApPhExportFieldsListSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApPhExportFieldsList());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedExportFieldsList,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApPhExportFieldsList(response.data));
    }
    yield put(apPhExportFieldsListFetchingComplete());
  } catch (error) {
    yield put(apPhExportFieldsListFetchingComplete());
  }
}

function* initGetApPhExportRecordsSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApPhExportRecords());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APPHExportToExcel, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApPhExportRecords(response.data));
      exportToExcel(response.data.dt, "ApPhListing", response.data.exportfileName);
    }

    yield put(apPhExportRecordsFetchingComplete());
  } catch (error) {
    yield put(apPhExportRecordsFetchingComplete());
  }
}


function* initGetApPhExportAllRecordsSaga(action) {
  const { payload } = action || {};
  yield put(fetchingApPhExportAllRecords());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, APPHExportAllToExcel, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchApPhExportAllRecords(response.data));
      exportToExcel(response.data.dt, "ApPhListing", response.data.exportfileName);
    }

    yield put(apPhExportAllRecordsFetchingComplete());
  } catch (error) {
    yield put(apPhExportAllRecordsFetchingComplete());
  }
}

