import { fromJS } from "immutable";
import {
    INIT_DATALOAD_INFO,
    INIT_DUPS_INFO,
} from "./actionTypes";

export const initialState = fromJS({
    dataloadInfo: {},
    dupsInfo: {},
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_DATALOAD_INFO:
        case INIT_DUPS_INFO: {
            return state.set("loader", true);
        }
        default:
            return state;
    }
};
export default reducer;
