import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { storage } from "../../../config/storage";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from "../../../config/index";
import {
  VendorClaimsVendorClaimFilterFields,
  VendorClaimsListOfVendorClaims,
  VendorClaimsVendorClaimsInvoiceDetails,
} from "../../../config/APINames";
import {
  INIT_VENDOR_CLAIM_FILTER_FIELDS,
  INIT_VENDOR_CLAIM_LIST,
  INIT_VENDOR_CLAIM_SUMMARY_LIST,
} from "./actionTypes";
import { fetchVendorClaimList, fetchVendorClaimSummaryList } from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(
      INIT_VENDOR_CLAIM_FILTER_FIELDS,
      initGetVendorClaimFilterFieldsSaga
    ),
    takeLatest(INIT_VENDOR_CLAIM_LIST, initGetVendorClaimListSaga),
    takeLatest(
      INIT_VENDOR_CLAIM_SUMMARY_LIST,
      initGetVendorClaimSummaryListSaga
    ),
  ]);
}

function* initGetVendorClaimFilterFieldsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      userName,
    });
    const response = yield call(
      axios,
      VendorClaimsVendorClaimFilterFields,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetVendorClaimListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      VendorClaimsListOfVendorClaims,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchVendorClaimList(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initGetVendorClaimSummaryListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      VendorClaimsVendorClaimsInvoiceDetails,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchVendorClaimSummaryList(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
