import { fromJS } from "immutable";
import {
  INIT_IPQC_PRIORITIZATION_DATA,
  FETCH_IPQC_PRIORITIZATION_DATA,
  FETCH_IPQC_PRIORITIZATION_EXPORT_FIELDS,
  FETCH_IPQC_PRIORITIZATION_FILTER_NAME,
  FETCH_IPQC_PRIORITIZATION_HEADER_FIELDS,
  FETCH_IPQC_PRIORITIZATION_PAGES_COUNT,
  FETCH_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS,
  FETCH_IPQC_PRIORITIZATION_FILTER_FIELDS,
  INIT_IPQC_PRIORITIZATION_PAGE_EXIT,
  INIT_IPQC_PRIORITIZATION_RUN_FILTER,
} from "./actionTypes";
export const initialState = fromJS({
  tableRowData: {},
  exportFields: {},
  filterNames: {},
  headerFields: [],
  pagesCount: 0,
  roleBasedActions: [],
  filterFields: {},
  pageLoader: false,
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_IPQC_PRIORITIZATION_DATA:
      return state.set("pageLoader", true);
    case INIT_IPQC_PRIORITIZATION_RUN_FILTER:
      return state.set("pageLoader", true);
    case FETCH_IPQC_PRIORITIZATION_DATA:
      return state.set("tableRowData", action.data).set("pageLoader", false);
    case FETCH_IPQC_PRIORITIZATION_EXPORT_FIELDS:
      return state.set("exportFields", action.data);
    case FETCH_IPQC_PRIORITIZATION_FILTER_NAME:
      return state.set("filterNames", action.data);
    case FETCH_IPQC_PRIORITIZATION_HEADER_FIELDS:
      return state.set("headerFields", action.data);
    case FETCH_IPQC_PRIORITIZATION_PAGES_COUNT:
      return state.set("pagesCount", action.data);
    case FETCH_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS:
      return state.set("roleBasedActions", action.data);
    case FETCH_IPQC_PRIORITIZATION_FILTER_FIELDS:
      return state.set("filterFields", action.data);
    case INIT_IPQC_PRIORITIZATION_PAGE_EXIT:
      return state
        .set("tableRowData", {})
        .set("exportFields", {})
        .set("filterNames", {})
        .set("headerFields", {})
        .set("pagesCount", 0)
        .set("roleBasedActions", [])
        .set("filterFields", {})
        .set("pageLoader", 0);
    default:
      return state;
  }
};
export default reducer;
