import {
  INIT_FETCH_APPH_FILTER_COL_NAMES,
  FETCHING_APPH_FILTER_COL_NAMES,
  APPH_FILTER_COL_NAMES_FETCHED,
  APPH_FILTER_COL_NAMES_FETCH_COMPLETE,
  APPH_FILTER_COL_NAMES_FETCH_FAIL,
  ASSIGN_INITAL_DATA,
  INIT_SAVE_APPH_FILTERS,
  INIT_FETCH_APPH_LIST_HEADER_NAMES,
  FETCHING_APPH_LIST_HEADER_NAMES,
  APPH_LIST_HEADER_NAMES_FETCHED,
  APPH_LIST_HEADER_NAMES_FETCH_COMPLETE,
  APPH_LIST_HEADER_NAMES_FETCH_FAIL,
  INIT_FETCH_APPH_ROLE_BASED_ACTIONS,
  FETCHING_APPH_ROLE_BASED_ACTIONS,
  APPH_ROLE_BASED_ACTIONS_FETCHED,
  APPH_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
  APPH_ROLE_BASED_ACTIONS_FETCH_FAIL,
  INIT_FETCH_APPH_FILTER_NAMES,
  INIT_GET_APPH_COUNT,
  FETCHING_APPH_COUNT,
  FETCH_APPH_COUNT,
  APPH_COUNT_FETCHING_COMPLETE,
  INIT_FETCH_APPH_LIST,
  FETCHING_APPH_LIST,
  APPH_LIST_FETCHED,
  APPH_LIST_FETCH_COMPLETE,
  APPH_LIST_FETCH_FAIL,
  INIT_FETCH_APPH_FILTER_DETAILS,
  INIT_DELETE_APPH_FILTERS,
  INIT_GET_APPH_RIGHT_ALIGNED_FIELDS,
  APPH_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE,
  FETCHING_APPH_RIGHT_ALIGNED_FIELDS,
  FETCH_APPH_RIGHT_ALIGNED_FIELDS,
  INIT_GET_APPH_EXPORT_FIELDS_LIST,
  FETCHING_APPH_EXPORT_FIELDS_LIST,
  FETCH_APPH_EXPORT_FIELDS_LIST,
  APPH_EXPORT_FIELDS_LIST_FETCHING_COMPLETE,
  INIT_GET_APPH_EXPORT_RECORDS,
  FETCHING_APPH_EXPORT_RECORDS,
  FETCH_APPH_EXPORT_RECORDS,
  APPH_EXPORT_RECORDS_FETCHING_COMPLETE,
  INIT_GET_APPH_EXPORT_ALL_RECORDS,
  FETCHING_APPH_EXPORT_ALL_RECORDS,
  FETCH_APPH_EXPORT_ALL_RECORDS,
  APPH_EXPORT_ALL_RECORDS_FETCHING_COMPLETE,
} from "./actionTypes";

export const initFetchApPhColNames = (payload) => {
  return {
    type: INIT_FETCH_APPH_FILTER_COL_NAMES,
    payload,
  };
};
export const fetchingApPhColNames = () => {
  return {
    type: FETCHING_APPH_FILTER_COL_NAMES,
  };
};

export const fetchApPhColNames = (data) => {
  return {
    type: APPH_FILTER_COL_NAMES_FETCHED,
    data,
  };
};

export const apPhColNameFetchComplete = () => {
  return {
    type: APPH_FILTER_COL_NAMES_FETCH_COMPLETE,
  };
};
export const apPhColNameFetchFailed = () => {
  return {
    type: APPH_FILTER_COL_NAMES_FETCH_FAIL,
  };
};

export function assignInitalData() {
  return {
    type: ASSIGN_INITAL_DATA,
  };
}

export const initSaveApPhFilter = (payload, callback) => {
  return {
    type: INIT_SAVE_APPH_FILTERS,
    payload,
    callback,
  };
};

export const initFetchApPhListHeaderNames = (payload) => {
  return {
    type: INIT_FETCH_APPH_LIST_HEADER_NAMES,
    payload,
  };
};
export const fetchingApPhListHeaderNames = () => {
  return {
    type: FETCHING_APPH_LIST_HEADER_NAMES,
  };
};

export const fetchApPhListHeaderNames = (data) => {
  return {
    type: APPH_LIST_HEADER_NAMES_FETCHED,
    data,
  };
};

export const apPhListHeaderNameFetchComplete = () => {
  return {
    type: APPH_LIST_HEADER_NAMES_FETCH_COMPLETE,
  };
};
export const apPhListHeaderNameFetchFailed = () => {
  return {
    type: APPH_LIST_HEADER_NAMES_FETCH_FAIL,
  };
};

export const initFetchApPhRoleBasedActions = (payload) => {
  return {
    type: INIT_FETCH_APPH_ROLE_BASED_ACTIONS,
    payload,
  };
};
export const fetchingApPhRoleBasedActions = () => {
  return {
    type: FETCHING_APPH_ROLE_BASED_ACTIONS,
  };
};

export const fetchApPhRoleBasedActions = (data) => {
  return {
    type: APPH_ROLE_BASED_ACTIONS_FETCHED,
    data,
  };
};

export const apPhRoleBasedActionsFetchComplete = () => {
  return {
    type: APPH_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
  };
};
export const apPhRoleBasedActionsFetchFailed = () => {
  return {
    type: APPH_ROLE_BASED_ACTIONS_FETCH_FAIL,
  };
};

export const initGetApPhFilterNames = (payload, callback) => {
  return {
    type: INIT_FETCH_APPH_FILTER_NAMES,
    payload,
    callback,
  };
};

export const initGetApPhCount = (payload) => {
  return {
    type: INIT_GET_APPH_COUNT,
    payload,
  };
};

export function fetchingApPhCount() {
  return {
    type: FETCHING_APPH_COUNT,
  };
}

export function fetchApPhCount(data) {
  return {
    type: FETCH_APPH_COUNT,
    data,
  };
}

export function apPhCountFetchingComplete() {
  return {
    type: APPH_COUNT_FETCHING_COMPLETE,
  };
}

export const initFetchApPhList = (payload) => {
  return {
    type: INIT_FETCH_APPH_LIST,
    payload,
  };
};

export const fetchingApPhList = () => {
  return {
    type: FETCHING_APPH_LIST,
  };
};

export const fetchApPhList = (data) => {
  return {
    type: APPH_LIST_FETCHED,
    data,
  };
};

export const apPhListFetchComplete = () => {
  return {
    type: APPH_LIST_FETCH_COMPLETE,
  };
};
export const apPhListFetchFailed = () => {
  return {
    type: APPH_LIST_FETCH_FAIL,
  };
};

export const initFecthApPhFilterDetails = (payload, callback) => {
  return {
    type: INIT_FETCH_APPH_FILTER_DETAILS,
    payload,
    callback,
  };
};

export const initDeleteApPhFilter = (payload, callback) => {
  return {
    type: INIT_DELETE_APPH_FILTERS,
    payload,
    callback,
  };
};

export const initGetApPhRightAlignedFields = (payload) => {
  return {
    type: INIT_GET_APPH_RIGHT_ALIGNED_FIELDS,
    payload,
  };
};

export function fetchingApPhRightAlignedFields() {
  return {
    type: FETCHING_APPH_RIGHT_ALIGNED_FIELDS,
  };
}

export function fetchApPhRightAlignedFields(data) {
  return {
    type: FETCH_APPH_RIGHT_ALIGNED_FIELDS,
    data,
  };
}

export function apPhRightAlignedFieldsFetchingComplete() {
  return {
    type: APPH_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE,
  };
}

export const initGetApPhExportFieldsList = (payload) => {
  return {
    type: INIT_GET_APPH_EXPORT_FIELDS_LIST,
    payload,
  };
};

export function fetchingApPhExportFieldsList() {
  return {
    type: FETCHING_APPH_EXPORT_FIELDS_LIST,
  };
}

export function fetchApPhExportFieldsList(data) {
  return {
    type: FETCH_APPH_EXPORT_FIELDS_LIST,
    data,
  };
}

export function apPhExportFieldsListFetchingComplete() {
  return {
    type: APPH_EXPORT_FIELDS_LIST_FETCHING_COMPLETE,
  };
}


export const initGetApPhExportRecords = (payload) => {
  return {
    type: INIT_GET_APPH_EXPORT_RECORDS,
    payload,
  };
};

export function fetchingApPhExportRecords() {
  return {
    type: FETCHING_APPH_EXPORT_RECORDS,
  };
}

export function fetchApPhExportRecords(data) {
  return {
    type: FETCH_APPH_EXPORT_RECORDS,
    data,
  };
}

export function apPhExportRecordsFetchingComplete() {
  return {
    type: APPH_EXPORT_RECORDS_FETCHING_COMPLETE,
  };
}


export const initGetApPhExportAllRecords = (payload) => {
  return {
    type: INIT_GET_APPH_EXPORT_ALL_RECORDS,
    payload,
  };
};

export function fetchingApPhExportAllRecords() {
  return {
    type: FETCHING_APPH_EXPORT_ALL_RECORDS,
  };
}

export function fetchApPhExportAllRecords(data) {
  return {
    type: FETCH_APPH_EXPORT_ALL_RECORDS,
    data,
  };
}

export function apPhExportAllRecordsFetchingComplete() {
  return {
    type: APPH_EXPORT_ALL_RECORDS_FETCHING_COMPLETE,
  };
}

