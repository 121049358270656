import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import {
  CompletedJobGetFilterName,
  completedJobsGetEditFilterFields,
  CompletedJobsViewJobListing,
  CompletedJobDeleteAdvanceFilter,
  CompletedJobsAddAdvanceFilter,
  CompletedJobsCount,
  CompletedJobsViewJobDetails,
  CompletedJobsGetEditFilterPageData,
  RunningJobsClientCompletedJobsList,
  RunningJobsCount,
} from "../../../config/APINames";
import {
  fetchColNames,
  fetchingJobColNames,
  fetchingJobCount,
  fetchingJobDetails,
  fetchingJobList,
  fetchJobCount,
  fetchJobDetails,
  fetchJobList,
  jobColNameFetchComplete,
  jobColNameFetchFailed,
  jobCountFetchingComplete,
  jobDetailsFetchingComplete,
  jobListFetchComplete,
  jobListFetchFailed,
} from "./actions";
import {
  INIT_DELETE_JOB_FILTERS,
  INIT_FETCH_JOB_FILTER_COL_NAMES,
  INIT_FETCH_JOB_FILTERS,
  INIT_FETCH_JOB_LIST,
  INIT_JOB_FILTER_FETCH,
  INIT_SAVE_FILTER,
  INIT_GET_JOB_COUNT,
  INIT_GET_COMPLETED_JOB_DETAILS,
  INIT_CLIENT_COMPLETED_JOB_LIST,
  INIT_CLIENT_JOB_COUNT,
} from "./actionTypes";
import { storage } from "../../../config/storage";

export default function* watchLogin() {
  yield all([
    takeLatest(INIT_FETCH_JOB_LIST, initJobListFetchSaga),
    takeLatest(INIT_FETCH_JOB_FILTER_COL_NAMES, initGetJobColNamesSaga),
    takeLatest(INIT_FETCH_JOB_FILTERS, initGetFilterNamesSaga),
    takeLatest(INIT_JOB_FILTER_FETCH, initGetFilterDetailsSaga),
    takeLatest(INIT_DELETE_JOB_FILTERS, initDeleteFilterDetailsSaga),
    takeLatest(INIT_SAVE_FILTER, initSaveFilterSaga),
    takeLatest(INIT_GET_JOB_COUNT, initGetJobCountSaga),
    takeLatest(INIT_GET_COMPLETED_JOB_DETAILS, initGetJobDetailsSaga),
    takeLatest(INIT_CLIENT_COMPLETED_JOB_LIST, initClientJobListSaga),
    takeLatest(INIT_CLIENT_JOB_COUNT, initClientJobCountSaga),
  ]);
}

function* initJobListFetchSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName, userId: ExceptionSetId } = sessionDetails || {};
  yield put(fetchingJobList());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      userName,
      ExceptionSetId,
    });
    const response = yield call(
      axios,
      CompletedJobsViewJobListing,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(fetchJobList(response.data));
      yield put(jobListFetchComplete());
    } else {
      yield put(jobListFetchFailed());
    }
  } catch (error) {
    yield put(jobColNameFetchFailed());
  }
}

function* initGetJobColNamesSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userId, userName,  clientName } = sessionDetails || {};
  yield put(fetchingJobColNames());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userId,
      userName,
      
      clientName,
    });
    const response = yield call(
      axios,
      completedJobsGetEditFilterFields,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(fetchColNames(response.data));
      yield put(jobColNameFetchComplete());
    } else {
      yield put(jobColNameFetchFailed());
    }
  } catch (error) {
    yield put(jobColNameFetchFailed());
  }
}

function* initGetFilterNamesSaga(action) {
  const { payload } = action || {};
  
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
     
    });
    const response = yield call(
      axios,
      CompletedJobGetFilterName,
      requestDetails
    );
    if (action.callback) action.callback(response);
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetFilterDetailsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userId, userName, sourceDomain, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userId,
      userName,
      sourceDomain,
      clientName,
    });
    const response = yield call(
      axios,
      CompletedJobsGetEditFilterPageData,
      requestDetails
    );
    if (action.callback) action.callback(response);
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initDeleteFilterDetailsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userName,
      clientName,
    });
    const response = yield call(
      axios,
      CompletedJobDeleteAdvanceFilter,
      requestDetails
    );
    if (action.callback) action.callback(response);
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initSaveFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const {
    userId,
    userName,
    sourceDomain: domainId,
    clientName,
  } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      UserId: userId,
      UserName: userName,
      DomainId: domainId.toString(),
      ClientName: clientName,
    });
    const response = yield call(
      axios,
      CompletedJobsAddAdvanceFilter,
      requestDetails
    );
    if (action.callback) action.callback(response);
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetJobCountSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userId, userName } = sessionDetails || {};
  yield put(fetchingJobCount());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userId,
      userName,
    });
    const response = yield call(axios, CompletedJobsCount, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchJobCount(response.data));
    }
    yield put(jobCountFetchingComplete());
  } catch (error) {
    yield put(jobCountFetchingComplete());
  }
}

function* initGetJobDetailsSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName } = sessionDetails || {};
  yield put(fetchingJobDetails());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      userName,
    });
    const response = yield call(
      axios,
      CompletedJobsViewJobDetails,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(fetchJobDetails(response.data));
    }
    yield put(jobDetailsFetchingComplete());
  } catch (error) {
    yield put(jobDetailsFetchingComplete());
  }
}
function* initClientJobListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RunningJobsClientCompletedJobsList,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(fetchJobList(response.data.resultSet.completedListing));
    }
    yield put(jobListFetchComplete());
  } catch (error) {
    yield put(fetchJobList([]));
    yield put(jobListFetchComplete());
  }
}

function* initClientJobCountSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, RunningJobsCount, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchJobCount(response.data.resultSet.completedListing));
    }
    yield put(jobCountFetchingComplete());
  } catch (error) {
    yield put(fetchJobCount(0));
    yield put(jobCountFetchingComplete());
  }
}
