import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
import DateInput from "./DateInputs";

const DatePick = (props) => {
  let [openDatePicker, setopenDatePicker] = useState(false);
  let handleonChange = (date) => {
    props.onChange(date);
    setopenDatePicker(!openDatePicker);
  };
  return (
    <div className="date-picker">
      <DatePicker
      selected={props.selectedDate}
      onChange={(date) => {
        handleonChange(date);
      }}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      scrollableYearDropdown
      scrollableMonthDropdown
      open={openDatePicker}
      id={props.id}
      name={props.name}
      required={props.required}
      showError={props.showError}
      noFloating={props.noFloating}
      onFocusHandler={props.onFocusHandler}
      placement={props.position || "auto"}
      popperPlacement={props.position || "bottom-end"}
      showPopperArrow={false}
      popperProps={{
        strategy: 'fixed'
      }}
      minDate={props.minDate || ""}
      showDisabledMonthNavigation={props.showDisabledMonthNavigation || false}
      customInput={
        <DateInput
          setDatePicker={() => setopenDatePicker(!openDatePicker)}
          value={props.selectedDate}
          label={props.label}
          type={props.inputType}
        />
      }
    />
    </div>
  );
};
export default DatePick;
