import {
  INIT_AI_ADHOC_RUN_DUPS,
  INIT_AI_ADHOC_EXPORT_EXCEPTIONS,
  INIT_AI_ADHOC_JOB_DETAILS,
  INIT_AI_ADHOC_JOB_LIST,
  INIT_AI_ADHOC_EXCEL_VALIDATION,
  FETCH_AI_ADHOC_JOB_DETAILS,
  FETCH_AI_ADHOC_JOB_LIST,
  INIT_AI_ADHOC_INITAL_STATE,
} from "./actionTypes";

export const initRunAdhocDups = (payload, callback) => {
  return {
    type: INIT_AI_ADHOC_RUN_DUPS,
    payload,
    callback,
  };
};
export const initExportExceptions = (payload, callback) => {
  return {
    type: INIT_AI_ADHOC_EXPORT_EXCEPTIONS,
    payload,
    callback,
  };
};
export const initJobDetails = (payload, callback) => {
  return {
    type: INIT_AI_ADHOC_JOB_DETAILS,
    payload,
    callback,
  };
};
export const initJobList = (payload, callback) => {
  return {
    type: INIT_AI_ADHOC_JOB_LIST,
    payload,
    callback,
  };
};
export const fetchJobDetails = (data) => {
  return {
    type: FETCH_AI_ADHOC_JOB_DETAILS,
    data,
  };
};
export const fetchJobList = (data) => {
  return {
    type: FETCH_AI_ADHOC_JOB_LIST,
    data,
  };
};

export const initExcelValidation = (payload, callback) => {
  return {
    type: INIT_AI_ADHOC_EXCEL_VALIDATION,
    payload,
    callback,
  };
};

export const initInitalState = () => {
  return {
    type: INIT_AI_ADHOC_INITAL_STATE,
  };
};
