import { fromJS } from "immutable";
import {
    FETCHING_DATA_RETRIVING_STATUS
} from "./actionTypes";

export const initialState = fromJS({
    fetchingStatus:false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_DATA_RETRIVING_STATUS: {
      return state
        .set("fetchingStatus", action.data)
    }
    default:
      return state;
  }
};

export default reducer;
