import { fromJS } from "immutable";
import {
    INIT_LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESSFULL,
    UPDATE_AUTH_DETAILS,
    LOGOUT,
    SSO_CALLBACK_AUTH,
    FETCH_APPLICATION_NAME,
} from "./actionTypes";

export const initialState = fromJS({
    loginLoading: false,
    loginStatus: false,
    authDetails: {},
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_LOGIN: {
            return state.set("loginLoading", true);
        }
        case LOGIN_SUCCESSFULL: {
            return state
                .set("loginStatus", true)
                .set("loginLoading", false)
                .set("authDetails", action.data);
        }
        case LOGIN_FAILED: {
            return state.set("loginLoading", false);
        }
        case UPDATE_AUTH_DETAILS: {
            return state.set("authDetails", action.data);
        }
        case LOGOUT: {
            return state.set("loginStatus", false);
        }
        case SSO_CALLBACK_AUTH: {
            return state.set("loginLoading", true);
        }
        case FETCH_APPLICATION_NAME: {
            return state.set("applicationname", action.data);
        }
        default:
            return state;
    }
};

export default reducer;
