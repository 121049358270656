export const customStyles = {
  container: (base, state) => ({
    ...base,
    position: "relative",
    boxSizing: "border-box",
    width: "100%",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
  }),

  indicatorsContainer: (base, state) => ({
    ...base,
    position: "absolute",
    right: "0",
    bottom: "0.25rem",
  }),
  menu: (base) => ({
    ...base,
    padding: "0.5rem",
    left: 0,
    boxShadow: "0rem 0rem 0.375rem rgba(0, 0, 0, 0.15)",
    zIndex: 3,
    marginBottom: "1.925rem",
    marginTop: "0rem",
  }),
  placeholder: (base, state) => ({
    ...base,
    fontFamily: "Graphik-Regular",
    color: state.isDisabled ?"rgb(96, 111, 125)":"rgb(33, 43, 54)",
    fontSize: "0.875rem",
  }),
  valueContainer: (base, state) => ({
    ...base,
    fontSize: "1rem",
    alignItems: "baseline",
    padding: "0 0.8rem",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "0.75em",
  }),
  control: (base, state) => {
    const { isDisabled } = state.selectProps;
    return {
      ...base,
      height: "0",
      minHeight: "1.6rem",
      borderWidth: `0.1rem`,
      backgroundColor: "transparent",
      borderRadius: "0.5em",
      cursor: isDisabled ? "not-allowed" : "pointer",
      "&:hover": {
        border: "none",
        boxShadow: "none",
      },
      borderColor: "transparent",
      boxShadow: "none",
      border: "none",
      alignItems: "baseline",
    };
  },
};
