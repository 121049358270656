export const FETCH_CLIENTS_LIST="FETCH_CLIENTS_LIST";
export const INIT_GET_QUEUED_JOB_LIST="INIT_GET_QUEUED_JOB_LIST";
export const FETCH_QUEUED_JOB_LIST="FETCH_QUEUED_JOB_LIST";
export const INIT_GET_QUEUE_SUSPEND_STATE="INIT_GET_QUEUE_SUSPEND_STATE";
export const FETCH_QUEUE_SUSPEND_STATE="FETCH_QUEUE_SUSPEND_STATE";
export const INIT_GET_SUSPENDED_QUEUE="INIT_GET_SUSPENDED_QUEUE";
export const INIT_GET_RESUMED_QUEUE="INIT_GET_RESUMED_QUEUE";
export const INIT_DELETE_QUEUE="INIT_DELETE_QUEUE";
export const INIT_SAVE_REORDERED_JOBS="INIT_SAVE_REORDERED_JOBS";
export const FETCH_SAVE_REORDERED_JOBS_COMPLETE="FETCH_SAVE_REORDERED_JOBS_COMPLETE";
export const FETCH_RESUMEQUEUE_COMPLETE="FETCH_RESUMEQUEUE_COMPLETE";
export const FETCH_SUSPENDQUEUE_COMPLETE="FETCH_SUSPENDQUEUE_COMPLETE";
export const INIT_GET_CLIENT_QUEUEDJOB_LISTING="INIT_GET_CLIENT_QUEUEDJOB_LISTING";
export const FETCH_CLIENT_QUEUEDJOB_LISTING="FETCH_CLIENT_QUEUEDJOB_LISTING";