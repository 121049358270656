import { fromJS } from "immutable";
import {
    INIT_GET_CLIENT_QUEUE_SUMMARY, INIT_GET_CLIENT_QUEUE_SUMMARY_FAILED, FETCH_GET_CLIENT_QUEUE_SUMMARY_LIST
} from "./actionTypes";

export const initialState = fromJS({
    clientQueueSummaryList: {},
    loader: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {        
        case INIT_GET_CLIENT_QUEUE_SUMMARY: {
            return state.set("loader", true);
        }
        case INIT_GET_CLIENT_QUEUE_SUMMARY_FAILED: {
            return state.set("loader", false);
        }
        case FETCH_GET_CLIENT_QUEUE_SUMMARY_LIST: {
            return state.set("clientQueueSummaryList", fromJS(action.data)).set("loader", false);
        }
        default:
            return state;
    }
};

export default reducer;

