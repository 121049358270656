import {
  INIT_GET_GRID_CATEGORY,
  FETCH_GRID_CATEGORY,
  INIT_GRID_CATEGORY_LISTING_LIST,
  FETCH_GRID_CATEGORY_LISTING_LIST,
  INIT_GRID_DETAILS,
  FETCH_GRID_DETAILS,
  INIT_SAVE_GRID_DETAILS
} from "./actionsTypes";

export const initGetGridCategory = (payload, callback) => {
  return {
    type: INIT_GET_GRID_CATEGORY,
    payload,
    callback,
  };
};
export const fetchGridCategory = (data) => {
  return {
    type: FETCH_GRID_CATEGORY,
    data,
  };
};
export const initGridListingList = (payload) => {
  return {
    type: INIT_GRID_CATEGORY_LISTING_LIST,
    payload,
  };
};

export const fetchGridListingList = (data) => {
  return {
    type: FETCH_GRID_CATEGORY_LISTING_LIST,
    data,
  };
};
export const initGridDetails = (payload,callback) => {
  return {
    type: INIT_GRID_DETAILS,
    payload,
    callback
  };
};
export const fetchGridDetails = (data) => {
  return {
    type: FETCH_GRID_DETAILS,
    data,
  };
};
export const initSaveGridDetails = (payload,callback)=>{
  return{
    type: INIT_SAVE_GRID_DETAILS,
    payload,
    callback
  }
}
