const customStyles = {
  container: (base, state) => {
    const { customStyle } = state.selectProps;
    const { container } = customStyle || {};
    return {
      ...base,
      position: "relative",
      boxSizing: "border-box",
      width: "100%",
      minWidth: "3.75rem",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      ...container,
    };
  },
  singleValue: (base, state) => ({
    ...base,
    fontSize: "0.875rem",
    color: state.selectProps.isDisabled
      ? "rgb(181,192,202)"
      : "rgb(99, 115, 129)",
  }),
  menu: (base, state) => {
    const { customStyle } = state.selectProps;
    const { menu } = customStyle || {};
    return {
      ...base,
      boxShadow: "0rem 0rem 0.375rem rgba(0, 0, 0, 0.15)",
      left: 0,
      zIndex: 3,
      margin: 0,
      ...menu,
    };
  },

  option: (base, state) => ({
    ...base,
    borderRadius: "0.25em",
    backgroundColor: state.isSelected
      ? "rgb(244, 251, 255)"
      : state.isFocused
      ? "rgb(0, 65, 240)"
      : "rgb(255, 255, 255)",
    color: state.isSelected
      ? "rgb(0, 65, 240)"
      : state.isFocused
      ? "rgb(255, 255, 255)"
      : "rgb(123, 142, 152)",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    fontFamily: "Graphik-Regular",
    fontSize: "0.875rem",
    padding: "0.5rem",
  }),
};

export default customStyles;
