import React from "react";
import Hamburger from "../../../../assets/images/layout/header/hamburger.svg";
import Close from "../../../../assets/images/layout/header/close.svg";
import { useTranslation } from "react-i18next";
const NavToggler = (props) => {
  const {t} =useTranslation();
  const handleEnterHit = ({ key }) => {
    if (key === "Enter") props.onMenuToggle();
  };
  return (
    <>
      <label className="nav-toggler">
        <input
          className="nav-toggle"
          type="checkbox"
          checked={props.isOpen}
          onChange={props.onMenuToggle}
          onKeyUp={handleEnterHit}
        />
        {props.isOpen ? (
          <img src={Close} 
          alt={t('atcat.toggleMenu')} />
        ) : (
          <img src={Hamburger} 
          alt={t('atcat.toggleMenu')} />
        )}
      </label>
    </>
  );
};

export default NavToggler;
