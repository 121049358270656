import React, { useState } from "react";
import Confirm from "../Button/Confirm";
import { ReactComponent as Cross } from "../../assets/images/common/crossInCircle.svg";
import { ReactComponent as Back } from "../../assets/images/common/CircleLeft.svg";
import { useTranslation } from "react-i18next";
import "./style.scss";

const ModuleHeader = (props) => {
  const { t } = useTranslation();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  return (
    <section className="module-header">
      {props.handleGoingBack ? (
        <button
          aria-label={t("atcat.goToPreviousPage")}
          className="icon-wrapper icon-wrapper-back"
          onClick={props.handleGoingBack}
          title={t("atcat.goToPreviousPage")}
        >
          <Back />
        </button>
      ) : null}
      <h1 className="module-header-title">{props.title}</h1>

      {props.enableNavigation ? (
        <div className="duplicate-navigation-wrapper">
          {props.disabledPrev ? (
            ""
          ) : (
            <button
              aria-label={t("atcat.goToPreviousPage")}
              className="icon-wrapper prev-wrapper icon-wrapper-back"
              onClick={props.handlePrev}
              title={t("atcat.goToPreviousPage")}
            >
              <Back />
            </button>
          )}
          {props.disabledNext ? (
            ""
          ) : (
            <button
              aria-label={t("atcat.goToNextPage")}
              className="icon-wrapper  navigation-wrapper icon-wrapper-back"
              onClick={props.handleNext}
              title={t("atcat.goToNextPage")}
            >
              <Back />
            </button>
          )}
          <span className="separator-line"></span>
          <button
            aria-label={t("atcat.close")}
            className="icon-wrapper icon-wrapper-styled"
            onClick={() => {
              props.showConfirmation
                ? setShowConfirmationPopup(true)
                : props.handleCancel();
            }}
            title={t("atcat.close")}
          >
            <Cross />
          </button>
        </div>
      ) : props.handleCancel ? (
        <button
          aria-label={t("atcat.close")}
          className="icon-wrapper icon-wrapper-styled"
          onClick={() => {
            props.showConfirmation
              ? setShowConfirmationPopup(true)
              : props.handleCancel();
          }}
          title={t("atcat.close")}
        >
          <Cross />
        </button>
      ) : null}
      {props.showConfirmation && (
        <Confirm
          showConfirmation={showConfirmationPopup}
          handleConfirmation={() =>
            props.handleCancel(false) && setShowConfirmationPopup(false)
          }
          message={t("atcat.confirmCancel")}
          handleCancel={() => setShowConfirmationPopup(false)}
        />
      )}
    </section>
  );
};

export default ModuleHeader;
