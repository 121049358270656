import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from "../../../config/index";
import generateKey from "../../../config/generateKey";

import { storage } from "../../../config/storage";
import exportToExcel from "../../../helper/exportToExcel";
import {
  SavedFilterFilterFields,
  SavedFilterAddAdvancedFilter,
  SavedFilterFilterName,
  SavedFilterFilterCount,
  SavedFilterRunFilterWithSort,
  SavedFilterEditFilterPageData,
  SavedFilterDeleteAdvancedFilter,
  SavedFilterDuplicateExportToExcel,
  SavedFilterDuplicateExportAllToExcel,
  SavedFilterReleaseExceptionsInExceptionQuery,
  ClientUDLGetUDLDetails,
  UserManagementGetUserList,
  UserActionAuthenticationGetRoleBasedActions,
  ReVisitedDupsUnattendedDupsExceptionListHeaders,
  ReVisitedDupsUnattendedExceptionRecords,
  DairyGetReportDataDairyList,
  ReVisitedClaimsDocumentsList,
  ReVisitedClaimsUpdateDocument,
  ReVisitedClaimsAddDocument,
  ReVisitedClaimsDocumentDetails,
  DairyAddDairy,
  DairyGetDairyDetails,
  ReVisitedClaimsDeleteDocument,
  DairyUpdateDairy,
  HistoryCount,
  HistoryData,
  ReVisitedDupsUnattendedPost,
  MoveAndCopyToClaim,
  DairyUpdateCompletedFlag,
  DairyUpdateUnCompletedFlag,
  ReVisitedDupsUnattendedSaveExceptionDetails,
  ReVisitedDupsUnattendedPrimusDFMURL,
} from "../../../config/APINames";
import {
  INIT_FETCH_EXCEPTION_FILTER_COL_NAMES,
  INIT_SAVE_EXCEPTION_FILTER,
  INIT_FETCH_EXCEPTION_FILTER_NAMES,
  INIT_GET_EXCEPTION_COUNT,
  INIT_FETCH_EXCEPTION_LIST,
  INIT_FETCH_EXCEPTION_FILTER_DETAILS,
  INIT_DELETE_EXCEPTION_FILTER,
  INIT_GET_EXCEPTION_RECORDS_EXPORT,
  INIT_GET_EXCEPTION_RECORDS_EXPORT_ALL,
  INIT_GET_RELEASE_EXCEPTION,
  INIT_GET_INVESTIGATION_TYPE,
  INIT_USERMANGEMENT_GET_USERS_LIST,
  INIT_GET_DUPS_TYPE,
  INIT_USER_ROLE_BASED_ACTION,
  INIT_FETCH_EXCEPTION_RECORD_HEADER,
  INIT_GET_EXCEPTION_RECORD_LIST,
  INIT_GET_DIARY_LIST,
  INIT_GET_DOCUMENTS_LIST,
  INIT_ADD_DOCUMENT,
  INIT_DOCUMENT_DETAILS,
  INIT_UPDATE_DOCUMENT,
  INIT_GET_DIARY_TYPE,
  INIT_SAVE_ADD_DIARY,
  INIT_GET_DIARY_DETAILS,
  INIT_DELETE_DOCUMENT,
  INIT_UPDATE_DIARY_DETAILS,
  INIT_HISTORY_COUNT,
  INIT_HISTORY_DATA,
  INIT_DUPLICATE_EXCEPTIONS_POST,
  INIT_MOVE_AND_COPY_TO_CLAIM,
  INIT_MARK_DIARY_COMPLETE,
  INIT_UNMARK_DIARY_COMPLETE,
  INIT_SAVE_DUPLICATE_EXCEPTIONS_DETAILS,
  INIT_GET_PRIMUS_DFM_URL_DETAILS,
} from "./actionTypes";
import {
  fetchExceptionColNames,
  fetchingExceptionColNames,
  exceptionColNameFetchComplete,
  exceptionColNameFetchFailed,
  fetchExceptionCount,
  fetchingExceptionCount,
  exceptionCountFetchingComplete,
  fetchExceptionList,
  fetchingExceptionList,
  exceptionListFetchComplete,
  exceptionListFetchFailed,
  fetchingExceptionRecodsExport,
  fetchExceptionRecordsExport,
  exceptionRecordsExportFetchingComplete,
  fetchingExceptionRecodsExportAll,
  fetchExceptionRecordsExportAll,
  exceptionRecordsExportAllFetchingComplete,
  fetchReleaseException,
  fetchingReleaseException,
  releaseExceptionFetchingComplete,
  fetchingInvestigationType,
  fetchInvestigationType,
  investigationTypeFetchingComplete,
  fetchUsermangementGetUsersList,
  fetchingDupsType,
  fetchDupsType,
  dupsTypeFetchingComplete,
  fetchUserRoleBasedAction,
  fetchingExceptionRecordHeader,
  fetchExceptionRecordHeader,
  exceptionRecordHeaderFetchingComplete,
  fetchingExceptionRecordList,
  fetchExceptionRecordList,
  exceptionRecordListFetchingComplete,
  fetchingDiaryList,
  fetchDiaryList,
  diaryListFetchingComplete,
  fetchDocumentsList,
  fetchDiaryType,
  fetchingDiaryType,
  diaryTypeFetchingComplete,
  addDiaryFailed,
  addDiarySuccess,
  fetchingDiaryDetails,
  diaryDetailsFetchingComplete,
  fetchingDeleteDocument,
  deleteDocumentFetchingComplete,
  updateDiaryFailed,
  updateDiarySuccess,
  fetchHistoryCount,
  fetchHistoryData,
  markDiaryCompleteFailed,
  markDiaryCompleteSuccess,
  unmarkDiaryCompleteFailed,
  unmarkDiaryCompleteSuccess,
  fetchBulkReleaseReasonType,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(
      INIT_FETCH_EXCEPTION_FILTER_COL_NAMES,
      initGetExceptionColNamesSaga
    ),
    takeLatest(INIT_SAVE_EXCEPTION_FILTER, initSaveExceptionFilterSaga),
    takeLatest(
      INIT_FETCH_EXCEPTION_FILTER_NAMES,
      initGetExceptionFilterNamesSaga
    ),
    takeLatest(INIT_GET_EXCEPTION_COUNT, initGetExceptionCountSaga),
    takeLatest(INIT_FETCH_EXCEPTION_LIST, initExceptionListFetchSaga),
    takeLatest(
      INIT_FETCH_EXCEPTION_FILTER_DETAILS,
      initGetExceptionFilterDetailsSaga
    ),
    takeLatest(INIT_DELETE_EXCEPTION_FILTER, initDeleteExceptionFilterSaga),
    takeLatest(
      INIT_GET_EXCEPTION_RECORDS_EXPORT,
      initGetExceptionRecordsExportSaga
    ),
    takeLatest(
      INIT_GET_EXCEPTION_RECORDS_EXPORT_ALL,
      initGetExceptionRecordsExportAllSaga
    ),
    takeLatest(INIT_GET_RELEASE_EXCEPTION, initGetReleaseExceptionSaga),
    takeLatest(INIT_GET_INVESTIGATION_TYPE, initGetInvestigationTypeSaga),
    takeLatest(
      INIT_USERMANGEMENT_GET_USERS_LIST,
      initUserManagementUsersListSaga
    ),
    takeLatest(INIT_GET_DUPS_TYPE, initGetDupsTypeSaga),
    takeLatest(INIT_USER_ROLE_BASED_ACTION, initGetRoleBasedActionsSaga),
    takeLatest(
      INIT_FETCH_EXCEPTION_RECORD_HEADER,
      initGetExceptionRecordHeaderSaga
    ),
    takeLatest(INIT_GET_EXCEPTION_RECORD_LIST, initGetExceptionRecordListSaga),
    takeLatest(INIT_GET_DIARY_LIST, initGetDiaryListSaga),
    takeLatest(INIT_GET_DOCUMENTS_LIST, initGetDocumentsListSaga),
    takeLatest(INIT_ADD_DOCUMENT, initAddDocumentSaga),
    takeLatest(INIT_DOCUMENT_DETAILS, initDocumentDetailsSaga),
    takeLatest(INIT_UPDATE_DOCUMENT, initUdateDocumentDetailsSaga),
    takeLatest(INIT_GET_DIARY_TYPE, initGetDiaryTypeSaga),
    takeLatest(INIT_SAVE_ADD_DIARY, initSaveAddDiary),
    takeLatest(INIT_GET_DIARY_DETAILS, initGetDiaryDetails),
    takeLatest(INIT_DELETE_DOCUMENT, initDeleteDocument),
    takeLatest(INIT_UPDATE_DIARY_DETAILS, initUpdateDiaryDetails),
    takeLatest(INIT_HISTORY_COUNT, initHistoryCountSaga),
    takeLatest(INIT_HISTORY_DATA, initHistoryDataSaga),
    takeLatest(INIT_DUPLICATE_EXCEPTIONS_POST, initDuplicateExceptionsPostSaga),
    takeLatest(INIT_MOVE_AND_COPY_TO_CLAIM, initMoveAndCopyToClaimSaga),
    takeLatest(INIT_MARK_DIARY_COMPLETE, initMarkDiaryComplete),
    takeLatest(INIT_UNMARK_DIARY_COMPLETE, initUnmarkDiaryComplete),
    takeLatest(
      INIT_SAVE_DUPLICATE_EXCEPTIONS_DETAILS,
      initSaveDuplicateExceptionDetailsSaga
    ),
    takeLatest(INIT_GET_PRIMUS_DFM_URL_DETAILS, initGetPrimusDFMUrlSaga),
  ]);
}

function* initGetExceptionColNamesSaga(action) {
  const { payload } = action || {};
  yield put(fetchingExceptionColNames());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, SavedFilterFilterFields, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchExceptionColNames(response.data));
      yield put(exceptionColNameFetchComplete());
    } else {
      yield put(exceptionColNameFetchFailed());
    }
  } catch (error) {
    yield put(exceptionColNameFetchFailed());
  }
}

function* initSaveExceptionFilterSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      SavedFilterAddAdvancedFilter,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetExceptionFilterNamesSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, sourceDomain, clientName, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userId,
      userName,
      sourceDomain,
      clientName,
    });
    const response = yield call(axios, SavedFilterFilterName, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetExceptionCountSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName } = sessionDetails || {};
  yield put(fetchingExceptionCount());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userName,
      clientName: clientName.toLowerCase(),
    });
    const response = yield call(axios, SavedFilterFilterCount, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchExceptionCount(response.data));
    }
    yield put(exceptionCountFetchingComplete());
  } catch (error) {
    yield put(exceptionCountFetchingComplete());
  }
}

function* initExceptionListFetchSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName, clientId } = sessionDetails || {};
  yield put(fetchingExceptionList());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      userName,
      ClientId: clientId,
    });
    const response = yield call(
      axios,
      SavedFilterRunFilterWithSort,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchExceptionList(response.data));
      yield put(exceptionListFetchComplete());
    } else {
      yield put(exceptionListFetchFailed());
    }
  } catch (error) {
    yield put(exceptionListFetchFailed());
  }
}

function* initGetExceptionFilterDetailsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, sourceDomain, clientName, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      sourceDomain,
      userId,
      userName,
    });
    const response = yield call(
      axios,
      SavedFilterEditFilterPageData,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initDeleteExceptionFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userName,
      clientName,
    });
    const response = yield call(
      axios,
      SavedFilterDeleteAdvancedFilter,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetExceptionRecordsExportSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName, clientId, selectedLanguage } =
    sessionDetails || {};
  yield put(fetchingExceptionRecodsExport());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName: clientName.toLowerCase(),
      userName,
      ClientID: clientId,
      language: selectedLanguage,
    });
    const response = yield call(
      axios,
      SavedFilterDuplicateExportToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchExceptionRecordsExport(response.data));
      exportToExcel(
        response.data.duplicateExceptionListing,
        "DuplicateExceptionsListing",
        response.data.exportfileName
      );
    }

    yield put(exceptionRecordsExportFetchingComplete());
  } catch (error) {
    yield put(exceptionRecordsExportFetchingComplete());
  }
}

function* initGetExceptionRecordsExportAllSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName, clientId, selectedLanguage } =
    sessionDetails || {};
  yield put(fetchingExceptionRecodsExportAll());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientname: clientName.toLowerCase(),
      userName,
      ClientID: clientId,
      language: selectedLanguage,
    });
    const response = yield call(
      axios,
      SavedFilterDuplicateExportAllToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchExceptionRecordsExportAll(response.data));
      exportToExcel(
        response.data.duplicateExceptionListing,
        "DuplicateExceptionsListing",
        response.data.exportfileName
      );
    }
    yield put(exceptionRecordsExportAllFetchingComplete());
  } catch (error) {
    yield put(exceptionRecordsExportAllFetchingComplete());
  }
}

function* initGetReleaseExceptionSaga(action) {
  const { payload, callback } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName } = sessionDetails || {};
  yield put(fetchingReleaseException());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientname: clientName.toLowerCase(),
      userName,
    });
    const response = yield call(
      axios,
      SavedFilterReleaseExceptionsInExceptionQuery,
      requestDetails
    );
    callback !== undefined && callback(response);
    yield put(releaseExceptionFetchingComplete());
  } catch (error) {
    yield put(releaseExceptionFetchingComplete());
  }
}

function* initGetInvestigationTypeSaga(action) {
  const { payload } = action || {};
  yield put(fetchingInvestigationType());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, ClientUDLGetUDLDetails, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchInvestigationType(response.data));
    }
    yield put(investigationTypeFetchingComplete());
  } catch (error) {
    yield put(investigationTypeFetchingComplete());
  }
}

function* initUserManagementUsersListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });

    const response = yield call(
      axios,
      UserManagementGetUserList,
      requestDetails
    );
    if (response && response.status === 200) {
      if (response.data.length !== 0) {
        yield put(fetchUsermangementGetUsersList(response.data));
      } else {
        yield put(fetchUsermangementGetUsersList(payload.userName));
      }
    }
  } catch (error) {
    yield put(exceptionColNameFetchFailed());
  }
}

function* initGetDupsTypeSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingDupsType());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, ClientUDLGetUDLDetails, requestDetails);
    if (response && response.status === 200) {
      if (payload.udl === "Bulk Release Reason Type") {
        response =
          response.data.udlTypeDetail.length > 0
            ? response.data.udlTypeDetail
            : [];
        yield put(fetchBulkReleaseReasonType(response));
        callback && callback(response);
      } else {
        yield put(fetchDupsType(response.data));
      }
    }
    yield put(dupsTypeFetchingComplete());
  } catch (error) {
    yield put(dupsTypeFetchingComplete());
  }
}

function* initGetRoleBasedActionsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });

    const response = yield call(
      axios,
      UserActionAuthenticationGetRoleBasedActions,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(fetchUserRoleBasedAction(response.data.resultSet.actionNames));
    }
  } catch (error) {
    yield put(exceptionColNameFetchFailed());
  }
}

function* initGetExceptionRecordHeaderSaga(action) {
  const { payload } = action || {};
  yield put(fetchingExceptionRecordHeader());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedDupsExceptionListHeaders,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchExceptionRecordHeader(response.data));
    }
    yield put(exceptionRecordHeaderFetchingComplete());
  } catch (error) {
    yield put(exceptionRecordHeaderFetchingComplete());
  }
}

function* initGetExceptionRecordListSaga(action) {
  const { payload } = action || {};
  yield put(fetchingExceptionRecordList());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedExceptionRecords,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchExceptionRecordList(response.data));
    }
    yield put(exceptionRecordListFetchingComplete());
  } catch (error) {
    yield put(exceptionRecordListFetchingComplete());
  }
}

function* initGetDiaryListSaga(action) {
  const { payload } = action || {};
  yield put(fetchingDiaryList());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      DairyGetReportDataDairyList,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchDiaryList(response.data.resultSet.DiaryDetails));
    }
    yield put(diaryListFetchingComplete());
  } catch (error) {
    yield put(diaryListFetchingComplete());
  }
}

function* initGetDocumentsListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsDocumentsList,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchDocumentsList(response.data));
    }
  } catch (error) {
    yield put(diaryListFetchingComplete());
  }
}

function* initAddDocumentSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsAddDocument,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      callback && callback(response.data);
    }
  } catch (error) {
    yield put(diaryListFetchingComplete());
  }
}

function* initDocumentDetailsSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsDocumentDetails,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      callback && callback(response.data);
    }
  } catch (error) {
    yield put(diaryListFetchingComplete());
  }
}
function* initUdateDocumentDetailsSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsUpdateDocument,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      callback && callback(response.data);
    }
  } catch (error) {
    yield put(diaryListFetchingComplete());
  }
}

function* initGetDiaryTypeSaga(action) {
  const { payload } = action || {};
  yield put(fetchingDiaryType());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, ClientUDLGetUDLDetails, requestDetails);
    if (response && response.status === 200) {
      yield put(fetchDiaryType(response.data));
    }
    yield put(diaryTypeFetchingComplete());
  } catch (error) {
    yield put(diaryTypeFetchingComplete());
  }
}

function* initSaveAddDiary(action) {
  const { payload } = action || {};
  yield put(addDiarySuccess());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    if (
      Date.parse(payload.FollowupDate) > Date.parse(payload.CommunicationDate)
    ) {
      const response = yield call(axios, DairyAddDairy, requestDetails);
      if (response.data.message === Error_Msg) {
        generateNotification(
          "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
          "danger"
        );
      } else if (response !== Error_Msg) {
        yield put(addDiarySuccess());
        if (action.callback) action.callback(response);
      }
    } else {
      generateNotification(
        "Followup date should be greater than Communication date",
        "danger"
      );
    }
  } catch (error) {
    yield put(addDiaryFailed());
  }
}

function* initGetDiaryDetails(action) {
  const { payload, callback } = action || {};
  yield put(fetchingDiaryDetails());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, DairyGetDairyDetails, requestDetails);
    if (response && response.status === 200) {
      callback && callback(response.data.getJobDetailsOfScheduleID[0]);
      yield put(diaryDetailsFetchingComplete());
    }
  } catch (error) {
    yield put(diaryDetailsFetchingComplete());
  }
}

function* initDeleteDocument(action) {
  const { payload, callback } = action || {};
  yield put(fetchingDeleteDocument());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsDeleteDocument,
      requestDetails
    );
    if (response && response.status === 200) {
      callback && callback(response.data);
      if (response.data === "Deleted document(s) successfully")
        generateNotification("atcat.documentsDeletedSuccessfully", "success");
      else generateNotification(generateKey(response.data), "success");
      yield put(deleteDocumentFetchingComplete());
    }
  } catch (error) {
    yield put(deleteDocumentFetchingComplete());
    generateNotification(generateKey(Error_Msg), "danger");
  }
}

function* initUpdateDiaryDetails(action) {
  const { payload } = action || {};
  yield put(updateDiarySuccess());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    if (
      Date.parse(payload.FollowupDate) > Date.parse(payload.CommunicationDate)
    ) {
      const response = yield call(axios, DairyUpdateDairy, requestDetails);
      if (response.data.message === Error_Msg) {
        generateNotification(
          "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
          "danger"
        );
      } else if (response !== Error_Msg) {
        yield put(updateDiarySuccess());
        if (action.callback) action.callback(response);
      }
    } else {
      generateNotification(
        "atcat.followupDateShouldBeGreaterThanAndEqualToCommunicationDate",
        "danger"
      );
    }
  } catch (error) {
    yield put(updateDiaryFailed());
  }
}

function* initHistoryCountSaga(action) {
  const { payload } = action || {};

  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, HistoryCount, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(generateKey(Error_Msg), "danger");
    } else if (response !== Error_Msg) {
      yield put(fetchHistoryCount(response.data));
    }
  } catch (error) {
    generateNotification(generateKey(Error_Msg), "danger");
  }
}

function* initHistoryDataSaga(action) {
  const { payload } = action || {};

  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, HistoryData, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(generateKey(Error_Msg), "danger");
    } else if (response !== Error_Msg) {
      yield put(fetchHistoryData(response.data));
    }
  } catch (error) {
    generateNotification(generateKey(Error_Msg), "danger");
  }
}

function* initDuplicateExceptionsPostSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      ClientName: clientName,
      UserName: userName,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedPost,
      requestDetails
    );
    if (response.data === Error_Msg)
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    else {
      if (
        response.data !==
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam"
      ) {
        if (action.callback) action.callback(response.data);
      } else {
        generateNotification(generateKey(response.data), "danger");
      }
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initMoveAndCopyToClaimSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, MoveAndCopyToClaim, requestDetails);
    if (response.data.resultStatus) {
      callback && callback(response.data);
    } else {
      generateNotification(generateKey(response.data.message), "danger");
    }
  } catch (error) {
    generateNotification(generateKey(Error_Msg), "danger");
  }
}

function* initMarkDiaryComplete(action) {
  const { payload, callback } = action || {};
  yield put(markDiaryCompleteSuccess());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      DairyUpdateCompletedFlag,
      requestDetails
    );
    if (response.data !== Error_Msg) {
      yield put(markDiaryCompleteSuccess());
      callback && callback(response.data);
    } else {
      generateNotification(generateKey(response.data), "danger");
    }
  } catch (error) {
    yield put(markDiaryCompleteFailed());
  }
}

function* initUnmarkDiaryComplete(action) {
  const { payload, callback } = action || {};
  yield put(unmarkDiaryCompleteSuccess());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      DairyUpdateUnCompletedFlag,
      requestDetails
    );
    if (response.data !== Error_Msg) {
      yield put(unmarkDiaryCompleteSuccess());
      callback && callback(response.data);
    } else {
      generateNotification(generateKey(response.data), "danger");
    }
  } catch (error) {
    yield put(unmarkDiaryCompleteFailed());
  }
}

function* initSaveDuplicateExceptionDetailsSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedSaveExceptionDetails,
      requestDetails
    );
    if (response.data === "Exception details saved successfully") {
      generateNotification(generateKey(response.data), "success");
    } else {
      generateNotification(generateKey(response.data), "danger");
    }
    callback && callback();
  } catch (error) {
    generateNotification(generateKey(Error_Msg), "danger");
  }
}

function* initGetPrimusDFMUrlSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedPrimusDFMURL,
      requestDetails
    );
    if (response.data !== Error_Msg) {
      callback && callback(response.data);
    } else generateNotification(generateKey(Error_Msg), "danger");
  } catch (error) {
    generateNotification(generateKey(Error_Msg), "danger");
  }
}
