import { fromJS } from "immutable";
import {
    FETCH_FOOTER,
    
} from "./actionTypes";

export const initialState = fromJS({
    footerValue: "",
   
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FOOTER: {
            return state.set("footerValue", action.data);
        }
        
        default:
            return state;
    }
};

export default reducer;
