import React from "react";
import Logo from "./Logo";
import NavToggler from "./NavToggler";
import CurrentUser from "./CurrentUser";

import "./style.scss";
import ChangeLang from "./ChangeLang";
import MyProfile from "./MyProfile";
const HeaderView = (props) => {
  return (
    <header className="header">
      <NavToggler isOpen={props.isOpen} onMenuToggle={props.onMenuToggle} {...props}/>
      <Logo {...props}/>
      <ul className="push-right header-right-content">
        <CurrentUser {...props}/>
        <li className="separator"></li>
        <ChangeLang {...props}/>
        <li className="separator"></li>
        <MyProfile {...props}/>
      </ul>
    </header>
  );
};

export default HeaderView;
