import React from "react";

const FolderIcon = (props) => {
  return (
    <svg
      className="icons"
      viewBox="0 0 24 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-753.000000, -230.000000)">
          <g  transform="translate(450.000000, 210.000000)">
            <g transform="translate(303.000000, 16.000000)">
              <path d="M21.9979167,10.6666667 L20,10.6666667 L20,8.66666666 C20,7.56208332 19.1045834,6.66666665 18,6.66666665 L11.3333333,6.66666665 L8.66666666,3.99999998 L1.99999997,3.99999998 C0.895416639,3.99999998 -3.00000004e-08,4.89541665 -3.00000004e-08,5.99999998 L-3.00000004e-08,18 C-3.00000004e-08,19.1045834 0.895416639,20 1.99999997,20 L18.0440417,20 C19.2135417,20 20.2996667,19.386125 20.9023334,18.3816667 L23.712875,13.6956667 C24.51,12.3671667 23.5535417,10.6666667 21.9979167,10.6666667 Z M1.99999998,5.33333332 L8.11437499,5.33333332 L10.7810417,7.99999999 L18,7.99999999 C18.3682083,7.99999999 18.6666667,8.29845832 18.6666667,8.66666666 L18.6666667,10.6666667 L7.13237499,10.6666667 C5.96287498,10.6666667 4.87674998,11.2805417 4.27408331,12.285 L1.33333331,17.18625 L1.33333331,5.99999998 C1.33333331,5.63179165 1.63179164,5.33333332 1.99999998,5.33333332 Z M22.570625,13.0096667 L19.759,17.6956667 C19.3975744,18.2980842 18.7465624,18.6666667 18.0440417,18.6666667 L1.86762497,18.6666667 L5.28499998,12.971 C5.64645117,12.3685881 6.29747095,12 6.99999999,12 L21.9989584,12 C22.5171667,12 22.83725,12.5653333 22.570625,13.0096667 L22.570625,13.0096667 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FolderIcon;
