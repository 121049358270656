import React, { Component } from "react";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { fetchStatements,initFooter} from "./store/actions";
import FooterView from "../../../components/Layout/Footer";
import { selectAuthDetails } from "../../Login/store/selectors";
import {selectFooterValue} from "./store/selector";
import { Button } from "../../../components";
import { withTranslation } from "react-i18next";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalFlag: false,
      children: {
        title: "",
        content: "",
      },
    };
    this.callBackfunc = this.callBackfunc.bind(this);
  }
  callBackfunc(data) {
    const { t } = this.props;
    let child = this.state.children;
    if (child.title === "Terms of Use") {
      child.content = (
        <>
          <div className="modal-content">{data.resultSet.ToUStat}</div>
          <div className="modal-button">
            <Button 
            label={t("atcat.close")}
            handleClick={() => {
              this.setState({ modalFlag: !this.state.modalFlag });
            }}
            />
          </div>
        </>
      );
    } else if (child.title === "Privacy Statement") {
      child.content = (
        <>
          <div className="modal-content">{data.resultSet.PSStat}</div>
          <div className="modal-button">
          <Button 
            label={t("atcat.close")}
            handleClick={() => {
              this.setState({ modalFlag: !this.state.modalFlag });
            }}
            />
          </div>
        </>
      );
    } else {
      child.content = (
        <>
          <div className="modal-content">
            {t("atcat.contactSupportAccentureDesc")}
            <br />
            <a
              href={data.resultSet.ContactSupport}
              target={"_blank"}
              rel={"noreferrer"}
            >
              {data.resultSet.ContactSupport}
            </a>
            <br />
            <br />
            {t("atcat.contactSupportNonAccentureDesc")}
            <br />
            <a href={"mailto:" + data.resultSet.ContactSupportMail}>
              {data.resultSet.ContactSupportMail}
            </a>
          </div>
          <div className="modal-button">
          <Button 
            label={t("atcat.close")}
            handleClick={() => {
              this.setState({ modalFlag: !this.state.modalFlag });
            }}
            />
          </div>
        </>
      );
    }
    this.setState({
      modalFlag: true,
      children: child,
    });
  }
  componentDidMount(){
    this.props.initFooter();
  }
  componentDidUpdate() {
    this.props.initFooter();
  
  }
  render() {
    // this.props.initFooter();
    let authDetails = this.props.authDetails;

    return (
      <>
        <FooterView
          handleOnClick={(title) => {
            let child = { title: title, content: "" };
            this.setState({ children: child });
            this.props.fetchStatements(
              authDetails.selectedLanguage,
              this.callBackfunc
            );
          }}
          children={this.state.children}
          modalFlag={this.state.modalFlag}
          handleModal={() => {
            this.setState({ modalFlag: !this.state.modalFlag });
          }}
          footerValue={this.props.footerDetail}
        />
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  authDetails: selectAuthDetails(),
  footerDetail: selectFooterValue(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    fetchStatements: (payload, callback) => {
      dispatch(fetchStatements(payload, callback));
    },
    initFooter: () => {
      dispatch(initFooter());
    },
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(withTranslation()(Footer));
