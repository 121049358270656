import {
  INIT_BULKRELEASE_GET_TIME,
  FETCHING_BULKRELEASE_GET_TIME,
  BULKRELEASE_GET_TIME_FETCHED,
  BULKRELEASE_GET_TIME_FETCH_COMPLETE,
  BULKRELEASE_GET_TIME_FETCH_FAIL,
  INIT_BULKRELEASE_SUBMIT_TIME,
  FETCHING_BULKRELEASE_SUBMIT_TIME,
  BULKRELEASE_SUBMIT_TIME_FETCHED,
  BULKRELEASE_SUBMIT_TIME_FETCH_COMPLETE,
  BULKRELEASE_SUBMIT_TIME_FETCH_FAIL,
} from "./actionTypes";

export const initBulkReleaseGetTime = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_GET_TIME,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseGetTime = () => {
  return {
    type: FETCHING_BULKRELEASE_GET_TIME,
  };
};
export const fetchBulkReleaseGetTime = (data) => {
  return {
    type: BULKRELEASE_GET_TIME_FETCHED,
    data,
  };
};
export const bulkReleaseGetTimeComplete = () => {
  return {
    type: BULKRELEASE_GET_TIME_FETCH_COMPLETE,
  };
};
export const bulkReleaseGetTimeFail = () => {
  return {
    type: BULKRELEASE_GET_TIME_FETCH_FAIL,
  };
};

export const initBulkReleaseSubmitTime = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_SUBMIT_TIME,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseSubmitTime = () => {
  return {
    type: FETCHING_BULKRELEASE_SUBMIT_TIME,
  };
};
export const fetchBulkReleaseSubmitTime = (data) => {
  return {
    type: BULKRELEASE_SUBMIT_TIME_FETCHED,
    data,
  };
};
export const bulkReleaseSubmitTimeComplete = () => {
  return {
    type: BULKRELEASE_SUBMIT_TIME_FETCH_COMPLETE,
  };
};
export const bulkReleaseSubmitTimeFail = () => {
  return {
    type: BULKRELEASE_SUBMIT_TIME_FETCH_FAIL,
  };
};
