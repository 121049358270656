import { fromJS } from "immutable";
import {
    FETCH_VENDOR_LIST_HEADER_NAMES,
    FETCH_VENDOR_EXPORT_FIELD_LIST,
    FETCH_VENDOR_ROLE_BASED_ACTIONS,
    FETCH_VENDOR_LIST,
    FETCH_VENDOR_FILTER_COLUMNS,
    INIT_VENDOR_LIST,
    FETCH_COUNTRY_LIST,
} from "./actionTypes";

export const initialState = fromJS({
    vendorFilterColumns: [],
    fetchingColumns: false,
    vendorHeaderList: [],
    vendorExportFieldList: {},
    vendorRoleBasedActions: {},
    vendorList: {},
    countryList: [],
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VENDOR_LIST_HEADER_NAMES: {
            return state.set("vendorHeaderList", action.data);
        }
        case FETCH_VENDOR_EXPORT_FIELD_LIST: {
            return state.set("vendorExportFieldList", action.data);
        }
        case FETCH_VENDOR_ROLE_BASED_ACTIONS: {
            return state.set("vendorRoleBasedActions", action.data);
        }
        case INIT_VENDOR_LIST: {
            return state.set("loader", true);
        }
        case FETCH_VENDOR_LIST: {
            return state.set("vendorList", action.data).set("loader", false);;
        }
        case FETCH_VENDOR_FILTER_COLUMNS: {
            return state.set("vendorFilterColumns", action.data);
        }
        case FETCH_COUNTRY_LIST: {
            return state.set("countryList", action.data);
        }
        default:
            return state;
    }
};

export default reducer;
