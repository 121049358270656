export const INIT_AI_EXCEPTION_LIST_HEADER_NAMES = "INIT_AI_EXCEPTION_LIST_HEADER_NAMES";
export const FETCH_AI_EXCEPTION_LIST_HEADER_NAMES = "FETCH_AI_EXCEPTION_LIST_HEADER_NAMES";
export const INIT_AI_EXCEPTION_EXPORT_FIELD_LIST = "INIT_AI_EXCEPTION_EXPORT_FIELD_LIST";
export const FETCH_AI_EXCEPTION_EXPORT_FIELD_LIST = "FETCH_AI_EXCEPTION_EXPORT_FIELD_LIST";
export const INIT_AI_EXCEPTION_TOTAL_COUNT = "INIT_AI_EXCEPTION_TOTAL_COUNT";
export const FETCH_AI_EXCEPTION_TOTAL_COUNT = "FETCH_AI_EXCEPTION_TOTAL_COUNT";
export const INIT_AI_EXCEPTION_LIST = "INIT_AI_EXCEPTION_LIST";
export const FETCH_AI_EXCEPTION_LIST = "FETCH_AI_EXCEPTION_LIST";
export const INIT_AI_EXCEPTION_FILTER_NAMES = "INIT_AI_EXCEPTION_FILTER_NAMES";
export const INIT_DELETE_FILTER_AI_EXCEPTION = "INIT_DELETE_FILTER_AI_EXCEPTION";
export const INIT_AI_EXCEPTION_RECORDS_EXPORT = "INIT_AI_EXCEPTION_RECORDS_EXPORT";
export const FETCH_AI_EXCEPTION_RECORDS_EXPORT = "FETCH_AI_EXCEPTION_RECORDS_EXPORT";
export const INIT_AI_EXCEPTION_RECORDS_EXPORT_ALL = "INIT_AI_EXCEPTION_RECORDS_EXPORT_ALL";
export const FETCH_AI_EXCEPTION_RECORDS_EXPORT_ALL = "FETCH_AI_EXCEPTION_RECORDS_EXPORT_ALL";
export const INIT_RELEASE_UNRELEASE_AI_EXCEPTIONS = "INIT_RELEASE_UNRELEASE_AI_EXCEPTIONS";
export const INIT_AI_MASS_RELEASE_WITH_COMMENTS = "INIT_AI_MASS_RELEASE_WITH_COMMENTS";
export const INIT_AI_EXCEPTION_FILTER_COLUMNS = "INIT_AI_EXCEPTION_FILTER_COLUMNS";
export const FETCH_AI_EXCEPTION_FILTER_COLUMNS = "FETCH_AI_EXCEPTION_FILTER_COLUMNS";
export const INIT_AI_EXCEPTION_FILTER_DETAILS = "INIT_AI_EXCEPTION_FILTER_DETAILS";
export const INIT_SAVE_FILTER_AI_EXCEPTION = "INIT_SAVE_FILTER_AI_EXCEPTION";

