export const INIT_DASHBOARD_DATA = "INIT_DASHBOARD_DATA";
export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const INIT_STARTDATE_ENDDATE_UI = "INIT_STARTDATE_ENDDATE_UI";
export const FETCH_STARTDATE_ENDDATE_UI = "FETCH_STARTDATE_ENDDATE_UI";
export const INIT_DASHBOARD_AGEING = "INIT_DASHBOARD_AGEING";
export const FETCH_DASHBOARD_AGEING = "FETCH_DASHBOARD_AGEING";
export const INIT_CLAIM_AGEING = "INIT_CLAIM_AGEING";
export const FETCH_CLAIM_AGEING = "FETCH_CLAIM_AGEING";
export const INIT_SUPPLIER_DATA = "INIT_SUPPLIER_DATA";
export const FETCH_SUPPLIER_DATA = "FETCH_SUPPLIER_DATA";
export const FETCH_SUPPLIER_DATA_BY_COUNT = "FETCH_SUPPLIER_DATA_BY_COUNT";
export const INIT_AUDITOR_LIST = "INIT_AUDITOR_LIST";
export const FETCH_AUDITOR_LIST = "FETCH_AUDITOR_LIST";
export const INIT_SUPPLIER_CLAIM_PRODUCTIVITY =
  "INIT_SUPPLIER_CLAIM_PRODUCTIVITY";
export const FETCH_SUPPLIER_CLAIM_PRODUCTIVITY =
  "FETCH_SUPPLIER_CLAIM_PRODUCTIVITY";
export const FETCH_CLAIM_AGEING_NOTIFICATION =
  "FETCH_CLAIM_AGEING_NOTIFICATION";
export const FETCH_EXCEPTION_AGEING_NOTIFICATION =
  "FETCH_EXCEPTION_AGEING_NOTIFICATION";
export const FETCH_SUPPLIER_CLAIM_PRODUCTIVITY_BY_COUNT =
  "FETCH_SUPPLIER_CLAIM_PRODUCTIVITY_BY_COUNT";
export const ASSIGN_VARIABLE_INITAL_STATE = "ASSIGN_VARIABLE_INITAL_STATE";
