import {
  INIT_GET_JOBS_LIST,
  FETCHING_JOBS_LIST,
  FETCH_JOBS_LIST,
  JOBS_LIST_FETCHING_COMPLETE,
  INIT_DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILED,
  INIT_RUN_JOB,
  RUN_JOB_FAILED,
  RUN_JOB_SUCCESS,
  INIT_GET_LOGS_FOR_DUPS_AND_DATALOAD,
  FETCHING_LOGS_FOR_DUPS_AND_DATALOAD,
  FETCH_LOGS_FOR_DUPS_AND_DATALOAD,
  LOGS_FOR_DUPS_AND_DATALOAD_FETCHING_COMPLETE,
  INIT_GET_JOB_DETAILS,
  FETCHING_JOB_DETAILS,
  JOB_DETAILS_FETCH_COMPLETE,
  INIT_GET_SERVER_DATE_TIME,
  FETCHING_SERVER_DATE_TIME,
  FETCH_SERVER_DATE_TIME,
  SERVER_DATE_TIME_FETCH_COMPLETE,
  INIT_SAVE_ADD_JOB,
  ADD_JOB_SAVE_FAILED,
  ADD_JOB_SAVE_SUCCESS,
  INIT_UPDATE_JOB_DETAILS,
  JOB_DETAILS_UPDATE_SUCCESS,
  JOB_DETAILS_UPDATE_FAILED,
} from "./actionTypes";

export function initGetJobsList(payload) {
  return {
    type: INIT_GET_JOBS_LIST,
    payload,
  };
}

export function fetchingJobsList() {
  return {
    type: FETCHING_JOBS_LIST,
  };
}

export function fetchJobsList(data) {
  return {
    type: FETCH_JOBS_LIST,
    data,
  };
}

export function JobsListFetchingComplete() {
  return {
    type: JOBS_LIST_FETCHING_COMPLETE,
  };
}

export function initDeleteJob(payload,callback) {
  return {
    type: INIT_DELETE_JOB,
    payload,
    callback
  };
}

export function jobDeleteSuccess() {
  return {
    type: DELETE_JOB_SUCCESS,
  };
}

export function jobDeleteFailed(data) {
  return {
    type: DELETE_JOB_FAILED,
    data,
  };
}

export function initRunJob(payload) {
  return {
    type: INIT_RUN_JOB,
    payload,
  };
}

export function jobRunSuccess() {
  return {
    type: RUN_JOB_SUCCESS,
  };
}

export function jobRunFailed(data) {
  return {
    type: RUN_JOB_FAILED,
    data,
  };
}

export function initGetLogsForDupsAndDataLoad(payload) {
  return {
    type: INIT_GET_LOGS_FOR_DUPS_AND_DATALOAD,
    payload,
  };
}

export function fetchingLogsForDupsAndDataLoad() {
  return {
    type: FETCHING_LOGS_FOR_DUPS_AND_DATALOAD,
  };
}

export function fetchLogsForDupsAndDataLoad(data) {
  return {
    type: FETCH_LOGS_FOR_DUPS_AND_DATALOAD,
    data,
  };
}

export function LogsForDupsAndDataLoadFetchingComplete() {
  return {
    type: LOGS_FOR_DUPS_AND_DATALOAD_FETCHING_COMPLETE,
  };
}

export const initGetJobDetails = (payload, callback) => {
  return {
    type: INIT_GET_JOB_DETAILS,
    payload,
    callback,
  };
};

export const fetchingJobDetails = () => {
  return {
    type: FETCHING_JOB_DETAILS,
  };
};

export const jobDetailsFetchingComplete = () => {
  return {
    type: JOB_DETAILS_FETCH_COMPLETE,
  };
};

export function initGetServerDateTime(callback) {
  return {
      type: INIT_GET_SERVER_DATE_TIME,
      callback,
  };
}

export function fetchingServerDateTime() {
  return {
    type: FETCHING_SERVER_DATE_TIME,
  };
}

export function fetchServerDateTime(data) {
  return {
    type: FETCH_SERVER_DATE_TIME,
    data,
  };
}

export function serverDateTimeFetchingComplete() {
  return {
    type: SERVER_DATE_TIME_FETCH_COMPLETE,
  };
}

export const initAddJob = (payload, callback) => {
  return {
    type: INIT_SAVE_ADD_JOB,
    payload,
    callback,
  };
};

export const addJobSuccess = () => {
  return {
    type: ADD_JOB_SAVE_SUCCESS,
  };
};

export const addJobFailed = () => {
  return {
    type: ADD_JOB_SAVE_FAILED,
  };
};

export const initUpdateJob = (payload, callback) => {
  return {
    type: INIT_UPDATE_JOB_DETAILS,
    payload,
    callback,
  };
};

export const updateJobSuccess = () => {
  return {
    type: JOB_DETAILS_UPDATE_SUCCESS,
  };
};

export const updateJobFailed = () => {
  return {
    type: JOB_DETAILS_UPDATE_FAILED,
  };
};