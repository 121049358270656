import { fromJS } from "immutable";
import {
  FETCHING_JOBS_LIST,
  FETCH_JOBS_LIST,
  JOBS_LIST_FETCHING_COMPLETE,
  INIT_DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILED,
  INIT_RUN_JOB,
  RUN_JOB_SUCCESS,
  RUN_JOB_FAILED,
  FETCHING_LOGS_FOR_DUPS_AND_DATALOAD,
  FETCH_LOGS_FOR_DUPS_AND_DATALOAD,
  LOGS_FOR_DUPS_AND_DATALOAD_FETCHING_COMPLETE,
  FETCHING_JOB_DETAILS,
  JOB_DETAILS_FETCH_COMPLETE,
  FETCHING_SERVER_DATE_TIME,
  FETCH_SERVER_DATE_TIME,
  SERVER_DATE_TIME_FETCH_COMPLETE,
  INIT_SAVE_ADD_JOB,
  ADD_JOB_SAVE_SUCCESS,
  ADD_JOB_SAVE_FAILED,
  INIT_UPDATE_JOB_DETAILS,
  JOB_DETAILS_UPDATE_SUCCESS,
  JOB_DETAILS_UPDATE_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingJobsList: "",
  jobsList: "",
  jobsListFetchingComplete: "",
  deletingJob: false,
  runJob: false,
  fetchingLogsForDupsAndDataLoad: false,
  logsForDupsAndDataLoad: {},
  fetchingServerDateTime:"",
  serverDateTime:"",
  savingAddJob:false,
  updatingJobDetails: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_JOBS_LIST: {
      return state.set("fetchingJobsList", true);
    }

    case FETCH_JOBS_LIST: {
      return state.set("jobsList", action.data);
    }

    case JOBS_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingJobsList", false);
    }

    case INIT_DELETE_JOB: {
      return state.set("deletingJob", true);
    }

    case DELETE_JOB_SUCCESS: {
      return state.set("deletingJob", false);
    }

    case DELETE_JOB_FAILED: {
      return state.set("deletingJob", false);
    }

    case INIT_RUN_JOB: {
      return state.set("runJob", true);
    }

    case RUN_JOB_SUCCESS: {
      return state.set("runJob", false);
    }

    case RUN_JOB_FAILED: {
      return state.set("runJob", false);
    }

    case FETCHING_LOGS_FOR_DUPS_AND_DATALOAD: {
      return state.set("fetchingLogsForDupsAndDataLoad", true);
    }

    case FETCH_LOGS_FOR_DUPS_AND_DATALOAD: {
      return state.set("logsForDupsAndDataLoad", action.data);
    }

    case LOGS_FOR_DUPS_AND_DATALOAD_FETCHING_COMPLETE: {
      return state.set("fetchingLogsForDupsAndDataLoad", false);
    }

    case FETCHING_JOB_DETAILS: {
      return state.set("fetchingJobDetails", true);
    }
    case JOB_DETAILS_FETCH_COMPLETE: {
      return state.set("fetchingJobDetails", false);
    }

    case FETCHING_SERVER_DATE_TIME: {
      return state.set("fetchingServerDateTime", true);
    }

    case FETCH_SERVER_DATE_TIME: {
      return state.set("serverDateTime", action.data);
    }

    case SERVER_DATE_TIME_FETCH_COMPLETE: {
      return state.set("fetchingServerDateTime", false);
    }

    case INIT_SAVE_ADD_JOB: {
      return state.set("savingAddJob", true);
    }

    case ADD_JOB_SAVE_SUCCESS: {
      return state.set("savingAddJob", false);
    }

    case ADD_JOB_SAVE_FAILED: {
      return state.set("savingAddJob", false);
    }

    case INIT_UPDATE_JOB_DETAILS: {
      return state.set("updatingJobDetails", true);
    }

    case JOB_DETAILS_UPDATE_SUCCESS: {
      return state.set("updatingJobDetails", false);
    }

    case JOB_DETAILS_UPDATE_FAILED: {
      return state.set("updatingJobDetails", false);
    }


    default:
      return state;
  }
};

export default reducer;
