import { fromJS } from "immutable";
import {
  FETCHING_APPH_FILTER_COL_NAMES,
  APPH_FILTER_COL_NAMES_FETCHED,
  APPH_FILTER_COL_NAMES_FETCH_COMPLETE,
  APPH_FILTER_COL_NAMES_FETCH_FAIL,
  ASSIGN_INITAL_DATA,
  FETCHING_APPH_LIST_HEADER_NAMES,
  APPH_LIST_HEADER_NAMES_FETCHED,
  APPH_LIST_HEADER_NAMES_FETCH_COMPLETE,
  APPH_LIST_HEADER_NAMES_FETCH_FAIL,
  FETCHING_APPH_ROLE_BASED_ACTIONS,
  APPH_ROLE_BASED_ACTIONS_FETCHED,
  APPH_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
  APPH_ROLE_BASED_ACTIONS_FETCH_FAIL,
  FETCHING_APPH_COUNT,
  FETCH_APPH_COUNT,
  APPH_COUNT_FETCHING_COMPLETE,
  FETCHING_APPH_LIST,
  APPH_LIST_FETCHED,
  APPH_LIST_FETCH_COMPLETE,
  APPH_LIST_FETCH_FAIL,
  FETCHING_APPH_RIGHT_ALIGNED_FIELDS,
  FETCH_APPH_RIGHT_ALIGNED_FIELDS,
  APPH_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE,
  FETCHING_APPH_EXPORT_FIELDS_LIST,
  FETCH_APPH_EXPORT_FIELDS_LIST,
  APPH_EXPORT_FIELDS_LIST_FETCHING_COMPLETE,
} from "./actionTypes";

export const initialState = fromJS({
  columnList: [],
  fetchingColumns: false,
  apPhHeaderList: [],
  fetchingApPhListHeaders: false,
  apPhRoleActions: [],
  fetchingApPhRoleBasedActions: false,
  fetchingApPhCount: false,
  apPhCount: 0,
  apPhList: [],
  fetchingApPhList: false,
  apPhRightAlignedFieldsList: [],
  fetchingApPhRightAlignedFields: false,
  apPhExportFieldsList: [],
  fetchingApPhExportFieldsList: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_APPH_FILTER_COL_NAMES: {
      return state.set("fetchingColumns", true).set("columnList", []);
    }
    case APPH_FILTER_COL_NAMES_FETCHED: {
      return state.set("columnList", action.data);
    }
    case APPH_FILTER_COL_NAMES_FETCH_COMPLETE:
    case APPH_FILTER_COL_NAMES_FETCH_FAIL: {
      return state.set("fetchingColumns", false);
    }

    case FETCHING_APPH_LIST_HEADER_NAMES: {
      return state
        .set("fetchingApPhListHeaders", true)
        .set("apPhHeaderList", []);
    }
    case APPH_LIST_HEADER_NAMES_FETCHED: {
      return state.set("apPhHeaderList", action.data);
    }
    case APPH_LIST_HEADER_NAMES_FETCH_COMPLETE:
    case APPH_LIST_HEADER_NAMES_FETCH_FAIL: {
      return state.set("fetchingApPhListHeaders", false);
    }

    case FETCHING_APPH_ROLE_BASED_ACTIONS: {
      return state
        .set("fetchingApPhRoleBasedActions", true)
        .set("apPhRoleActions", []);
    }
    case APPH_ROLE_BASED_ACTIONS_FETCHED: {
      return state.set("apPhRoleActions", action.data);
    }
    case APPH_ROLE_BASED_ACTIONS_FETCH_COMPLETE:
    case APPH_ROLE_BASED_ACTIONS_FETCH_FAIL: {
      return state.set("fetchingApPhRoleBasedActions", false);
    }

    case FETCHING_APPH_COUNT: {
      return state.set("fetchingApPhCount", true);
    }
    case FETCH_APPH_COUNT: {
      return state.set("apPhCount", action.data);
    }

    case APPH_COUNT_FETCHING_COMPLETE: {
      return state.set("fetchingApPhCount", false);
    }

    case FETCHING_APPH_LIST: {
      return state.set("fetchingApPhList", true).set("apPhList", []);
    }
    case APPH_LIST_FETCHED: {
      return state.set("apPhList", action.data);
    }
    case APPH_LIST_FETCH_COMPLETE:
    case APPH_LIST_FETCH_FAIL: {
      return state.set("fetchingApPhList", false);
    }

    case FETCHING_APPH_RIGHT_ALIGNED_FIELDS: {
      return state.set("fetchingApPhRightAlignedFields", true);
    }
    case FETCH_APPH_RIGHT_ALIGNED_FIELDS: {
      return state.set("apPhRightAlignedFields", action.data);
    }

    case APPH_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE: {
      return state.set("fetchingApPhRightAlignedFields", false);
    }

    case FETCHING_APPH_EXPORT_FIELDS_LIST: {
      return state.set("fetchingApPhExportFieldsList", true);
    }
    case FETCH_APPH_EXPORT_FIELDS_LIST: {
      return state.set("apPhExportFieldsList", action.data);
    }

    case APPH_EXPORT_FIELDS_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingApPhExportFieldsList", false);
    }

    case ASSIGN_INITAL_DATA: {
      return state
        .set("fetchingColumns", false)
        .set("columnList", [])
        .set("fetchingApPhListHeaders", false)
        .set("apPhHeaderList", [])
        .set("fetchingApPhRoleBasedActions", false)
        .set("apPhRoleActions", [])
        .set("fetchingApPhCount", false)
        .set("apPhCount", 0)
        .set("apPhList", [])
        .set("fetchingApPhList", false)
        .set("apPhRightAlignedFields", [])
        .set("fetchingApPhRightAlignedFields", false)
        .set("apPhExportFieldsList", [])
        .set("fetchingApPhExportFieldsList", false);
    }

    default:
      return state;
  }
};

export default reducer;
