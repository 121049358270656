export const INIT_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS =
  "INIT_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS";
export const FETCH_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS =
  "FETCH_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS";
export const INIT_FRAUD_AND_ERROR_RIGHT_ALIGNED_FIELDS =
  "INIT_FRAUD_AND_ERROR_RIGHT_ALIGNED_FIELDS";
export const FETCH_FRAUD_AND_ERROR_RIGHT_ALIGNED_FIELDS =
  "FETCH_FRAUD_AND_ERROR_RIGHT_ALIGNED_FIELDS";
export const INIT_FRAUD_AND_ERROR_FILTER_NAME =
  "INIT_FRAUD_AND_ERROR_FILTER_NAME";
export const FETCH_FRAUD_AND_ERROR_FILTER_NAME =
  "FETCH_FRAUD_AND_ERROR_FILTER_NAME";
export const INIT_FRAUD_AND_ERROR_EXPORT_FIELDS =
  "INIT_FRAUD_AND_ERROR_EXPORT_FIELDS";
export const FETCH_FRAUD_AND_ERROR_EXPORT_FIELDS =
  "FETCH_FRAUD_AND_ERROR_EXPORT_FIELDS";
export const INIT_FRAUD_AND_ERROR_HEADER_FIELDS =
  "INIT_FRAUD_AND_ERROR_HEADER_FIELDS";
export const FETCH_FRAUD_AND_ERROR_HEADER_FIELDS =
  "FETCH_FRAUD_AND_ERROR_HEADER_FIELDS";
export const INIT_FRAUD_AND_ERROR_DATA = "INIT_FRAUD_AND_ERROR_DATA";
export const FETCH_FRAUD_AND_ERROR_DATA = "FETCH_FRAUD_AND_ERROR_DATA";
export const INIT_FRAUD_AND_ERROR_PAGES_COUNT =
  "INIT_FRAUD_AND_ERROR_PAGES_COUNT";
export const FETCH_FRAUD_AND_ERROR_PAGES_COUNT =
  "FETCH_FRAUD_AND_ERROR_PAGES_COUNT";
export const INIT_FRAUD_AND_ERROR_FILTER_FIELDS =
  "INIT_FRAUD_AND_ERROR_FILTER_FIELDS";
export const FETCH_FRAUD_AND_ERROR_FILTER_FIELDS =
  "FETCH_FRAUD_AND_ERROR_FILTER_FIELDS";
export const INIT_FRAUD_AND_ERROR_ADD_FILTER =
  "INIT_FRAUD_AND_ERROR_ADD_FILTER";
export const INIT_DELETE_FILTER_OPTION = "INIT_DELETE_FILTER_OPTION";
export const INIT_FRAUD_AND_ERROR_RUN_FILTER =
  "INIT_FRAUD_AND_ERROR_RUN_FILTER";
export const FETCH_FRAUD_AND_ERROR_RUN_FILTER =
  "FETCH_FRAUD_AND_ERROR_RUN_FILTER";
export const INIT_FRAUD_AND_ERROR_SEARCH_RECORD =
  "INIT_FRAUD_AND_ERROR_SEARCH_RECORD";
export const FETCH_FRAUD_AND_ERROR_SEARCH_RECORD =
  "FETCH_FRAUD_AND_ERROR_SEARCH_RECORD";
export const INIT_FRAUD_AND_ERROR_RECORD_DETAILS =
  "INIT_FRAUD_AND_ERROR_RECORD_DETAILS";
export const INIT_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS =
  "INIT_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS";
export const FETCH_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS =
  "FETCH_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS";
export const INIT_FRAUD_AND_ERROR_SAVE_RECORD_DETAILS =
  "INIT_FRAUD_AND_ERROR_SAVE_RECORD_DETAILS";
export const INIT_FRAUD_AND_ERROR_EXPORT_RECORDS =
  "INIT_FRAUD_AND_ERROR_EXPORT_RECORDS";
export const INIT_FRAUD_AND_ERROR_EDIT_FILTER_DETAILS =
  "INIT_FRAUD_AND_ERROR_EDIT_FILTER_DETAILS";
export const INIT_FRAUD_AND_ERROR_SAVE_FILTER_DETAILS =
  "INIT_FRAUD_AND_ERROR_SAVE_FILTER_DETAILS";
export const INIT_FRAUD_AND_ERROR_PAGE_EXIT = "INIT_FRAUD_AND_ERROR_PAGE_EXIT";
