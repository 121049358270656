import { fromJS } from "immutable";
import {
  FETCHING_AP_FILTER_COL_NAMES,
  AP_FILTER_COL_NAMES_FETCHED,
  AP_FILTER_COL_NAMES_FETCH_COMPLETE,
  AP_FILTER_COL_NAMES_FETCH_FAIL,
  ASSIGN_INITAL_DATA,
  FETCHING_AP_LIST_HEADER_NAMES,
  AP_LIST_HEADER_NAMES_FETCHED,
  AP_LIST_HEADER_NAMES_FETCH_COMPLETE,
  AP_LIST_HEADER_NAMES_FETCH_FAIL,
  FETCHING_AP_ROLE_BASED_ACTIONS,
  AP_ROLE_BASED_ACTIONS_FETCHED,
  AP_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
  AP_ROLE_BASED_ACTIONS_FETCH_FAIL,
  FETCHING_AP_COUNT,
  FETCH_AP_COUNT,
  AP_COUNT_FETCHING_COMPLETE,
  FETCHING_AP_LIST,
  AP_LIST_FETCHED,
  AP_LIST_FETCH_COMPLETE,
  AP_LIST_FETCH_FAIL,
  FETCHING_AP_RIGHT_ALIGNED_FIELDS,
  FETCH_AP_RIGHT_ALIGNED_FIELDS,
  AP_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE,
  FETCHING_AP_EXPORT_FIELDS_LIST,
  FETCH_AP_EXPORT_FIELDS_LIST,
  AP_EXPORT_FIELDS_LIST_FETCHING_COMPLETE,
} from "./actionTypes";

export const initialState = fromJS({
  columnList: [],
  fetchingColumns: false,
  apHeaderList: [],
  fetchingApListHeaders: false,
  apRoleActions: [],
  fetchingApRoleBasedActions: false,
  fetchingApCount: false,
  apCount: 0,
  apList: [],
  fetchingApList: false,
  apapRightAlignedFieldsList: [],
  fetchingApRightAlignedFields: false,
  apExportFieldsList: [],
  fetchingApExportFieldsList: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_AP_FILTER_COL_NAMES: {
      return state.set("fetchingColumns", true).set("columnList", []);
    }
    case AP_FILTER_COL_NAMES_FETCHED: {
      return state.set("columnList", action.data);
    }
    case AP_FILTER_COL_NAMES_FETCH_COMPLETE:
    case AP_FILTER_COL_NAMES_FETCH_FAIL: {
      return state.set("fetchingColumns", false);
    }

    case FETCHING_AP_LIST_HEADER_NAMES: {
      return state.set("fetchingApListHeaders", true).set("apHeaderList", []);
    }
    case AP_LIST_HEADER_NAMES_FETCHED: {
      return state.set("apHeaderList", action.data);
    }
    case AP_LIST_HEADER_NAMES_FETCH_COMPLETE:
    case AP_LIST_HEADER_NAMES_FETCH_FAIL: {
      return state.set("fetchingApListHeaders", false);
    }

    case FETCHING_AP_ROLE_BASED_ACTIONS: {
      return state
        .set("fetchingApRoleBasedActions", true)
        .set("apRoleActions", []);
    }
    case AP_ROLE_BASED_ACTIONS_FETCHED: {
      return state.set("apRoleActions", action.data.resultSet.actionNames);
    }
    case AP_ROLE_BASED_ACTIONS_FETCH_COMPLETE:
    case AP_ROLE_BASED_ACTIONS_FETCH_FAIL: {
      return state.set("fetchingApRoleBasedActions", false);
    }

    case FETCHING_AP_COUNT: {
      return state.set("fetchingApCount", true);
    }
    case FETCH_AP_COUNT: {
      return state.set("apCount", action.data);
    }

    case AP_COUNT_FETCHING_COMPLETE: {
      return state.set("fetchingApCount", false);
    }

    case FETCHING_AP_LIST: {
      return state.set("fetchingApList", true).set("apList", []);
    }
    case AP_LIST_FETCHED: {
      return state.set("apList", action.data);
    }
    case AP_LIST_FETCH_COMPLETE:
    case AP_LIST_FETCH_FAIL: {
      return state.set("fetchingApList", false);
    }

    case FETCHING_AP_RIGHT_ALIGNED_FIELDS: {
      return state.set("fetchingApRightAlignedFields", true);
    }
    case FETCH_AP_RIGHT_ALIGNED_FIELDS: {
      return state.set("apRightAlignedFields", action.data);
    }

    case AP_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE: {
      return state.set("fetchingApRightAlignedFields", false);
    }

    case FETCHING_AP_EXPORT_FIELDS_LIST: {
      return state.set("fetchingApExportFieldsList", true);
    }
    case FETCH_AP_EXPORT_FIELDS_LIST: {
      return state.set("apExportFieldsList", action.data);
    }

    case AP_EXPORT_FIELDS_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingApExportFieldsList", false);
    }


    case ASSIGN_INITAL_DATA: {
      return state
        .set("fetchingColumns", false)
        .set("columnList", [])
        .set("fetchingApListHeaders", false)
        .set("apHeaderList", [])
        .set("fetchingApRoleBasedActions", false)
        .set("apRoleActions", [])
        .set("fetchingApCount", false)
        .set("apCount", 0)
        .set("apList", [])
        .set("fetchingApList", false)
        .set("apRightAlignedFields", [])
        .set("fetchingApRightAlignedFields", false)
        .set("apExportFieldsList", [])
        .set("fetchingApExportFieldsList", false);
    }

    default:
      return state;
  }
};

export default reducer;
