import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import {
  postRequestDetails,
} from "../../../config/requestHeaders";
import {
  INIT_SEQUENCE_FIELD_DETAILS,
  INIT_CRITERIA_FIELD,
  INIT_PROCESSING_DATA,
  INIT_EDIT_FILTER_PAGE,
  INIT_SEQUENCE_DETAILS,
  INIT_SUPPLEMENTRY_CRITERIA_DETAILS,
  INIT_DELETE_SEQUENCE,
  INIT_SAVE_SUPPLEMENTARY_DETAILS,
  INIT_SAVE_SEQUENCE_REORDER,
  INIT_SAVE_EDIT_FILTER_CRITERIA,
  INIT_SAVE_ADD_SEQUENCE,
  INIT_SAVE_EDIT_SEQUENCE,
} from "./actionTypes";
import {
  ProcessingCriteriaSequenceFieldDetails,
  ReOrderGetProcessing,
  CriteriaFieldsGetCriteriaFields,
  PreProcCriteriaEditFilterPageData,
  CriteriaFieldsGetSequenceFieldsEachSeq,
  SupplementaryCriteriaGet,
  ProcessingCriteriaDelete,
  SupplementaryCriteriaPut,
  ReOrderPostReoder,
  APPEditPreProcessingCriteria,
  ProcessingCriteriaPost,
  CriteriaFieldsUpdateSequenceFields,
} from "../../../config/APINames";
import {
  fetchSequenceDetails,
  fetchCriteriaField,
  fetchProcessingData,
  fetchEditFilterPage,
  fetchSeqDetails,
  fetchSupplementryCriteriaDetails,
} from "./actions";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import { Error_Msg } from "../../../config";
import generateKey from "../../../config/generateKey";
import { generateNotification } from "../../../helper/generateNotification";
export default function* watchProcessingCriteria() {
  yield all([
    takeLatest(INIT_SEQUENCE_FIELD_DETAILS, initSequenceField),
    takeLatest(INIT_CRITERIA_FIELD, initCriteriaField),
    takeLatest(INIT_PROCESSING_DATA, initProcessingData),
    takeLatest(INIT_EDIT_FILTER_PAGE, initEditFilterPage),
    takeLatest(INIT_SEQUENCE_DETAILS, initSequenceDetails),
    takeLatest(INIT_SUPPLEMENTRY_CRITERIA_DETAILS, initSupplementryCreteria),
    takeLatest(INIT_DELETE_SEQUENCE, initDeleteSequence),
    takeLatest(INIT_SAVE_SUPPLEMENTARY_DETAILS, initSaveSupplementaryDetails),
    takeLatest(INIT_SAVE_SEQUENCE_REORDER, initSaveSequenceReorder),
    takeLatest(INIT_SAVE_EDIT_FILTER_CRITERIA, initSaveFilterCriteria),
    takeLatest(INIT_SAVE_ADD_SEQUENCE, initAddSequence),
    takeLatest(INIT_SAVE_EDIT_SEQUENCE, initEditSequence),
  ]);
}
function* initSequenceField(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ProcessingCriteriaSequenceFieldDetails,
      requestDetails
    );
    response = response.data;
    yield put(fetchSequenceDetails(response.resultSet));
    callback(response.resultSet);
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initCriteriaField(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      CriteriaFieldsGetCriteriaFields,
      requestDetails
    );
    response = response.data;
    yield put(fetchCriteriaField(response));
    callback();
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initProcessingData(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, ReOrderGetProcessing, requestDetails);
    response = response.data;
    yield put(fetchProcessingData(response.resultSet));
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initEditFilterPage(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      PreProcCriteriaEditFilterPageData,
      requestDetails
    );
    response = response.data;
    yield put(fetchEditFilterPage(response));
    callback && callback(response);
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initSequenceDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      CriteriaFieldsGetSequenceFieldsEachSeq,
      requestDetails
    );
    response = response.data;
    yield put(fetchSeqDetails(response));
    callback && callback(response);
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initSupplementryCreteria(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, SupplementaryCriteriaGet, requestDetails);
    response = response.data;
    yield put(fetchSupplementryCriteriaDetails(response.resultSet));
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initDeleteSequence(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, ProcessingCriteriaDelete, requestDetails);
    response = response.data;
    const type =
      response.message === "Deleted the selected sequence/ s successfully"
        ? "success"
        : "danger";
    const notification = buildNotification({
      message: generateKey(response.message),
      type: type,
    });
    store.addNotification({
      ...notification,
    });
    yield put(fetchSupplementryCriteriaDetails(response.resultSet));
    callback !== undefined && callback();
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initSaveSupplementaryDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, SupplementaryCriteriaPut, requestDetails);
    response = response.data;
    const type =
      response.message === "Data Updated Successfully" ? "success" : "danger";
    const notification = buildNotification({
      message: generateKey(response.message),
      type: type,
    });
    store.addNotification({
      ...notification,
    });
    callback !== undefined && callback();
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initSaveSequenceReorder(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, ReOrderPostReoder, requestDetails);
    response = response.data;
    const type =
      response.message === "Sequence Re-order succesfully" ||
      response.message === "Re - Orderd Succesfully"
        ? "success"
        : "danger";
    const notification = buildNotification({
      message: generateKey(response.message),
      type: type,
    });
    store.addNotification({
      ...notification,
    });
    callback !== undefined && callback();
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initSaveFilterCriteria(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      APPEditPreProcessingCriteria,
      requestDetails
    );
    response = response.data;
    const type =
      response === "Details saved successfully" ? "success" : "danger";
    generateNotification(generateKey(response), type);
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(generateKey(Error_Msg), "danger");
  }
}

function* initAddSequence(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, ProcessingCriteriaPost, requestDetails);
    response = response.data;
    const type =
      response.message === "Sequence Added SuccessFully" ? "success" : "danger";
    const notification = buildNotification({
      message: generateKey(response.message),
      type: type,
    });
    store.addNotification({
      ...notification,
    });
    callback !== undefined && callback();
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}

function* initEditSequence(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      CriteriaFieldsUpdateSequenceFields,
      requestDetails
    );
    response = response.data;
    const type =
      response === "Sequence has been updated successfully"
        ? "success"
        : "danger";
    const notification = buildNotification({
      message: generateKey(response),
      type: type,
    });
    store.addNotification({
      ...notification,
    });
    callback !== undefined && callback();
  } catch (error) {
    const notification = buildNotification({
      message: generateKey(Error_Msg),
      type: "danger",
    });
    store.addNotification({
      ...notification,
    });
  }
}
