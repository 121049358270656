import {
  INIT_VENDOR_ANALYSIS_REPORT_LIST,
  FETCH_VENDOR_ANALYSIS_REPORT_LIST,
} from "./actionTypes";

export const initVendorAnalysisReportList = (payload) => {
  return {
    type: INIT_VENDOR_ANALYSIS_REPORT_LIST,
    payload,
  };
};
export const fetchVendorAnalysisReportList = (data) => {
  return {
    type: FETCH_VENDOR_ANALYSIS_REPORT_LIST,
    data,
  };
};
