import {INIT_GET_PASSWORD,FETCH_PASSWORD,INIT_SAVE_PASSWORD,FETCH_SAVEPASSWORD} from "./actionTypes";

export function initGetPassword(payload,callback){
    return{
        type:INIT_GET_PASSWORD,
        payload,
        callback
    }
}

export function fetchPasswordDetails() {
    return {
      type: FETCH_PASSWORD,
    };
  }
  
export function initSavePassword(payload){
  return{
    type:INIT_SAVE_PASSWORD,
    payload
  }
}

export function fetchSaveDetails(){
  return{
    type:FETCH_SAVEPASSWORD
  }
}