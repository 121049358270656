export const INIT_FETCH_AP_FILTER_COL_NAMES = "INIT_FETCH_AP_FILTER_COL_NAMES";
export const FETCHING_AP_FILTER_COL_NAMES = "FETCHING_AP_FILTER_COL_NAMES";
export const AP_FILTER_COL_NAMES_FETCHED = "AP_FILTER_COL_NAMES_FETCHED";
export const AP_FILTER_COL_NAMES_FETCH_COMPLETE =
  "AP_FILTER_COL_NAMES_FETCH_COMPLETE";
export const AP_FILTER_COL_NAMES_FETCH_FAIL = "AP_FILTER_COL_NAMES_FETCH_FAIL";

export const INIT_SAVE_AP_FILTER = "INIT_SAVE_AP_FILTER";

export const ASSIGN_INITAL_DATA = "ASSIGN_INITAL_DATA";

export const INIT_FETCH_AP_LIST_HEADER_NAMES =
  "INIT_FETCH_AP_LIST_HEADER_NAMES";
export const FETCHING_AP_LIST_HEADER_NAMES = "FETCHING_AP_LIST_HEADER_NAMES";
export const AP_LIST_HEADER_NAMES_FETCHED = "AP_LIST_HEADER_NAMES_FETCHED";
export const AP_LIST_HEADER_NAMES_FETCH_COMPLETE =
  "AP_LIST_HEADER_NAMES_FETCH_COMPLETE";
export const AP_LIST_HEADER_NAMES_FETCH_FAIL =
  "AP_LIST_HEADER_NAMES_FETCH_FAIL";

export const INIT_FETCH_AP_ROLE_BASED_ACTIONS =
  "INIT_FETCH_AP_ROLE_BASED_ACTIONS";
export const FETCHING_AP_ROLE_BASED_ACTIONS = "FETCHING_AP_ROLE_BASED_ACTIONS";
export const AP_ROLE_BASED_ACTIONS_FETCHED = "AP_ROLE_BASED_ACTIONS_FETCHED";
export const AP_ROLE_BASED_ACTIONS_FETCH_COMPLETE =
  "AP_ROLE_BASED_ACTIONS_FETCH_COMPLETE";
export const AP_ROLE_BASED_ACTIONS_FETCH_FAIL =
  "AP_ROLE_BASED_ACTIONS_FETCH_FAIL";

export const INIT_FETCH_AP_FILTER_NAMES = "INIT_FETCH_AP_FILTER_NAMES";

export const INIT_GET_AP_COUNT = "INIT_GET_AP_COUNT";
export const FETCHING_AP_COUNT = "FETCHING_AP_COUNT";
export const FETCH_AP_COUNT = "FETCH_AP_COUNT";
export const AP_COUNT_FETCHING_COMPLETE = "AP_COUNT_FETCHING_COMPLETE";

export const INIT_FETCH_AP_LIST = "INIT_FETCH_AP_LIST";
export const FETCHING_AP_LIST = "FETCHING_AP_LIST";
export const AP_LIST_FETCHED = "AP_LIST_FETCHED";
export const AP_LIST_FETCH_COMPLETE = "AP_LIST_FETCH_COMPLETE";
export const AP_LIST_FETCH_FAIL = "AP_LIST_FETCH_FAIL";

export const INIT_FETCH_AP_FILTER_DETAILS = "INIT_FETCH_AP_FILTER_DETAILS";

export const INIT_DELETE_AP_FILTER = "INIT_DELETE_AP_FILTER";

export const INIT_GET_AP_RIGHT_ALIGNED_FIELDS =
  "INIT_GET_AP_RIGHT_ALIGNED_FIELDS";
export const FETCHING_AP_RIGHT_ALIGNED_FIELDS =
  "FETCHING_AP_RIGHT_ALIGNED_FIELDS";
export const FETCH_AP_RIGHT_ALIGNED_FIELDS = "FETCH_AP_RIGHT_ALIGNED_FIELDS";
export const AP_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE =
  "AP_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE";

export const INIT_GET_AP_EXPORT_FIELDS_LIST = "INIT_GET_AP_EXPORT_FIELDS_LIST";
export const FETCHING_AP_EXPORT_FIELDS_LIST = "FETCHING_AP_EXPORT_FIELDS_LIST";
export const FETCH_AP_EXPORT_FIELDS_LIST = "FETCH_AP_EXPORT_FIELDS_LIST";
export const AP_EXPORT_FIELDS_LIST_FETCHING_COMPLETE =
  "AP_EXPORT_FIELDS_LIST_FETCHING_COMPLETE";

export const INIT_GET_AP_RECORDS_EXPORT = "INIT_GET_AP_RECORDS_EXPORT";
export const FETCHING_AP_RECORDS_EXPORT = "FETCHING_AP_RECORDS_EXPORT";
export const FETCH_AP_RECORDS_EXPORT = "FETCH_AP_RECORDS_EXPORT";
export const AP_RECORDS_EXPORT_FETCHING_COMPLETE =
  "AP_RECORDS_EXPORT_FETCHING_COMPLETE";

export const INIT_GET_AP_RECORDS_EXPORT_ALL = "INIT_GET_AP_RECORDS_EXPORT_ALL";
export const FETCHING_AP_RECORDS_EXPORT_ALL = "FETCHING_AP_RECORDS_EXPORT_ALL";
export const FETCH_AP_RECORDS_EXPORT_ALL = "FETCH_AP_RECORDS_EXPORT_ALL";
export const AP_RECORDS_EXPORT_ALL_FETCHING_COMPLETE =
  "AP_RECORDS_EXPORT_ALL_FETCHING_COMPLETE";

export const INIT_GET_REPORT = "INIT_GET_REPORT";
export const FETCHING_REPORT = "FETCHING_REPORT";
export const FETCH_REPORT = "FETCH_REPORT";
export const REPORT_FETCHING_COMPLETE =
  "REPORT_FETCHING_COMPLETE";