const Password = (props) => {
  return (
    <svg viewBox="0 0 24 24" className="icons">
      <title>Password Icon</title>
      <g>
        <g transform="translate(-152.000000, -480.000000)">
          <g transform="translate(105.000000, 141.000000)">
            <g transform="translate(0.000000, 99.000000)">
              <g transform="translate(32.000000, 223.000000)">
                <g transform="translate(15.000000, 16.000000)">
                  <path d="M15.7500001,2.50000001 C19.477922,2.50000001 22.5,5.52207814 22.5,9.25000003 C22.5,12.9779219 19.477922,16 15.7500001,16 C14.8608751,16 14.0120626,15.8277344 13.2346407,15.5153594 L11.25,17.5 L9.00000001,17.5 L9.00000001,20.5 L6.00000001,20.5 L6.00000001,23.5 L1.50000001,23.5 L1.50000001,19.7500001 L9.48464066,11.7653594 C9.17226566,10.9879375 9.00000001,10.139125 9.00000001,9.25000003 C9.00000001,5.52207814 12.0220782,2.50000001 15.7500001,2.50000001 L15.7500001,2.50000001 Z M15.7500001,1 C11.1945,1 7.5,4.69229689 7.5,9.25000003 C7.5,9.96751566 7.59117191,10.67275 7.77178128,11.3568907 L0.329484376,18.7991876 C0.118533023,19.0101716 0,19.2963029 0,19.5946563 L0,23.8750001 C0,24.4963282 0.503671877,25 1.125,25 L6.37500003,25 C6.99632815,25 7.5,24.4963282 7.5,23.8750001 L7.5,22 L9.37500004,22 C9.99632817,22 10.5,21.4963282 10.5,20.8750001 L10.5,19 L11.4053438,19 C11.7037032,19 11.989875,18.8814532 12.2008594,18.6705157 L13.6431563,17.2282188 C14.3272501,17.4088282 15.0324844,17.5 15.7500001,17.5 C20.3055001,17.5 24,13.8077032 24,9.25000003 C24,4.69450002 20.3077032,1 15.7500001,1 Z M18.0000001,6.06250002 C18.5169376,6.06250002 18.9375001,6.48306252 18.9375001,7.00000003 C18.9375001,7.51693753 18.5169376,7.93750003 18.0000001,7.93750003 C17.4830626,7.93750003 17.0625001,7.51693753 17.0625001,7.00000003 C17.0625001,6.48306252 17.4830626,6.06250002 18.0000001,6.06250002 L18.0000001,6.06250002 Z M18.0000001,4.75000002 C16.7573438,4.75000002 15.7500001,5.75734377 15.7500001,7.00000003 C15.7500001,8.24265628 16.7573438,9.25000002 18.0000001,9.25000002 C19.2426563,9.25000002 20.2500001,8.24265628 20.2500001,7.00000003 C20.2500001,5.75734377 19.2426563,4.75000002 18.0000001,4.75000002 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default Password;
