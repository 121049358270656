export const INIT_VENDOR_FILTER_NAMES = "INIT_VENDOR_FILTER_NAMES";
export const FETCH_VENDOR_FILTER_NAMES = "FETCH_VENDOR_FILTER_NAMES";
export const VENDOR_FILTER_NAMES_FETCH_COMPLETE = "VENDOR_FILTER_NAMES_FETCH_COMPLETE";
export const VENDOR_FILTER_NAMES_FETCH_FAIL = "VENDOR_FILTER_NAMES_FETCH_FAIL";
export const INIT_VENDOR_LIST_HEADER_NAMES = "INIT_VENDOR_LIST_HEADER_NAMES";
export const FETCH_VENDOR_LIST_HEADER_NAMES = "FETCH_VENDOR_LIST_HEADER_NAMES";
export const VENDOR_LIST_HEADER_NAMES_FETCH_COMPLETE = "VENDOR_LIST_HEADER_NAMES_FETCH_COMPLETE";
export const VENDOR_LIST_HEADER_NAMES_FETCH_FAIL = "VENDOR_LIST_HEADER_NAMES_FETCH_FAIL";
export const INIT_VENDOR_EXPORT_FIELD_LIST = "INIT_VENDOR_EXPORT_FIELD_LIST";
export const FETCH_VENDOR_EXPORT_FIELD_LIST = "FETCH_VENDOR_EXPORT_FIELD_LIST";
export const VENDOR_EXPORT_FIELD_LIST_FETCH_COMPLETE = "VENDOR_EXPORT_FIELD_LIST_FETCH_COMPLETE";
export const VENDOR_EXPORT_FIELD_LIST_FETCH_FAIL = "VENDOR_EXPORT_FIELD_LIST_FETCH_FAIL";
export const INIT_VENDOR_ROLE_BASED_ACTIONS = "INIT_VENDOR_ROLE_BASED_ACTIONS";
export const FETCH_VENDOR_ROLE_BASED_ACTIONS = "FETCH_VENDOR_ROLE_BASED_ACTIONS";
export const VENDOR_ROLE_BASED_ACTIONS_FETCH_COMPLETE = "VENDOR_ROLE_BASED_ACTIONS_FETCH_COMPLETE";
export const VENDOR_ROLE_BASED_ACTIONS_FETCH_FAIL = "VENDOR_ROLE_BASED_ACTIONS_FETCH_FAIL";
export const INIT_VENDOR_LIST = "INIT_VENDOR_LIST";
export const FETCH_VENDOR_LIST = "FETCH_VENDOR_LIST";
export const VENDOR_LIST_FETCH_COMPLETE = "VENDOR_LIST_FETCH_COMPLETE";
export const VENDOR_LIST_FETCH_FAIL = "VENDOR_LIST_FETCH_FAIL";
export const INIT_DELETE_VENDOR_FILTER = "INIT_DELETE_VENDOR_FILTER";
export const INIT_VENDOR_FILTER_COLUMNS = "INIT_VENDOR_FILTER_COLUMNS";
export const FETCH_VENDOR_FILTER_COLUMNS = "FETCH_VENDOR_FILTER_COLUMNS";
export const INIT_VENDOR_FILTER_DETAILS = "INIT_VENDOR_FILTER_DETAILS";
export const INIT_SAVE_VENDOR_FILTER = "INIT_SAVE_VENDOR_FILTER";
export const INIT_VENDOR_RECORDS_EXPORT = "INIT_VENDOR_RECORDS_EXPORT";
export const FETCH_VENDOR_RECORDS_EXPORT = "FETCH_VENDOR_RECORDS_EXPORT";
export const INIT_VENDOR_RECORDS_EXPORT_ALL = "INIT_VENDOR_RECORDS_EXPORT_ALL";
export const FETCH_VENDOR_RECORDS_EXPORT_ALL = "FETCH_VENDOR_RECORDS_EXPORT_ALL";
export const INIT_VENDOR_RECORDS_MARK_UNMARK_AS_PREFERRED = "INIT_VENDOR_RECORDS_MARK_UNMARK_AS_PREFERRED";
export const FETCH_VENDOR_RECORD_DETAILS = "FETCH_VENDOR_RECORD_DETAILS";
export const INIT_COUNTRY_LIST = "INIT_COUNTRY_LIST";
export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST";
export const SAVE_VENDOR_DETAILS = "SAVE_VENDOR_DETAILS";
export const INIT_VENDOR_CONTACT_DETAILS = "INIT_VENDOR_CONTACT_DETAILS";
export const SAVE_VENDOR_CONTACT_DETAILS = "SAVE_VENDOR_CONTACT_DETAILS";
export const UPDATE_VENDOR_CONTACT_DETAILS = "UPDATE_VENDOR_CONTACT_DETAILS";
export const DELETE_VENDOR_CONTACTS = "DELETE_VENDOR_CONTACTS";


