import { storage } from "./storage";
let sessionDetails = storage.getItem("access_token");
export const getRequestDetails = {
  method: "GET",
  cache: "no-cache",
};
export const postRequestDetails = () => {
  sessionDetails = storage.getItem("access_token");
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${sessionDetails}`,
    },
  };
};

export const nbcuTokenPostRequestDetails = () => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
};

export const nbcuClientInfoPostRequestDetails = () => {
  let nbcuClientToken = storage.getItem("nbcuClientToken");
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${nbcuClientToken}`,
    },
  };
};

export const putRequestDetails = {
  method: "PUT",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};
export const postNoCacheRequestDetails = {
  method: "POST",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
    "cache-control": "no-cache",
  },
};
