export const INIT_FETCH_CLAIM_FILTER_COL_NAMES =
  "INIT_FETCH_CLAIM_FILTER_COL_NAMES";
export const FETCHING_CLAIM_FILTER_COL_NAMES =
  "FETCHING_CLAIM_FILTER_COL_NAMES";
export const CLAIM_FILTER_COL_NAMES_FETCHED = "CLAIM_FILTER_COL_NAMES_FETCHED";
export const CLAIM_FILTER_COL_NAMES_FETCH_COMPLETE =
  "CLAIM_FILTER_COL_NAMES_FETCH_COMPLETE";
export const CLAIM_FILTER_COL_NAMES_FETCH_FAIL =
  "CLAIM_FILTER_COL_NAMES_FETCH_FAIL";

export const ASSIGN_INITAL_DATA = "ASSIGN_INITAL_DATA";

export const INIT_SAVE_CLAIM_FILTER = "INIT_SAVE_CLAIM_FILTER";

export const INIT_FETCH_CLAIM_FILTER_NAMES = "INIT_FETCH_CLAIM_FILTER_NAMES";

export const INIT_DUPLICATE_CLAIM_LIST = "INIT_DUPLICATE_CLAIM_LIST";

export const FETCH_DUPLICATE_CLAIMS_LIST = "FETCH_DUPLICATE_CLAIMS_LIST";

export const INIT_DELETE_CLAIM_FILTER = "INIT_DELETE_CLAIM_FILTER";

export const INIT_FETCH_CLAIM_FILTER_DETAILS =
  "INIT_FETCH_CLAIM_FILTER_DETAILS";

export const INIT_GET_CLAIM_RECORDS_EXPORT = "INIT_GET_CLAIM_RECORDS_EXPORT";
export const FETCHING_CLAIM_RECORDS_EXPORT = "FETCHING_CLAIM_RECORDS_EXPORT";
export const FETCH_CLAIM_RECORDS_EXPORT = "FETCH_CLAIM_RECORDS_EXPORT";
export const CLAIM_RECORDS_EXPORT_FETCHING_COMPLETE =
  "CLAIM_RECORDS_EXPORT_FETCHING_COMPLETE";

export const INIT_GET_CLAIM_RECORDS_EXPORT_ALL =
  "INIT_GET_CLAIM_RECORDS_EXPORT_ALL";
export const FETCHING_CLAIM_RECORDS_EXPORT_ALL =
  "FETCHING_CLAIM_RECORDS_EXPORT_ALL";
export const FETCH_CLAIM_RECORDS_EXPORT_ALL = "FETCH_CLAIM_RECORDS_EXPORT_ALL";
export const CLAIM_RECORDS_EXPORT_ALL_FETCHING_COMPLETE =
  "CLAIM_RECORDS_EXPORT_ALL_FETCHING_COMPLETE";

export const INIT_GET_CLAIM_DETAILS = "INIT_GET_CLAIM_DETAILS";

export const FETCH_CLAIM_DETAILS = "FETCH_CLAIM_DETAILS";

export const INIT_GET_USER_LIST = "INIT_GET_USER_LIST";
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const INIT_GET_MANUAL_CLAIMS_HISTORY = "INIT_GET_MANUAL_CLAIMS_HISTORY";
export const FETCH_MANUAL_CLAIMS_HISTORY = "FETCH_MANUAL_CLAIMS_HISTORY";

export const INIT_SEARCH_DUPLICATECLAIM_DETAILS =
  "INIT_SEARCH_DUPLICATECLIAM_DETAILS";
export const FETCH_SEARCH_DUPLICATECLAIM_DETAILS =
  "FETCH_SEARCH_DUPLICATECLAIM_DETAILS";

export const INIT_DUPLICATECLAIM_VENDOR_LETTER =
  "INIT_DUPLICATECLAIM_VENDOR_LETTER";
export const FETCH_DUPLICATECLAIM_VENDOR_LETTER =
  "FETCH_DUPLICATECLAIM_VENDOR_LETTER";

export const INIT_DUPLICATECLAIM_PAYBACK_RECORDS =
  "INIT_DUPLICATECLAIM_PAYBACK_RECORDS";
export const FETCH_DUPLICATECLAIM_PAYBACK_RECORDS =
  "FETCH_DUPLICATECLAIM_PAYBACK_RECORDS";

export const INIT_DUPLICATECLAIM_PAYBACK_DETAILS =
  "INIT_DUPLICATECLAIM_PAYBACK_DETAILS";
export const FETCH_DUPLICATECLAIM_PAYBACK_DETAILS =
  "FETCH_DUPLICATECLAIM_PAYBACK_DETAILS";

export const INIT_DUPLICATECLAIM_EXCEPTION_RECORDS =
  "INIT_DUPLICATECLAIM_EXCEPTION_RECORDS";
export const FETCH_DUPLICATECLAIM_EXCEPTION_RECORDS =
  "FETCH_DUPLICATECLAIM_EXCEPTION_RECORDS";

export const INIT_GET_VENDOR_LIST_FROM_EXCEPTION =
  "INIT_GET_VENDOR_LIST_FROM_EXCEPTION";
export const FETCH_VENDOR_LIST_FROM_EXCEPTION =
  "FETCH_VENDOR_LIST_FROM_EXCEPTION";
export const INIT_GET_VENDOR_FILTER_FIELDS = "INIT_GET_VENDOR_FILTER_FIELDS";
export const FETCH_VENDOR_FILTER_FIELDS = "FETCH_VENDOR_FILTER_FIELDS";
export const INIT_GET_VENDOR_LIST_FROM_EXCEPTION_COUNT =
  "INIT_GET_VENDOR_LIST_FROM_EXCEPTION_COUNT";
export const FETCH_VENDOR_LIST_FROM_EXCEPTION_COUNT =
  "FETCH_VENDOR_LIST_FROM_EXCEPTION_COUNT";
export const INIT_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS =
  "INIT_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS";
export const FETCH_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS =
  "FETCH_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS";
export const INIT_GET_VENDOR_LIST_DATA = "INIT_GET_VENDOR_LIST_DATA";
export const FETCH_VENDOR_LIST_DATA = "FETCH_VENDOR_LIST_DATA";
export const INIT_GET_VENDOR_LIST_COUNT = "INIT_GET_VENDOR_LIST_COUNT";
export const FETCH_VENDOR_LIST_COUNT = "FETCH_VENDOR_LIST_COUNT";
export const INIT_GET_OFFSET_RECORDS = "INIT_GET_OFFSET_RECORDS";
export const FETCH_OFFSET_RECORDS = "FETCH_OFFSET_RECORDS";
export const INIT_DELETE_OFFSET = "INIT_DELETE_OFFSET";
export const INIT_ADD_OFFSET = "INIT_ADD_OFFSET";
export const INIT_GET_OFFSET_TYPE = "INIT_GET_OFFSET_TYPE";
export const FETCH_OFFSET_TYPE = "FETCH_OFFSET_TYPE";
export const INIT_GET_OFFSET_DETAILS = "INIT_GET_OFFSET_DETAILS";
export const FETCH_OFFSET_DETAILS = "FETCH_OFFSET_DETAILS";
export const INIT_UPDATE_OFFSET = "INIT_UPDATE_OFFSET";
export const INIT_SAVE_CLAIM_DETAILS = "INIT_SAVE_CLAIM_DETAILS";
export const INIT_ADD_PAYBACK = "INIT_ADD_PAYBACK";
export const APPLY_INITAL_DUPLICATE_CLAIM_DATA =
  "APPLY_INITAL_DUPLICATE_CLAIM_DATA";

export const FETCHING_CLAIM_DETAILS = "FETCHING_CLAIM_DETAILS";
export const FETCHING_CLAIM_DETAILS_COMPLETE = "FETCHING_CLAIM_DETAILS_COMPLETE";
export const FETCHING_CLAIM_DETAILS_FAIL = "FETCHING_CLAIM_DETAILS_FAIL";
export const INIT_GET_PRIMUS_DFM_URL_DETAILS = "INIT_GET_PRIMUS_DFM_URL_DETAILS";
