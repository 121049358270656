import React from "react";
import { RadioButton } from "../..";
import "./style.scss";

const DualRadioButton = (props) => {
  const labelclass = `form-label  ${props.required ? `required` : ``}`;
  const wrapperclass = `dual-input-wrapper dual-radio-wrapper ${
    props.disabled ? `disabled` : ``
  }  ${props.showError ? `error` : ``}`;

  return (
    <>
      <div className={`dual ${wrapperclass}`}>
        <label htmlFor={props.title} className={labelclass}>
          {props.title}
        </label>
        <div className="dual-radio">
          <RadioButton
            id={props.id}
            name={props.name}
            key={props.name}
            checked={props.checked}
            value={props.value}
            handleCheck={props.handleCheck}
            label={props.label}
          />
          {props.dual &&
          (<RadioButton
            id={props.secondaryId}
            name={props.secondaryName}
            key={props.secondaryName}
            checked={props.secondaryChecked}
            value={props.secondaryValue}
            handleCheck={props.handleCheck}
            label={props.secondaryLabel}
          />)}
        </div>
      </div>
    </>
  );
};

export default DualRadioButton;
