import React from "react";
import Arrow from "../../../SVG/Arrow";

const ChangeLang = (props) => {
  return (
    <li tabIndex={0} className="dropdown changeLang">
      <p className="selected">
        {props.language} <Arrow />
      </p>
      <ul className="dropdown-content">
        <li className="dropdown-item " key={"EN"}>
          <button
            onClick={() => {
              props.changeLanuage("EN");
            }}
          >
            EN - ENGLISH
          </button>
        </li>
        {props.languageList}
      </ul>
    </li>
  );
};

export default ChangeLang;
