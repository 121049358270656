import React from "react";
import PropTypes from "prop-types";
import Notification from "../components/Notification";

export const buildNotification = ({ type, message, content }) => {
  return {
    content: <Notification type={type} message={message} content={content} />,
    insert: "top",
    container: "top-center",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 4000,
      pauseOnHover: true,
    },
  };
};

buildNotification.propTypes = {
  mode: PropTypes.oneOf(["color", "lite"]),
  type: PropTypes.oneOf(["danger", "warning", "success"]),
};

const notification = {
  insert: "top",
  container: "top-center",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
  width: 100,
  dismiss: {
    duration: 4000,
    showIcon: true,
    onScreen: true,
    pauseOnHover: true,
  },
};

export default notification;
