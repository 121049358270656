import React from "react";

const DataLoadDupsIcon = (props) => {
    return (
        <svg
            className="icons"
            viewBox="0 0 18 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g stroke="none" strokeWidth="1" fillRule="evenodd">
                <g transform="translate(-27.000000, -533.000000)" >
                    <g transform="translate(27.000000, 533.000000)">
                        <path d="M16.1856141,10.122807 L7.85964912,10.122807 L7.85964912,1.79684209 C7.85964912,1.48701753 7.60771929,1.22807016 7.30736842,1.22807016 C7.28315789,1.22807016 7.25824561,1.22982454 7.23333333,1.23333331 C3.05543858,1.82350875 -0.138947391,5.47122806 0.00456138102,9.84456141 C0.147368399,14.1964912 3.78631578,17.8350877 8.13789473,17.9778948 C8.2322807,17.9810527 8.32701754,17.9824562 8.42105263,17.9824562 C12.6680702,17.9824562 16.171579,14.8364912 16.7491228,10.7487719 C16.7957895,10.417193 16.5203509,10.122807 16.1856141,10.122807 Z M8.42105263,16.8596491 C8.33929824,16.8596491 8.2568421,16.8582456 8.17473684,16.8554386 C4.41192981,16.7319298 1.25017542,13.5701755 1.12666665,9.80736842 C1.0119298,6.29894736 3.38421051,3.2536842 6.7368421,2.46771928 L6.7368421,11.245614 L15.5140351,11.245614 C14.7463158,14.4887719 11.8066667,16.8596491 8.42105263,16.8596491 Z M10.1333333,0.018947346 C10.1210526,0.0178947144 10.1087719,0.0175438372 10.0968421,0.0175438372 C9.79298246,0.0175438372 9.54385965,0.278596469 9.54385965,0.587017523 L9.54385965,8.43859649 L17.3954386,8.43859649 C17.7161404,8.43859649 17.9856141,8.16877193 17.9638597,7.8491228 C17.674386,3.65754385 14.3249123,0.308070154 10.1333333,0.018947346 Z M10.6666667,7.31578947 L10.6666667,1.20701752 C13.7982456,1.69157893 16.2905263,4.18421051 16.7754386,7.31578947 L10.6666667,7.31578947 Z" ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default DataLoadDupsIcon;
