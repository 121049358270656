import {
  FETCH_ALERT_LIST,
  FETCH_ALERT_DETAILS,
  FETCHING_DATA_RETRIVING_STATUS,
  SAVE_ALERT_DETAILS,
} from "./actionTypes";

export const fetchAlertList = (payload, callback) => {
  return {
    type: FETCH_ALERT_LIST,
    payload,
    callback,
  };
};

export const fetchAlertDetails = (payload, callback) => {
  return {
    type: FETCH_ALERT_DETAILS,
    payload,
    callback,
  };
};

export const fetchStatus = (data) => {
  return {
    type: FETCHING_DATA_RETRIVING_STATUS,
    data,
  };
};
export const saveAlertDetails = (payload, callback) => {
  return { type: SAVE_ALERT_DETAILS, payload, callback };
};
