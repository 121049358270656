import {
  EnviromentName,
  DEV_API_URL,
  TEST_API_URL,
  PREPROD_API_URL,
  PROD_API_URL,
} from "./index";
export const getBaseURL = () => {
  switch (EnviromentName) {
    case "development":
      return DEV_API_URL;
    case "test":
      return TEST_API_URL;
    case "preProduction":
      return PREPROD_API_URL;
    case "production":
      return PROD_API_URL;
    default:
      return DEV_API_URL;
  }
};
