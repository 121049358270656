import React from "react";
import FileUploader from "../Uploader";
import "./style.scss";

const LabeledUploader = (props) => {
  return (
    <div className="img-uploader-wrapper uploader-wrapper">
      <div className="input-control">
        <label className="input-label" htmlFor={props.id}>
          {props.title}
        </label>
        <FileUploader {...props} />
      </div>
      {props.selectedFile ? (
        <div className="preview">
          <img
            src={`data:image/*;base64, ${props.selectedFile}`}
            alt="No content"
          />
        </div>
      ) : null}
    </div>
  );
};

export default LabeledUploader;
