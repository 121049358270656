import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import generateKey from "../../../config/generateKey";
import {
    AddNewClient,
    ClientMActiveClient,
    ClientMClientFunctionality,
    ClientMInactiveClient,
    ClientMTotalClient,
    ClientMActionOnClient,
    ClientMDeleteClient,
    ClientMGetCountryList,
    UDLMaintenanceGetUDLDetails,
    ClientMAtcatVersionList,
    ClientMDefaultValueOnClickofAddClient,
    ClientMClientDetailsByName,
    ClientMUpdateClientProfile,
    ClientMGetClientLanguage,
    ReportManagementSavedReportsList,
    ReportManagementUploadReports,
} from "../../../config/APINames";
import {
    atcatVersionFetchComplete,
    atcatVersionFetching,
    clientActionFailed,
    clientActionSuccess,
    clientDataSaveFailed,
    clientDataSaveSuccess,
    clientDeleteFailed,
    clientDeleteSuccess,
    clientsListFetchingComplete,
    countriesFetchComplete,
    countriesFetching,
    defaultValuesFetchComplete,
    defaultValuesFetching,
    fetchActiveClients,
    fetchAtcatVersion,
    fetchClientsList,
    fetchCountries,
    fetchFilteredTotalClients,
    fetchInActiveClients,
    fetchingClientsList,
    fetchTotalClients,
    fetchUDLDetails,
    udlDetailsFetchComplete,
    udlDetailsFetching,
    fetchingClientByName,
    clientByNameFetchingComplete,
    fetchingSavedReportsList,
    savedReportsListFetchingComplete,
    reportsUploadSaveSuccess,
    reportsUploadSaveFailed,
} from "./actions";
import {
    INIT_CLIENT_ACTION,
    INIT_DELETE_CLIENT,
    INIT_GET_ACTIVE_CLIENTS,
    INIT_GET_ATCAT_VERSION,
    INIT_GET_CLIENTS_LIST,
    INIT_GET_CLIENT_BY_NAME,
    INIT_GET_COUNTRIES,
    INIT_GET_DEFAULT_VALUES,
    INIT_GET_INACTIVE_CLIENTS,
    INIT_GET_TOTAL_CLIENTS,
    INIT_GET_UDL_DETAILS,
    INIT_SAVE_CLIENT_DATA,
    INIT_GET_CLIENT_LANGUAGES,
    INIT_GET_SAVED_REPORTS_LIST,
    INIT_UPLOAD_REPORTS,
} from "./actionTypes";
import { storage } from "../../../config/storage";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from "../../../config/index";

export default function* watchLogin() {
    yield all([
        takeLatest(INIT_GET_CLIENTS_LIST, initGetClientsList),
        takeLatest(INIT_GET_TOTAL_CLIENTS, initGetTotalClients),
        takeLatest(INIT_GET_ACTIVE_CLIENTS, initGetActiveClients),
        takeLatest(INIT_GET_INACTIVE_CLIENTS, initGetInActiveClients),
        takeLatest(INIT_CLIENT_ACTION, initClientActionSaga),
        takeLatest(INIT_DELETE_CLIENT, initDeleteClientSaga),
        takeLatest(INIT_GET_COUNTRIES, initGetCountriesSaga),
        takeLatest(INIT_GET_CLIENT_LANGUAGES, initGetClientLanguagesSaga),
        takeLatest(INIT_GET_UDL_DETAILS, initGetUDLDetailsSaga),
        takeLatest(INIT_GET_ATCAT_VERSION, initGetAtcatVersionSaga),
        takeLatest(INIT_GET_DEFAULT_VALUES, initGetDefaultValuesSaga),
        takeLatest(INIT_GET_CLIENT_BY_NAME, initGetClientByNameSaga),
        takeLatest(INIT_SAVE_CLIENT_DATA, initSaveClientDataSaga),
        takeLatest(INIT_GET_SAVED_REPORTS_LIST, initGetSavedReportsList),
        takeLatest(INIT_UPLOAD_REPORTS, initUploadReportsSaga),
    ]);
}

function* initGetTotalClients(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName: sessionDetails && sessionDetails.clientName,
            userName: sessionDetails && sessionDetails.name,
        });
        const response = yield call(axios, ClientMTotalClient, requestDetails);
        if (response && response.status === 200) {
            if (payload && payload.search) {
                yield put(fetchFilteredTotalClients(response.data));
            } else {
                yield put(fetchTotalClients(response.data));
            }
        }
    } catch (error) {
        action.callback && action.callback();
    }
}

function* initGetActiveClients(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName: sessionDetails && sessionDetails.clientName,
            userName: sessionDetails && sessionDetails.name,
        });
        const response = yield call(axios, ClientMActiveClient, requestDetails);
        if (response && response.status === 200) {
            yield put(fetchActiveClients(response.data));
        }
    } catch (error) {
        action.callback && action.callback();
    }
}

function* initGetInActiveClients(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName: sessionDetails && sessionDetails.clientName,
            userName: sessionDetails && sessionDetails.name,
        });
        const response = yield call(axios, ClientMInactiveClient, requestDetails);
        if (response && response.status === 200) {
            yield put(fetchInActiveClients(response.data));
        }
    } catch (error) {
        action.callback && action.callback();
    }
}

function* initGetClientsList(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { payload } = action || {};
    yield put(fetchingClientsList());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName: sessionDetails && sessionDetails.clientName,
            userName: sessionDetails && sessionDetails.name,
        });
        const response = yield call(
            axios,
            ClientMClientFunctionality,
            requestDetails
        );
        if (response && response.status === 200) {
            if (response.data.clients === "Invalid Search") {
                generateNotification("atcat.pleaseEnterTheValidInputAndSearch", "danger");
            }
            yield put(fetchClientsList(response.data));
        }
        yield put(clientsListFetchingComplete());
    } catch (error) {
        yield put(clientsListFetchingComplete());
    }
}

function* initDeleteClientSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            userName: sessionDetails && sessionDetails.name,
        });
        const response = yield call(axios, ClientMDeleteClient, requestDetails);
        if (response && response.status === 200) {
            if (response.data === "Selected client(s) deleted successfully!") {
                yield put(clientDeleteSuccess());
                generateNotification(generateKey(response.data), "success");
                callback && callback();
            } else {
                yield put(clientDeleteFailed());
                generateNotification(generateKey(response.data), "danger");
            }
        } else {
            yield put(clientDeleteFailed());
            generateNotification(generateKey(response.data), "danger");
        }
    } catch (error) {
        yield put(clientDeleteFailed());
        generateNotification(error, "danger");
    }
}

function* initClientActionSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName: sessionDetails && sessionDetails.clientName,
            userName: sessionDetails && sessionDetails.name,
        });
        const response = yield call(axios, ClientMActionOnClient, requestDetails);
        if (response && response.status === 200) {
            if (
                response.data === "Selected client(s) activated successfully!" ||
                response.data === "Selected client(s) deactivated successfully!"
            ) {
                yield put(clientActionSuccess());
                generateNotification(generateKey(response.data), "success");
                callback && callback();
            } else {
                yield put(clientActionFailed());
                generateNotification(generateKey(response.data), "danger");
            }
        } else {
            yield put(clientActionFailed());
            generateNotification(generateKey(response.data), "danger");
        }
    } catch (error) {
        yield put(clientActionFailed());
        generateNotification(error, "danger");
    }
}

function* initGetCountriesSaga() {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    yield put(countriesFetching());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            clientName: sessionDetails && sessionDetails.clientName,
            userName: sessionDetails && sessionDetails.name,
        });
        const response = yield call(axios, ClientMGetCountryList, requestDetails);
        if (response && response.status === 200) {
            const data =
                response.data && response.data.length
                    ? response.data.map((country) => ({
                        ...country,
                        id: country.CountryID,
                        value: country.CountryName,
                    }))
                    : [];
            yield put(fetchCountries(data));
        }
        yield put(countriesFetchComplete());
    } catch (error) {
        yield put(countriesFetchComplete());
    }
}

function* initGetClientLanguagesSaga(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ClientMGetClientLanguage,
            requestDetails
        );
        callback && callback(response);
    } catch (error) {
        callback && callback(error);
    }
}

function* initGetUDLDetailsSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { payload } = action || {};
    yield put(udlDetailsFetching());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            clientName: sessionDetails && sessionDetails.clientName,
            userName: sessionDetails && sessionDetails.name,
            ...payload,
        });
        const response = yield call(axios, UDLMaintenanceGetUDLDetails, requestDetails);
        if (response && (response.status === 200) & (response.data !== Error_Msg)) {
            if (response.data.udlDetail !== undefined) {
                yield put(fetchUDLDetails(response.data));
            }
            else { generateNotification(response.data, "danger"); }
        } else {
            generateNotification(response.data, "danger");
        }
        yield put(udlDetailsFetchComplete());
    } catch (error) {
        yield put(udlDetailsFetchComplete());
    }
}

function* initGetAtcatVersionSaga() {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    yield put(atcatVersionFetching());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            clientName: sessionDetails && sessionDetails.clientName,
            userName: sessionDetails && sessionDetails.name,
        });
        const response = yield call(axios, ClientMAtcatVersionList, requestDetails);
        if (response && response.status === 200) {
            const data =
                response.data && response.data.length
                    ? response.data.map((version) => ({
                        ...version,
                        id: version.ATCATVersionID,
                        value: version.VersionType,
                    }))
                    : [];
            yield put(fetchAtcatVersion(data));
        }
        yield put(atcatVersionFetchComplete());
    } catch (error) {
        yield put(atcatVersionFetchComplete());
    }
}

function* initGetDefaultValuesSaga(action) {
    const { payload, callback } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    yield put(defaultValuesFetching());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            clientName: sessionDetails && sessionDetails.clientName,
            userName: sessionDetails && sessionDetails.name,
            ClientId: payload
        });
        const response = yield call(
            axios,
            ClientMDefaultValueOnClickofAddClient,
            requestDetails
        );
        if (response && response.status === 200) {
            callback && callback(response.data);
        }
        yield put(defaultValuesFetchComplete());
    } catch (error) {
        yield put(defaultValuesFetchComplete());
    }
}

function* initGetClientByNameSaga(action) {
    const { payload, callback } = action || {};
    yield put(fetchingClientByName());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ClientMClientDetailsByName,
            requestDetails
        );
        if (response && response.status === 200) {
            callback && callback(response.data);
        }
        yield put(clientByNameFetchingComplete());
    } catch (error) {
        yield put(clientByNameFetchingComplete());
    }
}

function* initSaveClientDataSaga(action) {
    const { payload, callback } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    try {
        const requestDetails = postRequestDetails();
        let updatedPayload = {
            ...payload,
            userName: sessionDetails && sessionDetails.name,
        };
        if (payload && payload.ClientID) {
            updatedPayload = {
                ...updatedPayload,
                ClientName: sessionDetails && sessionDetails.clientName,
            };
        } else {
            updatedPayload = {
                ...updatedPayload,
                LoggedClient: sessionDetails && sessionDetails.clientName,
            };
        }
        yield (requestDetails.data = {
            ...updatedPayload,
        });
        const url =
            payload && payload.ClientID ? ClientMUpdateClientProfile : AddNewClient;
        const response = yield call(axios, url, requestDetails);
        if (response && response.status === 200 && response.data !== "Request could not be processed at this time. Please contact support team.") {
            callback && callback(response);
            yield put(clientDataSaveSuccess());
        } else {
            yield put(clientDataSaveFailed());
        }
    } catch (error) {
        callback && callback(error);
        yield put(clientDataSaveFailed());
    }
}

function* initGetSavedReportsList(action) {
    const { payload, callback } = action || {};
    yield put(fetchingSavedReportsList());
    try {
        const requestDetails = postRequestDetails();
        let url = ReportManagementSavedReportsList;
        if (payload && payload.ClientName) url = `${url}/${payload.ClientName}`;
        const response = yield call(axios, url, requestDetails);
        if (response && response.status === 200) {
            callback && callback(response);
        }
        yield put(savedReportsListFetchingComplete());
    } catch (error) {
        yield put(savedReportsListFetchingComplete());
    }
}

function* initUploadReportsSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ReportManagementUploadReports,
            requestDetails
        );
        if (response && response.status === 200) {
            if (response.data && response.data.resultStatus) {
                generateNotification(response.data.message, "success");
            } else {
                generateNotification(response.data.message, "danger");
            }
            yield put(reportsUploadSaveSuccess());
        } else {
            yield put(reportsUploadSaveFailed());
        }
    } catch (error) {
        yield put(reportsUploadSaveFailed());
    }
}
