import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { storage } from "../../../config/storage";
import { generateNotification } from "../../../helper/generateNotification";
import {
  ClientSystemParamGetClientSystemParameter,
  ClientSystemParamSaveClientSystemParameter,
} from "../../../config/APINames";
import {
  INIT_CLIENT_SYSTEM_PARAMETER,
  INIT_SAVE_CLIENT_SYSTEM_PARAMETER,
} from "./actionTypes";
import { fetchClientSystemParameter } from "./actions";
export default function* watchLogin() {
  yield all([takeLatest(INIT_CLIENT_SYSTEM_PARAMETER, initSystemClientSaga)]);
  yield all([
    takeLatest(INIT_SAVE_CLIENT_SYSTEM_PARAMETER, initSaveSystemClientSaga),
  ]);
}
function* initSystemClientSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ClientSystemParamGetClientSystemParameter,
      requestDetails
    );
    if (response && response.status === 200) {
      storage.setItem("clientSystemParameters", JSON.stringify(response.data));
      yield put(fetchClientSystemParameter(response.data));
      callback && callback(response.data);
    }
  } catch (error) {
    generateNotification(error, "danger");
  }
}
function* initSaveSystemClientSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ClientSystemParamSaveClientSystemParameter,
      requestDetails
    );
    if (response && response.status === 200) {
      callback && callback(response.data);
    }
  } catch (error) {
    generateNotification(error, "danger");
  }
}
