import React from "react";
import PropTypes from "prop-types";
import Input from "../Input";
import "./style.scss";

const LabeledInput = (props) => {
  const labelclass = `form-label  ${props.required ? `required` : ``}`;
  const wrapperclass = `input-wrapper  ${props.disabled ? `disabled` : ``}  ${
    props.showError ? `error` : ``
  }`;
  return (
    <div className={wrapperclass}>
      <div className="labeled-input">
        <label htmlFor={props.id} className={labelclass}>
          {props.label}
        </label>
        <Input
          type={props.type}
          ariaLabel={props.ariaLabel || props.label}
          placeholder={props.placeholder}
          value={props.value}
          id={props.id}
          name={props.name}
          disabled={props.disabled}
          readOnly={props.readOnly}
          {...props}
        />
        {props.iconRight && (
          <button
            className="right"
            onClick={props.onIconRightClick}
            tabIndex={props.onIconRightClick ? 0 : -1}
            aria-label={props.rightIconAriaLabel}
          >
            {props.iconRight}
          </button>
        )}
      </div>
    </div>
  );
};

LabeledInput.propTypes ={
  type:PropTypes.string,
  placeholder:PropTypes.any,
  value:PropTypes.any,
  id:PropTypes.any,
  name:PropTypes.any,
  disabled:PropTypes.bool,
  readOnly:PropTypes.bool,
  handleClick:PropTypes.func,
  handleFocus:PropTypes.func,
  ariaLabel:PropTypes.string,
  iconRight:PropTypes.any,
  rightIconAriaLabel:PropTypes.string,
  onIconRightClick:PropTypes.func,
};

export default LabeledInput;
