import getEnv from './EnvConfig';
const env = getEnv();
export const EnviromentName = env.NODE_ENV || "";
export const Inital_Page_Size =
  parseInt(env.REACT_APP_DATA_TABLE_PER_PAGE) || 10;
export const PAGE_SIZE_LIST = env
  .REACT_APP_DATA_TABLE_ROWS_PER_PAGE_OPTIONS
  ? env.REACT_APP_DATA_TABLE_ROWS_PER_PAGE_OPTIONS.split(",")
  : ["05", "10", "30", "50"];
export const MAX_OTP_LENGTH = parseInt(env.MAX_OTP_LENGTH) || 10;
export const userLogoutTimeInSecs =
  (parseInt(env.REACT_APP_LOGOUT_TIME_IN_MINS) || 20) * 60;
export const RedirectURL = env.REACT_APP_REDIRECT_URL || "";
export const ClientID = env.REACT_APP_SSO_CLIENT_ID || "";
export const Authority = env.REACT_APP_AUTHORITY || "";
export const AppVersion = env.REACT_APP_VERSION || "";
export const ClientCount = env.REACT_APP_CLIENT_COUNT || "";
export const Tenant = env.REACT_APP_ATCAT_TENANT || "";
export const AtcatAdminName = env.REACT_APP_ATCAT_ADMIN_NAME || "ATCAT";
export const ReportURL = env.REACT_APP_REPORT_URL || "";
export const ExportPath = env.REACT_APP_EXPORT_PATH || "";
export const DEV_API_URL = env.REACT_APP_DEV_API_URL;
export const TEST_API_URL = env.REACT_APP_TEST_API_URL;
export const PREPROD_API_URL = env.REACT_APP_PREPROD_API_URL;
export const PROD_API_URL = env.REACT_APP_PROD_API_URL;
export const PackageReleaseVersion = env
  .REACT_APP_PACKAGE_RELEASE_VERSION || "";
export const Error_Msg =
  "Request could not be processed at this time. Please contact support team.";
export const ReleaseMsg = "Exception Sets Released Successfully";
export const UnreleaseMsg = "Exception Set Unreleased Successfully";
export const DeleteJobMsg =
  "Success: Selected jobs are deleted successfully..!!";
export const RunJobMsg = "Job(s) submitted successfully.";
export const PasswordLimitExceed =
  "Reset Password has exceed the limit.Please contact ATCAT support team.";
export const IncorrectCredentials =
  "Incorrect Credentials Please contact ATCAT support team.";
export const ProvideValidInfo = "Please provide valid information";
export const OTPError = "You didn't enter the expected OTP. Please try again.";
export const PasswordChangeMsg =
  "If the user exists, Password Changed Successfully.";
