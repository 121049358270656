import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { AtcatAdminName, Error_Msg } from "../../../config";
import { postRequestDetails } from "../../../config/requestHeaders";
import {
  UDLMaintenanceGetUDLDataList,
  UDLMaintenanceGetUDLDetails,
  UDLMaintenanceSaveUDLDetails,
  ClientUDLGetUDLListData,
  ClientUDLGetUDLDetails,
  ClientUDLSaveUDLDetails,
} from "../../../config/APINames";
import {
  FETCH_UDLMAINTENANCE,
  INIT_UDLMAINTENANCE_DETAILS,
  SAVE_UDLMAINTENANCEDETAILS,
} from "./actionTypes";
import {
  fetchUDLMaintenanceList,
  fetchUDLMaintenanceDetailsdData,
} from "./actions";
import { loginFailed } from "../../Login/store/actions";
import { generateNotification } from "../../../helper/generateNotification";
import generateKey from "../../../config/generateKey";
export default function* watchUDLMaintenance() {
  yield all([
    takeLatest(FETCH_UDLMAINTENANCE, initGetUDLData),
    takeLatest(INIT_UDLMAINTENANCE_DETAILS, initGetUDLDetailsData),
    takeLatest(SAVE_UDLMAINTENANCEDETAILS, saveUDLDetailsData),
  ]);
}
function* initGetUDLData(action) {
  const { payload } = action || {};
  const { clientName = "" } = payload;
  try {
    let url;
    if (clientName.toUpperCase() === AtcatAdminName) {
      delete payload.AtcatVersionType;
      url = UDLMaintenanceGetUDLDataList;
    } else {
      url = ClientUDLGetUDLListData;
    }
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, url, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data === "Request cannot be processed") {
      generateNotification("Request cannot be processed", "danger");
    } else if (response !== Error_Msg) {
      yield put(fetchUDLMaintenanceList(response.data));
      response = response.data;
    }
  } catch (error) {
    yield put(loginFailed(error));
  }
}

function* initGetUDLDetailsData(action) {
  const { payload, callback } = action || {};
  const { clientName = "" } = payload;
  try {
    let url;
    if (clientName.toUpperCase() === AtcatAdminName) {
      url = UDLMaintenanceGetUDLDetails;
    } else {
      url = ClientUDLGetUDLDetails;
    }
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, url, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data === "Request cannot be processed") {
      generateNotification("Request cannot be processed", "danger");
    } else if (response !== Error_Msg) {
      yield put(fetchUDLMaintenanceDetailsdData(response.data));
      response = response.data;
      callback(response);
    }
  } catch (error) {
    yield put(loginFailed(error));
  }
}

function* saveUDLDetailsData(action) {
  const { payload, callback } = action || {};
  const { clientName = "" } = payload;
  try {
    let url;
    if (clientName.toUpperCase() === AtcatAdminName) {
      url = UDLMaintenanceSaveUDLDetails;
    } else {
      url = ClientUDLSaveUDLDetails;
    }
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, url, requestDetails);
    response = response.data;
    if (clientName.toUpperCase() === AtcatAdminName) {
      if (response.isSuccess === true) {
        generateNotification("atcat.udlSavedSuccessfully", "success");
        callback && callback();
      } else if (response.isSuccess === false) {
        const msg = generateKey(response.deletedFailedItems);
        generateNotification(msg, "danger");
      } else {
        generateNotification(
          "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
          "danger"
        );
      }
    } else {
      if (payload.UDLName === "Manual Claim Category Type") {
        if (response === "UDL saved successfully") {
          generateNotification("atcat.udlSavedSuccessfully", "success");
          callback && callback();
        } else {
          generateNotification(
            "atcat.itemSCouldnotbedeletedbecauseoftheirexistingreferencesinthesystem",
            "danger"
          );
        }
      } else {
        if (response === "UDL saved successfully") {
          generateNotification("atcat.udlSavedSuccessfully", "success");
          callback && callback();
        } else {
          generateNotification(
            "atcat.pleaseContactSystemAdminstrator",
            "danger"
          );
        }
      }
    }
  } catch (error) {
    yield put(loginFailed(error));
  }
}
