import {
  INIT_FETCH_EXCEPTION_FILTER_COL_NAMES,
  FETCHING_EXCEPTION_FILTER_COL_NAMES,
  EXCEPTION_FILTER_COL_NAMES_FETCHED,
  EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE,
  EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL,
  INIT_SAVE_EXCEPTION_FILTER,
  ASSIGN_INITAL_DATA,
  INIT_FETCH_EXCEPTION_FILTER_NAMES,
  INIT_FETCH_EXCEPTION_LIST,
  FETCHING_EXCEPTION_LIST,
  EXCEPTION_LIST_FETCHED,
  EXCEPTION_LIST_FETCH_COMPLETE,
  EXCEPTION_LIST_FETCH_FAIL,
  INIT_GET_EXCEPTION_COUNT,
  FETCHING_EXCEPTION_COUNT,
  FETCH_EXCEPTION_COUNT,
  EXCEPTION_COUNT_FETCHING_COMPLETE,
  INIT_FETCH_EXCEPTION_FILTER_DETAILS,
  INIT_DELETE_EXCEPTION_FILTER,
  INIT_GET_EXCEPTION_RECORDS_EXPORT,
  FETCHING_EXCEPTION_RECORDS_EXPORT,
  FETCH_EXCEPTION_RECORDS_EXPORT,
  EXCEPTION_RECORDS_EXPORT_FETCHING_COMPLETE,
  INIT_GET_EXCEPTION_RECORDS_EXPORT_ALL,
  FETCHING_EXCEPTION_RECORDS_EXPORT_ALL,
  FETCH_EXCEPTION_RECORDS_EXPORT_ALL,
  EXCEPTION_RECORDS_EXPORT_ALL_FETCHING_COMPLETE,
  INIT_GET_RELEASE_EXCEPTION,
  FETCHING_RELEASE_EXCEPTION,
  FETCH_RELEASE_EXCEPTION,
  RELEASE_EXCEPTION_FETCHING_COMPLETE,
  INIT_GET_INVESTIGATION_TYPE,
  FETCHING_INVESTIGATION_TYPE,
  FETCH_INVESTIGATION_TYPE,
  INVESTIGATION_TYPE_FETCHING_COMPLETE,
  INIT_USERMANGEMENT_GET_USERS_LIST,
  FETCH_USERMANGEMENT_GET_USERS_LIST,
  INIT_GET_DUPS_TYPE,
  FETCHING_DUPS_TYPE,
  FETCH_DUPS_TYPE,
  DUPS_TYPE_FETCHING_COMPLETE,
  INIT_USER_ROLE_BASED_ACTION,
  FETCH_USER_ROLE_BASED_ACTION,
  INIT_FETCH_EXCEPTION_RECORD_HEADER,
  FETCHING_EXCEPTION_RECORD_HEADER,
  FETCH_EXCEPTION_RECORD_HEADER,
  EXCEPTION_RECORD_HEADER_FETCHING_COMPLETE,
  INIT_GET_EXCEPTION_RECORD_LIST,
  FETCHING_EXCEPTION_RECORD_LIST,
  FETCH_EXCEPTION_RECORD_LIST,
  EXCEPTION_RECORD_LIST_FETCHING_COMPLETE,
  INIT_GET_DIARY_LIST,
  FETCHING_DIARY_LIST,
  FETCH_DIARY_LIST,
  DIARY_LIST_FETCHING_COMPLETE,
  INIT_GET_DOCUMENTS_LIST,
  FETCH_DOCUMENTS_LIST,
  INIT_ADD_DOCUMENT,
  INIT_UPDATE_DOCUMENT,
  INIT_DOCUMENT_DETAILS,
  INIT_GET_DIARY_TYPE,
  FETCHING_DIARY_TYPE,
  FETCH_DIARY_TYPE,
  DIARY_TYPE_FETCHING_COMPLETE,
  INIT_SAVE_ADD_DIARY,
  ADD_DIARY_SAVE_FAILED,
  ADD_DIARY_SAVE_SUCCESS,
  INIT_GET_DIARY_DETAILS,
  FETCHING_DIARY_DETAILS,
  DIARY_DETAILS_FETCH_COMPLETE,
  INIT_DELETE_DOCUMENT,
  FETCHING_DELETE_DOCUMENT,
  DELETE_DOCUMENT_FETCH_COMPLETE,
  INIT_UPDATE_DIARY_DETAILS,
  DIARY_DETAILS_UPDATE_FAILED,
  DIARY_DETAILS_UPDATE_SUCCESS,
  INIT_HISTORY_COUNT,
  FETCH_HISTORY_COUNT,
  INIT_HISTORY_DATA,
  FETCH_HISTORY_DATA,
  INIT_DUPLICATE_EXCEPTIONS_POST,
  INIT_MOVE_AND_COPY_TO_CLAIM,
  FETCH_MOVE_AND_COPY_TO_CLAIM,
  INIT_MARK_DIARY_COMPLETE,
  MARK_DIARY_COMPLETE_FAILED,
  MARK_DIARY_COMPLETE_SUCCESS,
  INIT_UNMARK_DIARY_COMPLETE,
  UNMARK_DIARY_COMPLETE_FAILED,
  UNMARK_DIARY_COMPLETE_SUCCESS,
  INIT_SAVE_DUPLICATE_EXCEPTIONS_DETAILS,
  INIT_GET_PRIMUS_DFM_URL_DETAILS,
  FETCH_BULK_RELEASE_REASON_TYPE,
  APPLY_INITAL_DUPLICATE_EXCEPTION_VALUES,
} from "./actionTypes";

export const initFetchExceptionColNames = (payload) => {
  return {
    type: INIT_FETCH_EXCEPTION_FILTER_COL_NAMES,
    payload,
  };
};
export const fetchingExceptionColNames = () => {
  return {
    type: FETCHING_EXCEPTION_FILTER_COL_NAMES,
  };
};

export const fetchExceptionColNames = (data) => {
  return {
    type: EXCEPTION_FILTER_COL_NAMES_FETCHED,
    data,
  };
};

export const exceptionColNameFetchComplete = () => {
  return {
    type: EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE,
  };
};
export const exceptionColNameFetchFailed = () => {
  return {
    type: EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL,
  };
};

export const initSaveExceptionFilter = (payload, callback) => {
  return {
    type: INIT_SAVE_EXCEPTION_FILTER,
    payload,
    callback,
  };
};

export function assignInitalData() {
  return {
    type: ASSIGN_INITAL_DATA,
  };
}

export const initGetExceptionFilterNames = (payload, callback) => {
  return {
    type: INIT_FETCH_EXCEPTION_FILTER_NAMES,
    payload,
    callback,
  };
};

export const initGetExceptionCount = (payload) => {
  return {
    type: INIT_GET_EXCEPTION_COUNT,
    payload,
  };
};

export function fetchingExceptionCount() {
  return {
    type: FETCHING_EXCEPTION_COUNT,
  };
}

export function fetchExceptionCount(data) {
  return {
    type: FETCH_EXCEPTION_COUNT,
    data,
  };
}

export function exceptionCountFetchingComplete() {
  return {
    type: EXCEPTION_COUNT_FETCHING_COMPLETE,
  };
}

export const initFetchExceptionList = (payload) => {
  return {
    type: INIT_FETCH_EXCEPTION_LIST,
    payload,
  };
};

export const fetchingExceptionList = () => {
  return {
    type: FETCHING_EXCEPTION_LIST,
  };
};

export const fetchExceptionList = (data) => {
  return {
    type: EXCEPTION_LIST_FETCHED,
    data,
  };
};

export const exceptionListFetchComplete = () => {
  return {
    type: EXCEPTION_LIST_FETCH_COMPLETE,
  };
};
export const exceptionListFetchFailed = () => {
  return {
    type: EXCEPTION_LIST_FETCH_FAIL,
  };
};

export const initFecthExceptionFilterDetails = (payload, callback) => {
  return {
    type: INIT_FETCH_EXCEPTION_FILTER_DETAILS,
    payload,
    callback,
  };
};

export const initDeleteExceptionFilter = (payload, callback) => {
  return {
    type: INIT_DELETE_EXCEPTION_FILTER,
    payload,
    callback,
  };
};

export const initGetExceptionRecordsExport = (payload) => {
  return {
    type: INIT_GET_EXCEPTION_RECORDS_EXPORT,
    payload,
  };
};

export function fetchingExceptionRecodsExport() {
  return {
    type: FETCHING_EXCEPTION_RECORDS_EXPORT,
  };
}

export function fetchExceptionRecordsExport(data) {
  return {
    type: FETCH_EXCEPTION_RECORDS_EXPORT,
    data,
  };
}

export function exceptionRecordsExportFetchingComplete() {
  return {
    type: EXCEPTION_RECORDS_EXPORT_FETCHING_COMPLETE,
  };
}

export const initGetExceptionRecordsExportAll = (payload) => {
  return {
    type: INIT_GET_EXCEPTION_RECORDS_EXPORT_ALL,
    payload,
  };
};

export function fetchingExceptionRecodsExportAll() {
  return {
    type: FETCHING_EXCEPTION_RECORDS_EXPORT_ALL,
  };
}

export function fetchExceptionRecordsExportAll(data) {
  return {
    type: FETCH_EXCEPTION_RECORDS_EXPORT_ALL,
    data,
  };
}

export function exceptionRecordsExportAllFetchingComplete() {
  return {
    type: EXCEPTION_RECORDS_EXPORT_ALL_FETCHING_COMPLETE,
  };
}

export const initGetReleaseException = (payload, callback) => {
  return {
    type: INIT_GET_RELEASE_EXCEPTION,
    payload,
    callback,
  };
};

export function fetchingReleaseException() {
  return {
    type: FETCHING_RELEASE_EXCEPTION,
  };
}

export function fetchReleaseException(data) {
  return {
    type: FETCH_RELEASE_EXCEPTION,
    data,
  };
}

export function releaseExceptionFetchingComplete() {
  return {
    type: RELEASE_EXCEPTION_FETCHING_COMPLETE,
  };
}

export function initGetInvestigationType(payload) {
  return {
    type: INIT_GET_INVESTIGATION_TYPE,
    payload,
  };
}

export function fetchingInvestigationType() {
  return {
    type: FETCHING_INVESTIGATION_TYPE,
  };
}

export function fetchInvestigationType(data) {
  return {
    type: FETCH_INVESTIGATION_TYPE,
    data,
  };
}

export function investigationTypeFetchingComplete() {
  return {
    type: INVESTIGATION_TYPE_FETCHING_COMPLETE,
  };
}
export const initUsermangementGetUsersList = (payload) => {
  return {
    type: INIT_USERMANGEMENT_GET_USERS_LIST,
    payload,
  };
};
export function fetchUsermangementGetUsersList(data) {
  return {
    type: FETCH_USERMANGEMENT_GET_USERS_LIST,
    data,
  };
}

export function initGetDupsType(payload, callback) {
  return {
    type: INIT_GET_DUPS_TYPE,
    payload,
    callback,
  };
}

export function fetchingDupsType() {
  return {
    type: FETCHING_DUPS_TYPE,
  };
}

export function fetchDupsType(data) {
  return {
    type: FETCH_DUPS_TYPE,
    data,
  };
}

export function dupsTypeFetchingComplete() {
  return {
    type: DUPS_TYPE_FETCHING_COMPLETE,
  };
}

export const initUserRoleBasedAction = (payload) => {
  return {
    type: INIT_USER_ROLE_BASED_ACTION,
    payload,
  };
};
export function fetchUserRoleBasedAction(data) {
  return {
    type: FETCH_USER_ROLE_BASED_ACTION,
    data,
  };
}

export const initFetchExceptionRecordHeader = (payload) => {
  return {
    type: INIT_FETCH_EXCEPTION_RECORD_HEADER,
    payload,
  };
};
export const fetchingExceptionRecordHeader = () => {
  return {
    type: FETCHING_EXCEPTION_RECORD_HEADER,
  };
};

export const fetchExceptionRecordHeader = (data) => {
  return {
    type: FETCH_EXCEPTION_RECORD_HEADER,
    data,
  };
};

export const exceptionRecordHeaderFetchingComplete = () => {
  return {
    type: EXCEPTION_RECORD_HEADER_FETCHING_COMPLETE,
  };
};

export function initGetExceptionRecordList(payload) {
  return {
    type: INIT_GET_EXCEPTION_RECORD_LIST,
    payload,
  };
}

export function fetchingExceptionRecordList() {
  return {
    type: FETCHING_EXCEPTION_RECORD_LIST,
  };
}

export function fetchExceptionRecordList(data) {
  return {
    type: FETCH_EXCEPTION_RECORD_LIST,
    data,
  };
}

export function exceptionRecordListFetchingComplete() {
  return {
    type: EXCEPTION_RECORD_LIST_FETCHING_COMPLETE,
  };
}

export function initGetDiaryList(payload) {
  return {
    type: INIT_GET_DIARY_LIST,
    payload,
  };
}

export function fetchingDiaryList() {
  return {
    type: FETCHING_DIARY_LIST,
  };
}

export function fetchDiaryList(data) {
  return {
    type: FETCH_DIARY_LIST,
    data,
  };
}

export function diaryListFetchingComplete() {
  return {
    type: DIARY_LIST_FETCHING_COMPLETE,
  };
}

export function initGetDocumentsList(payload) {
  return {
    type: INIT_GET_DOCUMENTS_LIST,
    payload,
  };
}

export function fetchDocumentsList(data) {
  return {
    type: FETCH_DOCUMENTS_LIST,
    data,
  };
}
export const initAddDocument = (payload, callback) => {
  return {
    type: INIT_ADD_DOCUMENT,
    payload,
    callback,
  };
};
export const initUpdateDocument = (payload, callback) => {
  return {
    type: INIT_UPDATE_DOCUMENT,
    payload,
    callback,
  };
};
export const initDocumentDetails = (payload, callback) => {
  return {
    type: INIT_DOCUMENT_DETAILS,
    payload,
    callback,
  };
};

export function initGetDiaryType(payload) {
  return {
    type: INIT_GET_DIARY_TYPE,
    payload,
  };
}

export function fetchingDiaryType() {
  return {
    type: FETCHING_DIARY_TYPE,
  };
}

export function fetchDiaryType(data) {
  return {
    type: FETCH_DIARY_TYPE,
    data,
  };
}

export function diaryTypeFetchingComplete() {
  return {
    type: DIARY_TYPE_FETCHING_COMPLETE,
  };
}

export const initAddDiary = (payload, callback) => {
  return {
    type: INIT_SAVE_ADD_DIARY,
    payload,
    callback,
  };
};

export const addDiarySuccess = () => {
  return {
    type: ADD_DIARY_SAVE_SUCCESS,
  };
};

export const addDiaryFailed = () => {
  return {
    type: ADD_DIARY_SAVE_FAILED,
  };
};

export const initGetDiaryDetails = (payload, callback) => {
  return {
    type: INIT_GET_DIARY_DETAILS,
    payload,
    callback,
  };
};

export const fetchingDiaryDetails = () => {
  return {
    type: FETCHING_DIARY_DETAILS,
  };
};

export const diaryDetailsFetchingComplete = () => {
  return {
    type: DIARY_DETAILS_FETCH_COMPLETE,
  };
};

export const initDeleteDocument = (payload, callback) => {
  return {
    type: INIT_DELETE_DOCUMENT,
    payload,
    callback,
  };
};
export const fetchingDeleteDocument = () => {
  return {
    type: FETCHING_DELETE_DOCUMENT,
  };
};

export const deleteDocumentFetchingComplete = () => {
  return {
    type: DELETE_DOCUMENT_FETCH_COMPLETE,
  };
};

export const initUpdateDiary = (payload, callback) => {
  return {
    type: INIT_UPDATE_DIARY_DETAILS,
    payload,
    callback,
  };
};

export const updateDiarySuccess = () => {
  return {
    type: DIARY_DETAILS_UPDATE_SUCCESS,
  };
};

export const updateDiaryFailed = () => {
  return {
    type: DIARY_DETAILS_UPDATE_FAILED,
  };
};
export const initHistoryCount = (payload) => {
  return {
    type: INIT_HISTORY_COUNT,
    payload,
  };
};

export const fetchHistoryCount = (data) => {
  return {
    type: FETCH_HISTORY_COUNT,
    data,
  };
};
export const initHistoryData = (payload) => {
  return {
    type: INIT_HISTORY_DATA,
    payload,
  };
};

export const fetchHistoryData = (data) => {
  return {
    type: FETCH_HISTORY_DATA,
    data,
  };
};

export const initDuplicateExceptionsPost = (payload, callback) => {
  return {
    type: INIT_DUPLICATE_EXCEPTIONS_POST,
    payload,
    callback,
  };
};

export const initMoveAndCopyToClaim = (payload, callback) => {
  return {
    type: INIT_MOVE_AND_COPY_TO_CLAIM,
    payload,
    callback,
  };
};
export const fetchMoveAndCopyToClaim = (data) => {
  return {
    type: FETCH_MOVE_AND_COPY_TO_CLAIM,
    data,
  };
};

export const initMarkDiaryComplete = (payload, callback) => {
  return {
    type: INIT_MARK_DIARY_COMPLETE,
    payload,
    callback,
  };
};

export const markDiaryCompleteSuccess = () => {
  return {
    type: MARK_DIARY_COMPLETE_SUCCESS,
  };
};

export const markDiaryCompleteFailed = () => {
  return {
    type: MARK_DIARY_COMPLETE_FAILED,
  };
};

export const initUnmarkDiaryComplete = (payload, callback) => {
  return {
    type: INIT_UNMARK_DIARY_COMPLETE,
    payload,
    callback,
  };
};

export const unmarkDiaryCompleteSuccess = () => {
  return {
    type: UNMARK_DIARY_COMPLETE_SUCCESS,
  };
};

export const unmarkDiaryCompleteFailed = () => {
  return {
    type: UNMARK_DIARY_COMPLETE_FAILED,
  };
};

export const initSaveDuplicateExceptionDetails = (payload, callback) => {
  return {
    type: INIT_SAVE_DUPLICATE_EXCEPTIONS_DETAILS,
    payload,
    callback,
  };
};

export const initGetPrimusDFMUrl = (payload, callback) => {
  return {
    type: INIT_GET_PRIMUS_DFM_URL_DETAILS,
    payload,
    callback,
  };
};

export const fetchBulkReleaseReasonType = (data) => {
  return {
    type: FETCH_BULK_RELEASE_REASON_TYPE,
    data,
  };
};
export const applyInitalValues = () => {
  return {
    type: APPLY_INITAL_DUPLICATE_EXCEPTION_VALUES,
  };
};
