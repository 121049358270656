export const INIT_FETCH_JOB_LIST = "INIT_FETCH_JOB_LIST";
export const FETCHING_JOB_LIST = "FETCHING_JOB_LIST";
export const JOB_LIST_FETCHED = "JOB_LIST_FETCHED";
export const JOB_LIST_FETCH_COMPLETE = "JOB_LIST_FETCH_COMPLETE";
export const JOB_LIST_FETCH_FAIL = "JOB_LIST_FETCH_FAIL";

export const INIT_FETCH_JOB_FILTER_COL_NAMES =
  "INIT_FETCH_JOB_FILTER_COL_NAMES";
export const FETCHING_JOB_FILTER_COL_NAMES = "FETCHING_JOB_FILTER_COL_NAMES";
export const JOB_FILTER_COL_NAMES_FETCHED = "JOB_FILTER_COL_NAMES_FETCHED";
export const JOB_FILTER_COL_NAMES_FETCH_COMPLETE =
  "JOB_FILTER_COL_NAMES_FETCH_COMPLETE";
export const JOB_FILTER_COL_NAMES_FETCH_FAIL =
  "JOB_FILTER_COL_NAMES_FETCH_FAIL";

export const INIT_FETCH_JOB_FILTERS = "INIT_FETCH_JOB_FILTERS";

export const INIT_SAVE_JOB_FILTERS = "INIT_SAVE_JOB_FILTERS";

export const INIT_JOB_FILTER_FETCH = "INIT_JOB_FILTER_FETCH";

export const INIT_DELETE_JOB_FILTERS = "INIT_DELETE_JOB_FILTERS";

export const INIT_SAVE_FILTER = "INIT_SAVE_FILTER";

export const INIT_GET_JOB_COUNT = "INIT_GET_JOB_COUNT";
export const FETCHING_JOB_COUNT = "FETCHING_JOB_COUNT";
export const FETCH_JOB_COUNT = "FETCH_JOB_COUNT";
export const JOB_COUNT_FETCHING_COMPLETE = "JOB_COUNT_FETCHING_COMPLETE";

export const INIT_GET_COMPLETED_JOB_DETAILS = "INIT_GET_COMPLETED_JOB_DETAILS";
export const FETCHING_JOB_DETAILS = "FETCHING_JOB_DETAILS";
export const FETCH_JOB_DETAILS = "FETCH_JOB_DETAILS";
export const JOB_DETAILS_FETCHING_COMPLETE = "JOB_DETAILS_FETCHING_COMPLETE";
export const ASSIGN_INITAL_DATA = "ASSIGN_INITAL_DATA";
export const INIT_CLIENT_COMPLETED_JOB_LIST = "INIT_CLIENT_COMPLETED_JOB_LIST";
export const INIT_CLIENT_JOB_COUNT = "INIT_CLIENT_JOB_COUNT";
