import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { CompletedJobsClientQueueSummary } from "../../../config/APINames";
import { INIT_GET_CLIENT_QUEUE_SUMMARY } from "./actionTypes";
import { fetchClientQueueSummaryList, initGetClientQueueSummaryFailed } from "./actions";

export default function* watchClientQueueSummary() {
    yield all([
        takeLatest(INIT_GET_CLIENT_QUEUE_SUMMARY, initGetClientQueueSummarySaga),
    ]);
}
function* initGetClientQueueSummarySaga(action) {
    const { payload } = action || {};
    try {
        let url = CompletedJobsClientQueueSummary;
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(axios, url, requestDetails);
        if (response && response.status === 200) {
            yield put(fetchClientQueueSummaryList(response.data));
        }        
        response = response.data;
    } catch (error) {
        yield put(initGetClientQueueSummaryFailed());
    }
}
