export const INIT_SEQUENCE_FIELD_DETAILS = "INIT_SEQUENCE_FIELD_DETAILS";
export const INIT_PROCESSING_DATA = "INIT_PROCESSING_DATA";
export const INIT_CRITERIA_FIELD = "INIT_CRITERIA_FIELD";
export const FETCH_SEQUENCE_FIELD_DETAILS = "FETCH_SEQUENCE_FIELD_DETAILS";
export const FETCH_PROCESSING_DATA = "FETCH_PROCESSING_DATA";
export const FETCH_CRITERIA_FIELD = "FETCH_CRITERIA_FIELD";
export const INIT_INITAL_STATE = "INIT_INITAL_STATE";
export const INIT_EDIT_FILTER_PAGE = "INIT_EDIT_FILTER_PAGE";
export const FETCH_EDIT_FILTER_PAGE = "FETCH_EDIT_FILTER_PAGE";
export const INIT_SEQUENCE_DETAILS = "INIT_SEQUENCE_DETAILS";
export const FETCH_SEQUENCE_DETAILS = "FETCH_SEQUENCE_DETAILS";
export const INIT_SUPPLEMENTRY_CRITERIA_DETAILS =
  "INIT_SUPPLEMENTRY_CRITERIA_DETAILS";
export const FETCH_SUPPLEMENTRY_CRITERIA_DETAILS =
  "FETCH_SUPPLEMENTRY_CRITERIA_DETAILS";
export const INIT_DELETE_SEQUENCE = "INIT_DELETE_SEQUENCE";
export const INIT_SAVE_SUPPLEMENTARY_DETAILS =
  "INIT_SAVE_SUPPLEMENTARY_DETAILS";
export const INIT_SAVE_SEQUENCE_REORDER = "INIT_SAVE_SEQUENCE_REORDER";
export const INIT_SAVE_EDIT_FILTER_CRITERIA = "INIT_SAVE_EDIT_FILTER_CRITERIA";
export const INIT_SAVE_ADD_SEQUENCE = "INIT_SAVE_ADD_SEQUENCE";
export const INIT_SAVE_EDIT_SEQUENCE = "INIT_SAVE_EDIT_SEQUENCE";
