import React, { useState } from "react";
import Menu from "./Menu";
import Confirm from "../../Button/Confirm";
import { routes } from "../../../routes/routes";
import { connect } from "react-redux";
import { compose } from "redux";
import { initSetRoute } from "../../../store/actions";
import { getPath } from "../../../helper";
import { storage } from "../../../config/storage";
import { createStructuredSelector } from "reselect";
import { selectRoute } from "../../../store/selectors";
import { selectAuthDetails } from "../../../containers/Login/store/selectors";
import { selectMobileReportUrl } from "../../../containers/Layout/Header/store/selectors";
import { useTranslation } from "react-i18next";
const Navigation = (props) => {
  const { t } = useTranslation();
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [route, setRoutes] = useState("");
  let path = storage.getItem("currPath");
  if (path) {
    path = JSON.parse(path).pathname;
  }
  const nest = (items, parent = 0) => {
    const nested = [];

    Object.values(items).forEach((item) => {
      const treeItem = {
        ...item,
      };
      if (item.parentKey === parent) {
        const children = nest(items, item.key);

        if (children.length) {
          treeItem.children = children;
        }

        nested.push(treeItem);
      }
    });

    return nested;
  };
  let authRoutes = routes
    ? routes.filter((item) => {
        return (
          item.sourceDomain.includes(props.authDetails.sourceDomain) &&
          item.version.includes(props.authDetails.atcatVersion) &&
          item.role.includes(props.authDetails.role)
        );
      })
    : [];
  authRoutes =
    props.authDetails.clientId !== props.authDetails.ipqcClientId
      ? authRoutes.filter((item) => {
          return item.key !== "ipqc";
        })
      : authRoutes;
  authRoutes =
    props.mobileUrl === "true" || props.mobileUrl === true 
      ? authRoutes
      : authRoutes.filter((item) => {
          return item.key !== "CfoDashboard";
        });
  const nestedMenus = nest(
    process.env.REACT_APP_AUTH_STAT ? routes : authRoutes
  );

  const activePath = getPath(nestedMenus, path);
  const setPath = (obj) => {
    const visitedState = storage.getItem("visited");
    setConfirmationPopup(visitedState || false);
    setRoutes(obj);
    visitedState !== "true" && props.setRoute(obj);
  };
  return (
    <>
      <nav
        id="nav"
        className={`nav ${props.isOpen ? `open` : ``}`}
        role="navigation"
      >
        {nestedMenus && nestedMenus.length ? (
          <Menu
            activePath={activePath || ["Home"]}
            menuList={nestedMenus}
            setRoute={(obj) => {
              setPath(obj);
            }}
          />
        ) : null}
      </nav>
      {showConfirmationPopup === "true" && (
        <Confirm
          showConfirmation={showConfirmationPopup === "true" ? true : false}
          handleConfirmation={() => {
            props.setRoute(route);
            setConfirmationPopup(false);
            storage.setItem("visited", false);
          }}
          message={t("atcat.confirmCancel")}
          handleCancel={() => setConfirmationPopup(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentRoute: selectRoute(),
  authDetails: selectAuthDetails(),
  mobileUrl: selectMobileReportUrl(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    setRoute: (route) => {
      dispatch(initSetRoute(route));
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Navigation);
