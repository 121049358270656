export const INIT_FETCH_PH_FILTER_COL_NAMES = "INIT_FETCH_PH_FILTER_COL_NAMES";
export const FETCHING_PH_FILTER_COL_NAMES = "FETCHING_PH_FILTER_COL_NAMES";
export const PH_FILTER_COL_NAMES_FETCHED = "PH_FILTER_COL_NAMES_FETCHED";
export const PH_FILTER_COL_NAMES_FETCH_COMPLETE =
  "PH_FILTER_COL_NAMES_FETCH_COMPLETE";
export const PH_FILTER_COL_NAMES_FETCH_FAIL = "PH_FILTER_COL_NAMES_FETCH_FAIL";

export const ASSIGN_INITAL_DATA = "ASSIGN_INITAL_DATA";

export const INIT_SAVE_PH_FILTER = "INIT_SAVE_PH_FILTER";

export const INIT_FETCH_PH_FILTERS = "INIT_FETCH_PH_FILTERS";

export const INIT_FETCH_PH_LIST_HEADER_NAMES =
  "INIT_FETCH_PH_LIST_HEADER_NAMES";
export const FETCHING_PH_LIST_HEADER_NAMES = "FETCHING_PH_LIST_HEADER_NAMES";
export const PH_LIST_HEADER_NAMES_FETCHED = "PH_LIST_HEADER_NAMES_FETCHED";
export const PH_LIST_HEADER_NAMES_FETCH_COMPLETE =
  "PH_LIST_HEADER_NAMES_FETCH_COMPLETE";
export const PH_LIST_HEADER_NAMES_FETCH_FAIL =
  "PH_LIST_HEADER_NAMES_FETCH_FAIL";

export const INIT_FETCH_PH_ROLE_BASED_ACTIONS =
  "INIT_FETCH_PH_ROLE_BASED_ACTIONS";
export const FETCHING_PH_ROLE_BASED_ACTIONS = "FETCHING_PH_ROLE_BASED_ACTIONS";
export const PH_ROLE_BASED_ACTIONS_FETCHED = "PH_ROLE_BASED_ACTIONS_FETCHED";
export const PH_ROLE_BASED_ACTIONS_FETCH_COMPLETE =
  "PH_ROLE_BASED_ACTIONS_FETCH_COMPLETE";
export const PH_ROLE_BASED_ACTIONS_FETCH_FAIL =
  "PH_ROLE_BASED_ACTIONS_FETCH_FAIL";

export const INIT_DELETE_PH_FILTERS = "INIT_DELETE_PH_FILTERS";

export const INIT_PH_FILTER_FETCH = "INIT_PH_FILTER_FETCH";

export const INIT_GET_PH_COUNT = "INIT_GET_PH_COUNT";
export const FETCHING_PH_COUNT = "FETCHING_PH_COUNT";
export const FETCH_PH_COUNT = "FETCH_PH_COUNT";
export const PH_COUNT_FETCHING_COMPLETE = "PH_COUNT_FETCHING_COMPLETE";

export const INIT_FETCH_PH_LIST = "INIT_FETCH_PH_LIST";
export const FETCHING_PH_LIST = "FETCHING_PH_LIST";
export const PH_LIST_FETCHED = "PH_LIST_FETCHED";
export const PH_LIST_FETCH_COMPLETE = "PH_LIST_FETCH_COMPLETE";
export const PH_LIST_FETCH_FAIL = "PH_LIST_FETCH_FAIL";

export const INIT_GET_PH_RECORDS_EXPORT = "INIT_GET_PH_RECORDS_EXPORT";
export const FETCHING_PH_RECORDS_EXPORT = "FETCHING_PH_RECORDS_EXPORT";
export const FETCH_PH_RECORDS_EXPORT = "FETCH_PH_RECORDS_EXPORT";
export const PH_RECORDS_EXPORT_FETCHING_COMPLETE =
  "PH_RECORDS_EXPORT_FETCHING_COMPLETE";

export const INIT_GET_PH_RECORDS_EXPORT_ALL = "INIT_GET_PH_RECORDS_EXPORT_ALL";
export const FETCHING_PH_RECORDS_EXPORT_ALL = "FETCHING_PH_RECORDS_EXPORT_ALL";
export const FETCH_PH_RECORDS_EXPORT_ALL = "FETCH_PH_RECORDS_EXPORT_ALL";
export const PH_RECORDS_EXPORT_ALL_FETCHING_COMPLETE =
  "PH_RECORDS_EXPORT_ALL_FETCHING_COMPLETE";
