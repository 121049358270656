export const INIT_EXCEPTION_FILTER_NAMES = "INIT_EXCEPTION_FILTER_NAMES";
export const INIT_EXCEPTION_LIST_HEADER_NAMES =
  "INIT_EXCEPTION_LIST_HEADER_NAMES";
export const FETCH_EXCEPTION_LIST_HEADER_NAMES =
  "FETCH_EXCEPTION_LIST_HEADER_NAMES";
export const INIT_EXCEPTION_LIST = "INIT_EXCEPTION_LIST";
export const FETCH_EXCEPTION_LIST = "FETCH_EXCEPTION_LIST";
export const INIT_EXCEPTION_FILTER_COLUMNS = "INIT_EXCEPTION_FILTER_COLUMNS";
export const FETCH_EXCEPTION_FILTER_COLUMNS = "FETCH_EXCEPTION_FILTER_COLUMNS";
export const INIT_EXCEPTION_FILTER_DETAILS = "INIT_EXCEPTION_FILTER_DETAILS";
export const INIT_SAVE_FILTER_EXCEPTION = "INIT_SAVE_FILTER_EXCEPTION";
export const INIT_EXCEPTION_TOTAL_COUNT = "INIT_EXCEPTION_TOTAL_COUNT";
export const FETCH_EXCEPTION_TOTAL_COUNT = "FETCH_EXCEPTION_TOTAL_COUNT";
export const INIT_DELETE_FILTER_EXCEPTION = "INIT_DELETE_FILTER_EXCEPTION";
export const INIT_EXCEPTION_RECORDS_EXPORT = "INIT_EXCEPTION_RECORDS_EXPORT";
export const FETCH_EXCEPTION_RECORDS_EXPORT = "FETCH_EXCEPTION_RECORDS_EXPORT";
export const INIT_EXCEPTION_RECORDS_EXPORT_ALL =
  "INIT_EXCEPTION_RECORDS_EXPORT_ALL";
export const FETCH_EXCEPTION_RECORDS_EXPORT_ALL =
  "FETCH_EXCEPTION_RECORDS_EXPORT_ALL";
export const INIT_RELEASE_UNRELEASE_EXCEPTIONS =
  "INIT_RELEASE_UNRELEASE_EXCEPTIONS";
export const INIT_MASS_RELEASE_WITH_COMMENTS =
  "INIT_MASS_RELEASE_WITH_COMMENTS";
export const INIT_BULK_RELEASE_UDL_LIST = "INIT_BULK_RELEASE_UDL_LIST";
export const FETCH_BULK_RELEASE_UDL_LIST = "FETCH_BULK_RELEASE_UDL_LIST";
export const INITAL_EXCEPTION_DATA = "INITAL_EXCEPTION_DATA";
