import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Checkbox = (props) => {
  return (
    <>
      <input
        className={`checkbox ${props.indeterminate ? `indeterminate` : ``}`}
        id={props.id}
        key={props.key}
        name={props.name}
        type="checkbox"
        checked={!!props.checked}
        onChange={props.handleCheck}
        disabled={props.disabled}
        aria-label={props.ariaLabel || props.label}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.any,
  disabled: PropTypes.any,
  handleCheck: PropTypes.any,
  handleClick: PropTypes.any,
  id: PropTypes.any,
  key: PropTypes.string,
  indeterminate: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
};

export default Checkbox;
