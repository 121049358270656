import { download } from "../helper";
import { ExportPath } from "../config";
const exportToExcel = (data, sheetName, fileName) => {
  const url = ExportPath + fileName;
  download(
    url,
    fileName,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
};

export default exportToExcel;
