import { fromJS } from "immutable";
import {
  FETCHING_PH_FILTER_COL_NAMES,
  PH_FILTER_COL_NAMES_FETCHED,
  PH_FILTER_COL_NAMES_FETCH_COMPLETE,
  PH_FILTER_COL_NAMES_FETCH_FAIL,
  ASSIGN_INITAL_DATA,
  FETCHING_PH_LIST_HEADER_NAMES,
  PH_LIST_HEADER_NAMES_FETCHED,
  PH_LIST_HEADER_NAMES_FETCH_COMPLETE,
  PH_LIST_HEADER_NAMES_FETCH_FAIL,
  FETCHING_PH_ROLE_BASED_ACTIONS,
  PH_ROLE_BASED_ACTIONS_FETCHED,
  PH_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
  PH_ROLE_BASED_ACTIONS_FETCH_FAIL,
  FETCHING_PH_COUNT,
  FETCH_PH_COUNT,
  PH_COUNT_FETCHING_COMPLETE,
  FETCHING_PH_LIST,
  PH_LIST_FETCHED,
  PH_LIST_FETCH_COMPLETE,
  PH_LIST_FETCH_FAIL,
} from "./actionTypes";

export const initialState = fromJS({
  columnList: [],
  fetchingColumns: false,
  phHeaderList: [],
  fetchingPhListHeaders: false,
  phRoleActions: [],
  fetchingPhRoleBasedActions: false,
  fetchingPhCount: false,
  phCount: 0,
  phList: [],
  fetchingPhList: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_PH_FILTER_COL_NAMES: {
      return state.set("fetchingColumns", true).set("columnList", []);
    }
    case PH_FILTER_COL_NAMES_FETCHED: {
      return state.set("columnList", action.data);
    }
    case PH_FILTER_COL_NAMES_FETCH_COMPLETE:
    case PH_FILTER_COL_NAMES_FETCH_FAIL: {
      return state.set("fetchingColumns", false);
    }
    case FETCHING_PH_LIST_HEADER_NAMES: {
      return state.set("fetchingPhListHeaders", true).set("phHeaderList", []);
    }
    case PH_LIST_HEADER_NAMES_FETCHED: {
      return state.set("phHeaderList", action.data);
    }
    case PH_LIST_HEADER_NAMES_FETCH_COMPLETE:
    case PH_LIST_HEADER_NAMES_FETCH_FAIL: {
      return state.set("fetchingPhListHeaders", false);
    }

    case FETCHING_PH_ROLE_BASED_ACTIONS: {
      return state
        .set("fetchingPhRoleBasedActions", true)
        .set("phRoleActions", []);
    }
    case PH_ROLE_BASED_ACTIONS_FETCHED: {
      return state.set("phRoleActions", action.data);
    }
    case PH_ROLE_BASED_ACTIONS_FETCH_COMPLETE:
    case PH_ROLE_BASED_ACTIONS_FETCH_FAIL: {
      return state.set("fetchingPhRoleBasedActions", false);
    }
    case FETCHING_PH_COUNT: {
      return state.set("fetchingPhCount", true);
    }
    case FETCH_PH_COUNT: {
      return state.set("phCount", action.data);
    }

    case PH_COUNT_FETCHING_COMPLETE: {
      return state.set("fetchingPhCount", false);
    }

    case FETCHING_PH_LIST: {
      return state.set("fetchingPhList", true).set("phList", []);
    }
    case PH_LIST_FETCHED: {
      return state.set("phList", action.data);
    }
    case PH_LIST_FETCH_COMPLETE:
    case PH_LIST_FETCH_FAIL: {
      return state.set("fetchingPhList", false);
    }
    case ASSIGN_INITAL_DATA: {
      return state

        .set("fetchingColumns", false)
        .set("columnList", [])
        .set("fetchingPhListHeaders", false)
        .set("phHeaderList", [])
        .set("fetchingPhRoleBasedActions", false)
        .set("phRoleActions", [])
        .set("fetchingPhCount", false)
        .set("phCount", 0)
        .set("phList", [])
        .set("fetchingPhList", false);
    }
    default:
      return state;
  }
};

export default reducer;
