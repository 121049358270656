import { fromJS } from "immutable";
import {
  INIT_FRAUD_AND_ERROR_DATA,
  FETCH_FRAUD_AND_ERROR_DATA,
  FETCH_FRAUD_AND_ERROR_EXPORT_FIELDS,
  FETCH_FRAUD_AND_ERROR_FILTER_NAME,
  FETCH_FRAUD_AND_ERROR_HEADER_FIELDS,
  FETCH_FRAUD_AND_ERROR_PAGES_COUNT,
  FETCH_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS,
  FETCH_FRAUD_AND_ERROR_FILTER_FIELDS,
  FETCH_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS,
  INIT_FRAUD_AND_ERROR_PAGE_EXIT,
  INIT_FRAUD_AND_ERROR_RUN_FILTER,
} from "./actionTypes";
export const initialState = fromJS({
  tableRowData: {},
  exportFields: {},
  filterNames: {},
  headerFields: {},
  pagesCount: 0,
  roleBasedActions: {},
  filterFields: {},
  dropDownFields: [],
  pageLoader: false,
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_FRAUD_AND_ERROR_DATA:
      return state.set("pageLoader", true);
    case INIT_FRAUD_AND_ERROR_RUN_FILTER:
      return state.set("pageLoader", true);
    case FETCH_FRAUD_AND_ERROR_DATA:
      return state.set("tableRowData", action.data).set("pageLoader", false);
    case FETCH_FRAUD_AND_ERROR_EXPORT_FIELDS:
      return state.set("exportFields", action.data);
    case FETCH_FRAUD_AND_ERROR_FILTER_NAME:
      return state.set("filterNames", action.data);
    case FETCH_FRAUD_AND_ERROR_HEADER_FIELDS:
      return state.set("headerFields", action.data);
    case FETCH_FRAUD_AND_ERROR_PAGES_COUNT:
      return state.set("pagesCount", action.data);
    case FETCH_FRAUD_AND_ERROR_ROLE_BASED_ACTIONS:
      return state.set("roleBasedActions", action.data);
    case FETCH_FRAUD_AND_ERROR_FILTER_FIELDS:
      return state.set("filterFields", action.data);
    case FETCH_FRAUD_AND_ERROR_DETAILS_DROPDOWN_FIELDS:
      return state.set("dropDownFields", action.data);
    case INIT_FRAUD_AND_ERROR_PAGE_EXIT:
      return state
        .set("tableRowData", {})
        .set("exportFields", {})
        .set("filterNames", {})
        .set("headerFields", {})
        .set("pagesCount", 0)
        .set("roleBasedActions", {})
        .set("filterFields", {})
        .set("dropDownFields", [])
        .set("pageLoader", 0);
    default:
      return state;
  }
};
export default reducer;
