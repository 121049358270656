import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import ArrowDown from "../SVG/ArrowDown";

const DropdownAction = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={`dropdown dropdown-action  ${
        props.disabled ? `dropdown-action-disabled` : ``
      }`}
    >
      <button className="action-button">
        {props.title} <ArrowDown />
      </button>

      <ul className="dropdown-content dropdown-modal">
        {props.options && props.options.length > 0 ? (
          props.options.map((item) => (
            <li
              className={`dropdown-item option-select  ${
                item.invisible ? `invisible` : ``
              }`}
              key={item.value}
            >
              <button
                id={item.value}
                onClick={item.handleOnClick}
                disabled={item.disableOptions}
              >
                {item.label}
              </button>
            </li>
          ))
        ) : (
          <span> {t("atcat.noOptions")} </span>
        )}
      </ul>
    </div>
  );
};

export default DropdownAction;
