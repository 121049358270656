import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "../..";

const Confirm = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        title={t("atcat.confirm")}
        centered
        show={props.showConfirmation}
        preventOutterClickClose
        maxWidth="30em"
        onClose={props.handleCancel}
      >
        <div className="modal-content modal-content-gap">
          {props.message
            ? props.message
            : t("atcat.areYouSureYouWantToPerformThisAction")}
        </div>
        <div className="modal-button">
          <Button
            btnType="primary"
            label={t("atcat.no")}
            handleClick={(e)=>props.handleCancel(e)}
          />
          <Button
            label={t("atcat.yes")}
            handleClick={(e)=>props.handleConfirmation(e)}
          />
        </div>
      </Modal>
    </>
  );
};

Confirm.propTypes = {
  message: PropTypes.any,
};

export default Confirm;
