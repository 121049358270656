import {
  INIT_IPQC_BULKRELEASE_EXCEL_VALIDATION,
  FETCHING_IPQC_BULKRELEASE_EXCEL_VALIDATION,
  IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCHED,
  IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_COMPLETE,
  IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_FAIL,
  INIT_IPQC_BULKRELEASE_UPLOAD,
  FETCHING_IPQC_BULKRELEASE_UPLOAD,
  IPQC_BULKRELEASE_UPLOAD_FETCHED,
  IPQC_BULKRELEASE_UPLOAD_FETCH_COMPLETE,
  IPQC_BULKRELEASE_UPLOAD_FETCH_FAIL,
  INIT_IPQC_BULKRELEASE_UPLOAD_FILE,
  FETCHING_IPQC_BULKRELEASE_UPLOAD_FILE,
  IPQC_BULKRELEASE_UPLOAD_FILE_FETCHED,
  IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_COMPLETE,
  IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_FAIL,
  INIT_IPQC_BULKRELEASE_RELEASE_ALL,
  FETCHING_IPQC_BULKRELEASE_RELEASE_ALL,
  IPQC_BULKRELEASE_RELEASE_ALL_FETCHED,
  IPQC_BULKRELEASE_RELEASE_ALL_FETCH_COMPLETE,
  IPQC_BULKRELEASE_RELEASE_ALL_FETCH_FAIL,
} from "./actionTypes";

export const initIpqcBulkReleaseExcelValidation = (payload, callback) => {
  return {
    type: INIT_IPQC_BULKRELEASE_EXCEL_VALIDATION,
    payload,
    callback,
  };
};
export const fetchingIpqcBulkReleaseExcelValidation = () => {
  return {
    type: FETCHING_IPQC_BULKRELEASE_EXCEL_VALIDATION,
  };
};
export const fetchIpqcBulkReleaseExcelValidation = (data) => {
  return {
    type:  IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCHED,
    data,
  };
};
export const ipqcBulkReleaseExcelValidationComplete = () => {
  return {
    type: IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_COMPLETE,
  };
};
export const ipqcBulkReleaseExcelValidationFail = () => {
  return {
    type: IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_FAIL,
  };
};

export const initIpqcBulkReleaseUpload = (payload, callback) => {
  return {
    type: INIT_IPQC_BULKRELEASE_UPLOAD,
    payload,
    callback,
  };
};
export const fetchingIpqcBulkReleaseUpload = () => {
  return {
    type: FETCHING_IPQC_BULKRELEASE_UPLOAD,
  };
};
export const fetchIpqcBulkReleaseUpload = (data) => {
  return {
    type: IPQC_BULKRELEASE_UPLOAD_FETCHED,
    data,
  };
};
export const ipqcBulkReleaseUploadComplete = () => {
  return {
    type: IPQC_BULKRELEASE_UPLOAD_FETCH_COMPLETE,
  };
};
export const ipqcBulkReleaseUploadFail = () => {
  return {
    type: IPQC_BULKRELEASE_UPLOAD_FETCH_FAIL,
  };
};

export const initIpqcBulkReleaseUploadFile = (payload, callback) => {
  return {
    type: INIT_IPQC_BULKRELEASE_UPLOAD_FILE,
    payload,
    callback,
  };
};
export const fetchingIpqcBulkReleaseUploadFile = () => {
  return {
    type: FETCHING_IPQC_BULKRELEASE_UPLOAD_FILE,
  };
};
export const fetchIpqcBulkReleaseUploadFile = (data) => {
  return {
    type: IPQC_BULKRELEASE_UPLOAD_FILE_FETCHED,
    data,
  };
};
export const ipqcBulkReleaseUploadFileComplete = () => {
  return {
    type: IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_COMPLETE,
  };
};
export const ipqcBulkReleaseUploadFileFail = () => {
  return {
    type: IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_FAIL,
  };
};

export const initIpqcBulkReleaseReleaseAll = (payload, callback) => {
  return {
    type: INIT_IPQC_BULKRELEASE_RELEASE_ALL,
    payload,
    callback,
  };
};
export const fetchingIpqcBulkReleaseReleaseAll = () => {
  return {
    type: FETCHING_IPQC_BULKRELEASE_RELEASE_ALL,
  };
};
export const fetchIpqcBulkReleaseReleaseAll = (data) => {
  return {
    type: IPQC_BULKRELEASE_RELEASE_ALL_FETCHED,
    data,
  };
};
export const ipqcBulkReleaseReleaseAllComplete = () => {
  return {
    type: IPQC_BULKRELEASE_RELEASE_ALL_FETCH_COMPLETE,
  };
};
export const ipqcBulkReleaseReleaseAllFail = () => {
  return {
    type: IPQC_BULKRELEASE_RELEASE_ALL_FETCH_FAIL,
  };
};
