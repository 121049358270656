import axios from "./axios";
import { store } from "react-notifications-component";
import { buildNotification } from "./notification";
import { storage } from "./storage";
import { Error_Msg } from "../config/index";

const instance = axios;

const apiCall = (url, config) => {
    const onSuccess = (response) => {
        return response;
    };

    const onError = (error) => {
        if (error.response) {
            const { status } = error.response || {};
            if (status === 401) {
                storage.removeItem("sessionDetails");
                storage.removeItem("languageId");
                storage.removeItem("i18nextLng");
                window.open(window.location.href);
            }
            else if (status === 500) {

            }
            else if (status === 400) {
                let errorMessage = error.response.data;
                const notification = buildNotification({
                    message: errorMessage,
                    type: "danger",
                });
                store.addNotification({
                    ...notification,
                });
            }
            else {
                const notification = buildNotification({
                    message: Error_Msg,
                    type: "danger",
                });
                store.addNotification({
                    ...notification,
                });
            }
        }
        else {
            const notification = buildNotification({
                message: Error_Msg,
                type: "danger",
            });
            store.addNotification({
                ...notification,
            });
        }
        return Promise.reject(error.response || error.message);
    };

    return instance({
        url,
        ...config,
    })
        .then(onSuccess)
        .catch(onError);
};

export default apiCall;
