import * as msal from "@azure/msal-browser"
import { RedirectURL, ClientID, Authority } from "./index";

export const config = {
    auth: {
        clientId: ClientID,
        authority: Authority,
        redirectUri: RedirectURL,
        navigateToLoginRequestUrl: true,
        postLogoutRedirectUri: RedirectURL
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
        secureCookies: false
    }
};
export const loginRequest = {
    scopes: ['user.read']
};
export const msalInstance = new msal.PublicClientApplication(config);

export const MsalConfig = (props) => {
    const { Client_ID, RequestUrl } = props || {};
    return {
        auth: {
            clientId: Client_ID,
            authority: RequestUrl,
            redirectUri: RedirectURL,
            navigateToLoginRequestUrl: true,
            postLogoutRedirectUri: RedirectURL
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true,
            secureCookies: false
        }
    };
}

export const MsalLoginRequest = (props) => {
    const { Scope } = props;
    const scopes = Scope?.split(',');
    return {
        scopes: scopes
    };
}