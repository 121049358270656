import {
    INIT_VENDOR_FILTER_NAMES,
    FETCH_VENDOR_FILTER_NAMES,
    VENDOR_FILTER_NAMES_FETCH_COMPLETE,
    VENDOR_FILTER_NAMES_FETCH_FAIL,
    INIT_VENDOR_LIST_HEADER_NAMES,
    FETCH_VENDOR_LIST_HEADER_NAMES,
    VENDOR_LIST_HEADER_NAMES_FETCH_COMPLETE,
    VENDOR_LIST_HEADER_NAMES_FETCH_FAIL,
    INIT_VENDOR_EXPORT_FIELD_LIST,
    FETCH_VENDOR_EXPORT_FIELD_LIST,
    VENDOR_EXPORT_FIELD_LIST_FETCH_COMPLETE,
    VENDOR_EXPORT_FIELD_LIST_FETCH_FAIL,
    INIT_VENDOR_ROLE_BASED_ACTIONS,
    FETCH_VENDOR_ROLE_BASED_ACTIONS,
    VENDOR_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
    VENDOR_ROLE_BASED_ACTIONS_FETCH_FAIL,
    INIT_VENDOR_LIST,
    FETCH_VENDOR_LIST,
    VENDOR_LIST_FETCH_COMPLETE,
    VENDOR_LIST_FETCH_FAIL,
    INIT_DELETE_VENDOR_FILTER,
    INIT_VENDOR_FILTER_COLUMNS,
    FETCH_VENDOR_FILTER_COLUMNS,
    INIT_VENDOR_FILTER_DETAILS,
    INIT_SAVE_VENDOR_FILTER,
    INIT_VENDOR_RECORDS_EXPORT,
    INIT_VENDOR_RECORDS_EXPORT_ALL,
    FETCH_VENDOR_RECORDS_EXPORT,
    FETCH_VENDOR_RECORDS_EXPORT_ALL,
    INIT_VENDOR_RECORDS_MARK_UNMARK_AS_PREFERRED,
    FETCH_VENDOR_RECORD_DETAILS,
    INIT_COUNTRY_LIST,
    FETCH_COUNTRY_LIST,
    SAVE_VENDOR_DETAILS,
    SAVE_VENDOR_CONTACT_DETAILS,
    INIT_VENDOR_CONTACT_DETAILS,
    UPDATE_VENDOR_CONTACT_DETAILS,
    DELETE_VENDOR_CONTACTS,
} from "./actionTypes";

export const initVendorFilterNames = (payload, callback) => {
    return {
        type: INIT_VENDOR_FILTER_NAMES,
        payload,
        callback,
    };
};
export const fetchVendorColNames = (data) => {
    return {
        type: FETCH_VENDOR_FILTER_NAMES,
        data,
    };
};
export const vendorColNameFetchComplete = () => {
    return {
        type: VENDOR_FILTER_NAMES_FETCH_COMPLETE,
    };
};
export const vendorColNameFetchFailed = () => {
    return {
        type: VENDOR_FILTER_NAMES_FETCH_FAIL,
    };
};

export const initVendorListHeaderNames = (payload) => {
    return {
        type: INIT_VENDOR_LIST_HEADER_NAMES,
        payload,
    };
};
export const fetchVendorListHeaderNames = (data) => {
    return {
        type: FETCH_VENDOR_LIST_HEADER_NAMES,
        data,
    };
};
export const vendorListHeaderNamesFetchComplete = () => {
    return {
        type: VENDOR_LIST_HEADER_NAMES_FETCH_COMPLETE,
    };
};
export const vendorListHeaderNamesFetchFailed = () => {
    return {
        type: VENDOR_LIST_HEADER_NAMES_FETCH_FAIL,
    };
};

export const initVendorExportFieldList = (payload) => {
    return {
        type: INIT_VENDOR_EXPORT_FIELD_LIST,
        payload,
    };
};
export const fetchVendorExportFieldList = (data) => {
    return {
        type: FETCH_VENDOR_EXPORT_FIELD_LIST,
        data,
    };
};
export const vendorExportFieldListFetchComplete = () => {
    return {
        type: VENDOR_EXPORT_FIELD_LIST_FETCH_COMPLETE,
    };
};
export const vendorExportFieldListFetchFailed = () => {
    return {
        type: VENDOR_EXPORT_FIELD_LIST_FETCH_FAIL,
    };
};

export const initVendorRoleBasedActions = (payload) => {
    return {
        type: INIT_VENDOR_ROLE_BASED_ACTIONS,
        payload,
    };
};
export const fetchVendorRoleBasedActions = (data) => {
    return {
        type: FETCH_VENDOR_ROLE_BASED_ACTIONS,
        data,
    };
};
export const vendorRoleBasedActionsFetchComplete = () => {
    return {
        type: VENDOR_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
    };
};
export const vendorRoleBasedActionsFetchFailed = () => {
    return {
        type: VENDOR_ROLE_BASED_ACTIONS_FETCH_FAIL,
    };
};

export const initVendorList = (payload) => {
    return {
        type: INIT_VENDOR_LIST,
        payload,
    };
};
export const fetchVendorList = (data) => {
    return {
        type: FETCH_VENDOR_LIST,
        data,
    };
};
export const vendorListFetchComplete = () => {
    return {
        type: VENDOR_LIST_FETCH_COMPLETE,
    };
};
export const vendorListFetchFailed = () => {
    return {
        type: VENDOR_LIST_FETCH_FAIL,
    };
};
export const initDeleteVendorFilter = (payload, callback) => {
    return {
        type: INIT_DELETE_VENDOR_FILTER,
        payload,
        callback
    };
};
export const initVendorFilterColumns = (payload) => {
    return {
        type: INIT_VENDOR_FILTER_COLUMNS,
        payload,
    };
};
export const fetchVendorFilterColumns = (data) => {
    return {
        type: FETCH_VENDOR_FILTER_COLUMNS,
        data,
    };
};
export const initVendorFilterDetails = (payload, callback) => {
    return {
        type: INIT_VENDOR_FILTER_DETAILS,
        payload,
        callback
    };
};
export const initSaveVendorFilter = (payload, callback) => {
    return {
        type: INIT_SAVE_VENDOR_FILTER,
        payload,
        callback,
    };
};

export const initVendorRecordsExport = (payload) => {
    return {
        type: INIT_VENDOR_RECORDS_EXPORT,
        payload,
    };
};

export const fetchVendorRecordsExport = (data) => {
    return {
        type: FETCH_VENDOR_RECORDS_EXPORT,
        data,
    };
};

export const initVendorRecordsExportAll = (payload) => {
    return {
        type: INIT_VENDOR_RECORDS_EXPORT_ALL,
        payload,
    };
};

export const fetchVendorRecordsExportAll = (data) => {
    return {
        type: FETCH_VENDOR_RECORDS_EXPORT_ALL,
        data,
    };
};

export const initVendorRecordMarkUnMarkAsPreferred = (payload, callback) => {
    return {
        type: INIT_VENDOR_RECORDS_MARK_UNMARK_AS_PREFERRED,
        payload,
        callback
    };
};

export const fetchVendorRecordDetails = (payload, callback) => {
    return {
        type: FETCH_VENDOR_RECORD_DETAILS,
        payload,
        callback,
    };
};

export const initCountryList = (payload) => {
    return {
        type: INIT_COUNTRY_LIST,
        payload,
    };
};

export const fetchCountryList = (data) => {
    return {
        type: FETCH_COUNTRY_LIST,
        data,
    };
};

export const initSaveVendorDetails = (payload, callback) => {
    return {
        type: SAVE_VENDOR_DETAILS,
        payload,
        callback,
    };
};

export const initSaveVendorContactDetails = (payload, callback) => {
    return {
        type: SAVE_VENDOR_CONTACT_DETAILS,
        payload,
        callback,
    };
};

export const initVendorContactDetails = (payload, callback) => {
    return {
        type: INIT_VENDOR_CONTACT_DETAILS,
        payload,
        callback
    };
};

export const initUpdateVendorContactDetails = (payload, callback) => {
    return {
        type: UPDATE_VENDOR_CONTACT_DETAILS,
        payload,
        callback,
    };
}; 

export const initDeleteVendorContact = (payload, callback) => {
    return {
        type: DELETE_VENDOR_CONTACTS,
        payload,
        callback,
    };
};



