import { fromJS } from "immutable";
import {
  FETCHING_IPQC_BULKRELEASE_EXCEL_VALIDATION,
  IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCHED,
  IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_COMPLETE,
  IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_FAIL,
  FETCHING_IPQC_BULKRELEASE_UPLOAD,
  IPQC_BULKRELEASE_UPLOAD_FETCHED,
  IPQC_BULKRELEASE_UPLOAD_FETCH_COMPLETE,
  IPQC_BULKRELEASE_UPLOAD_FETCH_FAIL,
  FETCHING_IPQC_BULKRELEASE_UPLOAD_FILE,
  IPQC_BULKRELEASE_UPLOAD_FILE_FETCHED,
  IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_COMPLETE,
  IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_FAIL,
  FETCHING_IPQC_BULKRELEASE_RELEASE_ALL,
  IPQC_BULKRELEASE_RELEASE_ALL_FETCHED,
  IPQC_BULKRELEASE_RELEASE_ALL_FETCH_COMPLETE,
  IPQC_BULKRELEASE_RELEASE_ALL_FETCH_FAIL,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingIpqcExcelValidation: false,
  fetchingIpqcUpload: false,
  fetchingIpqcUploadFile: false,
  fetchingIpqcBulkReleaseAll: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_IPQC_BULKRELEASE_EXCEL_VALIDATION: {
      return state.set("fetchingIpqcExcelValidation", true);
    }
    case IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCHED: {
      return state.set("fetchedIpqcExcelValidation", action.data);
    }
    case IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_COMPLETE:
    case IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_FAIL: {
      return state.set("fetchingIpqcExcelValidation", false);
    }

    case FETCHING_IPQC_BULKRELEASE_UPLOAD: {
      return state.set("fetchingIpqcUpload", true);
    }
    case IPQC_BULKRELEASE_UPLOAD_FETCHED: {
      return state.set("fetchedIpqcUpload", action.data);
    }
    case IPQC_BULKRELEASE_UPLOAD_FETCH_COMPLETE:
    case IPQC_BULKRELEASE_UPLOAD_FETCH_FAIL: {
      return state.set("fetchingIpqcUpload", false);
    }

    case FETCHING_IPQC_BULKRELEASE_UPLOAD_FILE: {
      return state.set("fetchingIpqcUploadFile", true);
    }
    case IPQC_BULKRELEASE_UPLOAD_FILE_FETCHED: {
      return state.set("fetchedIpqcUploadFile", action.data);
    }
    case IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_COMPLETE:
    case IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_FAIL: {
      return state.set("fetchingIpqcUploadFile", false);
    }

    case FETCHING_IPQC_BULKRELEASE_RELEASE_ALL: {
      return state.set("fetchingIpqcBulkReleaseAll", true);
    }
    case IPQC_BULKRELEASE_RELEASE_ALL_FETCHED: {
      return state.set("fetchedIpqcBulkReleaseAll", action.data);
    }
    case IPQC_BULKRELEASE_RELEASE_ALL_FETCH_COMPLETE:
    case IPQC_BULKRELEASE_RELEASE_ALL_FETCH_FAIL: {
      return state.set("fetchingIpqcBulkReleaseAll", false);
    }

    default:
      return state;
  }
};

export default reducer;
