import {
  INIT_CLIENT_SYSTEM_PARAMETER,
  FETCH_CLIENT_SYSTEM_PARAMETER,
  FETCH_CLIENT_SYSTEM_PARAMETER_DATA,
  INIT_SAVE_CLIENT_SYSTEM_PARAMETER,
} from "./actionTypes";

export const initClientSystemParameter = (payload, callback) => {
  return {
    type: INIT_CLIENT_SYSTEM_PARAMETER,
    payload,
    callback,
  };
};
export const fetchClientSystemParameter = (data) => {
  return {
    type: FETCH_CLIENT_SYSTEM_PARAMETER,
    data,
  };
};
export const fetchClientSystemParameterData = (data) => {
  return {
    type: FETCH_CLIENT_SYSTEM_PARAMETER_DATA,
    data,
  };
};
export const initSaveClientSystemParameter =(payload,callback)=>{
  return{
    type: INIT_SAVE_CLIENT_SYSTEM_PARAMETER,
    payload,
    callback,
  }
}
