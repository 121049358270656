export const INIT_MANUAL_CLAIM_FILTER_FIELDS =
  "INIT_MANUAL_CLAIM_FILTER_FIELDS";
export const INIT_MANUAL_CLAIM_LIST = "INIT_MANUAL_CLAIM_LIST";
export const FETCH_MANUAL_CLAIM_LIST = "FETCH_MANUAL_CLAIM_LIST";
export const INIT_MANUAL_CLAIM_RECORDS_EXPORT =
  "INIT_MANUAL_CLAIM_RECORDS_EXPORT";
export const INIT_MANUAL_CLAIM_RECORDS_EXPORT_ALL =
  "INIT_MANUAL_CLAIM_RECORDS_EXPORT_ALL";
export const INIT_MANUAL_CLAIM_FIELDS = "INIT_MANUAL_CLAIM_FIELDS";
export const FETCH_MANUAL_CLAIM_FIELDS = "FETCH_MANUAL_CLAIM_FIELDS";
export const INIT_ADD_MANUAL_CLAIM = "INIT_ADD_MANUAL_CLAIM";
export const INIT_SAVE_AND_COPY_MANUAL_CLAIM = "INIT_SAVE_AND_COPY_MANUAL_CLAIM";
export const INIT_MANUAL_CLAIM_DETAILS = "INIT_MANUAL_CLAIM_DETAILS";
export const FETCH_MANUAL_CLAIM_DETAILS = "FETCH_MANUAL_CLAIM_DETAILS";
export const INIT_MANUAL_CLAIM_RECORD_DETAILS =
  "INIT_MANUAL_CLAIM_RECORD_DETAILS";
export const FETCH_MANUAL_CLAIM_RECORD_DETAILS =
  "FETCH_MANUAL_CLAIM_RECORD_DETAILS";
export const INIT_GET_MANUAL_OFFSET_RECORDS = "INIT_GET_MANUAL_OFFSET_RECORDS";
export const FETCH_MANUAL_OFFSET_RECORDS = "FETCH_MANUAL_OFFSET_RECORDS";
export const INIT_MANUAL_DELETE_OFFSET = "INIT_MANUAL_DELETE_OFFSET";
export const INIT_ADD_MANUAL_OFFSET = "INIT_ADD_MANUAL_OFFSET";
export const INIT_GET_MANUAL_OFFSET_DETAILS = "INIT_GET_MANUAL_OFFSET_DETAILS";
export const FETCH_MANUAL_OFFSET_DETAILS = "FETCH_MANUAL_OFFSET_DETAILS";
export const INIT_UPDATE_MANUAL_OFFSET = "INIT_UPDATE_MANUAL_OFFSET";
export const INIT_SAVE_MANUAL_CLAIM_DETAILS = "INIT_SAVE_MANUAL_CLAIM_DETAILS";

export const INIT_GETAPPH_FILTER_NAME = "INIT_GETAPPH_FILTER_NAME";
export const FETCHING_APPH_FILTER_NAME = "FETCHING_APPH_FILTER_NAME";
export const APPH_FILTER_NAME_FETCHED = "APPH_FILTER_NAME_FETCHED";
export const APPH_FILTER_NAME_FETCH_COMPLETE =
  "APPH_FILTER_NAME_FETCH_COMPLETE";
export const APPH_FILTER_NAME_FETCH_FAIL = "APPH_FILTER_NAME_FETCH_FAIL";

export const INIT_DELETE_APPH_FILTER = "INIT_DELETE_APPH_FILTER";

export const INIT_GETAPPH_FILTER_FIELDS = "INIT_GETAPPH_FILTER_FIELDS";
export const FETCHING_APPH_FILTER_FIELDS = "FETCHING_APPH_FILTER_FIELDS";
export const APPH_FILTER_FIELDS_FETCHED = "APPH_FILTER_FIELDS_FETCHED";
export const APPH_FILTER_FIELDS_FETCH_COMPLETE =
  "APPH_FILTER_FIELDS_FETCH_COMPLETE";
export const APPH_FILTER_FIELDS_FETCH_FAIL = "APPH_FILTER_FIELDS_FETCH_FAIL";

export const INIT_EDIT_APPH_FILTER = "INIT_EDIT_APPH_FILTER";
export const FETCHING_EDIT_APPH_FILTER = "FETCHING_EDIT_APPH_FILTER";
export const EDIT_APPH_FILTER_FETCHED = "EDIT_APPH_FILTER_FETCHED";
export const EDIT_APPH_FILTER_FETCH_COMPLETE =
  "EDIT_APPH_FILTER_FETCH_COMPLETE";
export const EDIT_APPH_FILTER_FETCH_FAIL = "EDIT_APPH_FILTER_FETCH_FAIL";

export const INIT_SAVE_APPH_FILTER = "INIT_SAVE_APPH_FILTER";
export const FETCHING_SAVE_APPH_FILTER = "FETCHING_SAVE_APPH_FILTER";
export const SAVE_APPH_FILTER_FETCHED = "SAVE_APPH_FILTER_FETCHED";
export const SAVE_APPH_FILTER_FETCH_COMPLETE =
  "SAVE_APPH_FILTER_FETCH_COMPLETE";
export const SAVE_APPH_FILTER_FETCH_FAIL = "SAVE_APPH_FILTER_FETCH_FAIL";

export const INIT_APPH_LIST_COUNT = "INIT_APPH_LIST_COUNT";
export const FETCHING_APPH_LIST_COUNT = "FETCHING_APPH_LIST_COUNT";
export const APPH_LIST_COUNT_FETCHED = "APPH_LIST_COUNT_FETCHED";
export const APPH_LIST_COUNT_FETCH_COMPLETE = "APPH_LIST_COUNT_FETCH_COMPLETE";
export const APPH_LIST_COUNT_FETCH_FAIL = "APPH_LIST_COUNT_FETCH_FAIL";

export const INIT_APPH_LIST = "INIT_APPH_LIST";
export const FETCHING_APPH_LIST = "FETCHING_APPH_LIST";
export const APPH_LIST_FETCHED = "APPH_LIST_FETCHED";
export const APPH_LIST_FETCH_COMPLETE = "APPH_LIST_FETCH_COMPLETE";
export const APPH_LIST_FETCH_FAIL = "APPH_LIST_FETCH_FAIL";
export const INIT_CALCULATE_CLAIM_VALUES = "INIT_CALCULATE_CLAIM_VALUES";
export const INIT_SEARCH_MANUAL_CLAIM_DETAILS =
  "INIT_SEARCH_MANUAL_CLAIM_DETAILS";
export const FETCH_SEARCH_MANUAL_CLAIM_DETAILS =
  "FETCH_SEARCH_MANUAL_CLAIM_DETAILS";
export const INIT_MANUAL_CLAIM_PAYBACK_RECORDS =
  "INIT_MANUAL_CLAIM_PAYBACK_RECORDS";
export const FETCH_MANUAL_CLAIM_PAYBACK_RECORDS =
  "FETCH_MANUAL_CLAIM_PAYBACK_RECORDS";
export const INIT_MANUAL_CLAIM_PAYBACK_DETAILS =
  "INIT_MANUAL_CLAIM_PAYBACK_DETAILS";
export const FETCH_MANUAL_CLAIM_PAYBACK_DETAILS =
  "FETCH_MANUAL_CLAIM_PAYBACK_DETAILS";
export const INIT_MANUAL_CLAIM_ADD_PAYBACK = "INIT_MANUAL_CLAIM_ADD_PAYBACK";

export const INIT_MANUAL_CLAIM_APPH_ASSIGN = "INIT_MANUAL_CLAIM_APPH_ASSIGN";
export const FETCH_MANUAL_CLAIM_APPH_ASSIGN = "FETCH_MANUAL_CLAIM_APPH_ASSIGN";

export const INIT_MANUAL_CLAIM_APPH_ASSIGNCALCULATE =
  "INIT_MANUAL_CLAIM_APPH_ASSIGNCALCULATE";
export const FETCH_MANUAL_CLAIM_APPH_ASSIGNCALCULATE =
  "FETCH_MANUAL_CLAIM_APPH_ASSIGNCALCULATE";
export const INIT_ACTIONS_MANUAL_CLAIM_RECORDS =
  "INIT_ACTIONS_MANUAL_CLAIM_RECORDS";
export const FETCH_ACTIONS_MANUAL_CLAIM_RECORDS =
  "FETCH_ACTIONS_MANUAL_CLAIM_RECORDS";
export const INIT_RECORD_MODIFIED_CLAIM_CREATION_HEADERS =
  "INIT_RECORD_MODIFIED_CLAIM_CREATION_HEADERS";
export const FETCH_RECORD_MODIFIED_SINCE_CLAIM_CREATION_HEADERS =
  "FETCH_RECORD_MODIFIED_SINCE_CLAIM_CREATION_HEADERS";
export const INIT_RECORD_MODIFIED_CLAIM_RECORD =
  "INIT_RECORD_MODIFIED_CLAIM_RECORD";
