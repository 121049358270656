import PropTypes from "prop-types";
import React from "react";
import Select from "../Select";
import "../../MultiSelect/style.scss"
import { customStyles } from "./style";

const LabeledSelect = (props) => {
  const labelclass = `label ${props.required ? `required` : ``}`;
  return (
    <div
      className={`form-dropdown ${props.disabled ? `disabled` : ``} ${
        props.showError ? `error` : ``
      } ${props.display ? `multi-select display` : ``}`}
    >
      <label className={labelclass} htmlFor={props.id}>
        {props.label}
      </label>
      <Select styles={customStyles} {...props} />
    </div>
  );
};

LabeledSelect.propTypes = {
  disabled: PropTypes.any,
  id: PropTypes.any,
  label: PropTypes.any,
  required: PropTypes.any,
  showError: PropTypes.any,
};

export default LabeledSelect;
