import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../../config/axiosCall";
import { postRequestDetails } from "../../../../config/requestHeaders";
import { INIT_SAVE_CLIENT } from "./actionTypes";
import { AddNewClient } from "../../../../config/APINames";
import { clientSaveFailed, clientSaveSuccess } from "./actions";
export default function* watchLogin() {
  yield all([takeLatest(INIT_SAVE_CLIENT, initSaveClientSaga)]);
}

function* initSaveClientSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, "TempAPI", requestDetails);
    if (response && response.status === 200) {
      yield put(clientSaveSuccess());
    }
  } catch (error) {
    yield put(clientSaveFailed());
  }
}
