import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import LabeledInput from "../Inputs/LabeledInput";
import Checkbox from "../Checkboxes/Checkbox";
import Arrow from "../SVG/Arrow";
const MultiSelect = (props) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [visited, setVisited] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [search, setSearch] = useState("");

  const options =
    props && props.options && props.options.length > 0 ? props.options : [];

  const updatedOptions = options
    .map((item) =>
      selectedIds && selectedIds.includes(item.value)
        ? {
            ...item,
            selected: true,
          }
        : {
            ...item,
            selected: false,
          }
    )
    .sort((a, b) => Number(b.selected) - Number(a.selected));

  const idxOfLastOption = updatedOptions
    .map((elem) => elem.selected)
    .lastIndexOf(true);

  useEffect(() => {
    setSelectedOptions(
      props.value && props.value.length > 0 ? props.value : []
    );
    setSelectedIds(
      props.value && props.value.length > 0
        ? props.value.map((item) => item.value)
        : []
    );
  }, [props]);

  const handleOnChange = (item) => {
    let cpySelectedIds = [...selectedIds];
    let cpySelectedOptions = [...selectedOptions];
    const findIndex = cpySelectedOptions.findIndex(
      (option) => option.value === item.value
    );
    if (findIndex === -1) {
      cpySelectedOptions.push(item);
      cpySelectedIds.push(item.value);
    } else {
      cpySelectedOptions.splice(findIndex, 1);
      cpySelectedIds.splice(findIndex, 1);
    }
    setSelectedIds(cpySelectedIds);
    setSelectedOptions(cpySelectedOptions);
    setSearch("");
    props.onChangeHandler &&
      props.onChangeHandler({
        target: {
          value: cpySelectedOptions,
          id: props.id,
          name: props.name,
          required: props.required,
          options: options,
        },
      });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setShowDropdown(true);
  };

  const useOutsideHandler = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
          setVisited(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideHandler(wrapperRef);
  return (
    <div className={`multi-select ${props.display ? `display` : ``}`} ref={wrapperRef}>
      <LabeledInput
        aria-label={t("atcat.multiselect")}
        type="text"
        id={props.id || "multiSelect"}
        placeholder={props.placeholder || `--${t("atcat.select")}--`}
        maxLength="100"
        label={props.label}
        required={props.required}
        value={
          props.disabled
            ? props.value && props.value.length
              ? props.value.map((item) => item.label).join(",")
              : ""
            : search
            ? search
            : selectedOptions.length < 1
            ? ""
            : selectedOptions.length === 1
            ? selectedOptions[0].label
            : `${selectedOptions.length} ${t("atcat.selected")}`
        }
        readOnly
        handleClick={() => {
          setShowDropdown(!showDropdown);
          setVisited(!visited);
        }}
        handleChange={handleSearch}
        onIconRightClick={() => {
          setShowDropdown(!showDropdown);
          setVisited(!visited);
        }}
        iconRight={<Arrow ariaHidden={true} />}
        rightIconAriaLabel={t("atcat.openMenu")}
      />
      {showDropdown ? (
        <div className="options-wrapper" style={{zIndex:props.zIndex}}>
          <ul>
            {updatedOptions && updatedOptions.length > 0 ? (
              updatedOptions.map((item, i) => (
                <>
                  <li
                    key={item.label || i}
                    className={`${
                      selectedIds.includes(item.value) ? "checked" : ""
                    }`}
                  >
                    <Checkbox
                      id={item.label || i}
                      label={item.label}
                      checked={selectedIds.includes(item.value)}
                      handleCheck={() => handleOnChange(item)}
                    />
                  </li>
                  <div
                    className="divider"
                    tabIndex={-1}
                    style={{
                      display:
                        i !== updatedOptions.length - 1 &&
                        idxOfLastOption !== -1 &&
                        idxOfLastOption === i
                          ? "flex"
                          : "none",
                    }}
                  />
                </>
              ))
            ) : (
              <span> {t("atcat.noOptions")} </span>
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default MultiSelect;
