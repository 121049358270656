import {
    INIT_MANUAL_CLAIM_FILTER_FIELDS,
    INIT_MANUAL_CLAIM_LIST,
    FETCH_MANUAL_CLAIM_LIST,
    INIT_MANUAL_CLAIM_RECORDS_EXPORT,
    INIT_MANUAL_CLAIM_RECORDS_EXPORT_ALL,
    INIT_MANUAL_CLAIM_FIELDS,
    FETCH_MANUAL_CLAIM_FIELDS,
    INIT_ADD_MANUAL_CLAIM,
    INIT_MANUAL_CLAIM_DETAILS,
    FETCH_MANUAL_CLAIM_DETAILS,
    INIT_MANUAL_CLAIM_RECORD_DETAILS,
    FETCH_MANUAL_CLAIM_RECORD_DETAILS,
    INIT_GET_MANUAL_OFFSET_RECORDS,
    FETCH_MANUAL_OFFSET_RECORDS,
    INIT_MANUAL_DELETE_OFFSET,
    INIT_ADD_MANUAL_OFFSET,
    INIT_GET_MANUAL_OFFSET_DETAILS,
    FETCH_MANUAL_OFFSET_DETAILS,
    INIT_UPDATE_MANUAL_OFFSET,
    INIT_SAVE_MANUAL_CLAIM_DETAILS,
    INIT_GETAPPH_FILTER_NAME,
    INIT_DELETE_APPH_FILTER,
    INIT_GETAPPH_FILTER_FIELDS,
    INIT_SAVE_APPH_FILTER,
    INIT_EDIT_APPH_FILTER,
    FETCHING_APPH_FILTER_NAME,
    APPH_FILTER_NAME_FETCHED,
    APPH_FILTER_NAME_FETCH_COMPLETE,
    APPH_FILTER_NAME_FETCH_FAIL,
    FETCHING_APPH_FILTER_FIELDS,
    APPH_FILTER_FIELDS_FETCHED,
    APPH_FILTER_FIELDS_FETCH_COMPLETE,
    APPH_FILTER_FIELDS_FETCH_FAIL,
    FETCHING_EDIT_APPH_FILTER,
    EDIT_APPH_FILTER_FETCHED,
    EDIT_APPH_FILTER_FETCH_COMPLETE,
    EDIT_APPH_FILTER_FETCH_FAIL,
    FETCHING_SAVE_APPH_FILTER,
    SAVE_APPH_FILTER_FETCHED,
    SAVE_APPH_FILTER_FETCH_COMPLETE,
    SAVE_APPH_FILTER_FETCH_FAIL,
    FETCHING_APPH_LIST_COUNT,
    APPH_LIST_COUNT_FETCHED,
    APPH_LIST_COUNT_FETCH_COMPLETE,
    APPH_LIST_COUNT_FETCH_FAIL,
    FETCHING_APPH_LIST,
    APPH_LIST_FETCHED,
    APPH_LIST_FETCH_COMPLETE,
    APPH_LIST_FETCH_FAIL,
    INIT_APPH_LIST_COUNT,
    INIT_APPH_LIST,
    INIT_CALCULATE_CLAIM_VALUES,
    INIT_SEARCH_MANUAL_CLAIM_DETAILS,
    FETCH_SEARCH_MANUAL_CLAIM_DETAILS,
    INIT_MANUAL_CLAIM_PAYBACK_RECORDS,
    FETCH_MANUAL_CLAIM_PAYBACK_RECORDS,
    INIT_MANUAL_CLAIM_PAYBACK_DETAILS,
    FETCH_MANUAL_CLAIM_PAYBACK_DETAILS,
    INIT_MANUAL_CLAIM_ADD_PAYBACK,
    INIT_MANUAL_CLAIM_APPH_ASSIGN,
    FETCH_MANUAL_CLAIM_APPH_ASSIGN,
    FETCH_MANUAL_CLAIM_APPH_ASSIGNCALCULATE,
    INIT_MANUAL_CLAIM_APPH_ASSIGNCALCULATE,
    INIT_ACTIONS_MANUAL_CLAIM_RECORDS,
    FETCH_ACTIONS_MANUAL_CLAIM_RECORDS,
    INIT_RECORD_MODIFIED_CLAIM_CREATION_HEADERS,
    FETCH_RECORD_MODIFIED_SINCE_CLAIM_CREATION_HEADERS,
    INIT_RECORD_MODIFIED_CLAIM_RECORD,
    INIT_SAVE_AND_COPY_MANUAL_CLAIM,
} from "./actionTypes";
export const initManualClaimAPPHAssign = (payload, callback) => {
    return {
        type: INIT_MANUAL_CLAIM_APPH_ASSIGN,
        payload,
        callback,
    };
};

export const fetchManualClaimAPPHAssignCalculate = (data) => {
    return {
        type: FETCH_MANUAL_CLAIM_APPH_ASSIGNCALCULATE,
        data,
    };
};

export const initManualClaimAPPHAssignCalculate = (payload, callback) => {
    return {
        type: INIT_MANUAL_CLAIM_APPH_ASSIGNCALCULATE,
        payload,
        callback,
    };
};

export const fetchManualClaimAPPHAssign = (data) => {
    return {
        type: FETCH_MANUAL_CLAIM_APPH_ASSIGN,
        data,
    };
};
export const initManualClaimFilterFields = (payload, callback) => {
    return {
        type: INIT_MANUAL_CLAIM_FILTER_FIELDS,
        payload,
        callback,
    };
};

export const initManualClaimList = (payload) => {
    return {
        type: INIT_MANUAL_CLAIM_LIST,
        payload,
    };
};

export const fetchManualClaimList = (data) => {
    return {
        type: FETCH_MANUAL_CLAIM_LIST,
        data,
    };
};

export const initManualClaimRecordsExport = (payload) => {
    return {
        type: INIT_MANUAL_CLAIM_RECORDS_EXPORT,
        payload,
    };
};

export const initManualClaimRecordsExportAll = (payload) => {
    return {
        type: INIT_MANUAL_CLAIM_RECORDS_EXPORT_ALL,
        payload,
    };
};

export const initAddManualClaimFields = (payload) => {
    return {
        type: INIT_MANUAL_CLAIM_FIELDS,
        payload,
    };
};

export const fetchAddManualClaimFields = (data) => {
    return {
        type: FETCH_MANUAL_CLAIM_FIELDS,
        data,
    };
};

export const initAddManualClaim = (payload, callback) => {
    return {
        type: INIT_ADD_MANUAL_CLAIM,
        payload,
        callback,
    };
};

export const initSaveAndCopyManualClaim = (payload, callback) => {
    return {
        type: INIT_SAVE_AND_COPY_MANUAL_CLAIM,
        payload,
        callback,
    };
};

export const initGetManualClaimDetails = (payload, callback) => {
    return {
        type: INIT_MANUAL_CLAIM_DETAILS,
        payload,
        callback,
    };
};

export const fetchManualClaimDetails = (data) => {
    return {
        type: FETCH_MANUAL_CLAIM_DETAILS,
        data,
    };
};

export const initGetManualClaimRecordDetails = (payload) => {
    return {
        type: INIT_MANUAL_CLAIM_RECORD_DETAILS,
        payload,
    };
};

export const fetchManualClaimRecordDetails = (data) => {
    return {
        type: FETCH_MANUAL_CLAIM_RECORD_DETAILS,
        data,
    };
};

export const initGetOffSetRecords = (payload) => {
    return {
        type: INIT_GET_MANUAL_OFFSET_RECORDS,
        payload,
    };
};

export const fetchOffsetRecords = (data) => {
    return {
        type: FETCH_MANUAL_OFFSET_RECORDS,
        data,
    };
};

export const initDeleteOffset = (payload, callback) => {
    return {
        type: INIT_MANUAL_DELETE_OFFSET,
        payload,
        callback,
    };
};

export const initAddManualOffset = (payload, callback) => {
    return {
        type: INIT_ADD_MANUAL_OFFSET,
        payload,
        callback,
    };
};

export const initGetOffsetDetails = (payload, callback) => {
    return {
        type: INIT_GET_MANUAL_OFFSET_DETAILS,
        payload,
        callback,
    };
};

export const fetchOffsetDetails = (data) => {
    return {
        type: FETCH_MANUAL_OFFSET_DETAILS,
        data,
    };
};

export const initUpdateOffset = (payload, callback) => {
    return {
        type: INIT_UPDATE_MANUAL_OFFSET,
        payload,
        callback
    };
};

export const initSaveManualClaimDetails = (payload, callback) => {
    return {
        type: INIT_SAVE_MANUAL_CLAIM_DETAILS,
        payload,
        callback,
    };
};

export const initFilterName = (payload, callback) => {
    return {
        type: INIT_GETAPPH_FILTER_NAME,
        payload,
        callback,
    };
};
export const fetchingAPPHColNames = () => {
    return {
        type: FETCHING_APPH_FILTER_NAME,
    };
};
export const fetchAPPHColNames = (data) => {
    return {
        type: APPH_FILTER_NAME_FETCHED,
        data,
    };
};
export const apphColNameFetchComplete = () => {
    return {
        type: APPH_FILTER_NAME_FETCH_COMPLETE,
    };
};
export const apphColNameFetchFailed = () => {
    return {
        type: APPH_FILTER_NAME_FETCH_FAIL,
    };
};

export const initDeleteFilter = (payload, callback) => {
    return {
        type: INIT_DELETE_APPH_FILTER,
        payload,
        callback,
    };
};

export const initAddAPPHFilter = (payload) => {
    return {
        type: INIT_GETAPPH_FILTER_FIELDS,
        payload,
    };
};
export const fetchingAddAPPHFilter = () => {
    return {
        type: FETCHING_APPH_FILTER_FIELDS,
    };
};
export const fetchAddAPPHFilter = (data) => {
    return {
        type: APPH_FILTER_FIELDS_FETCHED,
        data,
    };
};
export const apphAddFilterFetchComplete = () => {
    return {
        type: APPH_FILTER_FIELDS_FETCH_COMPLETE,
    };
};
export const apphAddFiltereFetchFailed = () => {
    return {
        type: APPH_FILTER_FIELDS_FETCH_FAIL,
    };
};

export const initEditAPPHFilter = (payload) => {
    return {
        type: INIT_EDIT_APPH_FILTER,
        payload,
    };
};
export const fetchingEditAPPHFilter = () => {
    return {
        type: FETCHING_EDIT_APPH_FILTER,
    };
};
export const fetchEditAPPHFilter = (data) => {
    return {
        type: EDIT_APPH_FILTER_FETCHED,
        data,
    };
};
export const editAPPHFilterFetchComplete = () => {
    return {
        type: EDIT_APPH_FILTER_FETCH_COMPLETE,
    };
};
export const editAPPHFilterFetchFailed = () => {
    return {
        type: EDIT_APPH_FILTER_FETCH_FAIL,
    };
};

export const initSaveAPPHFilter = (payload, callback) => {
    return {
        type: INIT_SAVE_APPH_FILTER,
        payload,
        callback,
    };
};
export const fetchingSaveAPPHFilter = () => {
    return {
        type: FETCHING_SAVE_APPH_FILTER,
    };
};
export const fetchSaveAPPHFilter = (data) => {
    return {
        type: SAVE_APPH_FILTER_FETCHED,
        data,
    };
};
export const saveAPPHFilterFetchComplete = () => {
    return {
        type: SAVE_APPH_FILTER_FETCH_COMPLETE,
    };
};
export const saveAPPHFilterFetchFailed = () => {
    return {
        type: SAVE_APPH_FILTER_FETCH_FAIL,
    };
};

export const initAPPHListCount = (payload) => {
    return {
        type: INIT_APPH_LIST_COUNT,
        payload,
    };
};
export const fetchingAPPHListCount = () => {
    return {
        type: FETCHING_APPH_LIST_COUNT,
    };
};

export const fetchAPPHListCount = (data) => {
    return {
        type: APPH_LIST_COUNT_FETCHED,
        data,
    };
};

export const apphListCountFetchComplete = () => {
    return {
        type: APPH_LIST_COUNT_FETCH_COMPLETE,
    };
};
export const apphListCountrFetchFailed = () => {
    return {
        type: APPH_LIST_COUNT_FETCH_FAIL,
    };
};

export const initAPPHList = (payload) => {
    return {
        type: INIT_APPH_LIST,
        payload,
    };
};
export const fetchingAPPHList = () => {
    return {
        type: FETCHING_APPH_LIST,
    };
};
export const fetchAPPHList = (data) => {
    return {
        type: APPH_LIST_FETCHED,
        data,
    };
};
export const apphListFetchComplete = () => {
    return {
        type: APPH_LIST_FETCH_COMPLETE,
    };
};
export const apphListFetchFailed = () => {
    return {
        type: APPH_LIST_FETCH_FAIL,
    };
};

export const initCalculateClaimValues = (payload, callback) => {
    return {
        type: INIT_CALCULATE_CLAIM_VALUES,
        payload,
        callback,
    };
};

export const initSearchManualClaimDetails = (payload, callback) => {
    return {
        type: INIT_SEARCH_MANUAL_CLAIM_DETAILS,
        payload,
        callback,
    };
};

export const fetchSearchManualClaimDetails = (data) => {
    return {
        type: FETCH_SEARCH_MANUAL_CLAIM_DETAILS,
        data,
    };
};

export const initManualClaimPaybackRecords = (payload, callback) => {
    return {
        type: INIT_MANUAL_CLAIM_PAYBACK_RECORDS,
        payload,
        callback,
    };
};

export const fetchManualClaimPaybackRecords = (data) => {
    return {
        type: FETCH_MANUAL_CLAIM_PAYBACK_RECORDS,
        data,
    };
};

export const initManualClaimPaybackDetails = (payload, callback) => {
    return {
        type: INIT_MANUAL_CLAIM_PAYBACK_DETAILS,
        payload,
        callback,
    };
};

export const fetchManualClaimPaybackDetails = (data) => {
    return {
        type: FETCH_MANUAL_CLAIM_PAYBACK_DETAILS,
        data,
    };
};

export const initManualClaimAddPayback = (payload, callback) => {
    return {
        type: INIT_MANUAL_CLAIM_ADD_PAYBACK,
        payload,
        callback,
    };
};

export const initActionsManualClaimRecords = (payload, callback) => {
    return {
        type: INIT_ACTIONS_MANUAL_CLAIM_RECORDS,
        payload,
        callback,
    };
};

export const fetchActionsManualClaimRecords = (data) => {
    return {
        type: FETCH_ACTIONS_MANUAL_CLAIM_RECORDS,
        data,
    };
};
export const initRecordModifiedSinceClaimCretionHeaders = (
    payload,
    callback
) => {
    return {
        type: INIT_RECORD_MODIFIED_CLAIM_CREATION_HEADERS,
        payload,
        callback,
    };
};
export const fetchRecordModifiedSinceClaimCreationHeaders = (data) => {
    return {
        type: FETCH_RECORD_MODIFIED_SINCE_CLAIM_CREATION_HEADERS,
        data,
    };
};
export const initRecordModifiedClaimsRecord = (payload, callback) => {
    return {
        type: INIT_RECORD_MODIFIED_CLAIM_RECORD,
        payload,
        callback,
    };
};
