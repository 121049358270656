import { fromJS } from "immutable";
import {
    INIT_VENDOR_CLAIM_LIST,
    FETCH_VENDOR_CLAIM_LIST,
    FETCH_VENDOR_CLAIM_SUMMARY_LIST
} from "./actionTypes";

export const initialState = fromJS({
    vendorClaimList: {},
    vendorClaimSummaryList: {},
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_VENDOR_CLAIM_LIST: {
            return state.set("loader", true);
        }
        case FETCH_VENDOR_CLAIM_LIST: {
            return state.set("vendorClaimList", action.data).set("loader", false);
        }
        case FETCH_VENDOR_CLAIM_SUMMARY_LIST: {
            return state.set("vendorClaimSummaryList", action.data).set("loader", false);
        }
        default:
            return state;
    }
};

export default reducer;
