export const INIT_ADHOCDUPS_EXCEL_VALIDATION =
  "INIT_ADHOCDUPS_EXCEL_VALIDATION";
export const FETCH_ADHOCDUPS_EXCEL_VALIDATION =
  "FETCH_ADHOCDUPS_EXCEL_VALIDATION";
export const INIT_SUBMIT_ADHOCDUPS = "INIT_SUBMIT_ADHOCDUPS";
export const FETCH_SUBMIT_ADHOCDUPS = "FETCH_SUBMIT_ADHOCDUPS";
export const INIT_ADHOCDUPS_EXPORT_TO_EXCEL = "INIT_ADHOCDUPS_EXPORT_TO_EXCEL";
export const FETCH_ADHOCDUPS_EXPORT_TO_EXCEL =
  "FETCH_ADHOCDUPS_EXPORT_TO_EXCEL";
export const INIT_ADHOC_DUPS_JOBS = "INIT_ADHOC_DUPS_JOBS";
export const FETCH_ADHOC_DUPS_JOBS = "FETCH_ADHOC_DUPS_JOBS";
export const INIT_ADHOC_DUPS_JOBS_DETAILS = "INIT_ADHOC_DUPS_JOBS_DETAILS";
export const FETCH_ADHOC_DUPS_JOBS_DETAILS = "FETCH_ADHOC_DUPS_JOBS_DETAILS";
export const APPLY_INITAL_ADHOCDUPS_VALUES = "APPLY_INITAL_ADHOCDUPS_VALUES";
export const INIT_ADHOCDUPS_EXCEL_RECORD_COUNT = "INIT_ADHOCDUPS_EXCEL_RECORD_COUNT";
export const FETCH_ADHOCDUPS_EXCEL_RECORD_COUNT =    "FETCH_ADHOCDUPS_EXCEL_RECORD_COUNT";
