import commonReducer from "./../store/reducers";
import commonSaga from "./../store/sagas";
import loginReducer from "../containers/Login/store/reducer";
import loginSaga from "../containers/Login/store/saga";
import clientsReducer from "../containers/ClientManagement/store/reducer";
import clientsSaga from "../containers/ClientManagement/store/saga";
import formReducer from "../containers/BaseComponents/Form/store/reducer";
import formSaga from "../containers/BaseComponents/Form/store/saga";
import completedJobSaga from "../containers/CompletedJobs/store/saga";
import completedJobReducer from "../containers/CompletedJobs/store/reducer";
import changePasswordSaga from "../containers/ChangePassword/store/saga";
import gdprSaga from "../containers/GDPR/store/saga";
import footerSaga from "../containers/Layout/Footer/store/saga";
import footerReducer from "../containers/Layout/Footer/store/reducer";
import usersReducer from "../containers/ManageUser/store/reducer";
import usersSaga from "../containers/ManageUser/store/saga";
import udlReducer from "../containers/UDLMaintenance/store/reducer";
import udlSaga from "../containers/UDLMaintenance/store/saga";
import systemParametersSaga from "../containers/SystemParameters/store/saga";
import systemParametersReducer from "../containers/SystemParameters/store/reducer";
import alertListingSaga from "../containers/Alerts/store/saga";
import alertListingReducer from "../containers/Alerts/store/reducer";
import headerReducer from "../containers/Layout/Header/store/reducer";
import headerSaga from "../containers/Layout/Header/store/saga";
import clientQueueSummaryReducer from "../containers/ClientQueueSummary/store/reducer";
import clientQueueSummarySaga from "../containers/ClientQueueSummary/store/saga";
import runningJobReducer from "../containers/RunningJobs/store/reducer";
import runningJobSaga from "../containers/RunningJobs/store/saga";
import queuedJobsReducer from "../containers/QueuedJobs/store/reducer";
import queuedJobsSaga from "../containers/QueuedJobs/store/saga";
import jobsSchedulerReducer from "../containers/JobScheduler/store/reducer";
import jobsSchedulerSaga from "../containers/JobScheduler/store/saga";
import passwordManagementReducer from "../containers/PasswordManagement/store/reducer";
import passwordManagementSaga from "../containers/PasswordManagement/store/saga";
import manageGridSaga from "../containers/ManageGrid/store/saga";
import manageGridReducer from "../containers/ManageGrid/store/reducer";
import accountPayablesSaga from "../containers/AccountPayables/store/saga";
import accountPayablesReducer from "../containers/AccountPayables/store/reducer";
import paymentHistorySaga from "../containers/PaymentHistory/store/saga";
import paymentHistoryReducer from "../containers/PaymentHistory/store/reducer";
import apPhSaga from "../containers/APPHListing/store/saga";
import apPhReducer from "../containers/APPHListing/store/reducer";
import clientSystemParameterSaga from "../containers/SystemParametersClient/store/saga";
import clientSystemParameterReducer from "../containers/SystemParametersClient/store/reducer";
import vendorManagementSaga from "../containers/VendorsList/store/saga";
import vendorManagementReducer from "../containers/VendorsList/store/reducer";
import vendorGroupListSaga from "../containers/VendorGroupsList/store/saga";
import vendorGroupListReducer from "../containers/VendorGroupsList/store/reducer";
import vendorAnalysisReportReducer from "../containers/VendorAnalysisReports/store/reducer";
import vendorAnalysisReportSaga from "../containers/VendorAnalysisReports/store/saga";
import generalReportsListReducer from "../containers/GeneralReports/store/reducer";
import generalReportsListSaga from "../containers/GeneralReports/store/saga";
import duplicateExceptionReducer from "../containers/DuplicateException/store/reducer";
import duplicateExceptionSaga from "../containers/DuplicateException/store/saga";
import dataValidationReportListReducer from "../containers/DataValidationReports/store/reducer";
import dataValidationReportListSaga from "../containers/DataValidationReports/store/saga";
import duplicateClaimReducer from "../containers/DuplicateClaim/store/reducer";
import duplicateClaimSaga from "../containers/DuplicateClaim/store/saga";
import processingCriteriaReducer from "../containers/ProcessingCriteria/store/reducer";
import processingCriteriaSaga from "../containers/ProcessingCriteria/store/saga";
import adhocDupsSaga from "../containers/AdhocDups/store/saga";
import adhocDupsReducer from "../containers/AdhocDups/store/reducer";
import exceptionSaga from "../containers/Exceptions/store/saga";
import exceptionReducer from "../containers/Exceptions/store/reducer";
import aIExceptionSaga from "../containers/AIExceptions/store/saga";
import aIExceptionReducer from "../containers/AIExceptions/store/reducer";
import claimSaga from "../containers/DUPSClaims/store/saga";
import claimReducer from "../containers/DUPSClaims/store/reducer";
import manualClaimSaga from "../containers/ManualClaims/store/saga";
import manualClaimReducer from "../containers/ManualClaims/store/reducer";
import vendorClaimSaga from "../containers/ClaimsbyVendors/store/saga";
import vendorClaimReducer from "../containers/ClaimsbyVendors/store/reducer";
import generalDashboardSaga from "../containers/GeneralDashboard/store/saga";
import generalDashboardReducer from "../containers/GeneralDashboard/store/reducer";
import bulkReleaseSaga from "../containers/BulkRelease/store/saga";
import bulkReleaseReducer from "../containers/BulkRelease/store/reducer";
import aiBulkReleaseSaga from "../containers/AIBulkRelease/store/saga";
import aiBulkReleaseReducer from "../containers/AIBulkRelease/store/reducer";
import FraudAndErrorSaga from "../containers/FraudAndErrorDetection/store/saga";
import FraudAndErrorReducer from "../containers/FraudAndErrorDetection/store/reducer";
import runDataLoadandDUPSSaga from "../containers/RunDataLoadandDUPS/store/saga";
import runDataLoadandDUPSReducer from "../containers/RunDataLoadandDUPS/store/reducer";
import aiDuplicateExceptionReducer from "../containers/AIDuplicateException/store/reducer";
import aiDuplicateExceptionSaga from "../containers/AIDuplicateException/store/saga";
import setGlobalParameterReducer from "../containers/SetGlobalParameters/store/reducer";
import setGlobalParameterSaga from "../containers/SetGlobalParameters/store/saga";
import aiAdhocDupsSaga from "../containers/AIAdhocDups/store/saga";
import aiAdhocDupsReducer from "../containers/AIAdhocDups/store/reducer";
import renewTokenSaga from "../containers/RenewToken/store/saga";
import ipqcBulkReleaseSaga from "../containers/IPQCBulkRelease/store/saga";
import ipqcBulkReleaseReducer from "../containers/IPQCBulkRelease/store/reducer";
import ipqcPrioritizationSaga from "../containers/IPQCPrioritization/store/saga";
import ipqcPrioritizationReducer from "../containers/IPQCPrioritization/store/reducer";
import homeSaga from "../containers/Home/store/saga";
export const reducerDesc = [
    {
        key: "common",
        reducer: commonReducer,
    },
    {
        key: "login",
        reducer: loginReducer,
    },
    {
        key: "clients",
        reducer: clientsReducer,
    },
    {
        key: "form",
        reducer: formReducer,
    },
    {
        key: "completedJobs",
        reducer: completedJobReducer,
    },
    {
        key: "users",
        reducer: usersReducer,
    },
    {
        key: "systemParameters",
        reducer: systemParametersReducer,
    },
    {
        key: "alertListing",
        reducer: alertListingReducer,
    },
    {
        key: "udl",
        reducer: udlReducer,
    },
    {
        key: "clientQueueSummary",
        reducer: clientQueueSummaryReducer,
    },
    {
        key: "header",
        reducer: headerReducer,
    },
    {
        key: "runningJob",
        reducer: runningJobReducer,
    },
    {
        key: "queuedJobs",
        reducer: queuedJobsReducer,
    },
    {
        key: "jobs",
        reducer: jobsSchedulerReducer,
    },
    {
        key: "passwordManagement",
        reducer: passwordManagementReducer,
    },

    {
        key: "manageGrid",
        reducer: manageGridReducer,
    },
    {
        key: "accountPayables",
        reducer: accountPayablesReducer,
    },
    {
        key: "paymentHistory",
        reducer: paymentHistoryReducer,
    },
    {
        key: "apPhListing",
        reducer: apPhReducer,
    },
    {
        key: "clientSystemParameter",
        reducer: clientSystemParameterReducer,
    },
    {
        key: "vendorsList",
        reducer: vendorManagementReducer,
    },
    {
        key: "vendorGroupList",
        reducer: vendorGroupListReducer,
    },
    {
        key: "vendorAnalysisReport",
        reducer: vendorAnalysisReportReducer,
    },
    {
        key: "generalReport",
        reducer: generalReportsListReducer,
    },
    {
        key: "duplicateException",
        reducer: duplicateExceptionReducer,
    },
    {
        key: "aiDuplicateException",
        reducer: aiDuplicateExceptionReducer,
    },
    {
        key: "dataValidationReport",
        reducer: dataValidationReportListReducer,
    },
    {
        key: "duplicateClaim",
        reducer: duplicateClaimReducer,
    },
    {
        key: "processingCriteria",
        reducer: processingCriteriaReducer,
    },
    {
        key: "adhocDups",
        reducer: adhocDupsReducer,
    },
    {
        key: "exceptions",
        reducer: exceptionReducer,
    },
    {
        key: "aIExceptions",
        reducer: aIExceptionReducer,
    },
    {
        key: "dupsClaims",
        reducer: claimReducer,
    },
    {
        key: "manualClaims",
        reducer: manualClaimReducer,
    },
    {
        key: "claimsbyVendors",
        reducer: vendorClaimReducer,
    },
    {
        key: "generalDashboard",
        reducer: generalDashboardReducer,
    },
    {
        key: "bulkRelease",
        reducer: bulkReleaseReducer,
    },
    {
        key: "aiBulkRelease",
        reducer: aiBulkReleaseReducer,
    },
    {
        key: "fraudAndError",
        reducer: FraudAndErrorReducer,
    },
    {
        key: "runDataLoadandDUPS",
        reducer: runDataLoadandDUPSReducer,
    },
    {
        key: "aiAdhoc",
        reducer: aiAdhocDupsReducer,
    },
    {
        key: "setGlobalParameter",
        reducer: setGlobalParameterReducer,
    },
    {
        key: "ipqcBulkRelease",
        reducer: ipqcBulkReleaseReducer,
    },

    {
        key: "ipqcPrioritization",
        reducer: ipqcPrioritizationReducer,
    },
    {
        key: "footer",
        reducer: footerReducer,
    },
];
export const sagaDesc = [
    { key: "common", saga: commonSaga },
    { key: "login", saga: loginSaga },
    { key: "clients", saga: clientsSaga },
    { key: "form", saga: formSaga },
    { key: "gdpr", saga: gdprSaga },
    { key: "completedJobs", saga: completedJobSaga },
    { key: "changePassword", saga: changePasswordSaga },
    { key: "footer", saga: footerSaga },
    { key: "users", saga: usersSaga },
    { key: "udl", saga: udlSaga },
    { key: "systemParameters", saga: systemParametersSaga },
    { key: "alertListing", saga: alertListingSaga },
    { key: "header", saga: headerSaga },
    { key: "clientQueueSummary", saga: clientQueueSummarySaga },
    { key: "runningJob", saga: runningJobSaga },
    { key: "queuedJobs", saga: queuedJobsSaga },
    { key: "jobs", saga: jobsSchedulerSaga },
    { key: "passwordManagement", saga: passwordManagementSaga },
    { key: "manageGrid", saga: manageGridSaga },
    { key: "accountPayables", saga: accountPayablesSaga },
    { key: "paymentHistory", saga: paymentHistorySaga },
    { key: "apPhListing", saga: apPhSaga },
    { key: "clientSystemParameter", saga: clientSystemParameterSaga },
    { key: "vendorsList", saga: vendorManagementSaga },
    { key: "vendorGroupList", saga: vendorGroupListSaga },
    { key: "vendorAnalysisReport", saga: vendorAnalysisReportSaga },
    { key: "generalReport", saga: generalReportsListSaga },
    { key: "duplicateException", saga: duplicateExceptionSaga },
    { key: "aiDuplicateException", saga: aiDuplicateExceptionSaga },
    { key: "dataValidationReport", saga: dataValidationReportListSaga },
    { key: "duplicateClaim", saga: duplicateClaimSaga },
    { key: "processingCriteria", saga: processingCriteriaSaga },
    { key: "adhocDups", saga: adhocDupsSaga },
    { key: "exception", saga: exceptionSaga },
    { key: "aIException", saga: aIExceptionSaga },
    { key: "dupsClaims", saga: claimSaga },
    { key: "manualClaims", saga: manualClaimSaga },
    { key: "claimsbyVendors", saga: vendorClaimSaga },
    { key: "generalDashboard", saga: generalDashboardSaga },
    { key: "bulkRelease", saga: bulkReleaseSaga },
    { key: "aiBulkRelease", saga: aiBulkReleaseSaga },
    { key: "fraudAndError", saga: FraudAndErrorSaga },
    { key: "runDataLoadandDUPS", saga: runDataLoadandDUPSSaga },
    { key: "setGlobalParameter", saga: setGlobalParameterSaga },
    { key: "aiAdhoc", saga: aiAdhocDupsSaga },
    { key: "renewToken", saga: renewTokenSaga },
    { key: "ipqcBulkRelease", saga: ipqcBulkReleaseSaga },
    { key: "ipqcPrioritization", saga: ipqcPrioritizationSaga },
    { key: "home", saga: homeSaga },];
