import {
  INIT_AI_EXCEPTION_FILTER_COL_NAMES,
  FETCHING_AI_EXCEPTION_FILTER_COL_NAMES,
  AI_EXCEPTION_FILTER_COL_NAMES_FETCHED,
  AI_EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE,
  AI_EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL,
  INIT_SAVE_AI_EXCEPTION_FILTER,
  INIT_FETCH_AI_EXCEPTION_FILTER_DETAILS,
  INIT_FETCH_AI_EXCEPTION_FILTER_NAMES,
  INIT_GET_AI_EXCEPTION_COUNT,
  FETCHING_AI_EXCEPTION_COUNT,
  FETCH_AI_EXCEPTION_COUNT,
  AI_EXCEPTION_COUNT_FETCHING_COMPLETE,
  ASSIGN_INITAL_DATA,
  INIT_FETCH_AI_EXCEPTION_LIST,
  FETCHING_AI_EXCEPTION_LIST,
  AI_EXCEPTION_LIST_FETCHED,
  AI_EXCEPTION_LIST_FETCH_COMPLETE,
  AI_EXCEPTION_LIST_FETCH_FAIL,
  INIT_DELETE_AI_EXCEPTION_FILTER,
  INIT_GET_AI_EXCEPTION_RECORDS_EXPORT,
  FETCHING_AI_EXCEPTION_RECORDS_EXPORT,
  FETCH_AI_EXCEPTION_RECORDS_EXPORT,
  AI_EXCEPTION_RECORDS_EXPORT_FETCHING_COMPLETE,
  INIT_GET_AI_EXCEPTION_RECORDS_EXPORT_ALL,
  FETCHING_AI_EXCEPTION_RECORDS_EXPORT_ALL,
  FETCH_AI_EXCEPTION_RECORDS_EXPORT_ALL,
  AI_EXCEPTION_RECORDS_EXPORT_ALL_FETCHING_COMPLETE,
  INIT_GET_RELEASE_AI_EXCEPTION,
  FETCHING_RELEASE_AI_EXCEPTION,
  FETCH_RELEASE_AI_EXCEPTION,
  RELEASE_AI_EXCEPTION_FETCHING_COMPLETE,
  INIT_GET_AI_EXCEPTION_RECORD_LIST,
  FETCHING_AI_EXCEPTION_RECORD_LIST,
  FETCH_AI_EXCEPTION_RECORD_LIST,
  AI_EXCEPTION_RECORD_LIST_FETCHING_COMPLETE,
  INIT_AI_MOVE_AND_COPY_TO_CLAIM,
  FETCH_AI_MOVE_AND_COPY_TO_CLAIM,
  INIT_SAVE_AI_DUPLICATE_EXCEPTIONS_DETAILS,
  INIT_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS,
  FETCH_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS,
  INIT_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES,
  FETCH_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES,
  APPLY_INITAL_AI_DUPLICATE_EXCEPTION_VALUES,
} from "./actionTypes";

export const initFetchAIExceptionColNames = (payload) => {
  return {
    type: INIT_AI_EXCEPTION_FILTER_COL_NAMES,
    payload,
  };
};

export const fetchingAIExceptionColNames = () => {
  return {
    type: FETCHING_AI_EXCEPTION_FILTER_COL_NAMES,
  };
};

export const fetchAIExceptionColNames = (data) => {
  return {
    type: AI_EXCEPTION_FILTER_COL_NAMES_FETCHED,
    data,
  };
};

export const aiExceptionColNameFetchComplete = () => {
  return {
    type: AI_EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE,
  };
};

export const aiExceptionColNameFetchFailed = () => {
  return {
    type: AI_EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL,
  };
};

export const initSaveAIExceptionFilter = (payload, callback) => {
  return {
    type: INIT_SAVE_AI_EXCEPTION_FILTER,
    payload,
    callback,
  };
};

export const initFecthAIExceptionFilterDetails = (payload, callback) => {
  return {
    type: INIT_FETCH_AI_EXCEPTION_FILTER_DETAILS,
    payload,
    callback,
  };
};

export const initGetAIExceptionRightAlignedFields = (payload) => {
  return {
    type: INIT_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS,
    payload,
  };
};

export function fetchAIExceptionRightAlignedFields(data) {
  return {
    type: FETCH_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS,
    data,
  };
}

export function assignInitalData() {
  return {
    type: ASSIGN_INITAL_DATA,
  };
}

export const initGetAIExceptionFilterNames = (payload, callback) => {
  return {
    type: INIT_FETCH_AI_EXCEPTION_FILTER_NAMES,
    payload,
    callback,
  };
};

export const initGetAIExceptionCount = (payload) => {
  return {
    type: INIT_GET_AI_EXCEPTION_COUNT,
    payload,
  };
};

export function fetchingAIExceptionCount() {
  return {
    type: FETCHING_AI_EXCEPTION_COUNT,
  };
}

export function fetchAIExceptionCount(data) {
  return {
    type: FETCH_AI_EXCEPTION_COUNT,
    data,
  };
}

export function aiExceptionCountFetchingComplete() {
  return {
    type: AI_EXCEPTION_COUNT_FETCHING_COMPLETE,
  };
}

export const initFetchAIExceptionList = (payload) => {
  return {
    type: INIT_FETCH_AI_EXCEPTION_LIST,
    payload,
  };
};

export const fetchingAIExceptionList = () => {
  return {
    type: FETCHING_AI_EXCEPTION_LIST,
  };
};

export const fetchAIExceptionList = (data) => {
  return {
    type: AI_EXCEPTION_LIST_FETCHED,
    data,
  };
};

export const aiExceptionListFetchComplete = () => {
  return {
    type: AI_EXCEPTION_LIST_FETCH_COMPLETE,
  };
};

export const aiExceptionListFetchFailed = () => {
  return {
    type: AI_EXCEPTION_LIST_FETCH_FAIL,
  };
};

export const initDeleteAIExceptionFilter = (payload, callback) => {
  return {
    type: INIT_DELETE_AI_EXCEPTION_FILTER,
    payload,
    callback,
  };
};

export const initGetAIExceptionRecordsExport = (payload) => {
  return {
    type: INIT_GET_AI_EXCEPTION_RECORDS_EXPORT,
    payload,
  };
};

export function fetchingAIExceptionRecodsExport() {
  return {
    type: FETCHING_AI_EXCEPTION_RECORDS_EXPORT,
  };
}

export function fetchAIExceptionRecordsExport(data) {
  return {
    type: FETCH_AI_EXCEPTION_RECORDS_EXPORT,
    data,
  };
}

export function aiExceptionRecordsExportFetchingComplete() {
  return {
    type: AI_EXCEPTION_RECORDS_EXPORT_FETCHING_COMPLETE,
  };
}

export const initGetAIExceptionRecordsExportAll = (payload) => {
  return {
    type: INIT_GET_AI_EXCEPTION_RECORDS_EXPORT_ALL,
    payload,
  };
};

export function fetchingAIExceptionRecodsExportAll() {
  return {
    type: FETCHING_AI_EXCEPTION_RECORDS_EXPORT_ALL,
  };
}

export function fetchAIExceptionRecordsExportAll(data) {
  return {
    type: FETCH_AI_EXCEPTION_RECORDS_EXPORT_ALL,
    data,
  };
}

export function aiExceptionRecordsExportAllFetchingComplete() {
  return {
    type: AI_EXCEPTION_RECORDS_EXPORT_ALL_FETCHING_COMPLETE,
  };
}

export const initAIExceptionRecordListHeaderNames = (payload) => {
  return {
    type: INIT_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES,
    payload,
  };
};

export const fetchAIExceptionRecordListHeaderNames = (data) => {
  return {
    type: FETCH_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES,
    data,
  };
};

export const initGetReleaseAIException = (payload) => {
  return {
    type: INIT_GET_RELEASE_AI_EXCEPTION,
    payload,
  };
};

export function fetchingReleaseAIException() {
  return {
    type: FETCHING_RELEASE_AI_EXCEPTION,
  };
}

export function fetchReleaseAIException(data) {
  return {
    type: FETCH_RELEASE_AI_EXCEPTION,
    data,
  };
}

export function releaseAIExceptionFetchingComplete() {
  return {
    type: RELEASE_AI_EXCEPTION_FETCHING_COMPLETE,
  };
}

export function initGetAIExceptionRecordList(payload) {
  return {
    type: INIT_GET_AI_EXCEPTION_RECORD_LIST,
    payload,
  };
}

export function fetchingAIExceptionRecordList() {
  return {
    type: FETCHING_AI_EXCEPTION_RECORD_LIST,
  };
}

export function fetchAIExceptionRecordList(data) {
  return {
    type: FETCH_AI_EXCEPTION_RECORD_LIST,
    data,
  };
}

export function aiExceptionRecordListFetchingComplete() {
  return {
    type: AI_EXCEPTION_RECORD_LIST_FETCHING_COMPLETE,
  };
}

export const initAIMoveAndCopyToClaim = (payload, callback) => {
  return {
    type: INIT_AI_MOVE_AND_COPY_TO_CLAIM,
    payload,
    callback,
  };
};

export const fetchAIMoveAndCopyToClaim = (data) => {
  return {
    type: FETCH_AI_MOVE_AND_COPY_TO_CLAIM,
    data,
  };
};

export const initSaveAIDuplicateExceptionDetails = (payload, callback) => {
  return {
    type: INIT_SAVE_AI_DUPLICATE_EXCEPTIONS_DETAILS,
    payload,
    callback,
  };
};

export const applyIntialValue = () => {
  return {
    type: APPLY_INITAL_AI_DUPLICATE_EXCEPTION_VALUES,
  };
};
