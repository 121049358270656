import { fromJS } from "immutable";
import {
  INIT_SEQUENCE_FIELD_DETAILS,
  INIT_SUPPLEMENTRY_CRITERIA_DETAILS,
  INIT_EDIT_FILTER_PAGE,
  FETCH_CRITERIA_FIELD,
  FETCH_SEQUENCE_FIELD_DETAILS,
  FETCH_PROCESSING_DATA,
  FETCH_EDIT_FILTER_PAGE,
  FETCH_SEQUENCE_DETAILS,
  FETCH_SUPPLEMENTRY_CRITERIA_DETAILS,
  INIT_INITAL_STATE,
} from "./actionTypes";
export const initialState = fromJS({
  loader: false,
  sequenceFieldDetails: {},
  processingData: {},
  criteriaField: [],
  filterData: [],
  sequenceDetails: {},
  supplementryCriteria: {},
});
const reduer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_SEQUENCE_FIELD_DETAILS:
      return state.set("loader", true);
      case INIT_EDIT_FILTER_PAGE:
        return state.set("loader", true);
    case FETCH_SEQUENCE_FIELD_DETAILS:
      return state.set("sequenceFieldDetails", action.data);
    case FETCH_CRITERIA_FIELD:
      return state.set("criteriaField", action.data);
    case FETCH_PROCESSING_DATA:
      return state.set("processingData", action.data).set("loader", false);
    case FETCH_EDIT_FILTER_PAGE:
      return state.set("filterData", action.data);
    case FETCH_SEQUENCE_DETAILS:
      return state.set("sequenceDetails", action.data);
    case INIT_SUPPLEMENTRY_CRITERIA_DETAILS:
      return state.set("loader", true);
    case FETCH_SUPPLEMENTRY_CRITERIA_DETAILS:
      return state
        .set("supplementryCriteria", action.data)
        .set("loader", false);
    case INIT_INITAL_STATE:
      return state
        .set("loader", false)
        .set("sequenceFieldDetails", {})
        .set("processingData", {})
        .set("criteriaField", {})
        .set("filterData", {});
    default:
      return state;
  }
};
export default reduer;
