import { fromJS } from "immutable";
 import { FETCH_PASSWORD,INIT_GET_PASSWORD,INIT_SAVE_PASSWORD,FETCH_SAVEPASSWORD } from "./actionTypes";

export const initialState = fromJS({
  loader:false,
  saveChangePassword:false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_GET_PASSWORD:{
      return state.set("loader",true)
    }
    case FETCH_PASSWORD: {
      return state.set("loader",false);
    }
    case INIT_SAVE_PASSWORD:{
      return state.set("saveChangePassword",true);
    }
    case FETCH_SAVEPASSWORD:{
      return state.set("saveChangePassword",false)
    }
    default:
      return state;
  }
};

export default reducer;