import {
    INIT_FETCH_CLAIM_FILTER_COL_NAMES,
    FETCHING_CLAIM_FILTER_COL_NAMES,
    CLAIM_FILTER_COL_NAMES_FETCHED,
    CLAIM_FILTER_COL_NAMES_FETCH_COMPLETE,
    CLAIM_FILTER_COL_NAMES_FETCH_FAIL,
    ASSIGN_INITAL_DATA,
    INIT_SAVE_CLAIM_FILTER,
    INIT_FETCH_CLAIM_FILTER_NAMES,
    INIT_DUPLICATE_CLAIM_LIST,
    FETCH_DUPLICATE_CLAIMS_LIST,
    INIT_DELETE_CLAIM_FILTER,
    INIT_FETCH_CLAIM_FILTER_DETAILS,
    INIT_GET_CLAIM_RECORDS_EXPORT,
    FETCHING_CLAIM_RECORDS_EXPORT,
    FETCH_CLAIM_RECORDS_EXPORT,
    CLAIM_RECORDS_EXPORT_FETCHING_COMPLETE,
    INIT_GET_CLAIM_RECORDS_EXPORT_ALL,
    FETCHING_CLAIM_RECORDS_EXPORT_ALL,
    FETCHING_CLAIM_DETAILS,
    FETCHING_CLAIM_DETAILS_COMPLETE,
    FETCHING_CLAIM_DETAILS_FAIL,
    FETCH_CLAIM_RECORDS_EXPORT_ALL,
    CLAIM_RECORDS_EXPORT_ALL_FETCHING_COMPLETE,
    INIT_GET_CLAIM_DETAILS,
    FETCH_CLAIM_DETAILS,
    INIT_GET_USER_LIST,
    FETCH_USER_LIST,
    INIT_GET_MANUAL_CLAIMS_HISTORY,
    FETCH_MANUAL_CLAIMS_HISTORY,
    INIT_SEARCH_DUPLICATECLAIM_DETAILS,
    FETCH_SEARCH_DUPLICATECLAIM_DETAILS,
    INIT_DUPLICATECLAIM_VENDOR_LETTER,
    FETCH_DUPLICATECLAIM_VENDOR_LETTER,
    INIT_DUPLICATECLAIM_PAYBACK_RECORDS,
    FETCH_DUPLICATECLAIM_PAYBACK_RECORDS,
    FETCH_DUPLICATECLAIM_PAYBACK_DETAILS,
    INIT_DUPLICATECLAIM_EXCEPTION_RECORDS,
    FETCH_DUPLICATECLAIM_EXCEPTION_RECORDS,
    INIT_GET_VENDOR_LIST_FROM_EXCEPTION,
    FETCH_VENDOR_LIST_FROM_EXCEPTION,
    INIT_GET_VENDOR_FILTER_FIELDS,
    FETCH_VENDOR_FILTER_FIELDS,
    INIT_GET_VENDOR_LIST_FROM_EXCEPTION_COUNT,
    FETCH_VENDOR_LIST_FROM_EXCEPTION_COUNT,
    INIT_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS,
    FETCH_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS,
    INIT_GET_VENDOR_LIST_DATA,
    FETCH_VENDOR_LIST_DATA,
    INIT_GET_VENDOR_LIST_COUNT,
    FETCH_VENDOR_LIST_COUNT,
    INIT_GET_OFFSET_RECORDS,
    FETCH_OFFSET_RECORDS,
    INIT_DELETE_OFFSET,
    INIT_ADD_OFFSET,
    INIT_GET_OFFSET_TYPE,
    FETCH_OFFSET_TYPE,
    INIT_GET_OFFSET_DETAILS,
    FETCH_OFFSET_DETAILS,
    INIT_UPDATE_OFFSET,
    INIT_SAVE_CLAIM_DETAILS,
    INIT_ADD_PAYBACK,
    INIT_DUPLICATECLAIM_PAYBACK_DETAILS,
    APPLY_INITAL_DUPLICATE_CLAIM_DATA,
    INIT_GET_PRIMUS_DFM_URL_DETAILS
} from "./actionTypes";
export const initGetClaimRecordsExport = (payload) => {
    return {
        type: INIT_GET_CLAIM_RECORDS_EXPORT,
        payload,
    };
};

export function fetchingClaimRecodsExport() {
    return {
        type: FETCHING_CLAIM_RECORDS_EXPORT,
    };
}

export function fetchClaimRecordsExport(data) {
    return {
        type: FETCH_CLAIM_RECORDS_EXPORT,
        data,
    };
}

export function claimRecordsExportFetchingComplete() {
    return {
        type: CLAIM_RECORDS_EXPORT_FETCHING_COMPLETE,
    };
}

export const initGetClaimRecordsExportAll = (payload) => {
    return {
        type: INIT_GET_CLAIM_RECORDS_EXPORT_ALL,
        payload,
    };
};

export function fetchingClaimRecodsExportAll() {
    return {
        type: FETCHING_CLAIM_RECORDS_EXPORT_ALL,
    };
}

export function fetchClaimRecordsExportAll(data) {
    return {
        type: FETCH_CLAIM_RECORDS_EXPORT_ALL,
        data,
    };
}

export function claimRecordsExportAllFetchingComplete() {
    return {
        type: CLAIM_RECORDS_EXPORT_ALL_FETCHING_COMPLETE,
    };
}
export const initDeleteClaimFilter = (payload, callback) => {
    return {
        type: INIT_DELETE_CLAIM_FILTER,
        payload,
        callback,
    };
};

export const initFecthClaimFilterDetails = (payload, callback) => {
    return {
        type: INIT_FETCH_CLAIM_FILTER_DETAILS,
        payload,
        callback,
    };
};

export const initFetchClaimColNames = (payload) => {
    return {
        type: INIT_FETCH_CLAIM_FILTER_COL_NAMES,
        payload,
    };
};
export const fetchingClaimColNames = () => {
    return {
        type: FETCHING_CLAIM_FILTER_COL_NAMES,
    };
};

export const fetchClaimColNames = (data) => {
    return {
        type: CLAIM_FILTER_COL_NAMES_FETCHED,
        data,
    };
};

export const claimColNameFetchComplete = () => {
    return {
        type: CLAIM_FILTER_COL_NAMES_FETCH_COMPLETE,
    };
};
export const claimColNameFetchFailed = () => {
    return {
        type: CLAIM_FILTER_COL_NAMES_FETCH_FAIL,
    };
};
export function assignInitalData() {
    return {
        type: ASSIGN_INITAL_DATA,
    };
}

export const initSaveClaimFilter = (payload, callback) => {
    return {
        type: INIT_SAVE_CLAIM_FILTER,
        payload,
        callback,
    };
};

export const initGetClaimFilterNames = (payload, callback) => {
    return {
        type: INIT_FETCH_CLAIM_FILTER_NAMES,
        payload,
        callback,
    };
};

export const initGetDuplicateClaimsList = (payload, callback) => {
    return {
        type: INIT_DUPLICATE_CLAIM_LIST,
        payload,
        callback,
    };
};

export const fetchDuplicateClaimsList = (data) => {
    return {
        type: FETCH_DUPLICATE_CLAIMS_LIST,
        data,
    };
};

export const initGetClaimDetails = (payload, callback) => {
    return {
        type: INIT_GET_CLAIM_DETAILS,
        payload,
        callback,
    };
};

export const fetchingClaimDetails = () => {
    return {
        type: FETCHING_CLAIM_DETAILS,
    };
};

export const fetchingClaimDetailsComplete = () => {
    return {
        type: FETCHING_CLAIM_DETAILS_COMPLETE,
    };
};

export const fetchingClaimDetailsFail = () => {
    return {
        type: FETCHING_CLAIM_DETAILS_FAIL,
    };
};

export const fetchClaimDetails = (data) => {
    return {
        type: FETCH_CLAIM_DETAILS,
        data,
    };
};
export const initGetUserList = (payload) => {
    return {
        type: INIT_GET_USER_LIST,
        payload,
    };
};

export const fetchUserList = (data) => {
    return {
        type: FETCH_USER_LIST,
        data,
    };
};

export const initGetManualClaimsHistory = (payload) => {
    return {
        type: INIT_GET_MANUAL_CLAIMS_HISTORY,
        payload,
    };
};

export const fetchManualClaimsHistory = (data) => {
    return {
        type: FETCH_MANUAL_CLAIMS_HISTORY,
        data,
    };
};

export const initSearchDuplicateClaimDetails = (payload, callback) => {
    return {
        type: INIT_SEARCH_DUPLICATECLAIM_DETAILS,
        payload,
        callback,
    };
};

export const fetchSearchDuplicateClaimDetails = (data) => {
    return {
        type: FETCH_SEARCH_DUPLICATECLAIM_DETAILS,
        data,
    };
};

export const initDuplicateClaimVendorLetter = (payload) => {
    return {
        type: INIT_DUPLICATECLAIM_VENDOR_LETTER,
        payload,
    };
};

export const fetchDuplicateClaimVendorLetter = (data) => {
    return {
        type: FETCH_DUPLICATECLAIM_VENDOR_LETTER,
        data,
    };
};

export const initDuplicateClaimPaybackRecords = (payload, callback) => {
    return {
        type: INIT_DUPLICATECLAIM_PAYBACK_RECORDS,
        payload,
        callback,
    };
};

export const fetchDuplicateClaimPaybackRecords = (data) => {
    return {
        type: FETCH_DUPLICATECLAIM_PAYBACK_RECORDS,
        data,
    };
};

export const initDuplicateClaimPaybackDetails = (payload, callback) => {
    return {
        type: INIT_DUPLICATECLAIM_PAYBACK_DETAILS,
        payload,
        callback,
    };
};

export const fetchDuplicateClaimPaybackDetails = (data) => {
    return {
        type: FETCH_DUPLICATECLAIM_PAYBACK_DETAILS,
        data,
    };
};

export const initDuplicateClaimExceptionRecords = (payload) => {
    return {
        type: INIT_DUPLICATECLAIM_EXCEPTION_RECORDS,
        payload,
    };
};

export const fetchDuplicateClaimExceptionRecords = (data) => {
    return {
        type: FETCH_DUPLICATECLAIM_EXCEPTION_RECORDS,
        data,
    };
};

export const initGetVendorListFromException = (payload) => {
    return {
        type: INIT_GET_VENDOR_LIST_FROM_EXCEPTION,
        payload,
    };
};

export const fetchVendorListFromException = (data) => {
    return {
        type: FETCH_VENDOR_LIST_FROM_EXCEPTION,
        data,
    };
};

export const intGetVendorFilterFields = (payload,callback) => {
    return {
        type: INIT_GET_VENDOR_FILTER_FIELDS,
        payload,
        callback
    };
};

export const fetchVendorFilterFields = (data) => {
    return {
        type: FETCH_VENDOR_FILTER_FIELDS,
        data,
    };
};

export const initGetVendorListFromExceptionCount = (payload) => {
    return {
        type: INIT_GET_VENDOR_LIST_FROM_EXCEPTION_COUNT,
        payload,
    };
};

export const fetchVendorListFromExceptionCount = (data) => {
    return {
        type: FETCH_VENDOR_LIST_FROM_EXCEPTION_COUNT,
        data,
    };
};

export const initActionsDuplicateClaimExceptionRec = (payload, callback) => {
    return {
        type: INIT_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS,
        payload,
        callback,
    };
};

export const fetchActionsDuplicateClaimExceptionRec = (data) => {
    return {
        type: FETCH_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS,
        data,
    };
};

export const initGetVendorListData = (payload) => {
    return {
        type: INIT_GET_VENDOR_LIST_DATA,
        payload,
    };
};

export const fetchVendorListData = (data) => {
    return {
        type: FETCH_VENDOR_LIST_DATA,
        data,
    };
};

export const initGetVendorListCount = (payload) => {
    return {
        type: INIT_GET_VENDOR_LIST_COUNT,
        payload,
    };
};

export const fetchVendorListCount = (data) => {
    return {
        type: FETCH_VENDOR_LIST_COUNT,
        data,
    };
};

export const initGetOffSetRecords = (payload) => {
    return {
        type: INIT_GET_OFFSET_RECORDS,
        payload,
    };
};

export const fetchOffsetRecords = (data) => {
    return {
        type: FETCH_OFFSET_RECORDS,
        data,
    };
};

export const initDeleteOffset = (payload, callback) => {
    return {
        type: INIT_DELETE_OFFSET,
        payload,
        callback,
    };
};

export const initAddOffset = (payload, callback) => {
    return {
        type: INIT_ADD_OFFSET,
        payload,
        callback,
    };
};

export const initAddPayback = (payload, callback) => {
    return {
        type: INIT_ADD_PAYBACK,
        payload,
        callback,
    };
};

export const initGetOffsetType = (payload) => {
    return {
        type: INIT_GET_OFFSET_TYPE,
        payload,
    };
};

export const fetchOffsetType = (data) => {
    return {
        type: FETCH_OFFSET_TYPE,
        data,
    };
};

export const initGetOffsetDetails = (payload, callback) => {
    return {
        type: INIT_GET_OFFSET_DETAILS,
        payload,
        callback,
    };
};

export const fetchOffsetDetails = (data) => {
    return {
        type: FETCH_OFFSET_DETAILS,
        data,
    };
};

export const initUpdateOffset = (payload, callback) => {
    return {
        type: INIT_UPDATE_OFFSET,
        payload,
        callback,
    };
};

export const initSaveClaimDetails = (payload, callback) => {
    return {
        type: INIT_SAVE_CLAIM_DETAILS,
        payload,
        callback,
    };
};
export const applyInitalData = () => {
    return {
        type: APPLY_INITAL_DUPLICATE_CLAIM_DATA,
    };
};

export const initGetPrimusDFMUrl = (payload, callback) => {
    return {
        type: INIT_GET_PRIMUS_DFM_URL_DETAILS,
        payload,
        callback,
    };
};
