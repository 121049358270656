import {
  INIT_FETCH_AP_FILTER_COL_NAMES,
  FETCHING_AP_FILTER_COL_NAMES,
  AP_FILTER_COL_NAMES_FETCHED,
  AP_FILTER_COL_NAMES_FETCH_COMPLETE,
  AP_FILTER_COL_NAMES_FETCH_FAIL,
  ASSIGN_INITAL_DATA,
  INIT_SAVE_AP_FILTER,
  INIT_FETCH_AP_LIST_HEADER_NAMES,
  FETCHING_AP_LIST_HEADER_NAMES,
  AP_LIST_HEADER_NAMES_FETCHED,
  AP_LIST_HEADER_NAMES_FETCH_COMPLETE,
  AP_LIST_HEADER_NAMES_FETCH_FAIL,
  INIT_FETCH_AP_ROLE_BASED_ACTIONS,
  FETCHING_AP_ROLE_BASED_ACTIONS,
  AP_ROLE_BASED_ACTIONS_FETCHED,
  AP_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
  AP_ROLE_BASED_ACTIONS_FETCH_FAIL,
  INIT_FETCH_AP_FILTER_NAMES,
  INIT_GET_AP_COUNT,
  FETCHING_AP_COUNT,
  FETCH_AP_COUNT,
  AP_COUNT_FETCHING_COMPLETE,
  INIT_FETCH_AP_LIST,
  FETCHING_AP_LIST,
  AP_LIST_FETCHED,
  AP_LIST_FETCH_COMPLETE,
  AP_LIST_FETCH_FAIL,
  INIT_FETCH_AP_FILTER_DETAILS,
  INIT_DELETE_AP_FILTER,
  INIT_GET_AP_RIGHT_ALIGNED_FIELDS,
  AP_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE,
  FETCHING_AP_RIGHT_ALIGNED_FIELDS,
  FETCH_AP_RIGHT_ALIGNED_FIELDS,
  INIT_GET_AP_EXPORT_FIELDS_LIST,
  FETCHING_AP_EXPORT_FIELDS_LIST,
  FETCH_AP_EXPORT_FIELDS_LIST,
  AP_EXPORT_FIELDS_LIST_FETCHING_COMPLETE,
  INIT_GET_AP_RECORDS_EXPORT,
  FETCHING_AP_RECORDS_EXPORT,
  FETCH_AP_RECORDS_EXPORT,
  AP_RECORDS_EXPORT_FETCHING_COMPLETE,
  INIT_GET_AP_RECORDS_EXPORT_ALL,
  FETCHING_AP_RECORDS_EXPORT_ALL,
  FETCH_AP_RECORDS_EXPORT_ALL,
  AP_RECORDS_EXPORT_ALL_FETCHING_COMPLETE,
  INIT_GET_REPORT,
  FETCHING_REPORT,
  FETCH_REPORT,
  REPORT_FETCHING_COMPLETE,
} from "./actionTypes";

export const initFetchApColNames = (payload) => {
  return {
    type: INIT_FETCH_AP_FILTER_COL_NAMES,
    payload,
  };
};
export const fetchingApColNames = () => {
  return {
    type: FETCHING_AP_FILTER_COL_NAMES,
  };
};

export const fetchApColNames = (data) => {
  return {
    type: AP_FILTER_COL_NAMES_FETCHED,
    data,
  };
};

export const apColNameFetchComplete = () => {
  return {
    type: AP_FILTER_COL_NAMES_FETCH_COMPLETE,
  };
};
export const apColNameFetchFailed = () => {
  return {
    type: AP_FILTER_COL_NAMES_FETCH_FAIL,
  };
};

export function assignInitalData() {
  return {
    type: ASSIGN_INITAL_DATA,
  };
}

export const initSaveApFilter = (payload, callback) => {
  return {
    type: INIT_SAVE_AP_FILTER,
    payload,
    callback,
  };
};

export const initFetchApListHeaderNames = (payload) => {
  return {
    type: INIT_FETCH_AP_LIST_HEADER_NAMES,
    payload,
  };
};
export const fetchingApListHeaderNames = () => {
  return {
    type: FETCHING_AP_LIST_HEADER_NAMES,
  };
};

export const fetchApListHeaderNames = (data) => {
  return {
    type: AP_LIST_HEADER_NAMES_FETCHED,
    data,
  };
};

export const apListHeaderNameFetchComplete = () => {
  return {
    type: AP_LIST_HEADER_NAMES_FETCH_COMPLETE,
  };
};
export const apListHeaderNameFetchFailed = () => {
  return {
    type: AP_LIST_HEADER_NAMES_FETCH_FAIL,
  };
};


export const initFetchApRoleBasedActions = (payload) => {
  return {
    type: INIT_FETCH_AP_ROLE_BASED_ACTIONS,
    payload,
  };
};
export const fetchingApRoleBasedActions = () => {
  return {
    type: FETCHING_AP_ROLE_BASED_ACTIONS,
  };
};

export const fetchApRoleBasedActions = (data) => {
  return {
    type: AP_ROLE_BASED_ACTIONS_FETCHED,
    data,
  };
};

export const apRoleBasedActionsFetchComplete = () => {
  return {
    type: AP_ROLE_BASED_ACTIONS_FETCH_COMPLETE,
  };
};
export const apRoleBasedActionsFetchFailed = () => {
  return {
    type: AP_ROLE_BASED_ACTIONS_FETCH_FAIL,
  };
};

export const initGetApFilterNames = (payload, callback) => {
  return {
    type: INIT_FETCH_AP_FILTER_NAMES,
    payload,
    callback,
  };
};

export const initGetApCount = (payload) => {
  return {
    type: INIT_GET_AP_COUNT,
    payload,
  };
};

export function fetchingApCount() {
  return {
    type: FETCHING_AP_COUNT,
  };
}

export function fetchApCount(data) {
  return {
    type: FETCH_AP_COUNT,
    data,
  };
}

export function apCountFetchingComplete() {
  return {
    type: AP_COUNT_FETCHING_COMPLETE,
  };
}

export const initFetchApList = (payload) => {
  return {
    type: INIT_FETCH_AP_LIST,
    payload,
  };
};

export const fetchingApList = () => {
  return {
    type: FETCHING_AP_LIST,
  };
};

export const fetchApList = (data) => {
  return {
    type: AP_LIST_FETCHED,
    data,
  };
};

export const apListFetchComplete = () => {
  return {
    type: AP_LIST_FETCH_COMPLETE,
  };
};
export const apListFetchFailed = () => {
  return {
    type: AP_LIST_FETCH_FAIL,
  };
};

export const initFecthApFilterDetails = (payload, callback) => {
  return {
    type: INIT_FETCH_AP_FILTER_DETAILS,
    payload,
    callback,
  };
};

export const initDeleteApFilter = (payload, callback) => {
  return {
    type: INIT_DELETE_AP_FILTER,
    payload,
    callback,
  };
};

export const initGetApRightAlignedFields = (payload) => {
  return {
    type: INIT_GET_AP_RIGHT_ALIGNED_FIELDS,
    payload,
  };
};

export function fetchingApRightAlignedFields() {
  return {
    type: FETCHING_AP_RIGHT_ALIGNED_FIELDS,
  };
}

export function fetchApRightAlignedFields(data) {
  return {
    type: FETCH_AP_RIGHT_ALIGNED_FIELDS,
    data,
  };
}

export function apRightAlignedFieldsFetchingComplete() {
  return {
    type: AP_RIGHT_ALIGNED_FIELDS_FETCHING_COMPLETE,
  };
}

export const initGetApExportFieldsList = (payload) => {
  return {
    type: INIT_GET_AP_EXPORT_FIELDS_LIST,
    payload,
  };
};

export function fetchingApExportFieldsList() {
  return {
    type: FETCHING_AP_EXPORT_FIELDS_LIST,
  };
}

export function fetchApExportFieldsList(data) {
  return {
    type: FETCH_AP_EXPORT_FIELDS_LIST,
    data,
  };
}

export function apExportFieldsListFetchingComplete() {
  return {
    type: AP_EXPORT_FIELDS_LIST_FETCHING_COMPLETE,
  };
}

export const initGetApRecordsExport = (payload) => {
  return {
    type: INIT_GET_AP_RECORDS_EXPORT,
    payload,
  };
};

export function fetchingApRecodsExport() {
  return {
    type: FETCHING_AP_RECORDS_EXPORT,
  };
}

export function fetchApRecordsExport(data) {
  return {
    type: FETCH_AP_RECORDS_EXPORT,
    data,
  };
}

export function apRecordsExportFetchingComplete() {
  return {
    type: AP_RECORDS_EXPORT_FETCHING_COMPLETE,
  };
}

export const initGetApRecordsExportAll = (payload) => {
  return {
    type: INIT_GET_AP_RECORDS_EXPORT_ALL,
    payload,
  };
};

export function fetchingApRecodsExportAll() {
  return {
    type: FETCHING_AP_RECORDS_EXPORT_ALL,
  };
}

export function fetchApRecordsExportAll(data) {
  return {
    type: FETCH_AP_RECORDS_EXPORT_ALL,
    data,
  };
}

export function apRecordsExportAllFetchingComplete() {
  return {
    type: AP_RECORDS_EXPORT_ALL_FETCHING_COMPLETE,
  };
}

export const initGetReport = (payload, callback) => {
  return {
    type: INIT_GET_REPORT,
    payload,
    callback,
  };
};

export function fetchingReport() {
  return {
    type: FETCHING_REPORT,
  };
}

export function fetchReport(data) {
  return {
    type: FETCH_REPORT,
    data,
  };
}

export function reportFetchingComplete() {
  return {
    type: REPORT_FETCHING_COMPLETE,
  };
}

