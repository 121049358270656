import {
  INIT_BULKRELEASE_EXCEL_VALIDATION,
  FETCHING_BULKRELEASE_EXCEL_VALIDATION,
  BULKRELEASE_EXCEL_VALIDATION_FETCHED,
  BULKRELEASE_EXCEL_VALIDATION_FETCH_COMPLETE,
  BULKRELEASE_EXCEL_VALIDATION_FETCH_FAIL,
  INIT_BULKRELEASE_UPLOAD,
  FETCHING_BULKRELEASE_UPLOAD,
  BULKRELEASE_UPLOAD_FETCHED,
  BULKRELEASE_UPLOAD_FETCH_COMPLETE,
  BULKRELEASE_UPLOAD_FETCH_FAIL,
  INIT_BULKRELEASE_UPLOAD_FILE,
  FETCHING_BULKRELEASE_UPLOAD_FILE,
  BULKRELEASE_UPLOAD_FILE_FETCHED,
  BULKRELEASE_UPLOAD_FILE_FETCH_COMPLETE,
  BULKRELEASE_UPLOAD_FILE_FETCH_FAIL,
  INIT_BULKRELEASE_RELEASE_ALL,
  FETCHING_BULKRELEASE_RELEASE_ALL,
  BULKRELEASE_RELEASE_ALL_FETCHED,
  BULKRELEASE_RELEASE_ALL_FETCH_COMPLETE,
  BULKRELEASE_RELEASE_ALL_FETCH_FAIL,
} from "./actionTypes";

export const initBulkReleaseExcelValidation = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_EXCEL_VALIDATION,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseExcelValidation = () => {
  return {
    type: FETCHING_BULKRELEASE_EXCEL_VALIDATION,
  };
};
export const fetchBulkReleaseExcelValidation = (data) => {
  return {
    type: BULKRELEASE_EXCEL_VALIDATION_FETCHED,
    data,
  };
};
export const bulkReleaseExcelValidationComplete = () => {
  return {
    type: BULKRELEASE_EXCEL_VALIDATION_FETCH_COMPLETE,
  };
};
export const bulkReleaseExcelValidationFail = () => {
  return {
    type: BULKRELEASE_EXCEL_VALIDATION_FETCH_FAIL,
  };
};

export const initBulkReleaseUpload = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_UPLOAD,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseUpload = () => {
  return {
    type: FETCHING_BULKRELEASE_UPLOAD,
  };
};
export const fetchBulkReleaseUpload = (data) => {
  return {
    type: BULKRELEASE_UPLOAD_FETCHED,
    data,
  };
};
export const bulkReleaseUploadComplete = () => {
  return {
    type: BULKRELEASE_UPLOAD_FETCH_COMPLETE,
  };
};
export const bulkReleaseUploadFail = () => {
  return {
    type: BULKRELEASE_UPLOAD_FETCH_FAIL,
  };
};

export const initBulkReleaseUploadFile = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_UPLOAD_FILE,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseUploadFile = () => {
  return {
    type: FETCHING_BULKRELEASE_UPLOAD_FILE,
  };
};
export const fetchBulkReleaseUploadFile = (data) => {
  return {
    type: BULKRELEASE_UPLOAD_FILE_FETCHED,
    data,
  };
};
export const bulkReleaseUploadFileComplete = () => {
  return {
    type: BULKRELEASE_UPLOAD_FILE_FETCH_COMPLETE,
  };
};
export const bulkReleaseUploadFileFail = () => {
  return {
    type: BULKRELEASE_UPLOAD_FILE_FETCH_FAIL,
  };
};

export const initBulkReleaseReleaseAll = (payload, callback) => {
  return {
    type: INIT_BULKRELEASE_RELEASE_ALL,
    payload,
    callback,
  };
};
export const fetchingBulkReleaseReleaseAll = () => {
  return {
    type: FETCHING_BULKRELEASE_RELEASE_ALL,
  };
};
export const fetchBulkReleaseReleaseAll = (data) => {
  return {
    type: BULKRELEASE_RELEASE_ALL_FETCHED,
    data,
  };
};
export const bulkReleaseReleaseAllComplete = () => {
  return {
    type: BULKRELEASE_RELEASE_ALL_FETCH_COMPLETE,
  };
};
export const bulkReleaseReleaseAllFail = () => {
  return {
    type: BULKRELEASE_RELEASE_ALL_FETCH_FAIL,
  };
};
