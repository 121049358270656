import { fromJS } from "immutable";
import {
  FETCHING_AI_EXCEPTION_FILTER_COL_NAMES,
  AI_EXCEPTION_FILTER_COL_NAMES_FETCHED,
  AI_EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE,
  AI_EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL,
  FETCH_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS,
  ASSIGN_INITAL_DATA,
  FETCHING_AI_EXCEPTION_COUNT,
  FETCH_AI_EXCEPTION_COUNT,
  AI_EXCEPTION_COUNT_FETCHING_COMPLETE,
  FETCHING_AI_EXCEPTION_LIST,
  AI_EXCEPTION_LIST_FETCHED,
  AI_EXCEPTION_LIST_FETCH_COMPLETE,
  AI_EXCEPTION_LIST_FETCH_FAIL,
  FETCHING_AI_EXCEPTION_RECORD_LIST,
  FETCH_AI_EXCEPTION_RECORD_LIST,
  AI_EXCEPTION_RECORD_LIST_FETCHING_COMPLETE,
  FETCH_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES,
  APPLY_INITAL_AI_DUPLICATE_EXCEPTION_VALUES,
} from "./actionTypes";

export const initialState = fromJS({
  exceptionColumnList: [],
  fetchingExceptionColumns: false,
  fetchingExceptionCount: false,
  exceptionCount: 0,
  exceptionList: [],
  fetchingExceptionList: false,
  fetchingRecordHeader: false,
  recordHeader: [],
  fetchingRecordList: false,
  recordList: [],
  aiExceptionRightAlignedFieldsList: [],
  aiExceptionRecordHeaderList: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_AI_EXCEPTION_FILTER_COL_NAMES: {
      return state.set("fetchingExceptionColumns", true);
    }

    case AI_EXCEPTION_FILTER_COL_NAMES_FETCHED: {
      return state.set("exceptionColumnList", action.data);
    }

    case AI_EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE:
    case AI_EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL: {
      return state.set("fetchingExceptionColumns", false);
    }
    case FETCH_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS: {
      return state.set("aiExceptionRightAlignedFieldsList", action.data);
    }

    case ASSIGN_INITAL_DATA: {
      return state
        .set("fetchingExceptionColumns", false)
        .set("exceptionColumnList", []);
    }

    case FETCHING_AI_EXCEPTION_COUNT: {
      return state.set("fetchingExceptionCount", true);
    }

    case FETCH_AI_EXCEPTION_COUNT: {
      return state.set("exceptionCount", action.data);
    }

    case AI_EXCEPTION_COUNT_FETCHING_COMPLETE: {
      return state.set("fetchingExceptionCount", false);
    }

    case FETCHING_AI_EXCEPTION_LIST: {
      return state.set("fetchingExceptionList", true).set("exceptionList", []);
    }

    case AI_EXCEPTION_LIST_FETCHED: {
      return state.set("exceptionList", action.data);
    }

    case AI_EXCEPTION_LIST_FETCH_COMPLETE:
    case AI_EXCEPTION_LIST_FETCH_FAIL: {
      return state.set("fetchingExceptionList", false);
    }

    case FETCHING_AI_EXCEPTION_RECORD_LIST: {
      return state.set("fetchingRecordList", true);
    }

    case FETCH_AI_EXCEPTION_RECORD_LIST: {
      return state.set("recordList", action.data);
    }

    case AI_EXCEPTION_RECORD_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingRecordList", false);
    }

    case FETCH_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES: {
      return state.set("aiExceptionRecordHeaderList", action.data);
    }
    case APPLY_INITAL_AI_DUPLICATE_EXCEPTION_VALUES: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
