import React from "react";

const Lock = (props) => {
  return (
    <svg className="icons" viewBox="0 0 13 14">
      <g
        id="Login-Screen"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Administration---User-Management-manageuser-unlock"
          transform="translate(-1180.000000, -819.000000)"
          fill="#15181B"
        >
          <g id="Group-3" transform="translate(262.000000, 242.000000)">
            <g id="Group-20" transform="translate(24.000000, 80.000000)">
              <g id="Group-4" transform="translate(0.000000, 48.000000)">
                <g
                  id="Group-6-Copy-14"
                  transform="translate(878.000000, 432.000000)"
                >
                  <g
                    id="\-Common/02-Regular/lock"
                    transform="translate(15.500000, 17.000000)"
                  >
                    <path
                      d="M11.8125,5.25 L10.9375,5.25 L10.9375,3.97578124 C10.9375,1.79921874 9.18750001,0.00546873251 7.0109375,-1.26621538e-05 C4.83437499,-0.00546876751 3.06249999,1.76367186 3.06249999,3.93749999 L3.06249999,5.25 L2.18749999,5.25 C1.46289061,5.25 0.874999985,5.83789062 0.874999985,6.5625 L0.874999985,12.6875 C0.874999985,13.4121094 1.46289061,14 2.18749999,14 L11.8125,14 C12.5371094,14 13.125,13.4121094 13.125,12.6875 L13.125,6.5625 C13.125,5.83789062 12.5371094,5.25 11.8125,5.25 Z M4.37499999,3.93749999 C4.37499999,2.49101561 5.55351562,1.31249999 7,1.31249999 C8.44648438,1.31249999 9.62500001,2.49101561 9.62500001,3.93749999 L9.62500001,5.25 L4.37499999,5.25 L4.37499999,3.93749999 Z M11.8125,12.6875 L2.18749999,12.6875 L2.18749999,6.5625 L11.8125,6.5625 L11.8125,12.6875 Z"
                      id="Worfklow,-Status-&amp;-UI/02-Regular/lock"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Lock;
