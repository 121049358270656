import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

const Uploader = (props) => {
  const { t } = useTranslation();
  const handleEnterHit = ({ key }) => {
    if (key === "Enter") hiddenFileInput.current.click();
  };
  const hiddenFileInput = useRef(null);
  const handleFileUpload = (data) => {
    props.handleOnChange(data);
    hiddenFileInput.current.value = null;
  };
  return (
    <>
      <input
        ref={hiddenFileInput}
        className="file-uploader"
        type="file"
        id={props.id}
        hidden
        onChange={(data)=>handleFileUpload(data)}
        accept={props.acceptableExt}
      />
      <label
        tabIndex={0}
        className="primary-button uploader-btn"
        onKeyPress={handleEnterHit}
        htmlFor={props.id}
      >
        {t("atcat.chooseFile")}
      </label>
    </>
  );
};

export default Uploader;
