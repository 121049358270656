const Client = (props) => {
  return (
    <svg viewBox="0 0 26 26" className="icons">
      <title>Client Icon</title>
      <path d="M25.35,17.55 L23.4,17.55 L23.4,6.49999998 C23.4,5.06593748 22.23325,3.89999998 20.8,3.89999998 L5.19999998,3.89999998 C3.76674998,3.89999998 2.59999997,5.06593748 2.59999997,6.49999998 L2.59999997,17.55 L0.649999969,17.55 C0.290874968,17.55 -3.25000004e-08,17.840875 -3.25000004e-08,18.2 L-3.25000004e-08,20.15 C-3.25000004e-08,21.9419688 1.45762497,23.4 3.24999998,23.4 L22.75,23.4 C24.542375,23.4 26,21.9419688 26,20.15 L26,18.2 C26,17.840875 25.709125,17.55 25.35,17.55 Z M3.89999998,6.49999998 C3.89999998,5.78215623 4.48215623,5.19999998 5.19999998,5.19999998 L20.8,5.19999998 C21.5178438,5.19999998 22.1,5.78215623 22.1,6.49999998 L22.1,17.55 L15.8896563,17.55 C15.7247188,17.55 15.6044688,17.6771563 15.5679063,17.8380313 C15.4366875,18.4173438 14.9187188,18.85 14.3,18.85 L11.7,18.85 C11.0812812,18.85 10.5633125,18.4173438 10.4320937,17.8380313 C10.3955312,17.6771563 10.2752812,17.55 10.1103437,17.55 L3.89999998,17.55 L3.89999998,6.49999998 Z M24.7,20.15 C24.7,21.2253438 23.8253438,22.1 22.75,22.1 L3.24999998,22.1 C2.17465622,22.1 1.29999997,21.2253438 1.29999997,20.15 L1.29999997,18.85 L9.21171874,18.85 C9.47943749,19.6064375 10.2021562,20.15 11.05,20.15 L14.95,20.15 C15.79825,20.15 16.5205625,19.6064375 16.7882813,18.85 L24.7,18.85 L24.7,20.15 Z"></path>
    </svg>
  );
};

export default Client;
