import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import generateKey from "../../../config/generateKey";
import { ReportManagementGetReportList } from "../../../config/APINames";
import { INIT_GET_GENERAL_REPORTS_LIST } from "./actionTypes";
import { fetchGeneralReportsList } from "./actions";

export default function* watchGeneralReportList() {
  yield all([
    takeLatest(INIT_GET_GENERAL_REPORTS_LIST, initGetGeneralReportsListSaga),
  ]);
}

function* initGetGeneralReportsListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReportManagementGetReportList,
      requestDetails
    );
    if (response && response.status === 200) {
      if (response.data.resultStatus === true) {
        yield put(fetchGeneralReportsList(response.data.resultSet));
      } else {
        generateNotification(generateKey(response.data.message), "danger");
      }
    }
  } catch(error) {
    generateNotification(error, "danger");
  }
}
