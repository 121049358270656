import React from "react";
import PropTypes from "prop-types";
import Select from "../../SelectDropDowns/Select";
import Input from "../Input";
import { styles } from "./style.js";
import "./style.scss";

const DropdownInput = (props) => {
    const labelclass = `form-label  ${props.required ? `required` : ``}`;
    const wrapperclass = `dual-input-wrapper ${props.disabled ? `disabled` : ``}  ${
        props.showError ? `error` : ``
      }`;
  return (
    <>
      <div className={`dual ${wrapperclass}`}>
        <label htmlFor={props.label} className={labelclass}>
          {props.label}
        </label>
        <div className="dropdown-input">
          <Select {...props} customStyle={styles}/>

          <Input
            type={"text"}  
            {...props}
            ariaLabel={props.secondaryLabel}
            value={props.secondaryValue}    
            id={props.secondaryId}
            name={props.secondaryName}
            handleChange={props.handleChange}
            disabled={props.disableSecondary}
          />
          <label htmlFor={props.inputDescription} className={"sub-title"}>
            {props.inputDescription}
          </label>
        </div>
      </div>
    </>
  );
};
DropdownInput.propTypes ={
  type:PropTypes.string,
  placeholder:PropTypes.any,
  value:PropTypes.any,
  id:PropTypes.any,
  name:PropTypes.any,
  disabled:PropTypes.bool,
  readOnly:PropTypes.bool,
  handleClick:PropTypes.func,
  handleFocus:PropTypes.func,
  ariaLabel:PropTypes.string,
};
export default DropdownInput;
