import React, { useEffect, useState } from "react";
import Footer from "../../containers/Layout/Footer";
import Header from "../../containers/Layout/Header";
import MainContent from "./MainContent";
import Timer from "../../components/Timer";
import Navigation from "./Navigation";

import "./style.scss";
const Layout = (props) => {
  const nav = document.getElementById("nav");
  const [menuOpened, setMenuOpened] = useState(false);
  const [navWidth, setNavWidth] = useState(nav ? nav.offsetWidth : 0);
  const authHeader = props.isAuthenticated ? (
    <>
      <Header
        isOpen={menuOpened}
        onMenuToggle={() => setMenuOpened(!menuOpened)}
      />
      <Timer />
    </>
  ) : null;

  useEffect(() => {
    const nav = document.getElementById("nav");
    props.isAuthenticated
      ? setNavWidth(nav ? nav.offsetWidth : 0)
      : setNavWidth(0);
  }, [menuOpened, props.isAuthenticated]);

  return (
    <div className="layout">
      {authHeader}
      <main className="main" role="main">
        {props.isAuthenticated ? <Navigation isOpen={menuOpened} /> : null}
        <MainContent
          navWidth={navWidth}
          isAuthenticated={props.isAuthenticated}
        />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
