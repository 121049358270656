export const INIT_IPQC_BULKRELEASE_EXCEL_VALIDATION =
  "INIT_IPQC_BULKRELEASE_EXCEL_VALIDATION";
export const FETCHING_IPQC_BULKRELEASE_EXCEL_VALIDATION =
  "FETCHING_IPQC_BULKRELEASE_EXCEL_VALIDATION";
export const IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCHED =
  "IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCHED";
export const IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_COMPLETE =
  "IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_COMPLETE";
export const IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_FAIL =
  "IPQC_BULKRELEASE_EXCEL_VALIDATION_FETCH_FAIL";

export const INIT_IPQC_BULKRELEASE_UPLOAD = "INIT_IPQC_BULKRELEASE_UPLOAD";
export const FETCHING_IPQC_BULKRELEASE_UPLOAD = "FETCHING_IPQC_BULKRELEASE_UPLOAD";
export const IPQC_BULKRELEASE_UPLOAD_FETCHED = "IPQC_BULKRELEASE_UPLOAD_FETCHED";
export const IPQC_BULKRELEASE_UPLOAD_FETCH_COMPLETE =
  "IPQC_BULKRELEASE_UPLOAD_FETCH_COMPLETE";
export const IPQC_BULKRELEASE_UPLOAD_FETCH_FAIL = "IPQC_BULKRELEASE_UPLOAD_FETCH_FAIL";

export const INIT_IPQC_BULKRELEASE_UPLOAD_FILE = "INIT_IPQC_BULKRELEASE_UPLOAD_FILE";
export const FETCHING_IPQC_BULKRELEASE_UPLOAD_FILE =
  "FETCHING_IPQC_BULKRELEASE_UPLOAD_FILE";
export const IPQC_BULKRELEASE_UPLOAD_FILE_FETCHED =
  "IPQC_BULKRELEASE_UPLOAD_FILE_FETCHED";
export const IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_COMPLETE =
  "IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_COMPLETE";
export const IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_FAIL =
  "IPQC_BULKRELEASE_UPLOAD_FILE_FETCH_FAIL";

export const INIT_IPQC_BULKRELEASE_RELEASE_ALL = "INIT_IPQC_BULKRELEASE_RELEASE_ALL";
export const FETCHING_IPQC_BULKRELEASE_RELEASE_ALL =
  "FETCHING_IPQC_BULKRELEASE_RELEASE_ALL";
export const IPQC_BULKRELEASE_RELEASE_ALL_FETCHED =
  "IPQC_BULKRELEASE_RELEASE_ALL_FETCHED";
export const IPQC_BULKRELEASE_RELEASE_ALL_FETCH_COMPLETE =
  "IPQC_BULKRELEASE_RELEASE_ALL_FETCH_COMPLETE";
export const IPQC_BULKRELEASE_RELEASE_ALL_FETCH_FAIL =
  "IPQC_BULKRELEASE_RELEASE_ALL_FETCH_FAIL";
