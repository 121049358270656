import React from "react";
import PropTypes from "prop-types";
import Input from "../Input";
import "./style.scss";

const IconInput = (props) => {
  const wrapperclass = `input-wrapper  ${props.disabled ? `disabled` : ``}`;
  return (
    <div className={wrapperclass}>
      <div className="icon-input">
        <Input
          type={props.type}
          ariaLabel={props.ariaLabel || props.label}
          placeholder={props.placeholder}
          value={props.value}
          id={props.id}
          name={props.name}
          disabled={props.disabled}
          readOnly={props.readOnly}
          {...props}
        />
        {props.iconLeft && (
          <button
            className="left-icon"
            onClick={props.onIconLeftClick}
            tabIndex={props.onIconLeftClick ? 0 : -1}
            aria-label={props.iconLeftAriaLabel}
          >
            {props.iconLeft}
          </button>
        )}
        {props.iconRight && (
          <button
            className="right-icon"
            onClick={props.onIconRightClick}
            tabIndex={props.onIconRightClick ? 0 : -1}
            aria-label={props.iconRightAriaLabel}
          >
            {props.iconRight}
          </button>
        )}
      </div>
    </div>
  );
};

IconInput.propTypes ={
  type:PropTypes.string,
  placeholder:PropTypes.any,
  value:PropTypes.any,
  id:PropTypes.any,
  name:PropTypes.any,
  disabled:PropTypes.bool,
  readOnly:PropTypes.bool,
  handleClick:PropTypes.func,
  handleFocus:PropTypes.func,
  ariaLabel:PropTypes.string,
  iconLeftt:PropTypes.any,
  iconLeftAriaLabel:PropTypes.string,
  onIconLeftClick:PropTypes.func,
  iconRight:PropTypes.any,
  iconRightAriaLabel:PropTypes.string,
  onIconRightClick:PropTypes.func,
};

export default IconInput;
