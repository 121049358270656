import {
    INIT_LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESSFULL,
    VALIDATE_OTP,
    INIT_LOGIN_PROCESS,
    INIT_FORGOT_PASSWORD,
    HANDLE_FORGOT_PASSWORD,
    UPDATE_AUTH_DETAILS,
    INIT_LOGOUT,
    LOGOUT,
    SSO_CALLBACK_AUTH,
    FETCH_APPLICATION_NAME,
    INIT_APPLICATION_NAME,
    INIT_NBCU_BASIC_AUTH,
    INIT_NBCU_CLIENT_TOKEN_DETAILS,
} from "./actionTypes";

export const initLogin = (payload, callback) => {
    return {
        type: INIT_LOGIN,
        payload,
        callback,
    };
};

export const loginSuccess = (data) => {
    return {
        type: LOGIN_SUCCESSFULL,
        data,
    };
};

export const loginFailed = (data) => {
    return {
        type: LOGIN_FAILED,
        data,
    };
};

export const validateOtp = (payload) => {
    return {
        type: VALIDATE_OTP,
        payload,
    };
};

export const handleLoginProcess = (payload, callback) => {
    return {
        type: INIT_LOGIN_PROCESS,
        payload,
        callback,
    };
};

export const initForgotPassword = (payload, callback) => {
    return {
        type: INIT_FORGOT_PASSWORD,
        payload,
        callback,
    };
};

export const handleForgotPassword = (payload, callback) => {
    return {
        type: HANDLE_FORGOT_PASSWORD,
        payload,
        callback,
    };
};

export const updateAuthDetails = (data) => {
    return {
        type: UPDATE_AUTH_DETAILS,
        data,
    };
};

export const initLogOut = (callback) => {
    return {
        type: INIT_LOGOUT,
        callback,
    };
};

export const logOut = () => {
    return {
        type: LOGOUT,
    };
};

export const ssoCallbackAuth = (payload) => {
    return {
        type: SSO_CALLBACK_AUTH,
        payload,
    };
};

export const initapplicationName = () => {
    return {
        type: INIT_APPLICATION_NAME,
       
    };
};
export const fetchapplicationName = (data) => {
    return {
        type: FETCH_APPLICATION_NAME,
        data,
    };
};

export const initNBCUBasicAuth = (payload, callback) => {
    return {
        type: INIT_NBCU_BASIC_AUTH,
        payload,
        callback,
    };
};

export const initNBCUClientTokenDetails = (payload, callback) => {
    return {
        type: INIT_NBCU_CLIENT_TOKEN_DETAILS,
        payload,
        callback,
    };
};


