import { fromJS } from "immutable";
import {
  FETCHING_CLAIM_FILTER_COL_NAMES,
  CLAIM_FILTER_COL_NAMES_FETCHED,
  CLAIM_FILTER_COL_NAMES_FETCH_COMPLETE,
  CLAIM_FILTER_COL_NAMES_FETCH_FAIL,
  ASSIGN_INITAL_DATA,
  INIT_DUPLICATE_CLAIM_LIST,
  FETCH_DUPLICATE_CLAIMS_LIST,
  FETCH_CLAIM_DETAILS,
  FETCH_USER_LIST,
  FETCH_SEARCH_DUPLICATECLAIM_DETAILS,
  FETCH_DUPLICATECLAIM_VENDOR_LETTER,
  FETCH_DUPLICATECLAIM_PAYBACK_RECORDS,
  FETCH_DUPLICATECLAIM_PAYBACK_DETAILS,
  FETCH_DUPLICATECLAIM_EXCEPTION_RECORDS,
  FETCH_VENDOR_LIST_FROM_EXCEPTION,
  FETCH_VENDOR_FILTER_FIELDS,
  FETCH_VENDOR_LIST_FROM_EXCEPTION_COUNT,
  FETCH_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS,
  FETCH_VENDOR_LIST_DATA,
  FETCH_VENDOR_LIST_COUNT,
  FETCH_OFFSET_RECORDS,
  FETCH_OFFSET_TYPE,
  FETCH_OFFSET_DETAILS,
  FETCH_MANUAL_CLAIMS_HISTORY,
  APPLY_INITAL_DUPLICATE_CLAIM_DATA,
  FETCHING_CLAIM_DETAILS,
  FETCHING_CLAIM_DETAILS_COMPLETE,
  FETCHING_CLAIM_DETAILS_FAIL,
} from "./actionTypes";
import {
  FETCH_INVESTIGATION_TYPE,
  FETCH_DOCUMENTS_LIST,
} from "../../DuplicateException/store/actionTypes";

export const initialState = fromJS({
  claimColumnList: [],
  fetchingClaimColumns: false,
  duplicateClaimsList: {},
  claimDetails: {},
  userList: [],
  vendorLetter: {},
  paybackRecords: [],
  paybackDetails: [],
  exceptionRecords: [],
  vendorListFromException: {},
  vendorFilterFields: [],
  vendorListFromExceptionCount: {},
  actionsExceptionRec: {},
  vendorListData: {},
  vendorListCount: {},
  offsetRecords: [],
  offsetType: {},
  offsetDetails: [],
  manualClaimHistory: {},
  fetchingClaimDetails: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_CLAIM_FILTER_COL_NAMES: {
      return state.set("fetchingClaimColumns", true);
    }
    case INIT_DUPLICATE_CLAIM_LIST: {
      return state.set("fetchingClaimColumns", true);
    }
    case CLAIM_FILTER_COL_NAMES_FETCHED: {
      return state.set("claimColumnList", action.data);
    }
    case CLAIM_FILTER_COL_NAMES_FETCH_COMPLETE:
    case CLAIM_FILTER_COL_NAMES_FETCH_FAIL: {
      return state.set("fetchingClaimColumns", false);
    }

    case ASSIGN_INITAL_DATA: {
      return state
        .set("fetchingClaimColumns", false)
        .set("claimColumnList", []);
    }

    case FETCH_DUPLICATE_CLAIMS_LIST: {
      return state
        .set("duplicateClaimsList", action.data)
        .set("fetchingClaimColumns", false);
    }
    case FETCHING_CLAIM_DETAILS: {
      return state.set("fetchingClaimDetails", true);
    }
    case FETCH_CLAIM_DETAILS: {
      return state.set("claimDetails", action.data);
    }
    case FETCHING_CLAIM_DETAILS_COMPLETE:
    case FETCHING_CLAIM_DETAILS_FAIL:  {
      return state.set("fetchingClaimDetails", false);
    }

    
    case FETCH_USER_LIST: {
      return state.set("userList", action.data);
    }

    case FETCH_SEARCH_DUPLICATECLAIM_DETAILS: {
      return state.set("searchDetails", action.data);
    }

    case FETCH_DUPLICATECLAIM_VENDOR_LETTER: {
      return state.set("vendorLetter", action.data);
    }

    case FETCH_DUPLICATECLAIM_PAYBACK_RECORDS: {
      return state.set("paybackRecords", action.data);
    }
    case FETCH_DUPLICATECLAIM_PAYBACK_DETAILS: {
      return state.set("paybackDetails", action.data);
    }
    case FETCH_INVESTIGATION_TYPE: {
      return state.set("udlDetails", action.data);
    }

    case FETCH_DOCUMENTS_LIST: {
      return state.set("claimsDocumentsList", action.data);
    }
    case FETCH_DUPLICATECLAIM_EXCEPTION_RECORDS: {
      return state.set("exceptionRecords", action.data);
    }
    case FETCH_VENDOR_LIST_FROM_EXCEPTION: {
      return state.set("vendorListFromException", action.data);
    }
    case FETCH_VENDOR_FILTER_FIELDS: {
      return state.set("vendorFilterFields", action.data);
    }
    case FETCH_VENDOR_LIST_FROM_EXCEPTION_COUNT: {
      return state.set("vendorListFromExceptionCount", action.data);
    }
    case FETCH_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS: {
      return state.set("actionsExceptionRec", action.data);
    }
    case FETCH_VENDOR_LIST_DATA: {
      return state.set("vendorListData", action.data);
    }
    case FETCH_VENDOR_LIST_COUNT: {
      return state.set("vendorListCount", action.data);
    }
    case FETCH_OFFSET_RECORDS: {
      return state.set("offsetRecords", action.data);
    }

    case FETCH_OFFSET_TYPE: {
      return state.set("offsetType", action.data);
    }
    case FETCH_OFFSET_DETAILS: {
      return state.set("offsetDetails", action.data);
    }
    case FETCH_MANUAL_CLAIMS_HISTORY: {
      return state.set("manualClaimHistory", action.data);
    }
    case APPLY_INITAL_DUPLICATE_CLAIM_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
