import { fromJS } from "immutable";
import {
  FETCHING_EXCEPTION_FILTER_COL_NAMES,
  EXCEPTION_FILTER_COL_NAMES_FETCHED,
  EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE,
  EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL,
  ASSIGN_INITAL_DATA,
  FETCHING_EXCEPTION_COUNT,
  FETCH_EXCEPTION_COUNT,
  EXCEPTION_COUNT_FETCHING_COMPLETE,
  FETCHING_EXCEPTION_LIST,
  EXCEPTION_LIST_FETCHED,
  EXCEPTION_LIST_FETCH_COMPLETE,
  EXCEPTION_LIST_FETCH_FAIL,
  FETCHING_INVESTIGATION_TYPE,
  FETCH_INVESTIGATION_TYPE,
  INVESTIGATION_TYPE_FETCHING_COMPLETE,
  FETCH_USERMANGEMENT_GET_USERS_LIST,
  FETCH_DUPS_TYPE,
  FETCHING_DUPS_TYPE,
  DUPS_TYPE_FETCHING_COMPLETE,
  FETCH_USER_ROLE_BASED_ACTION,
  FETCHING_EXCEPTION_RECORD_HEADER,
  FETCH_EXCEPTION_RECORD_HEADER,
  EXCEPTION_RECORD_HEADER_FETCHING_COMPLETE,
  FETCHING_EXCEPTION_RECORD_LIST,
  FETCH_EXCEPTION_RECORD_LIST,
  EXCEPTION_RECORD_LIST_FETCHING_COMPLETE,
  FETCHING_DIARY_LIST,
  FETCH_DIARY_LIST,
  DIARY_LIST_FETCHING_COMPLETE,
  FETCH_DOCUMENTS_LIST,
  FETCHING_DIARY_TYPE,
  FETCH_DIARY_TYPE,
  DIARY_TYPE_FETCHING_COMPLETE,
  INIT_SAVE_ADD_DIARY,
  ADD_DIARY_SAVE_FAILED,
  ADD_DIARY_SAVE_SUCCESS,
  FETCHING_DIARY_DETAILS,
  DIARY_DETAILS_FETCH_COMPLETE,
  FETCHING_DELETE_DOCUMENT,
  DELETE_DOCUMENT_FETCH_COMPLETE,
  INIT_UPDATE_DIARY_DETAILS,
  DIARY_DETAILS_UPDATE_FAILED,
  DIARY_DETAILS_UPDATE_SUCCESS,
  FETCH_HISTORY_COUNT,
  FETCH_HISTORY_DATA,
  INIT_MARK_DIARY_COMPLETE,
  MARK_DIARY_COMPLETE_SUCCESS,
  MARK_DIARY_COMPLETE_FAILED,
  INIT_UNMARK_DIARY_COMPLETE,
  UNMARK_DIARY_COMPLETE_FAILED,
  UNMARK_DIARY_COMPLETE_SUCCESS,
  FETCH_BULK_RELEASE_REASON_TYPE,
  APPLY_INITAL_DUPLICATE_EXCEPTION_VALUES,
} from "./actionTypes";

export const initialState = fromJS({
  exceptionColumnList: [],
  fetchingExceptionColumns: false,
  fetchingExceptionCount: false,
  exceptionCount: 0,
  exceptionList: [],
  fetchingExceptionList: false,
  fetchUserRoleBasedAction: {},
  fetchingRecordHeader: false,
  recordHeader: [],
  fetchingRecordList: false,
  recordList: [],
  fetchingDiaryList: false,
  diaryList: [],
  documentsList: [],
  savingAddDiary: false,
  fetchingDeleteDocument: false,
  updatingDiaryDetails: false,
  markDiaryComplete: false,
  unmarkDiaryComplete: false,
  bulkReleaseReasonType: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_EXCEPTION_FILTER_COL_NAMES: {
      return state.set("fetchingExceptionColumns", true);
    }
    case EXCEPTION_FILTER_COL_NAMES_FETCHED: {
      return state.set("exceptionColumnList", action.data);
    }
    case EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE:
    case EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL: {
      return state.set("fetchingExceptionColumns", false);
    }

    case ASSIGN_INITAL_DATA: {
      return state
        .set("fetchingExceptionColumns", false)
        .set("exceptionColumnList", []);
    }

    case FETCHING_EXCEPTION_COUNT: {
      return state.set("fetchingExceptionCount", true);
    }
    case FETCH_EXCEPTION_COUNT: {
      return state.set("exceptionCount", action.data);
    }

    case EXCEPTION_COUNT_FETCHING_COMPLETE: {
      return state.set("fetchingExceptionCount", false);
    }

    case FETCHING_EXCEPTION_LIST: {
      return state.set("fetchingExceptionList", true).set("exceptionList", []);
    }
    case EXCEPTION_LIST_FETCHED: {
      return state.set("exceptionList", action.data);
    }
    case EXCEPTION_LIST_FETCH_COMPLETE:
    case EXCEPTION_LIST_FETCH_FAIL: {
      return state.set("fetchingExceptionList", false);
    }

    case FETCHING_INVESTIGATION_TYPE: {
      return state.set("fetchingInvestigationType", true);
    }
    case FETCH_INVESTIGATION_TYPE: {
      return state.set("investigationType", action.data);
    }
    case INVESTIGATION_TYPE_FETCHING_COMPLETE: {
      return state.set("fetchingInvestigationType", false);
    }
    case FETCH_USERMANGEMENT_GET_USERS_LIST: {
      return state.set("fetchUsermangementUsersList", action.data);
    }

    case FETCHING_DUPS_TYPE: {
      return state.set("fetchingDupsType", true);
    }
    case FETCH_DUPS_TYPE: {
      return state.set("dupsType", action.data);
    }
    case DUPS_TYPE_FETCHING_COMPLETE: {
      return state.set("fetchingDupsType", false);
    }
    case FETCH_USER_ROLE_BASED_ACTION: {
      return state.set("fetchUserRoleBasedAction", action.data);
    }

    case FETCHING_EXCEPTION_RECORD_HEADER: {
      return state.set("fetchingRecordHeader", true);
    }
    case FETCH_EXCEPTION_RECORD_HEADER: {
      return state.set("recordHeader", action.data);
    }
    case EXCEPTION_RECORD_HEADER_FETCHING_COMPLETE: {
      return state.set("fetchingRecordHeader", false);
    }

    case FETCHING_EXCEPTION_RECORD_LIST: {
      return state.set("fetchingRecordList", true);
    }
    case FETCH_EXCEPTION_RECORD_LIST: {
      return state.set("recordList", action.data);
    }
    case EXCEPTION_RECORD_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingRecordList", false);
    }

    case FETCHING_DIARY_LIST: {
      return state.set("fetchingDiaryList", true);
    }
    case FETCH_DIARY_LIST: {
      return state.set("diaryList", action.data);
    }
    case DIARY_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingDiaryList", false);
    }
    case FETCH_DOCUMENTS_LIST: {
      return state.set("documentsList", action.data);
    }

    case FETCHING_DIARY_TYPE: {
      return state.set("fetchingDiaryType", true);
    }
    case FETCH_DIARY_TYPE: {
      return state.set("diaryType", action.data);
    }
    case DIARY_TYPE_FETCHING_COMPLETE: {
      return state.set("fetchingDiaryType", false);
    }

    case INIT_SAVE_ADD_DIARY: {
      return state.set("savingAddDiary", true);
    }

    case ADD_DIARY_SAVE_SUCCESS: {
      return state.set("savingAddDiary", false);
    }

    case ADD_DIARY_SAVE_FAILED: {
      return state.set("savingAddDiary", false);
    }

    case FETCHING_DIARY_DETAILS: {
      return state.set("fetchingDiaryDetails", true);
    }
    case DIARY_DETAILS_FETCH_COMPLETE: {
      return state.set("fetchingDiaryDetails", false);
    }
    case FETCHING_DELETE_DOCUMENT: {
      return state.set("fetchingDeleteDocument", true);
    }
    case DELETE_DOCUMENT_FETCH_COMPLETE: {
      return state.set("fetchingDeleteDocument", false);
    }

    case INIT_UPDATE_DIARY_DETAILS: {
      return state.set("updatingDiaryDetails", true);
    }

    case DIARY_DETAILS_UPDATE_SUCCESS: {
      return state.set("updatingDiaryDetails", false);
    }

    case DIARY_DETAILS_UPDATE_FAILED: {
      return state.set("updatingDiaryDetails", false);
    }
    case FETCH_HISTORY_COUNT: {
      return state.set("historyCount", action.data);
    }
    case FETCH_HISTORY_DATA: {
      return state.set("historyData", action.data);
    }

    case INIT_MARK_DIARY_COMPLETE: {
      return state.set("markDiaryComplete", true);
    }

    case MARK_DIARY_COMPLETE_SUCCESS: {
      return state.set("markDiaryComplete", false);
    }

    case MARK_DIARY_COMPLETE_FAILED: {
      return state.set("markDiaryComplete", false);
    }

    case INIT_UNMARK_DIARY_COMPLETE: {
      return state.set("unmarkDiaryComplete", true);
    }

    case UNMARK_DIARY_COMPLETE_SUCCESS: {
      return state.set("unmarkDiaryComplete", false);
    }

    case UNMARK_DIARY_COMPLETE_FAILED: {
      return state.set("unmarkDiaryComplete", false);
    }
    case FETCH_BULK_RELEASE_REASON_TYPE:
      return state.set("bulkReleaseReasonType", action.data);
    case APPLY_INITAL_DUPLICATE_EXCEPTION_VALUES: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
