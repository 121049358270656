export const styles = {
  container: {
    maxWidth: "9.875rem",
    minWidth:"9.875rem"
  },
  control: {
    minHeight: "1rem",
    maxHeight: "1.5rem",
    borderRadius: ".25rem",
  },
  valueContainer: {
    padding: 0,
    paddingLeft: ".5rem",
    position: "relative",
    overflow: "hidden",
  },
  dropdownIndicator: {
    padding: 0,
    paddingRight: ".25rem",
  },
};
