export const INIT_BULKRELEASEAI_EXCEL_VALIDATION =
  "INIT_BULKRELEASEAI_EXCEL_VALIDATION";
export const FETCHING_BULKRELEASEAI_EXCEL_VALIDATION =
  "FETCHING_BULKRELEASEAI_EXCEL_VALIDATION";
export const BULKRELEASEAI_EXCEL_VALIDATION_FETCHED =
  "BULKRELEASEAI_EXCEL_VALIDATION_FETCHED";
export const BULKRELEASEAI_EXCEL_VALIDATION_FETCH_COMPLETE =
  "BULKRELEASEAI_EXCEL_VALIDATION_FETCH_COMPLETE";
export const BULKRELEASEAI_EXCEL_VALIDATION_FETCH_FAIL =
  "BULKRELEASEAI_EXCEL_VALIDATION_FETCH_FAIL";

export const INIT_BULKRELEASE_DUPS_UPLOAD = "INIT_BULKRELEASE_DUPS_UPLOAD";
export const FETCHING_BULKRELEASE_DUPS_UPLOAD = "FETCHING_BULKRELEASE_DUPS_UPLOAD";
export const BULKRELEASE_DUPS_UPLOAD_FETCHED = "BULKRELEASE_DUPS_UPLOAD_FETCHED";
export const BULKRELEASE_DUPS_UPLOAD_FETCH_COMPLETE =
  "BULKRELEASE_DUPS_UPLOAD_FETCH_COMPLETE";
export const BULKRELEASE_DUPS_UPLOAD_FETCH_FAIL = "BULKRELEASE_DUPS_UPLOAD_FETCH_FAIL";

export const INIT_BULKRELEASE_DUPS_UPLOAD_FILE = "INIT_BULKRELEASE_DUPS_UPLOAD_FILE";
export const FETCHING_BULKRELEASE_DUPS_UPLOAD_FILE =
  "FETCHING_BULKRELEASE_DUPS_UPLOAD_FILE";
export const BULKRELEASE_DUPS_UPLOAD_FILE_FETCHED =
  "BULKRELEASE_DUPS_UPLOAD_FILE_FETCHED";
export const BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_COMPLETE =
  "BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_COMPLETE";
export const BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_FAIL =
  "BULKRELEASE_DUPS_UPLOAD_FILE_FETCH_FAIL";

export const INIT_BULKRELEASE_DUPS_RELEASE_ALL = "INIT_BULKRELEASE_DUPS_RELEASE_ALL";
export const FETCHING_BULKRELEASE_DUPS_RELEASE_ALL =
  "FETCHING_BULKRELEASE_DUPS_RELEASE_ALL";
export const BULKRELEASE_DUPS_RELEASE_ALL_FETCHED =
  "BULKRELEASE_DUPS_RELEASE_ALL_FETCHED";
export const BULKRELEASE_DUPS_RELEASE_ALL_FETCH_COMPLETE =
  "BULKRELEASE_DUPS_RELEASE_ALL_FETCH_COMPLETE";
export const BULKRELEASE_DUPS_RELEASE_ALL_FETCH_FAIL =
  "BULKRELEASE_DUPS_RELEASE_ALL_FETCH_FAIL";

  export const INIT_BULKRELEASE_FNE_UPLOAD = "INIT_BULKRELEASE_FNE_UPLOAD";
  export const FETCHING_BULKRELEASE_FNE_UPLOAD = "FETCHING_BULKRELEASE_FNE_UPLOAD";
  export const BULKRELEASE_FNE_UPLOAD_FETCHED = "BULKRELEASE_FNE_UPLOAD_FETCHED";
  export const BULKRELEASE_FNE_UPLOAD_FETCH_COMPLETE =
    "BULKRELEASE_FNE_UPLOAD_FETCH_COMPLETE";
  export const BULKRELEASE_FNE_UPLOAD_FETCH_FAIL = "BULKRELEASE_FNE_UPLOAD_FETCH_FAIL";
  
  export const INIT_BULKRELEASE_FNE_UPLOAD_FILE = "INIT_BULKRELEASE_FNE_UPLOAD_FILE";
  export const FETCHING_BULKRELEASE_FNE_UPLOAD_FILE =
    "FETCHING_BULKRELEASE_FNE_UPLOAD_FILE";
  export const BULKRELEASE_FNE_UPLOAD_FILE_FETCHED =
    "BULKRELEASE_FNE_UPLOAD_FILE_FETCHED";
  export const BULKRELEASE_FNE_UPLOAD_FILE_FETCH_COMPLETE =
    "BULKRELEASE_FNE_UPLOAD_FILE_FETCH_COMPLETE";
  export const BULKRELEASE_FNE_UPLOAD_FILE_FETCH_FAIL =
    "BULKRELEASE_FNE_UPLOAD_FILE_FETCH_FAIL";
  
  export const INIT_BULKRELEASE_FNE_RELEASE_ALL = "INIT_BULKRELEASE_FNE_RELEASE_ALL";
  export const FETCHING_BULKRELEASE_FNE_RELEASE_ALL =
    "FETCHING_BULKRELEASE_FNE_RELEASE_ALL";
  export const BULKRELEASE_FNE_RELEASE_ALL_FETCHED =
    "BULKRELEASE_FNE_RELEASE_ALL_FETCHED";
  export const BULKRELEASE_FNE_RELEASE_ALL_FETCH_COMPLETE =
    "BULKRELEASE_FNE_RELEASE_ALL_FETCH_COMPLETE";
  export const BULKRELEASE_FNE_RELEASE_ALL_FETCH_FAIL =
    "BULKRELEASE_FNE_RELEASE_ALL_FETCH_FAIL";
