import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import exportToExcel from "../../../helper/exportToExcel";
import {
    PHListingFilterFields,
    PHListingAddAdvanceFilter,
    PHListingFilterName,
    ReVisitedDupsUnattendedDupsExceptionListHeaders,
    UserActionAuthenticationGetRoleBasedActions,
    PHListingDeleteAdvanceFilter,
    PHListingEditFilterPageData,
    PHListingPHListCount,
    PHListingPHList,
    PHListingExportToExcel,
    PHListingExportAllToExcel,
} from "../../../config/APINames";
import {
    INIT_FETCH_PH_FILTER_COL_NAMES,
    INIT_SAVE_PH_FILTER,
    INIT_FETCH_PH_FILTERS,
    INIT_FETCH_PH_LIST_HEADER_NAMES,
    INIT_FETCH_PH_ROLE_BASED_ACTIONS,
    INIT_DELETE_PH_FILTERS,
    INIT_PH_FILTER_FETCH,
    INIT_GET_PH_COUNT,
    INIT_FETCH_PH_LIST,
    INIT_GET_PH_RECORDS_EXPORT,
    INIT_GET_PH_RECORDS_EXPORT_ALL,
} from "./actionTypes";
import { Error_Msg } from "../../../config";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import { storage } from "../../../config/storage";
import {
    fetchColNames,
    fetchingPhColNames,
    phColNameFetchComplete,
    phColNameFetchFailed,
    fetchPhListHeaderNames,
    fetchingPhListHeaderNames,
    phListHeaderNameFetchComplete,
    phListHeaderNameFetchFailed,
    fetchPhRoleBasedActions,
    fetchingPhRoleBasedActions,
    phRoleBasedActionsFetchComplete,
    phRoleBasedActionsFetchFailed,
    fetchingPhCount,
    fetchPhCount,
    phCountFetchingComplete,
    fetchPhList,
    fetchingPhList,
    phListFetchComplete,
    phListFetchFailed,
    fetchingPhRecodsExport,
    fetchPhRecordsExport,
    phRecordsExportFetchingComplete,
    fetchingPhRecodsExportAll,
    fetchPhRecordsExportAll,
    phRecordsExportAllFetchingComplete,
} from "./actions";

export default function* watchLogin() {
    yield all([
        takeLatest(INIT_FETCH_PH_FILTER_COL_NAMES, initGetPhColNamesSaga),
        takeLatest(INIT_SAVE_PH_FILTER, initSavePHFilterSaga),
        takeLatest(INIT_FETCH_PH_FILTERS, initGetFilterNamesSaga),
        takeLatest(INIT_FETCH_PH_LIST_HEADER_NAMES, initGetPhListHeaderNamesSaga),
        takeLatest(INIT_FETCH_PH_ROLE_BASED_ACTIONS, initGetRoleBasedActionsSaga),
        takeLatest(INIT_DELETE_PH_FILTERS, initDeleteFilterDetailsSaga),
        takeLatest(INIT_PH_FILTER_FETCH, initGetFilterDetailsSaga),
        takeLatest(INIT_GET_PH_COUNT, initGetPhCountSaga),
        takeLatest(INIT_FETCH_PH_LIST, initPhListFetchSaga),
        takeLatest(INIT_GET_PH_RECORDS_EXPORT, initGetPhRecordsExportSaga),
        takeLatest(INIT_GET_PH_RECORDS_EXPORT_ALL, initGetPhRecordsExportAllSaga),
    ]);
}
function* initGetPhRecordsExportSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName } = sessionDetails || {};
    yield put(fetchingPhRecodsExport());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientname: clientName.toLowerCase(),
            userName,
        });
        const response = yield call(axios, PHListingExportToExcel, requestDetails);
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchPhRecordsExport(response.data));
            exportToExcel(response.data.dt, "PaymentHistory", response.data.exportfileName);
        }

        yield put(phRecordsExportFetchingComplete());
    } catch (error) {
        yield put(phRecordsExportFetchingComplete());
    }
}

function* initGetPhRecordsExportAllSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName } = sessionDetails || {};
    yield put(fetchingPhRecodsExportAll());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientname: clientName.toLowerCase(),
            userName,
        });
        const response = yield call(
            axios,
            PHListingExportAllToExcel,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response.data !== Error_Msg) {
            yield put(fetchPhRecordsExportAll(response.data));
            exportToExcel(response.data.dt, "PaymentHistory", response.data.exportfileName);
        }
        yield put(phRecordsExportAllFetchingComplete());
    } catch (error) {
        yield put(phRecordsExportAllFetchingComplete());
    }
}

function* initGetFilterNamesSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, sourceDomain, clientName, userId = "" } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName,
            userId,
            userName,
            sourceDomain,
        });
        const response = yield call(axios, PHListingFilterName, requestDetails);
        if (action.callback) action.callback(response);
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initDeleteFilterDetailsSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, clientName } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            userName,
            clientName,
        });
        const response = yield call(
            axios,
            PHListingDeleteAdvanceFilter,
            requestDetails
        );
        if (action.callback) action.callback(response);
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetPhColNamesSaga(action) {
    const { payload } = action || {};
    yield put(fetchingPhColNames());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(axios, PHListingFilterFields, requestDetails);
        if (response.data === Error_Msg) {
            const notification = buildNotification({
                message: response.data,
                type: "danger",
            });
            store.addNotification({
                ...notification,
            });
        } else if (response !== Error_Msg) {
            yield put(fetchColNames(response.data));
            yield put(phColNameFetchComplete());
        } else {
            yield put(phColNameFetchFailed());
        }
    } catch (error) {
        yield put(phColNameFetchFailed());
    }
}

function* initSavePHFilterSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, sourceDomain: domainId, clientName, userId = "" } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            UserId: userId,
            userName,
            domainId,
            clientName,
        });
        const response = yield call(
            axios,
            PHListingAddAdvanceFilter,
            requestDetails
        );
        if (response.data === Error_Msg) {
            const notification = buildNotification({
                message: response.data,
                type: "danger",
            });
            store.addNotification({
                ...notification,
            });
        } else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetPhListHeaderNamesSaga(action) {
    const { payload } = action || {};
    yield put(fetchingPhListHeaderNames());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ReVisitedDupsUnattendedDupsExceptionListHeaders,
            requestDetails
        );
        if (response.data === Error_Msg) {
            const notification = buildNotification({
                message: response.data,
                type: "danger",
            });
            store.addNotification({
                ...notification,
            });
        } else if (response !== Error_Msg) {
            yield put(fetchPhListHeaderNames(response.data));
            yield put(phListHeaderNameFetchComplete());
        } else {
            yield put(phListHeaderNameFetchFailed());
        }
    } catch (error) {
        yield put(phListHeaderNameFetchFailed());
    }
}

function* initGetRoleBasedActionsSaga(action) {
    const { payload } = action || {};
    yield put(fetchingPhRoleBasedActions());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            UserActionAuthenticationGetRoleBasedActions,
            requestDetails
        );
        if (response && response.status === 200) {
            yield put(fetchPhRoleBasedActions(response.data));
        }
        yield put(phRoleBasedActionsFetchComplete());
    } catch (error) {
        yield put(phRoleBasedActionsFetchFailed());
    }
}

function* initGetFilterDetailsSaga(action) {
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { userName, sourceDomain, clientName, userId = "" } = sessionDetails || {};
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            UserId: userId,
            userName,
            sourceDomain,
            clientName,
        });
        const response = yield call(
            axios,
            PHListingEditFilterPageData,
            requestDetails
        );
        if (action.callback) action.callback(response);
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}

function* initGetPhCountSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName } = sessionDetails || {};
    yield put(fetchingPhCount());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            userName,
            clientName: clientName.toLowerCase(),
        });
        const response = yield call(axios, PHListingPHListCount, requestDetails);
        if (response && response.status === 200) {
            yield put(fetchPhCount(response.data));
        }
        yield put(phCountFetchingComplete());
    } catch (error) {
        yield put(phCountFetchingComplete());
    }
}

function* initPhListFetchSaga(action) {
    const { payload } = action || {};
    const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
    const { clientName, userName, clientId } = sessionDetails || {};
    yield put(fetchingPhList());
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
            clientName,
            userName,
            ClientID: clientId,
        });
        const response = yield call(axios, PHListingPHList, requestDetails);
        if (response && response.status === 200) {
            yield put(fetchPhList(response.data));
            yield put(phListFetchComplete());
        } else {
            yield put(phListFetchFailed());
        }
    } catch (error) {
        yield put(phListFetchFailed());
    }
}
