import {
  INIT_SEQUENCE_FIELD_DETAILS,
  INIT_CRITERIA_FIELD,
  INIT_PROCESSING_DATA,
  FETCH_SEQUENCE_FIELD_DETAILS,
  FETCH_CRITERIA_FIELD,
  FETCH_PROCESSING_DATA,
  INIT_INITAL_STATE,
  INIT_EDIT_FILTER_PAGE,
  FETCH_EDIT_FILTER_PAGE,
  INIT_SEQUENCE_DETAILS,
  FETCH_SEQUENCE_DETAILS,
  INIT_SUPPLEMENTRY_CRITERIA_DETAILS,
  FETCH_SUPPLEMENTRY_CRITERIA_DETAILS,
  INIT_DELETE_SEQUENCE,
  INIT_SAVE_SUPPLEMENTARY_DETAILS,
  INIT_SAVE_SEQUENCE_REORDER,
  INIT_SAVE_EDIT_FILTER_CRITERIA,
  INIT_SAVE_ADD_SEQUENCE,
  INIT_SAVE_EDIT_SEQUENCE,
} from "./actionTypes";

export const initSequenceFieldDetails = (payload, callback) => {
  return {
    type: INIT_SEQUENCE_FIELD_DETAILS,
    payload,
    callback,
  };
};

export const initCriteriaField = (payload, callback) => {
  return {
    type: INIT_CRITERIA_FIELD,
    payload,
    callback,
  };
};

export const initProcessingData = (payload) => {
  return {
    type: INIT_PROCESSING_DATA,
    payload,
  };
};

export const fetchSequenceDetails = (data) => {
  return {
    type: FETCH_SEQUENCE_FIELD_DETAILS,
    data,
  };
};
export const fetchCriteriaField = (data) => {
  return {
    type: FETCH_CRITERIA_FIELD,
    data,
  };
};
export const fetchProcessingData = (data) => {
  return {
    type: FETCH_PROCESSING_DATA,
    data,
  };
};
export const initInitalState = () => {
  return {
    type: INIT_INITAL_STATE,
  };
};

export const initEditFilter = (payload, callback) => {
  return {
    type: INIT_EDIT_FILTER_PAGE,
    payload,
    callback,
  };
};

export const fetchEditFilterPage = (data) => {
  return {
    type: FETCH_EDIT_FILTER_PAGE,
    data,
  };
};

export const initSequenceDetails = (payload, callback) => {
  return {
    type: INIT_SEQUENCE_DETAILS,
    payload,
    callback,
  };
};

export const fetchSeqDetails = (data) => {
  return {
    type: FETCH_SEQUENCE_DETAILS,
    data,
  };
};

export const initSupplementryCriteriaDetails = (payload) => {
  return {
    type: INIT_SUPPLEMENTRY_CRITERIA_DETAILS,
    payload,
  };
};

export const fetchSupplementryCriteriaDetails = (data) => {
  return {
    type: FETCH_SUPPLEMENTRY_CRITERIA_DETAILS,
    data,
  };
};

export const initDeleteSequence = (payload, callback) => {
  return {
    type: INIT_DELETE_SEQUENCE,
    payload,
    callback,
  };
};

export const initSaveSupplementaryDetails = (payload, callback) => {
  return {
    type: INIT_SAVE_SUPPLEMENTARY_DETAILS,
    payload,
    callback,
  };
};

export const initSaveSequenceReorder = (payload, callback) => {
  return {
    type: INIT_SAVE_SEQUENCE_REORDER,
    payload,
    callback,
  };
};

export const initSaveFilterCriteria = (payload, callback) => {
  return {
    type: INIT_SAVE_EDIT_FILTER_CRITERIA,
    payload,
    callback,
  };
};

export const initSaveAddSequence = (payload, callback) => {
  return {
    type: INIT_SAVE_ADD_SEQUENCE,
    payload,
    callback,
  };
};

export const initSaveEditSequence = (payload, callback) => {
  return {
    type: INIT_SAVE_EDIT_SEQUENCE,
    payload,
    callback,
  };
};
