import React from "react";
import HomeMenuIcon from "../components/SVG/HomeMenuIcon";
import AdminMenuIcon from "../components/SVG/AdminMenuIcon";
import DataAnalysisIcon from "../components/SVG/DataAnalysis";
import HelpIcon from "../components/SVG/Help";
import ClaimsManagementIcon from "../components/SVG/ClaimsManagement";
import DashboardIcon from "../components/SVG/Dashboard";
import ExceptionManagementIcon from "../components/SVG/ExceptionManagement";
import DataLoadDupsIcon from "../components/SVG/DataLoadDupsIcon";
import IPQCIcon from "../components/SVG/IPQC";
const HomePage = React.lazy(() => import("../containers/Home"));
const ClientManagement = React.lazy(() =>
  import("../containers/ClientManagement")
);
const ManageUser = React.lazy(() => import("../containers/ManageUser"));
const ChangePassword = React.lazy(() => import("../containers/ChangePassword"));
const PasswordManagement = React.lazy(() =>
  import("../containers/PasswordManagement")
);
const SystemParameters = React.lazy(() =>
  import("../containers/SystemParameters")
);
const UDLMaintenance = React.lazy(() => import("../containers/UDLMaintenance"));
const Alerts = React.lazy(() => import("../containers/Alerts"));
const ManageGrid = React.lazy(() => import("../containers/ManageGrid"));
const SystemParametersClient = React.lazy(() =>
  import("../containers/SystemParametersClient")
);
const ClientQueueSummary = React.lazy(() =>
  import("../containers/ClientQueueSummary")
);
const QueuedJobs = React.lazy(() => import("../containers/QueuedJobs"));
const RunningJobs = React.lazy(() => import("../containers/RunningJobs"));
const CompletedJobs = React.lazy(() => import("../containers/CompletedJobs"));
const JobScheduler = React.lazy(() => import("../containers/JobScheduler"));
const AccountPayables = React.lazy(() =>
  import("../containers/AccountPayables")
);
const PaymentHistory = React.lazy(() => import("../containers/PaymentHistory"));
const APPHListing = React.lazy(() => import("../containers/APPHListing"));
const VendorsList = React.lazy(() => import("../containers/VendorsList"));
const VendorGroupsList = React.lazy(() =>
  import("../containers/VendorGroupsList")
);
const DuplicateException = React.lazy(() =>
  import("../containers/DuplicateException")
);
const AIDuplicateException = React.lazy(() =>
  import("../containers/AIDuplicateException")
);
const DuplicateClaim = React.lazy(() => import("../containers/DuplicateClaim"));
const AdhocDups = React.lazy(() => import("../containers/AdhocDups"));
const GeneralReports = React.lazy(() => import("../containers/GeneralReports"));
const VendorAnalysisReports = React.lazy(() =>
  import("../containers/VendorAnalysisReports")
);
const DataValidationReports = React.lazy(() =>
  import("../containers/DataValidationReports")
);
const RunDataLoadandDUPS = React.lazy(() =>
  import("../containers/RunDataLoadandDUPS")
);
const BulkRelease = React.lazy(() => import("../containers/BulkRelease"));
const AIBulkRelease = React.lazy(() => import("../containers/AIBulkRelease"));
const ProcessingCriteria = React.lazy(() =>
  import("../containers/ProcessingCriteria")
);
const Exceptions = React.lazy(() => import("../containers/Exceptions"));
const AIExceptions = React.lazy(() => import("../containers/AIExceptions"));
const FraudAndErrorDetection = React.lazy(() =>
  import("../containers/FraudAndErrorDetection")
);
const SetGlobalParameters = React.lazy(() =>
  import("../containers/SetGlobalParameters")
);
const DUPSClaims = React.lazy(() => import("../containers/DUPSClaims"));
const ManualClaims = React.lazy(() => import("../containers/ManualClaims"));
const ClaimsbyVendors = React.lazy(() =>
  import("../containers/ClaimsbyVendors")
);
const GeneralDashboard = React.lazy(() =>
  import("../containers/GeneralDashboard")
);
const FAQ = React.lazy(() => import("../containers/FAQ"));
const AIAdhocDups = React.lazy(() => import("../containers/AIAdhocDups"));
const IpqcPrioritization = React.lazy(() =>
  import("../containers/IPQCPrioritization")
);
const IPQCBulkRelease = React.lazy(() =>
  import("../containers/IPQCBulkRelease")
);
const CFODashboard = React.lazy(() => import("../containers/CFODashboard"));
const TrainingMaterials = React.lazy(() =>
  import("../containers/TrainingMaterials")
);
export const routes = [
  {
    title: "atcat.home",
    key: "Home",
    parentKey: 0,
    version: [0, 1, 2, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ServiceReader",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    icon: <HomeMenuIcon />,
    path: HomePage,
  },

  {
    title: "atcat.administration",
    key: "Administration",
    parentKey: 0,
    version: [0, 1, 2, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ServiceReader",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    icon: <AdminMenuIcon />,
  },

  {
    title: "atcat.clientManagement",
    key: "ClientManagement",
    parentKey: "Administration",
    version: [0, 1, 2, 3],
    sourceDomain: [0],
    role: ["SuperUser", "ServiceUser", "ServiceReader"],
    path: ClientManagement,
  },

  {
    title: "atcat.userManagement",
    key: "UserManagement",
    parentKey: "Administration",
    version: [0, 1, 2, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ServiceReader",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
  },

  {
    title: "atcat.manageUser",
    key: "ManageUser",
    parentKey: "UserManagement",
    version: [0, 1, 2, 3],
    sourceDomain: [0, 1],
    role: ["SuperUser", "ServiceUser", "ClientAdministrator"],
    path: ManageUser,
  },

  {
    title: "atcat.changePassword",
    key: "ChangePassword",
    parentKey: "UserManagement",
    version: [0],
    sourceDomain: [0],
    role: ["SuperUser", "ServiceUser", "ServiceReader"],
    path: ChangePassword,
  },

  {
    title: "atcat.changePassword",
    key: "ChangePasswordClient",
    parentKey: "UserManagement",
    version: [1, 2, 3],
    sourceDomain: [1],
    role: ["ClientAdministrator", "Supervisor", "Auditor", "Reader"],
    path: ChangePassword,
  },

  {
    title: "atcat.passwordManagement",
    key: "PasswordManagement",
    parentKey: "UserManagement",
    version: [1, 2, 3],
    sourceDomain: [0, 1],
    role: ["SuperUser", "ServiceUser", "ClientAdministrator"],
    path: PasswordManagement,
  },

  {
    title: "atcat.systemMaintenance",
    key: "SystemMaintenance",
    parentKey: "Administration",
    version: [0],
    sourceDomain: [0],
    role: ["SuperUser"],
  },

  {
    title: "atcat.systemParameters",
    key: "SystemParameters",
    parentKey: "SystemMaintenance",
    version: [0],
    sourceDomain: [0],
    role: ["SuperUser"],
    path: SystemParameters,
  },

  {
    title: "atcat.udlMaintenance",
    key: "UDLMaintenance",
    parentKey: "SystemMaintenance",
    version: [0],
    sourceDomain: [0],
    role: ["SuperUser"],
    path: UDLMaintenance,
  },
  {
    title: "atcat.alerts",
    key: "Alerts",
    parentKey: "SystemMaintenance",
    version: [0],
    sourceDomain: [0],
    role: ["SuperUser"],
    path: Alerts,
  },

  {
    title: "atcat.systemMaintenance",
    key: "SystemMaintenanceClient",
    parentKey: "Administration",
    version: [1, 2, 3],
    sourceDomain: [0, 1],
    role: ["SuperUser", "ServiceUser", "ClientAdministrator"],
  },

  {
    title: "atcat.manageGrid",
    key: "ManageGridClient",
    parentKey: "SystemMaintenanceClient",
    version: [1, 2, 3],
    sourceDomain: [0, 1],
    role: ["SuperUser", "ServiceUser", "ClientAdministrator"],
    path: ManageGrid,
  },

  {
    title: "atcat.systemParameters",
    key: "SystemParametersClient",
    parentKey: "SystemMaintenanceClient",
    version: [1, 2, 3],
    sourceDomain: [0, 1],
    role: ["SuperUser", "ServiceUser"],
    path: SystemParametersClient,
  },

  {
    title: "atcat.udlMaintenance",
    key: "UDLMaintenanceClient",
    parentKey: "SystemMaintenanceClient",
    version: [1, 2, 3],
    sourceDomain: [0, 1],
    role: ["SuperUser", "ServiceUser", "ClientAdministrator"],
    path: UDLMaintenance,
  },
  {
    title: "atcat.alerts",
    key: "AlertsClient",
    parentKey: "SystemMaintenanceClient",
    version: [1, 2, 3],
    sourceDomain: [0, 1],
    role: ["SuperUser", "ServiceUser", "ClientAdministrator"],
    path: Alerts,
  },

  {
    title: "atcat.jobManager",
    key: "JobManager",
    parentKey: "Administration",
    version: [0, 1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ServiceReader",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
  },

  {
    title: "atcat.clientQueueSummary",
    key: "ClientQueueSummary",
    parentKey: "JobManager",
    version: [0],
    sourceDomain: [0],
    role: ["SuperUser", "ServiceUser"],
    path: ClientQueueSummary,
  },
  {
    title: "atcat.queuedJobs",
    key: "QueuedJobs",
    parentKey: "JobManager",
    version: [0, 1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: QueuedJobs,
  },
  {
    title: "atcat.runningJobs",
    key: "RunningJobs",
    parentKey: "JobManager",
    version: [0, 1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: RunningJobs,
  },
  {
    title: "atcat.completedJobs",
    key: "CompletedJobs",
    parentKey: "JobManager",
    version: [0, 1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: CompletedJobs,
  },
  {
    title: "atcat.jobScheduler",
    key: "JobScheduler",
    parentKey: "JobManager",
    version: [0],
    sourceDomain: [0],
    role: ["SuperUser", "ServiceReader"],
    path: JobScheduler,
  },
  {
    title: "atcat.jobScheduler",
    key: "JobSchedulerClient",
    parentKey: "JobManager",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: JobScheduler,
  },

  {
    title: "atcat.dataAnalysis",
    key: "DataAnalysis",
    parentKey: 0,
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    icon: <DataAnalysisIcon />,
  },

  {
    title: "atcat.businessData",
    key: "BusinessData",
    parentKey: "DataAnalysis",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
  },

  {
    title: "atcat.accountPayables",
    key: "AccountPayables",
    parentKey: "BusinessData",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: AccountPayables,
  },
  {
    title: "atcat.paymentHistory",
    key: "PaymentHistory",
    parentKey: "BusinessData",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: PaymentHistory,
  },
  {
    title: "atcat.apPhListing",
    key: "APPHListing",
    parentKey: "BusinessData",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: APPHListing,
  },
  {
    title: "atcat.vendorsList",
    key: "VendorsList",
    parentKey: "BusinessData",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: VendorsList,
  },
  {
    title: "atcat.vendorGroupList",
    key: "VendorGroupsList",
    parentKey: "BusinessData",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: VendorGroupsList,
  },

  {
    title: "atcat.exceptionAndClaimAnalysis",
    key: "ExceptionandClaimAnalysis",
    parentKey: "DataAnalysis",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
  },

  {
    title: "atcat.duplicateException",
    key: "DuplicateException",
    parentKey: "ExceptionandClaimAnalysis",
    version: [1],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: DuplicateException,
  },

  {
    title: "atcat.duplicateException",
    key: "AIDuplicateException",
    parentKey: "ExceptionandClaimAnalysis",
    version: [3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: AIDuplicateException,
  },

  {
    title: "atcat.duplicateClaim",
    key: "DuplicateClaim",
    parentKey: "ExceptionandClaimAnalysis",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: DuplicateClaim,
  },

  {
    title: "atcat.adhocDups",
    key: "AdhocDups",
    parentKey: "DataAnalysis",
    version: [1],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: AdhocDups,
  },
  {
    title: "atcat.adhocDups",
    key: "AIAdhocDups",
    parentKey: "DataAnalysis",
    version: [3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: AIAdhocDups,
  },
  {
    title: "atcat.generalReports",
    key: "GeneralReports",
    parentKey: "DataAnalysis",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: GeneralReports,
  },

  {
    title: "atcat.vendorAnalysisReports",
    key: "VendorAnalysisReports",
    parentKey: "DataAnalysis",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: VendorAnalysisReports,
  },

  {
    title: "atcat.dataValidationReports",
    key: "DataValidationReports",
    parentKey: "DataAnalysis",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: DataValidationReports,
  },

  {
    title: "atcat.dataAnalysis",
    key: "DataAnalysisNI",
    parentKey: 0,
    version: [2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    icon: <DataAnalysisIcon />,
  },

  {
    title: "atcat.accountPayables",
    key: "AccountPayablesNI",
    parentKey: "DataAnalysisNI",
    version: [2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: AccountPayables,
  },

  {
    title: "atcat.paymentHistory",
    key: "PaymentHistoryNI",
    parentKey: "DataAnalysisNI",
    version: [2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: PaymentHistory,
  },

  {
    title: "atcat.duplicateException",
    key: "DuplicateExceptionNI",
    parentKey: "DataAnalysisNI",
    version: [2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: DuplicateException,
  },

  {
    title: "atcat.duplicateClaim",
    key: "DuplicateClaimNI",
    parentKey: "DataAnalysisNI",
    version: [2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: DuplicateClaim,
  },

  {
    title: "atcat.dataloadAndDups",
    key: "DataLoadandDUPS",
    parentKey: 0,
    version: [2],
    sourceDomain: [0, 1],
    role: ["SuperUser", "ServiceUser", "Supervisor"],
    icon: <DataLoadDupsIcon />,
  },

  {
    title: "atcat.runDataloadAndDups",
    key: "RunDataLoadandDUPS",
    parentKey: "DataLoadandDUPS",
    version: [2],
    sourceDomain: [0, 1],
    role: ["SuperUser", "ServiceUser", "Supervisor"],
    path: RunDataLoadandDUPS,
  },

  {
    title: "atcat.bulkRelease",
    key: "BulkRelease",
    parentKey: "DataLoadandDUPS",
    version: [2],
    sourceDomain: [0, 1],
    role: ["SuperUser", "ServiceUser", "Supervisor"],
    path: BulkRelease,
  },
  {
    title: "atcat.exceptionManagement",
    key: "ExceptionManagement",
    parentKey: 0,
    version: [1, 2, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    icon: <ExceptionManagementIcon />,
  },

  {
    title: "atcat.processingCriteria",
    key: "ProcessingCriteria",
    parentKey: "ExceptionManagement",
    version: [1, 2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: ProcessingCriteria,
  },

  {
    title: "atcat.exceptions",
    key: "Exceptions",
    parentKey: "ExceptionManagement",
    version: [1, 2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: Exceptions,
  },

  {
    title: "atcat.exceptions",
    key: "AIExceptions",
    parentKey: "ExceptionManagement",
    version: [3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: AIExceptions,
  },

  {
    title: "atcat.dupsClaims",
    key: "DUPSClaimsNI",
    parentKey: "ExceptionManagement",
    version: [2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: DUPSClaims,
  },

  {
    title: "atcat.fraudAndErrorDetection",
    key: "FraudAndErrorDetection",
    parentKey: "ExceptionManagement",
    version: [3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: FraudAndErrorDetection,
  },

  {
    title: "atcat.bulkRelease",
    key: "BulkRelease",
    parentKey: "ExceptionManagement",
    version: [1],
    sourceDomain: [1],
    role: ["ClientAdministrator", "Supervisor"],
    path: BulkRelease,
  },

  {
    title: "atcat.bulkRelease",
    key: "AIBulkRelease",
    parentKey: "ExceptionManagement",
    version: [3],
    sourceDomain: [1],
    role: ["ClientAdministrator", "Supervisor"],
    path: AIBulkRelease,
  },

  {
    title: "atcat.setGlobalParameters",
    key: "SetGlobalParameters",
    parentKey: "ExceptionManagement",
    version: [1, 3],
    sourceDomain: [1],
    role: ["ClientAdministrator"],
    path: SetGlobalParameters,
  },
  {
    title: "atcat.ipqc",
    key: "ipqc",
    parentKey: 0,
    version: [3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    icon: <IPQCIcon />,
  },
  {
    title: "atcat.ipqcPrioritization",
    key: "ipqcPrioritization",
    parentKey: "ipqc",
    version: [3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: IpqcPrioritization,
  },
  {
    title: "atcat.ipqcBulkRelease",
    key: "ipqcBulkRelease",
    parentKey: "ipqc",
    version: [3],
    sourceDomain: [0, 1],
    role: ["ClientAdministrator", "Supervisor"],
    path: IPQCBulkRelease,
  },
  {
    title: "atcat.claimsManagement",
    parentKey: 0,
    key: "ClaimsManagement",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    icon: <ClaimsManagementIcon />,
  },

  {
    title: "atcat.dupsClaims",
    key: "DUPSClaims",
    parentKey: "ClaimsManagement",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: DUPSClaims,
  },

  {
    title: "atcat.manualClaims",
    key: "ManualClaims",
    parentKey: "ClaimsManagement",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: ManualClaims,
  },

  {
    title: "atcat.claimsByVendors",
    key: "ClaimsbyVendors",
    parentKey: "ClaimsManagement",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: ClaimsbyVendors,
  },

  {
    title: "atcat.dashboard",
    key: "Dashboard",
    parentKey: 0,
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    icon: <DashboardIcon />,
  },

  {
    title: "atcat.generalDashboard",
    key: "GeneralDashboard",
    parentKey: "Dashboard",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: GeneralDashboard,
  },

  {
    title: "atcat.cfoDashboard",
    key: "CfoDashboard",
    parentKey: "Dashboard",
    version: [1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path:CFODashboard
  },

  {
    title: "atcat.dashboardAndReport",
    key: "DashboardandReport",
    parentKey: 0,
    version: [2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    icon: <DashboardIcon />,
  },

  {
    title: "atcat.generalDashboard",
    key: "GeneralDashboardNI",
    parentKey: "DashboardandReport",
    version: [2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: GeneralDashboard,
  },

  {
    title: "atcat.generalReports",
    key: "GeneralReportsNI",
    parentKey: "DashboardandReport",
    version: [2],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: GeneralReports,
  },

  {
    title: "atcat.cfoDashboard",
    key: "CfoDashboard",
    parentKey: "DashboardandReport",
    version: [2],
    sourceDomain: [0, 1],
    role: [],
    path: CFODashboard,
  },

  {
    title: "atcat.help",
    key: "Help",
    parentKey: 0,
    version: [0, 1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ServiceReader",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    icon: <HelpIcon />,
  },

  {
    title: "atcat.faq",
    key: "FAQ",
    parentKey: "Help",
    version: [0, 1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ServiceReader",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: FAQ,
  },

  {
    title: "atcat.trainingMaterials",
    key: "TrainingMaterials",
    parentKey: "Help",
    version: [0, 1, 3],
    sourceDomain: [0, 1],
    role: [
      "SuperUser",
      "ServiceUser",
      "ServiceReader",
      "ClientAdministrator",
      "Supervisor",
      "Auditor",
      "Reader",
    ],
    path: TrainingMaterials,
  },
];
