import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import {
  INIT_DASHBOARD_DATA,
  INIT_STARTDATE_ENDDATE_UI,
  INIT_DASHBOARD_AGEING,
  INIT_CLAIM_AGEING,
  INIT_SUPPLIER_DATA,
  INIT_AUDITOR_LIST,
  INIT_SUPPLIER_CLAIM_PRODUCTIVITY,
} from "./actionTypes";
import {
  ClientDashbaordGetDashboardData,
  ClientDashboardStartDateEndDateUI,
  ClientDashboardAgeingByOption,
  ClientDashboardClaimAgeingByOption,
  ClientDashboardSupplierByOption,
  ClientDashboardAuditorList,
  ClientDashboardSupplier2ByOption,
} from "../../../config/APINames";
import {
  fetchDashboardData,
  fetchClaimAgeing,
  fetchDashboardAgeing,
  fetchStartEndDateUI,
  fetchSupplierData,
  fetchAuditorList,
  fetchSupplierProductivity,
  fetchClaimAgeingNotification,
  fetchExceptionAgeingNotification,
  fetchSupplierDataByCount,
  fetchSupplierProductivityByCount,
} from "./actions";
import { Error_Msg } from "../../../config/index";
import { generateNotification } from "../../../helper/generateNotification";
export default function* watchGeneralDashboard() {
  yield all([
    takeLatest(INIT_DASHBOARD_DATA, initDashboardData),
    takeLatest(INIT_STARTDATE_ENDDATE_UI, initStartEndDate),
    takeLatest(INIT_DASHBOARD_AGEING, initAgeingDetails),
    takeLatest(INIT_CLAIM_AGEING, initClaimAgeingDetails),
    takeLatest(INIT_SUPPLIER_DATA, initSupplierDetails),
    takeLatest(INIT_AUDITOR_LIST, initAuditorDetails),
    takeLatest(INIT_SUPPLIER_CLAIM_PRODUCTIVITY, initSupplierProductivity),
  ]);
}
function* initDashboardData(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ClientDashbaordGetDashboardData,
      requestDetails
    );
    response = response.data;
    yield put(fetchDashboardData(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(Error_Msg, "danger");
  }
}

function* initStartEndDate(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = payload);
    let response = yield call(
      axios,
      ClientDashboardStartDateEndDateUI,
      requestDetails
    );
    response = response.data;
    yield put(fetchStartEndDateUI(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(Error_Msg, "danger");
  }
}

function* initAgeingDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ClientDashboardAgeingByOption,
      requestDetails
    );
    response = response.data.exceptionAgeing
      ? response.data.exceptionAgeing
      : {};
    payload.exp_age_parameter === "Warnings"
      ? yield put(fetchDashboardAgeing(response))
      : yield put(fetchExceptionAgeingNotification(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(Error_Msg, "danger");
  }
}

function* initClaimAgeingDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ClientDashboardClaimAgeingByOption,
      requestDetails
    );
    response = response.data.claimAgeing ? response.data.claimAgeing : {};
    payload.claim_age_parameter === "Warnings"
      ? yield put(fetchClaimAgeing(response))
      : yield put(fetchClaimAgeingNotification(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(Error_Msg, "danger");
  }
}

function* initSupplierDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ClientDashboardSupplierByOption,
      requestDetails
    );
    response =
      response.data.supplierProductivity !== undefined
        ? response.data.supplierProductivity
        : {};
    payload.sup_data_parameter === "ByCount"
      ? yield put(fetchSupplierDataByCount(response))
      : yield put(fetchSupplierData(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(Error_Msg, "danger");
  }
}

function* initAuditorDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ClientDashboardAuditorList,
      requestDetails
    );
    response = response.data;
    yield put(fetchAuditorList(response.users));
    callback !== undefined && callback();
  } catch (error) {
  }
}

function* initSupplierProductivity(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ClientDashboardSupplier2ByOption,
      requestDetails
    );
    response =
      response.data.supplierClaimsProductivity !== undefined
        ? response.data.supplierClaimsProductivity
        : [];
    payload.sp_claims_parameter === "ByValue"
      ? yield put(fetchSupplierProductivity(response))
      : yield put(fetchSupplierProductivityByCount(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(Error_Msg, "danger");
  }
}
