export const INIT_LOGIN = "INIT_LOGIN";
export const LOGIN_SUCCESSFULL = "LOGIN_SUCCESSFULL";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const VALIDATE_OTP = "VALIDATE_OTP";
export const INIT_LOGIN_PROCESS = "INIT_LOGIN_PROCESS";
export const INIT_FORGOT_PASSWORD = "INIT_FORGOT_PASSWORD";
export const HANDLE_FORGOT_PASSWORD = "HANDLE_FORGOT_PASSWORD";
export const UPDATE_AUTH_DETAILS = "UPDATE_AUTH_DETAILS";
export const INIT_LOGOUT ="INIT_LOGOUT";
export const LOGOUT = "LOGOUT";
export const SSO_CALLBACK_AUTH = "SSO_CALLBACK_AUTH";
export const INIT_APPLICATION_NAME = "INIT_APPLICATION_NAME";
export const FETCH_APPLICATION_NAME = "FETCH_APPLICATION_NAME";
export const INIT_NBCU_BASIC_AUTH = "INIT_NBCU_BASIC_AUTH";
export const INIT_NBCU_CLIENT_TOKEN_DETAILS = "INIT_NBCU_CLIENT_TOKEN_DETAILS";