export const LanguageList = [
  { selector: "CS", title: "ČEŠTINA" },
  { selector: "DE", title: "DEUTSCH" },
  { selector: "ES", title: "ESPAÑOL" },
  { selector: "IT", title: "ITALIANO" },
  { selector: "JA", title: "日本" },
  { selector: "PT", title: "PORTUGUÊS" },
  { selector: "ZH", title: "中国人" },
];

export const redirectDNS = "https://login.microsoftonline.com/";

export const redirectOAuthUri = "/oauth2/logout?post_logout_redirect_uri=";
