import { fromJS } from "immutable";
import {
  FETCH_CLIENT_LIST,
  STORE_ADMIN_DETAILS,
  FETCH_CLIENT_SYSTEM_PARAMETERS,
  FETCH_MOBILE_REPORT_URL,
} from "./actionTypes";

export const initialState = fromJS({
  clientList: [],
  adminDetails: {},
  mobileReportUrl: "",
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENT_LIST: {
      return state.set("clientList", action.data);
    }
    case STORE_ADMIN_DETAILS: {
      return state.set("adminDetails", action.data);
    }
    case FETCH_CLIENT_SYSTEM_PARAMETERS: {
      return state.set("clientSystemParameters", action.data);
    }
    case FETCH_MOBILE_REPORT_URL: {
      return state.set("mobileReportUrl", action.data);
    }
    default:
      return state;
  }
};

export default reducer;
