import {
    INIT_IPQC_PRIORITIZATION_DATA,
    INIT_IPQC_PRIORITIZATION_EXPORT_FIELDS,
    INIT_IPQC_PRIORITIZATION_FILTER_NAME,
    INIT_IPQC_PRIORITIZATION_HEADER_FIELDS,
    INIT_IPQC_PRIORITIZATION_PAGES_COUNT,
    INIT_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS,
    INIT_IPQC_PRIORITIZATION_SEARCH_RECORD,
    INIT_IPQC_PRIORITIZATION_SAVE_RECORD_DETAILS,
    INIT_IPQC_PRIORITIZATION_DELETE_FILTER_OPTION,
    INIT_IPQC_PRIORITIZATION_EXPORT_RECORDS,
    INIT_IPQC_PRIORITIZATION_RECORD_DETAILS,
    INIT_IPQC_PRIORITIZATION_RUN_FILTER,
    INIT_IPQC_PRIORITIZATION_FILTER_FIELDS,
    INIT_IPQC_PRIORITIZATION_EDIT_FILTER_DETAILS,
    INIT_IPQC_PRIORITIZATION_SAVE_FILTER_DETAILS,
    FETCH_IPQC_PRIORITIZATION_DATA,
    FETCH_IPQC_PRIORITIZATION_EXPORT_FIELDS,
    FETCH_IPQC_PRIORITIZATION_FILTER_NAME,
    FETCH_IPQC_PRIORITIZATION_HEADER_FIELDS,
    FETCH_IPQC_PRIORITIZATION_PAGES_COUNT,
    FETCH_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS,
    FETCH_IPQC_PRIORITIZATION_SEARCH_RECORD,
    FETCH_IPQC_PRIORITIZATION_FILTER_FIELDS,
    INIT_IPQC_PRIORITIZATION_PAGE_EXIT,
  } from "./actionTypes";
  
  export const initTableData = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_DATA,
      payload,
      callback,
    };
  };
  export const fetchTableData = (data) => {
    return {
      type: FETCH_IPQC_PRIORITIZATION_DATA,
      data,
    };
  };
  export const initExportFields = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_EXPORT_FIELDS,
      payload,
      callback,
    };
  };
  export const fetchExportFields = (data) => {
    return {
      type: FETCH_IPQC_PRIORITIZATION_EXPORT_FIELDS,
      data,
    };
  };
  export const initFilterName = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_FILTER_NAME,
      payload,
      callback,
    };
  };
  export const fetchFilterName = (data) => {
    return {
      type: FETCH_IPQC_PRIORITIZATION_FILTER_NAME,
      data,
    };
  };
  export const initHeaderFieldsList = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_HEADER_FIELDS,
      payload,
      callback,
    };
  };
  export const fetchHeaderFieldsList = (data) => {
    return {
      type: FETCH_IPQC_PRIORITIZATION_HEADER_FIELDS,
      data,
    };
  };
  export const initPageCount = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_PAGES_COUNT,
      payload,
      callback,
    };
  };
  export const fetchPageCount = (data) => {
    return {
      type: FETCH_IPQC_PRIORITIZATION_PAGES_COUNT,
      data,
    };
  };
  export const initRoleBasedActions = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS,
      payload,
      callback,
    };
  };
  export const fetchRoleBasedActions = (data) => {
    return {
      type: FETCH_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS,
      data,
    };
  };
  export const initRecordSearch = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_SEARCH_RECORD,
      payload,
      callback,
    };
  };
  export const fetchRecordSeach = (data) => {
    return {
      type: FETCH_IPQC_PRIORITIZATION_SEARCH_RECORD,
      data,
    };
  };
  export const initSaveRecord = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_SAVE_RECORD_DETAILS,
      payload,
      callback,
    };
  };
  export const initDeleteFilter = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_DELETE_FILTER_OPTION,
      payload,
      callback,
    };
  };
  export const initExportRecord = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_EXPORT_RECORDS,
      payload,
      callback,
    };
  };
  export const initRecordDetails = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_RECORD_DETAILS,
      payload,
      callback,
    };
  };
  export const initRunFilter = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_RUN_FILTER,
      payload,
      callback,
    };
  };
  export const initFilterFields = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_FILTER_FIELDS,
      payload,
      callback,
    };
  };
  export const fetchFilterFields = (data) => {
    return {
      type: FETCH_IPQC_PRIORITIZATION_FILTER_FIELDS,
      data,
    };
  };
  export const initEditFilterDetails = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_EDIT_FILTER_DETAILS,
      payload,
      callback,
    };
  };
  export const initSaveFilterDetails = (payload, callback) => {
    return {
      type: INIT_IPQC_PRIORITIZATION_SAVE_FILTER_DETAILS,
      payload,
      callback,
    };
  };
  export const initExitPage = () => {
    return {
      type: INIT_IPQC_PRIORITIZATION_PAGE_EXIT,
    };
  };
  