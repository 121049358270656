import React, { useState, useRef } from "react";
import { FormInput } from "..";
import Tooltip from "../Tooltip";
import "./style.scss";
import { useTranslation } from "react-i18next";

const FormControl = (props) => {
    const { t } = useTranslation();
    let timeout = useRef(0);
    const [active, setActive] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const showTip = () => {
        timeout.current = setTimeout(() => {
            setActive(true);
        }, props.delay || 400);
    };
    const hideTip = () => {
        clearInterval(timeout.current);
        setActive(false);
    };

    const attrs = {
        ...props.attrs,
        error: props.error,
        showError: props.showError,
    };

    return (
        <>
            <div
                className="form-control-wrapper"
                ref={setReferenceElement}
                onMouseEnter={showTip}
                onMouseLeave={hideTip}
            >
                <FormInput
                    attrs={attrs}
                    inputType={props.inputType}
                    onChangeHandler={props.onChangeHandler}
                    onBlurHandler={props.onBlurHandler}
                    value={props.value}
                    loading={props.loading}
                    onClickHandler={props.onClickHandler}
                    onFocusHandler={props.onFocusHandler}
                    inTable={props.inTable}
                    resetVisited={props.resetVisited}
                />
                <p className="error-text">{props.showError ? t(props.error) : ""}</p>
            </div>
            {props.hint && active && (
                <Tooltip triggerElem={referenceElement}>{props.hint}</Tooltip>
            )}
        </>
    );
};

export default FormControl;
