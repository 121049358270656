import React from "react";
import "./style.scss";
import Checkbox from "../../Checkboxes/Checkbox";

const LabeledCheckbox = (props) => {
  const checklabel = `default-label ${props.required ? `required` : ``}`;
  const wrapperclass = `input-wrapper ${props.disabled ? `disabled` : ``}`;
  return (
    <div className={wrapperclass}>
      <div className="labeled-checkbox">
        <label className={checklabel}>{props.label}</label>
        <Checkbox
          id={props.id}
          name={props.name}
          checked={props.checked}
          handleCheck={props.handleCheck}
          handleClick={props.handleClick}
          disabled={props.disabled}
          ariaLabel={props.label}
          label="&nbsp;"
        />
      </div>
    </div>
  );
};

export default LabeledCheckbox;
