import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../../config/axiosCall";
import { loginFailed } from "../../../Login/store/actions";
import { Error_Msg } from "../../../../config";
import { FETCH_STATEMENTS, INIT_FOOTER } from "./actionTypes";
import { fetchFooter } from "./actions";
import { postNoCacheRequestDetails } from "../../../../config/requestHeaders";
import {
  SystemParametersViewToUandPSStatement,
  GetFooterDetails,
} from "../../../../config/APINames";
export default function* watchLogin() {
  yield all([takeLatest(FETCH_STATEMENTS, initFetchStatement)]);
  yield all([takeLatest(INIT_FOOTER, initFooterSaga)]);
}
function* initFetchStatement(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = { ...postNoCacheRequestDetails };
    yield (requestDetails.data = {
      Language: payload,
    });
    let response = yield call(
      axios,
      SystemParametersViewToUandPSStatement,
      requestDetails
    );
    response = response.data;
    callback(response);
  } catch (e) {
    yield put(loginFailed(Error_Msg));
  }
}

function* initFooterSaga() {
  try {
    const requestDetails = { ...postNoCacheRequestDetails };
    let response = yield call(axios, GetFooterDetails, requestDetails);
    response = response.data;
    yield put(fetchFooter(response));
  } catch (e) {
    yield put(loginFailed(Error_Msg));
  }
}
