import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import {
  PasswordManagementGetPassword,
  PasswordManagementUpdatePassword,
} from "../../../config/APINames";
import { INIT_GET_PASSWORD, INIT_SAVE_PASSWORD } from "./actionTypes";
import { fetchPasswordDetails, fetchSaveDetails } from "./actions";
import generateKey from "../../../config/generateKey";

export default function* watchLogin() {
  yield all([takeLatest(INIT_GET_PASSWORD, initGetPasswordSaga)]);
  yield all([takeLatest(INIT_SAVE_PASSWORD, initSavePasswordSaga)]);
}

function* initGetPasswordSaga(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      PasswordManagementGetPassword,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(fetchPasswordDetails());
      callback && callback(response.data);
    }
  } catch (error) {
    generateNotification(error, "danger");
  }
}

function* initSavePasswordSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      PasswordManagementUpdatePassword,
      requestDetails
    );
    if (response && response.status === 200) {
      if (response.data.resultStatus === true) {
        generateNotification(generateKey(response.data.message), "success");
        yield put(fetchSaveDetails());
      } else {
        generateNotification(generateKey(response.data.message), "danger");
      }
    }
  } catch (error) {
    generateNotification(error, "danger");
  }
}
