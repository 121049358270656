import {
  UPDATE_SELECTED_LANGUAGE,
  INIT_CLIENT_LIST,
  FETCH_CLIENT_LIST,
  INIT_SWITCH_CLIENT,
  STORE_ADMIN_DETAILS,
  INIT_CLIENT_SYSTEM_PARAMETERS,
  FETCH_CLIENT_SYSTEM_PARAMETERS,
  FETCH_MOBILE_REPORT_URL,
} from "./actionTypes";
export const updateSelectedLangauge = (language) => {
  return {
    type: UPDATE_SELECTED_LANGUAGE,
    language,
  };
};
export const initClientList = (payload) => {
  return {
    type: INIT_CLIENT_LIST,
    payload,
  };
};
export const fetchClientList = (data) => {
  return {
    type: FETCH_CLIENT_LIST,
    data,
  };
};
export const initSwitchClient = (payload) => {
  return {
    type: INIT_SWITCH_CLIENT,
    payload,
  };
};
export const storeAdminDetails = (data) => {
  return {
    type: STORE_ADMIN_DETAILS,
    data,
  };
};
export const initClientSystemParameters = (payload) => {
  return {
    type: INIT_CLIENT_SYSTEM_PARAMETERS,
    payload,
  };
};
export const fetchClientSystemParameters = (data) => {
  return {
    type: FETCH_CLIENT_SYSTEM_PARAMETERS,
    data,
  };
};
export const fetchMobileReport = (data) => {
  return {
    type: FETCH_MOBILE_REPORT_URL,
    data,
  };
};
