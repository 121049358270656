import { fromJS } from "immutable";
import {
  FETCH_QUEUED_JOB_LIST,
  INIT_GET_QUEUED_JOB_LIST,
  FETCH_QUEUE_SUSPEND_STATE,
  INIT_SAVE_REORDERED_JOBS,
  FETCH_SAVE_REORDERED_JOBS_COMPLETE,
  INIT_GET_RESUMED_QUEUE,
  FETCH_RESUMEQUEUE_COMPLETE,
  INIT_GET_SUSPENDED_QUEUE,
  FETCH_SUSPENDQUEUE_COMPLETE,
  FETCH_CLIENT_QUEUEDJOB_LISTING,
  INIT_GET_CLIENT_QUEUEDJOB_LISTING,
} from "./actionTypes";
export const initialState = fromJS({
  queuedJobList: [],
  clientqueuedJobList: [],
  loader: false,
  queueSuspendState: "",
  saveReorderedJobs: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUEUED_JOB_LIST: {
      return state.set("queuedJobList", action.data).set("loader", false);
    }
    case INIT_GET_QUEUED_JOB_LIST:
    case INIT_GET_RESUMED_QUEUE:
    case INIT_GET_CLIENT_QUEUEDJOB_LISTING:
    case INIT_GET_SUSPENDED_QUEUE: {
      return state.set("loader", true);
    }
    case FETCH_QUEUE_SUSPEND_STATE: {
      return state.set("queueSuspendState", action.data);
    }
    case FETCH_RESUMEQUEUE_COMPLETE:
    case FETCH_SUSPENDQUEUE_COMPLETE: {
      return state.set("loader", false);
    }
    case INIT_SAVE_REORDERED_JOBS: {
      return state.set("saveReorderedJobs", true);
    }
    case FETCH_SAVE_REORDERED_JOBS_COMPLETE: {
      return state.set("saveReorderedJobs", false);
    }
    case FETCH_CLIENT_QUEUEDJOB_LISTING: {
      return state
        .set("clientqueuedJobList", action.data)
        .set("queueSuspendState", false)
        .set("loader", false);
    }
    default:
      return state;
  }
};

export default reducer;
