import {
    INIT_VENDOR_CLAIM_FILTER_FIELDS,
    INIT_VENDOR_CLAIM_LIST,
    FETCH_VENDOR_CLAIM_LIST,
    INIT_VENDOR_CLAIM_SUMMARY_LIST,
    FETCH_VENDOR_CLAIM_SUMMARY_LIST,
} from "./actionTypes";

export const initVendorClaimFilterFields = (payload, callback) => {
    return {
        type: INIT_VENDOR_CLAIM_FILTER_FIELDS,
        payload,
        callback
    };
};

export const initVendorClaimList = (payload) => {
    return {
        type: INIT_VENDOR_CLAIM_LIST,
        payload,
    };
};

export const fetchVendorClaimList = (data) => {
    return {
        type: FETCH_VENDOR_CLAIM_LIST,
        data,
    };
};

export const initVendorClaimSummaryList = (payload) => {
    return {
        type: INIT_VENDOR_CLAIM_SUMMARY_LIST,
        payload,
    };
};

export const fetchVendorClaimSummaryList = (data) => {
    return {
        type: FETCH_VENDOR_CLAIM_SUMMARY_LIST,
        data,
    };
};
