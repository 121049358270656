import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { ReportManagementDataValidationReports } from "../../../config/APINames";
import { INIT_DATA_VALIDATION_REPORT_LIST } from "./actionTypes";
import { fetchDataValidationReportList } from "./actions";
export default function* watchLogin() {
  yield all([
    takeLatest(
      INIT_DATA_VALIDATION_REPORT_LIST,
      initDataValidationReportListSaga
    ),
  ]);
}
function* initDataValidationReportListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReportManagementDataValidationReports,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(fetchDataValidationReportList(response.data.resultSet.dataValidationReportList));
    }
  } catch (error) {
    generateNotification(error, "danger");
  }
}
