import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { storage } from "../../../config/storage";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import exportToExcel from "../../../helper/exportToExcel";
import { Error_Msg } from "../../../config";
import {
  ManageVendorFilterName,
  ReVisitedDupsUnattendedDupsExceptionListHeaders,
  ReVisitedDupsUnattendedExportFieldsList,
  UserActionAuthenticationGetRoleBasedActions,
  ManageVendorVendorList,
  ManageVendorDeleteAdvanceFilter,
  ManageVendorFilterFields,
  ManageVendorEditFilterPageData,
  ManageVendorAddAdvanceFilter,
  ManageVendorExportToExcel,
  ManageVendorExportAllToExcel,
  ManageVendorActionOnVendor,
  ManageVendorDetailsOfVendor,
  UserManagementGetCountryList,
  ManageVendorSaveDetailsVendor,
  ManageVendorAddVendorContactGroup,
  ManageVendorVendorContactGroup,
  ManageVendorUpdateVendorContactDetails,
  MangeVendorDeleteVendorContactDetails,
} from "../../../config/APINames";
import {
  INIT_VENDOR_FILTER_NAMES,
  INIT_VENDOR_LIST_HEADER_NAMES,
  INIT_VENDOR_EXPORT_FIELD_LIST,
  INIT_VENDOR_ROLE_BASED_ACTIONS,
  INIT_VENDOR_LIST,
  INIT_DELETE_VENDOR_FILTER,
  INIT_VENDOR_FILTER_COLUMNS,
  INIT_VENDOR_FILTER_DETAILS,
  INIT_SAVE_VENDOR_FILTER,
  INIT_VENDOR_RECORDS_EXPORT,
  INIT_VENDOR_RECORDS_EXPORT_ALL,
  INIT_VENDOR_RECORDS_MARK_UNMARK_AS_PREFERRED,
  FETCH_VENDOR_RECORD_DETAILS,
  INIT_COUNTRY_LIST,
  SAVE_VENDOR_DETAILS,
  SAVE_VENDOR_CONTACT_DETAILS,
  INIT_VENDOR_CONTACT_DETAILS,
  UPDATE_VENDOR_CONTACT_DETAILS,
  DELETE_VENDOR_CONTACTS,
} from "./actionTypes";
import {
  fetchVendorListHeaderNames,
  vendorListHeaderNamesFetchComplete,
  vendorListHeaderNamesFetchFailed,
  fetchVendorExportFieldList,
  vendorExportFieldListFetchComplete,
  vendorExportFieldListFetchFailed,
  fetchVendorRoleBasedActions,
  vendorRoleBasedActionsFetchComplete,
  vendorRoleBasedActionsFetchFailed,
  fetchVendorList,
  vendorListFetchComplete,
  vendorListFetchFailed,
  fetchVendorFilterColumns,
  fetchVendorRecordsExport,
  fetchVendorRecordsExportAll,
  fetchCountryList,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(INIT_VENDOR_FILTER_NAMES, initGetVendorFilterNamesSaga),
    takeLatest(INIT_VENDOR_LIST_HEADER_NAMES, initGetVendorListHeaderNamesSaga),
    takeLatest(INIT_VENDOR_EXPORT_FIELD_LIST, initGetVendorExportFieldListSaga),
    takeLatest(
      INIT_VENDOR_ROLE_BASED_ACTIONS,
      initGetVendorRoleBasedActionsSaga
    ),
    takeLatest(INIT_VENDOR_LIST, initGetVendorListSaga),
    takeLatest(INIT_DELETE_VENDOR_FILTER, initVendorDeleteFilterSaga),
    takeLatest(INIT_VENDOR_FILTER_COLUMNS, initVendorFilterColumsSaga),
    takeLatest(INIT_VENDOR_FILTER_DETAILS, initVendorFilterDetailsSaga),
    takeLatest(INIT_SAVE_VENDOR_FILTER, initSaveVendorFilterSaga),
    takeLatest(INIT_VENDOR_RECORDS_EXPORT, initVendorVendorRecordsExportSaga),
    takeLatest(
      INIT_VENDOR_RECORDS_EXPORT_ALL,
      initVendorVendorRecordsExportAllExportSaga
    ),
    takeLatest(
      INIT_VENDOR_RECORDS_MARK_UNMARK_AS_PREFERRED,
      initVendorRecordMarkUnMarkAsPreferredSaga
    ),
    takeLatest(FETCH_VENDOR_RECORD_DETAILS, fetchVendorRecordDetailsSaga),
    takeLatest(INIT_COUNTRY_LIST, initGetCountryListSaga),
    takeLatest(SAVE_VENDOR_DETAILS, initSaveVendorDetailsSaga),
    takeLatest(SAVE_VENDOR_CONTACT_DETAILS, initSaveVendorContactDetailsSaga),
    takeLatest(INIT_VENDOR_CONTACT_DETAILS, initVendorContactDetailsSaga),
    takeLatest(
      UPDATE_VENDOR_CONTACT_DETAILS,
      initUpdateVendorContactDetailsSaga
    ),
    takeLatest(DELETE_VENDOR_CONTACTS, initDeleteVendorContactsSaga),
  ]);
}

function* initGetVendorFilterNamesSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName, userId, sourceDomain } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      userName,
      userId,
      sourceDomain,
    });
    const response = yield call(axios, ManageVendorFilterName, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetVendorListHeaderNamesSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedDupsExceptionListHeaders,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchVendorListHeaderNames(response.data));
      yield put(vendorListHeaderNamesFetchComplete());
    } else {
      yield put(vendorListHeaderNamesFetchFailed());
    }
  } catch (error) {
    yield put(vendorListHeaderNamesFetchFailed());
  }
}

function* initGetVendorExportFieldListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedDupsUnattendedExportFieldsList,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchVendorExportFieldList(response.data));
      yield put(vendorExportFieldListFetchComplete());
    } else {
      yield put(vendorExportFieldListFetchFailed());
    }
  } catch (error) {
    yield put(vendorExportFieldListFetchFailed());
  }
}

function* initGetVendorRoleBasedActionsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      UserActionAuthenticationGetRoleBasedActions,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchVendorRoleBasedActions(response.data));
      yield put(vendorRoleBasedActionsFetchComplete());
    } else {
      yield put(vendorRoleBasedActionsFetchFailed());
    }
  } catch (error) {
    yield put(vendorRoleBasedActionsFetchFailed());
  }
}

function* initGetVendorListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, ManageVendorVendorList, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchVendorList(response.data));
      yield put(vendorListFetchComplete());
    } else {
      yield put(vendorListFetchFailed());
    }
  } catch (error) {
    yield put(vendorListFetchFailed());
  }
}

function* initVendorDeleteFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName, sourceDomain, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userId,
      clientName,
      sourceDomain,
      userName,
    });
    const response = yield call(
      axios,
      ManageVendorDeleteAdvanceFilter,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initVendorFilterColumsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageVendorFilterFields,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchVendorFilterColumns(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initVendorFilterDetailsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName, sourceDomain, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      sourceDomain,
      userId,
      userName,
    });
    const response = yield call(
      axios,
      ManageVendorEditFilterPageData,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initSaveVendorFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName, sourceDomain, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      sourceDomain,
      userId,
      userName,
    });
    const response = yield call(
      axios,
      ManageVendorAddAdvanceFilter,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initVendorVendorRecordsExportSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageVendorExportToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchVendorRecordsExport(response.data));
      exportToExcel(
        response.data.VendorListing,
        "ManageVendors",
        response.data.exportfileName
      );
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initVendorVendorRecordsExportAllExportSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageVendorExportAllToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchVendorRecordsExportAll(response.data));
      exportToExcel(
        response.data.vendorListing,
        "ManageVendors",
        response.data.exportfileName
      );
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initVendorRecordMarkUnMarkAsPreferredSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageVendorActionOnVendor,
      requestDetails
    );
    if (
      response.data.message ===
      "Preference for selected vendor(s) was successfully changed. "
    ) {
      generateNotification(
        "atcat.preferenceForSelectedVendorSWasSuccessfullyChanged",
        "success"
      );
      action.callback !== undefined && action.callback(response);
    } else if (
      response.data.message ===
      "Selected vendor(s) is already marked as preferred."
    ) {
      generateNotification(
        "atcat.pleaseSelectOnlyNotPreferredVendors",
        "danger"
      );
    } else if (
      response.data.message ===
      "Selected vendors are already marked as preferred."
    ) {
      generateNotification(
        "Selected vendors are already marked as preferred.",
        "danger"
      );
    } else if (response.data.message === "Please select preferred vendor(s)") {
      generateNotification("atcat.pleaseSelectOnlyPreferredVendors", "danger");
    } else if (
      response.data.message ===
      "Selected vendors are already unmarked as preferred."
    ) {
      generateNotification(
        "atcat.selectedVendorsAreAlreadyUnmarkedAsPreferred",
        "danger"
      );
    } else if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data.message === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* fetchVendorRecordDetailsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageVendorDetailsOfVendor,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetCountryListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      UserManagementGetCountryList,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else {
      yield put(fetchCountryList(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initSaveVendorDetailsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageVendorSaveDetailsVendor,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initSaveVendorContactDetailsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageVendorAddVendorContactGroup,
      requestDetails
    );
    if (response.data.message === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initVendorContactDetailsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageVendorVendorContactGroup,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initUpdateVendorContactDetailsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageVendorUpdateVendorContactDetails,
      requestDetails
    );
    if (response.data.message === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initDeleteVendorContactsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      MangeVendorDeleteVendorContactDetails,
      requestDetails
    );
    if (response.data.message === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}
