import { fromJS } from "immutable";
import {
  SAVE_TERMS_OF_USE,
  SAVE_TERMS_OF_USE_COMPLETE,
  SAVE_TERMS_OF_USE_FAILED,
  SAVE_SYSTEM_PARAMETERS,
  SAVE_SYSTEM_PARAMETERS_COMPLETED,
  SAVE_SYSTEM_PARAMETERS_FAILED,
  SYSTEM_PARAMETER_FORMDATA,
  TERMS_OF_USE_FORMDATA,
  UPDATE_SYSTEM_PARAMETER_FORMDATA,
  UPDATE_TERMS_OF_USE_FORMDATA,
} from "./actionTypes";

export const initialState = fromJS({
  saveTermsOfUse: false,
  systemParameters: false,
  systemParameterResponse: {},
  termsOfUseResponse: {},
  savedTermsOfUseResponse: {},
  savedSystemParametersResponse: {},
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TERMS_OF_USE: {
      return state.set("saveTermsOfUse", true);
    }
    case SAVE_TERMS_OF_USE_FAILED: {
      return state.set("saveTermsOfUse", false);
    }
    case SAVE_TERMS_OF_USE_COMPLETE: {
      return state
        .set("saveTermsOfUse", false)
        .set("savedTermsOfUseResponse", fromJS(action.data));
    }
    case SAVE_SYSTEM_PARAMETERS: {
      return state.set("systemParameters", true);
    }
    case SAVE_SYSTEM_PARAMETERS_FAILED: {
      return state.set("systemParameters", false);
    }
    case SAVE_SYSTEM_PARAMETERS_COMPLETED: {
      return state
        .set("systemParameters", false)
        .set("savedSystemParametersResponse", fromJS(action.data));
    }
    case SYSTEM_PARAMETER_FORMDATA: {
      return state
        .set("systemParameterResponse", fromJS(action.data))
        .set("savedSystemParametersResponse", fromJS(action.data));
    }
    case UPDATE_SYSTEM_PARAMETER_FORMDATA: {
      return state.set("systemParameterResponse", fromJS(action.payload));
    }
    case TERMS_OF_USE_FORMDATA: {
      return state
        .set("termsOfUseResponse", fromJS(action.data))
        .set("savedTermsOfUseResponse", fromJS(action.data));
    }
    case UPDATE_TERMS_OF_USE_FORMDATA: {
      return state.set("termsOfUseResponse", fromJS(action.payload));
    }

    default:
      return state;
  }
};

export default reducer;
