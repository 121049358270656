import { fromJS } from "immutable";
import {
    INIT_MANUAL_CLAIM_LIST,
    FETCH_MANUAL_CLAIM_LIST,
    INIT_MANUAL_CLAIM_FIELDS,
    FETCH_MANUAL_CLAIM_FIELDS,
    INIT_MANUAL_CLAIM_DETAILS,
    FETCH_MANUAL_CLAIM_DETAILS,
    INIT_MANUAL_CLAIM_RECORD_DETAILS,
    FETCH_MANUAL_CLAIM_RECORD_DETAILS,
    FETCH_MANUAL_OFFSET_RECORDS,
    FETCH_MANUAL_OFFSET_DETAILS,
    FETCHING_APPH_FILTER_NAME,
    APPH_FILTER_NAME_FETCHED,
    APPH_FILTER_NAME_FETCH_COMPLETE,
    APPH_FILTER_NAME_FETCH_FAIL,
    FETCHING_APPH_FILTER_FIELDS,
    APPH_FILTER_FIELDS_FETCHED,
    APPH_FILTER_FIELDS_FETCH_COMPLETE,
    APPH_FILTER_FIELDS_FETCH_FAIL,
    FETCHING_EDIT_APPH_FILTER,
    EDIT_APPH_FILTER_FETCHED,
    EDIT_APPH_FILTER_FETCH_COMPLETE,
    EDIT_APPH_FILTER_FETCH_FAIL,
    FETCHING_SAVE_APPH_FILTER,
    SAVE_APPH_FILTER_FETCHED,
    SAVE_APPH_FILTER_FETCH_COMPLETE,
    SAVE_APPH_FILTER_FETCH_FAIL,
    FETCHING_APPH_LIST_COUNT,
    APPH_LIST_COUNT_FETCHED,
    APPH_LIST_COUNT_FETCH_COMPLETE,
    APPH_LIST_COUNT_FETCH_FAIL,
    FETCHING_APPH_LIST,
    APPH_LIST_FETCHED,
    APPH_LIST_FETCH_COMPLETE,
    APPH_LIST_FETCH_FAIL,
    FETCH_SEARCH_MANUAL_CLAIM_DETAILS,
    FETCH_MANUAL_CLAIM_APPH_ASSIGN,
    FETCH_MANUAL_CLAIM_APPH_ASSIGNCALCULATE,
    FETCH_ACTIONS_MANUAL_CLAIM_RECORDS,
    FETCH_RECORD_MODIFIED_SINCE_CLAIM_CREATION_HEADERS,
} from "./actionTypes";

export const initialState = fromJS({
    manualClaimList: {},
    addManualClaimFields: {},
    manualClaimDetails: {},
    manualClaimRecordDetails: {},
    manualOffsetRecords: [],
    manualOffsetDetails: [],
    fetchingApphFilterName: false,
    fetchedApphFilterName: [],
    fetchingApphFilterFields: false,
    fetchedApphFilterFields: [],
    fetchingEditApphFilter: false,
    fetchedEditApphFilter: [],
    fetchingSaveApphFilter: false,
    fetchedSaveApphFilter: [],
    fetchingApphListCount: false,
    fetchedApphListCount: 0,
    fetchingApphList: false,
    fetchedApphList: [],
    actionsManualClaimRecords: {},
    recordModifiedClaimHeaders:[],
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_MANUAL_CLAIM_LIST: {
            return state.set("loader", true);
        }
        case FETCH_MANUAL_CLAIM_LIST: {
            return state.set("manualClaimList", action.data).set("loader", false);
        }
        case INIT_MANUAL_CLAIM_FIELDS: {
            return state.set("loader", true);
        }
        case FETCH_MANUAL_CLAIM_FIELDS: {
            return state
                .set("addManualClaimFields", action.data)
                .set("loader", false);
        }
        case INIT_MANUAL_CLAIM_DETAILS: {
            return state.set("loader", true);
        }
        case FETCH_MANUAL_CLAIM_DETAILS: {
            return state.set("manualClaimDetails", action.data).set("loader", false);
        }
        case INIT_MANUAL_CLAIM_RECORD_DETAILS: {
            return state.set("loader", true);
        }
        case FETCH_MANUAL_CLAIM_RECORD_DETAILS: {
            return state
                .set("manualClaimRecordDetails", action.data)
                .set("loader", false);
        }
        case FETCH_MANUAL_OFFSET_RECORDS: {
            return state.set("manualOffsetRecords", action.data).set("loader", false);
        }
        case FETCH_MANUAL_OFFSET_DETAILS: {
            return state.set("manualOffsetDetails", action.data).set("loader", false);
        }
        case FETCHING_APPH_FILTER_NAME: {
            return state.set("fetchingApphFilterName", true);
        }
        case APPH_FILTER_NAME_FETCHED: {
            return state.set("fetchedApphFilterName", action.data);
        }
        case APPH_FILTER_NAME_FETCH_COMPLETE:
        case APPH_FILTER_NAME_FETCH_FAIL: {
            return state.set("fetchingApphFilterName", false);
        }
        case FETCHING_APPH_FILTER_FIELDS: {
            return state.set("fetchingApphFilterFields", true);
        }
        case APPH_FILTER_FIELDS_FETCHED: {
            return state.set("fetchedApphFilterFields", action.data);
        }
        case APPH_FILTER_FIELDS_FETCH_COMPLETE:
        case APPH_FILTER_FIELDS_FETCH_FAIL: {
            return state.set("fetchingApphFilterFields", false);
        }
        case FETCHING_EDIT_APPH_FILTER: {
            return state.set("fetchingEditApphFilter", true);
        }
        case EDIT_APPH_FILTER_FETCHED: {
            return state.set("fetchedEditApphFilter", action.data);
        }
        case EDIT_APPH_FILTER_FETCH_COMPLETE:
        case EDIT_APPH_FILTER_FETCH_FAIL: {
            return state.set("fetchingEditApphFilter", false);
        }
        case FETCHING_SAVE_APPH_FILTER: {
            return state.set("fetchingSaveApphFilter", true);
        }
        case SAVE_APPH_FILTER_FETCHED: {
            return state.set("fetchedSaveApphFilter", action.data);
        }
        case SAVE_APPH_FILTER_FETCH_COMPLETE:
        case SAVE_APPH_FILTER_FETCH_FAIL: {
            return state.set("fetchingSaveApphFilter", false);
        }
        case FETCHING_APPH_LIST_COUNT: {
            return state.set("fetchingApphListCount", true);
        }
        case APPH_LIST_COUNT_FETCHED: {
            return state.set("fetchedApphListCount", action.data);
        }
        case APPH_LIST_COUNT_FETCH_COMPLETE:
        case APPH_LIST_COUNT_FETCH_FAIL: {
            return state.set("fetchingApphListCount", false);
        }
        case FETCHING_APPH_LIST: {
            return state.set("fetchingApphList", true);
        }
        case APPH_LIST_FETCHED: {
            return state.set("fetchedApphList", action.data);
        }
        case APPH_LIST_FETCH_COMPLETE:
        case APPH_LIST_FETCH_FAIL: {
            return state.set("fetchingApphList", false);
        }
        case FETCH_SEARCH_MANUAL_CLAIM_DETAILS: {
            return state.set("searchDetails", action.data);
        }
        case FETCH_MANUAL_CLAIM_APPH_ASSIGN: {
            return state.set("apphAssign", action.data);
        }
        case FETCH_MANUAL_CLAIM_APPH_ASSIGNCALCULATE: {
            return state.set("apphAssignCalculate", action.data);
        }
        case FETCH_ACTIONS_MANUAL_CLAIM_RECORDS: {
            return state.set("actionsManualClaimRecords", action.data);
        }
        case FETCH_RECORD_MODIFIED_SINCE_CLAIM_CREATION_HEADERS:{
            return state.set("recordModifiedClaimHeaders",action.data);
        }
        default:
            return state;
    }
};

export default reducer;
