import {    
    INIT_AI_EXCEPTION_LIST_HEADER_NAMES,
    FETCH_AI_EXCEPTION_LIST_HEADER_NAMES,
    INIT_AI_EXCEPTION_EXPORT_FIELD_LIST,
    FETCH_AI_EXCEPTION_EXPORT_FIELD_LIST,
    INIT_AI_EXCEPTION_TOTAL_COUNT,
    FETCH_AI_EXCEPTION_TOTAL_COUNT,
    INIT_AI_EXCEPTION_LIST,
    FETCH_AI_EXCEPTION_LIST,
    INIT_AI_EXCEPTION_FILTER_NAMES,
    INIT_DELETE_FILTER_AI_EXCEPTION,
    INIT_AI_EXCEPTION_RECORDS_EXPORT,
    FETCH_AI_EXCEPTION_RECORDS_EXPORT,
    INIT_AI_EXCEPTION_RECORDS_EXPORT_ALL,
    FETCH_AI_EXCEPTION_RECORDS_EXPORT_ALL,
    INIT_AI_MASS_RELEASE_WITH_COMMENTS,
    INIT_AI_EXCEPTION_FILTER_COLUMNS,
    FETCH_AI_EXCEPTION_FILTER_COLUMNS,
    INIT_AI_EXCEPTION_FILTER_DETAILS,
    INIT_SAVE_FILTER_AI_EXCEPTION,
    INIT_RELEASE_UNRELEASE_AI_EXCEPTIONS,
} from "./actionTypes";

export const initAIExceptionListFilterNames = (payload, callback) => {
    return {
        type: INIT_AI_EXCEPTION_FILTER_NAMES,
        payload,
        callback,
    };
};

export const initAIExceptionListHeaderNames = (payload) => {
    return {
        type: INIT_AI_EXCEPTION_LIST_HEADER_NAMES,
        payload,
    };
};

export const fetchAIExceptionListHeaderNames = (data) => {
    return {
        type: FETCH_AI_EXCEPTION_LIST_HEADER_NAMES,
        data,
    };
};

export const initAIExceptionList = (payload) => {
    return {
        type: INIT_AI_EXCEPTION_LIST,
        payload,
    };
};

export const fetchAIExceptionList = (data) => {
    return {
        type: FETCH_AI_EXCEPTION_LIST,
        data,
    };
};

export const initAIExceptionFilterColumns = (payload) => {
    return {
        type: INIT_AI_EXCEPTION_FILTER_COLUMNS,
        payload,
    };
};

export const fetchAIExceptionFilterColumns = (data) => {
    return {
        type: FETCH_AI_EXCEPTION_FILTER_COLUMNS,
        data,
    };
};

export const initAIExceptionFilterDetails = (payload, callback) => {
    return {
        type: INIT_AI_EXCEPTION_FILTER_DETAILS,
        payload,
        callback
    };
};

export const initSaveAIExceptionFilter = (payload, callback) => {
    return {
        type: INIT_SAVE_FILTER_AI_EXCEPTION,
        payload,
        callback,
    };
};

export const initAIExceptionTotalCount = (payload) => {
    return {
        type: INIT_AI_EXCEPTION_TOTAL_COUNT,
        payload,
    };
};

export const fetchAIExceptionTotalCount = (data) => {
    return {
        type: FETCH_AI_EXCEPTION_TOTAL_COUNT,
        data,
    };
};

export const initDeleteAIExceptionFilter = (payload, callback) => {
    return {
        type: INIT_DELETE_FILTER_AI_EXCEPTION,
        payload,
        callback
    };
};

export const initAIExceptionRecordsExport = (payload) => {
    return {
        type: INIT_AI_EXCEPTION_RECORDS_EXPORT,
        payload,
    };
};

export const fetchAIExceptionRecordsExport = (data) => {
    return {
        type: FETCH_AI_EXCEPTION_RECORDS_EXPORT,
        data,
    };
};

export const initAIExceptionRecordsExportAll = (payload) => {
    return {
        type: INIT_AI_EXCEPTION_RECORDS_EXPORT_ALL,
        payload,
    };
};

export const fetchAIExceptionRecordsExportAll = (data) => {
    return {
        type: FETCH_AI_EXCEPTION_RECORDS_EXPORT_ALL,
        data,
    };
};

export const initReleaseUnReleaseAIExceptions = (payload, callback) => {
    return {
        type: INIT_RELEASE_UNRELEASE_AI_EXCEPTIONS,
        payload,
        callback
    };
};

export const initAIMassReleaseWithComments = (payload, callback) => {
    return {
        type: INIT_AI_MASS_RELEASE_WITH_COMMENTS,
        payload,
        callback
    };
};

export const initAIExceptionExportFieldList = (payload) => {
    return {
        type: INIT_AI_EXCEPTION_EXPORT_FIELD_LIST,
        payload,
    };
};

export const fetchAIExceptionExportFieldList = (data) => {
    return {
        type: FETCH_AI_EXCEPTION_EXPORT_FIELD_LIST,
        data,
    };
};


