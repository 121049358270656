export const INIT_AI_EXCEPTION_FILTER_COL_NAMES =
  "INIT_FETCH_AI_EXCEPTION_FILTER_COL_NAMES";
export const FETCHING_AI_EXCEPTION_FILTER_COL_NAMES =
  "FETCHING_AI_EXCEPTION_FILTER_COL_NAMES";
export const AI_EXCEPTION_FILTER_COL_NAMES_FETCHED =
  "AI_EXCEPTION_FILTER_COL_NAMES_FETCHED";
export const AI_EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE =
  "AI_EXCEPTION_FILTER_COL_NAMES_FETCH_COMPLETE";
export const AI_EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL =
  "AI_EXCEPTION_FILTER_COL_NAMES_FETCH_FAIL";
export const INIT_SAVE_AI_EXCEPTION_FILTER = "INIT_SAVE_AI_EXCEPTION_FILTER";
export const INIT_FETCH_AI_EXCEPTION_FILTER_DETAILS =
  "INIT_FETCH_AI_EXCEPTION_FILTER_DETAILS";
export const INIT_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS =
  "INIT_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS";
export const FETCH_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS =
  "FETCH_AI_EXCEPTION_RIGHT_ALIGNED_FIELDS";
export const INIT_FETCH_AI_EXCEPTION_FILTER_NAMES =
  "INIT_FETCH_AI_EXCEPTION_FILTER_NAMES";
export const INIT_GET_AI_EXCEPTION_COUNT = "INIT_GET_AI_EXCEPTION_COUNT";
export const FETCHING_AI_EXCEPTION_COUNT = "FETCHING_AI_EXCEPTION_COUNT";
export const FETCH_AI_EXCEPTION_COUNT = "FETCH_AI_EXCEPTION_COUNT";
export const AI_EXCEPTION_COUNT_FETCHING_COMPLETE =
  "AI_EXCEPTION_COUNT_FETCHING_COMPLETE";
export const ASSIGN_INITAL_DATA = "ASSIGN_INITAL_DATA";
export const INIT_FETCH_AI_EXCEPTION_LIST = "INIT_FETCH_AI_EXCEPTION_LIST";
export const FETCHING_AI_EXCEPTION_LIST = "FETCHING_AI_EXCEPTION_LIST";
export const AI_EXCEPTION_LIST_FETCHED = "AI_EXCEPTION_LIST_FETCHED";
export const AI_EXCEPTION_LIST_FETCH_COMPLETE =
  "AI_EXCEPTION_LIST_FETCH_COMPLETE";
export const AI_EXCEPTION_LIST_FETCH_FAIL = "AI_EXCEPTION_LIST_FETCH_FAIL";
export const INIT_DELETE_AI_EXCEPTION_FILTER =
  "INIT_DELETE_AI_EXCEPTION_FILTER";
export const INIT_GET_AI_EXCEPTION_RECORDS_EXPORT =
  "INIT_GET_AI_EXCEPTION_RECORDS_EXPORT";
export const FETCHING_AI_EXCEPTION_RECORDS_EXPORT =
  "FETCHING_AI_EXCEPTION_RECORDS_EXPORT";
export const FETCH_AI_EXCEPTION_RECORDS_EXPORT =
  "FETCH_AI_EXCEPTION_RECORDS_EXPORT";
export const AI_EXCEPTION_RECORDS_EXPORT_FETCHING_COMPLETE =
  "AI_EXCEPTION_RECORDS_EXPORT_FETCHING_COMPLETE";
export const INIT_GET_AI_EXCEPTION_RECORDS_EXPORT_ALL =
  "INIT_GET_AI_EXCEPTION_RECORDS_EXPORT_ALL";
export const FETCHING_AI_EXCEPTION_RECORDS_EXPORT_ALL =
  "FETCHING_AI_EXCEPTION_RECORDS_EXPORT_ALL";
export const FETCH_AI_EXCEPTION_RECORDS_EXPORT_ALL =
  "FETCH_AI_EXCEPTION_RECORDS_EXPORT_ALL";
export const AI_EXCEPTION_RECORDS_EXPORT_ALL_FETCHING_COMPLETE =
  "AI_EXCEPTION_RECORDS_EXPORT_ALL_FETCHING_COMPLETE";
export const INIT_GET_RELEASE_AI_EXCEPTION = "INIT_GET_RELEASE_AI_EXCEPTION";
export const FETCHING_RELEASE_AI_EXCEPTION = "FETCHING_RELEASE_AI_EXCEPTION";
export const FETCH_RELEASE_AI_EXCEPTION = "FETCH_RELEASE_AI_EXCEPTION";
export const RELEASE_AI_EXCEPTION_FETCHING_COMPLETE =
  "RELEASE_AI_EXCEPTION_FETCHING_COMPLETE";
export const INIT_GET_AI_EXCEPTION_RECORD_LIST =
  "INIT_GET_AI_EXCEPTION_RECORD_LIST";
export const FETCHING_AI_EXCEPTION_RECORD_LIST =
  "FETCHING_AI_EXCEPTION_RECORD_LIST";
export const FETCH_AI_EXCEPTION_RECORD_LIST = "FETCH_AI_EXCEPTION_RECORD_LIST";
export const AI_EXCEPTION_RECORD_LIST_FETCHING_COMPLETE =
  "AI_EXCEPTION_RECORD_LIST_FETCHING_COMPLETE";
export const INIT_AI_MOVE_AND_COPY_TO_CLAIM = "INIT_AI_MOVE_AND_COPY_TO_CLAIM";
export const FETCH_AI_MOVE_AND_COPY_TO_CLAIM =
  "FETCH_AI_MOVE_AND_COPY_TO_CLAIM";
export const INIT_SAVE_AI_DUPLICATE_EXCEPTIONS_DETAILS =
  "INIT_SAVE_AI_DUPLICATE_EXCEPTIONS_DETAILS";
export const INIT_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES =
  "INIT_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES";
export const FETCH_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES =
  "FETCH_AI_EXCEPTION_RECORD_LIST_HEADER_NAMES";
export const APPLY_INITAL_AI_DUPLICATE_EXCEPTION_VALUES =
  "APPLY_INITAL_AI_DUPLICATE_EXCEPTION_VALUES";
