import {
    INIT_ADHOCDUPS_EXCEL_VALIDATION,
    FETCH_ADHOCDUPS_EXCEL_VALIDATION,
    INIT_SUBMIT_ADHOCDUPS,
    FETCH_SUBMIT_ADHOCDUPS,
    INIT_ADHOCDUPS_EXPORT_TO_EXCEL,
    INIT_ADHOC_DUPS_JOBS,
    FETCH_ADHOC_DUPS_JOBS,
    INIT_ADHOC_DUPS_JOBS_DETAILS,
    FETCH_ADHOC_DUPS_JOBS_DETAILS,
    APPLY_INITAL_ADHOCDUPS_VALUES,
    INIT_ADHOCDUPS_EXCEL_RECORD_COUNT,
    FETCH_ADHOCDUPS_EXCEL_RECORD_COUNT
} from "./actionTypes";

export const initAdhocDupsExcelValidation = (payload, callback) => {
    return {
        type: INIT_ADHOCDUPS_EXCEL_VALIDATION,
        payload,
        callback,
    };
};
export const fetchAdhocDupsExcelValidation = (data) => {
    return {
        type: FETCH_ADHOCDUPS_EXCEL_VALIDATION,
        data,
    };
};
export const initAdhocDupsExcelRecordCount = (payload) => {
    return {
        type: INIT_ADHOCDUPS_EXCEL_RECORD_COUNT,
        payload
    };
};
export const fetchAdhocDupsExcelRecordCount = (data) => {
    return {
        type: FETCH_ADHOCDUPS_EXCEL_RECORD_COUNT,
        data,
    };
};
export const initSubmitAdhocDups = (payload, callback) => {
    return {
        type: INIT_SUBMIT_ADHOCDUPS,
        payload,
        callback,
    };
};
export const fetchSubmitAdhocDups = (data) => {
    return {
        type: FETCH_SUBMIT_ADHOCDUPS,
        data,
    };
};
export const initAdhocDupsExportToExcel = (payload) => {
    return {
        type: INIT_ADHOCDUPS_EXPORT_TO_EXCEL,
        payload,
    };
};
export const initAdhocJobs = (payload, callback) => {
    return {
        type: INIT_ADHOC_DUPS_JOBS,
        payload,
        callback,
    };
};
export const fetchAdhocJobList = (data) => {
    return {
        type: FETCH_ADHOC_DUPS_JOBS,
        data,
    };
};
export const initAdhocJobsDetails = (payload, callback) => {
    return {
        type: INIT_ADHOC_DUPS_JOBS_DETAILS,
        payload,
        callback,
    };
};
export const fetchAdhocJobDetails = (data) => {
    return {
        type: FETCH_ADHOC_DUPS_JOBS_DETAILS,
        data,
    };
};
export const applyIntialValues = () => {
    return {
        type: APPLY_INITAL_ADHOCDUPS_VALUES,
    };
};
