import { fromJS } from "immutable";
import {
  INIT_FETCH_RUNNINGJOBS,
  FETCH_RUNNINGJOB_LIST,
  INIT_FETCH_KILLJOB,
  FETCH_JOB_LOGS,
  FETCH_RUNNING_JOB_STATUS,
  INIT_FETCH_JOB_LOGS,
  FETCH_CLIENT_RUNNINGJOB_LIST,
  FETCH_JOB_LOGS_COMPLETE,
  APPLY_RUNNING_JOBS_INITAL_VALUE,
} from "./actionTypes";

export const initialState = fromJS({
  runningJobList: {},
  fetchingRunningJob: false,
  fetchingKillingJob: false,
  fetchingJobLogs: false,
  killingJob: "",
  runningSuspendState: "",
  runningJobLogs: {},
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_FETCH_RUNNINGJOBS: {
      return state.set("fetchingRunningJob", true);
    }
    case FETCH_RUNNINGJOB_LIST: {
      return state
        .set("runningJobList", action.data)
        .set("fetchingRunningJob", false);
    }
    case FETCH_CLIENT_RUNNINGJOB_LIST: {
      return state
        .set("runningClientJobList", action.data)
        .set("fetchingRunningJob", false);
    }
    case INIT_FETCH_KILLJOB: {
      return state.set("killingJob", action.data);
    }
    case INIT_FETCH_JOB_LOGS: {
      return state.set("fetchingJobLogs", true);
    }
    case FETCH_JOB_LOGS: {
      return state.set("runningJobLogs", action.data);
    }
    case FETCH_JOB_LOGS_COMPLETE: {
      return state.set("fetchingJobLogs", false);
    }
    case FETCH_RUNNING_JOB_STATUS: {
      return state.set("runningSuspendState", action.data);
    }
    case APPLY_RUNNING_JOBS_INITAL_VALUE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
