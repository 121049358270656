import { all, call, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from "../../../config/index";
import {
    GetCalculatedClaimValues,
} from "../../../config/APINames";
import {
    INIT_CALCULATED_CLAIM_VALUES,
} from "./actionTypes";

export default function* watchLogin() {
    yield all([
        takeLatest(INIT_CALCULATED_CLAIM_VALUES, initCalculatedClaimValuesSaga),
    ]);
}

function* initCalculatedClaimValuesSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            GetCalculatedClaimValues,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification(
                "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                "danger"
            );
        } else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    } catch (error) {
        if (action.callback) action.callback(error);
    }
}
