import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Switch from "../Switch";
import "./style.scss";

const LabeledSwitch = (props) => {
  const { t } = useTranslation();
  const checklabel = `default-label ${props.required ? `required` : ``}`;
  return (
    <div className="labeled-switch">
      <label htmlFor={props.id} className={checklabel}>
        {props.label}
      </label>
      <Switch
        handleCheck={props.handleCheck}
        checked={props.checked}
        label="&nbsp;"
        {...props}
      />
      <span className={`label ${props.checked ? "true" : ""}`}>
        {props.checked ? t("atcat.yes") : t("atcat.no")}
      </span>
    </div>
  );
};
LabeledSwitch.propTypes = {
  checked: PropTypes.any,
  disabled: PropTypes.any,
  handleCheck: PropTypes.any,
  id: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
};
export default LabeledSwitch;
