import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
const Switch = (props) => {
  return (
    <label className="switch" htmlFor={props.id}>
      <input
        id={props.id}
        name={props.name}
        className="hidden-checkbox"
        type="checkbox"
        onChange={props.handleCheck}
        aria-label={props.ariaLabel || props.label}
        checked={props.checked}
        disabled={props.disabled}
      />
      <div
        className={`slider  ${props.disabled ? `slider-disabled` : ``}`}
        disabled={props.disabled}
      ></div>
    </label>
  );
};
Switch.propTypes = {
  checked: PropTypes.any,
  disabled: PropTypes.any,
  handleCheck: PropTypes.any,
  id: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
};
export default Switch;
