import { fromJS } from "immutable";
import {
  FETCHING_JOB_FILTER_COL_NAMES,
  FETCHING_JOB_LIST,
  JOB_FILTER_COL_NAMES_FETCHED,
  JOB_FILTER_COL_NAMES_FETCH_COMPLETE,
  JOB_FILTER_COL_NAMES_FETCH_FAIL,
  JOB_LIST_FETCHED,
  JOB_LIST_FETCH_COMPLETE,
  JOB_LIST_FETCH_FAIL,
  FETCHING_JOB_COUNT,
  FETCH_JOB_COUNT,
  JOB_COUNT_FETCHING_COMPLETE,
  FETCHING_JOB_DETAILS,
  FETCH_JOB_DETAILS,
  JOB_DETAILS_FETCHING_COMPLETE,
  ASSIGN_INITAL_DATA,
  INIT_CLIENT_COMPLETED_JOB_LIST,
  INIT_CLIENT_JOB_COUNT,
} from "./actionTypes";

export const initialState = fromJS({
  jobList: [],
  fetchingJobList: false,
  columnList: [],
  fetchingColumns: false,
  fetchingJobCount: false,
  jobCount: 0,
  fetchingJobDetails: false,
  jobDetails: {},
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_JOB_LIST:
    case INIT_CLIENT_COMPLETED_JOB_LIST: {
      return state.set("fetchingJobList", true).set("jobList", []);
    }
    case JOB_LIST_FETCHED: {
      return state.set("jobList", action.data);
    }
    case JOB_LIST_FETCH_COMPLETE:
    case JOB_LIST_FETCH_FAIL: {
      return state.set("fetchingJobList", false);
    }
    case FETCHING_JOB_FILTER_COL_NAMES: {
      return state.set("fetchingColumns", true).set("columnList", []);
    }
    case JOB_FILTER_COL_NAMES_FETCHED: {
      return state.set("columnList", action.data);
    }
    case JOB_FILTER_COL_NAMES_FETCH_COMPLETE:
    case JOB_FILTER_COL_NAMES_FETCH_FAIL: {
      return state.set("fetchingColumns", false);
    }
    case FETCHING_JOB_COUNT:
    case INIT_CLIENT_JOB_COUNT: {
      return state.set("fetchingJobCount", true);
    }
    case FETCH_JOB_COUNT: {
      return state.set("jobCount", action.data);
    }

    case JOB_COUNT_FETCHING_COMPLETE: {
      return state.set("fetchingJobCount", false);
    }
    case FETCHING_JOB_DETAILS: {
      return state.set("fetchingJobDetails", true);
    }
    case FETCH_JOB_DETAILS: {
      return state.set("jobDetails", action.data);
    }

    case JOB_DETAILS_FETCHING_COMPLETE: {
      return state.set("fetchingJobDetails", false);
    }
    case ASSIGN_INITAL_DATA: {
      return state
        .set("jobList", [])
        .set("fetchingJobList", false)
        .set("columnList", [])
        .set("fetchingColumns", false)
        .set("fetchingJobCount", false)
        .set("jobCount", 0)
        .set("fetchingJobDetails", false)
        .set("jobDetails", {});
    }
    default:
      return state;
  }
};

export default reducer;
