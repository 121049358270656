import {
  INIT_GET_GENERAL_REPORTS_LIST,
  FETCH_GENERAL_REPORTS_LIST,
} from "./actionTypes";
export function initGetGeneralReportsList(payload) {
  return {
    type: INIT_GET_GENERAL_REPORTS_LIST,
    payload,
  };
}

export function fetchGeneralReportsList(data) {
  return {
    type: FETCH_GENERAL_REPORTS_LIST,
    data,
  };
}
