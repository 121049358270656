import React from "react";

const Unlock = (props) => {
  return (
    <svg className="icons" viewBox="0 0 14 14">
      <g
        id="Login-Screen"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Administration---User-Management-manageuser-unlock"
          transform="translate(-1180.000000, -387.000000)"
          fill="#15181B"
        >
          <g id="Group-3" transform="translate(262.000000, 242.000000)">
            <g id="Group-20" transform="translate(24.000000, 80.000000)">
              <g id="Group-4" transform="translate(0.000000, 48.000000)">
                <g
                  id="Group-6-Copy-7"
                  transform="translate(878.000000, 0.000000)"
                >
                  <g
                    id="\-Common/02-Regular/unlock"
                    transform="translate(16.875000, 16.999900)"
                  >
                    <path
                      d="M10.9375,6.56259956 L3.5,6.56259956 L3.5,3.97041206 C3.5,2.52666205 4.65117189,1.32627142 6.09765627,1.31259955 C7.55781252,1.2961933 8.51904298,1.53086128 8.75000002,2.93759956 L8.75000002,3.59384956 C8.75000002,3.95752143 9.04257815,4.25009956 9.40625002,4.25009956 C9.7699219,4.25009956 10.0625,3.95752143 10.0625,3.59384956 L10.0625,2.97588081 C9.83825685,0.70957222 8.31250002,0.00556829637 6.13320314,1.22446891e-05 C3.95937501,-0.00536920366 2.18750001,1.76377143 2.18750001,3.93759956 L2.18750001,6.56259956 L1.3125,6.56259956 C0.587890626,6.56259956 0,7.15049019 0,7.87509957 L0,12.6875996 C0,13.412209 0.587890626,14.0000996 1.3125,14.0000996 L10.9375,14.0000996 C11.6621094,14.0000996 12.25,13.412209 12.25,12.6875996 L12.25,7.87509957 C12.25,7.15049019 11.6621094,6.56259956 10.9375,6.56259956 Z M10.9375,12.6875996 L1.3125,12.6875996 L1.3125,7.87509957 L10.9375,7.87509957 L10.9375,12.6875996 Z"
                      id="Worfklow,-Status-&amp;-UI/02-Regular/unlock"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Unlock;
