import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../../config/axiosCall";
import { postRequestDetails } from "../../../../config/requestHeaders";
import { fetchClientList, storeAdminDetails } from "./actions";
import { loginFailed, updateAuthDetails } from "../../../Login/store/actions";
import { storage } from "../../../../config/storage";
import { AtcatAdminName, Error_Msg } from "../../../../config";
import { generateNotification } from "../../../../helper/generateNotification";
import {
  ClientMClientList,
  GetClientAssigned,
  ClientMSwitchClient,
  ClientSystemParamGetClientSystemParameter,
  ClientDashboardMobileURL,
} from "../../../../config/APINames";
import {
  INIT_CLIENT_LIST,
  INIT_SWITCH_CLIENT,
  INIT_CLIENT_SYSTEM_PARAMETERS,
} from "./actionTypes";
import { initSetRoute } from "../../../../store/actions";
import { fetchClientSystemParameters, fetchMobileReport } from "./actions";
export default function* watchHeader() {
  yield all([takeLatest(INIT_CLIENT_LIST, initClientList)]);
  yield all([takeLatest(INIT_SWITCH_CLIENT, initSwitchClient)]);
  yield all([
    takeLatest(INIT_CLIENT_SYSTEM_PARAMETERS, initClientSystemParametersSaga),
  ]);
}

function* initClientList(action) {
  const { payload } = action || {};
  try {
    let requestDetails = postRequestDetails();
    payload.role === "ServiceUser"
      ? yield (requestDetails.data = {
          userId: payload.userId,
        })
      : yield (requestDetails.data = {
          clientname: payload.clientName,
          userName: payload.userName,
          filterCriteria: "SwitchClient",
        });
    let response = yield call(
      axios,
      payload.role === "ServiceUser" ? GetClientAssigned : ClientMClientList,
      requestDetails
    );
    response =
      payload.role === "ServiceUser"
        ? response.data.ClientName !== undefined
          ? response.data.ClientName
          : []
        : response.data;
    yield put(fetchClientList(response));
  } catch (error) {
    yield put(loginFailed(error));
  }
}

function* initSwitchClient(action) {
  const { payload } = action || {};
  try {
    let requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, ClientMSwitchClient, requestDetails);
    response = response.data;
    if (response.ClientName !== undefined) {
      const sessionDetails = JSON.parse(storage.getItem("loginDetails"));
      if (sessionDetails.clientName.toUpperCase() === AtcatAdminName) {
        storage.setItem("adminDetails", JSON.stringify(sessionDetails));
        yield put(storeAdminDetails(sessionDetails));
      }
      let data = {
        ...sessionDetails,
        loginstatus: true,
        clientName: response.ClientName,
        clientId: response.ClientID,
        logo: response.Logo,
        atcatVersion: response.IsAtcatLite,
      };
      storage.setItem("loginDetails", JSON.stringify(data));
      yield put(updateAuthDetails(data));
      yield put(initSetRoute({ pathname: "Home" }));
      yield (requestDetails.data = {
        clientName: payload.clientName,
      });
        let resp = yield call(axios, ClientDashboardMobileURL, requestDetails);
        storage.setItem('mobileReportURL', resp.data.mobileReportURL);
      resp =
        resp.data !== undefined && resp.data.isReportAvailable !== undefined
          ? Boolean(resp.data.isReportAvailable)
          : false;
      yield put(fetchMobileReport(resp));
        storage.setItem('mobileReportAvailble', resp);
    } else {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    }
  } catch (error) {
    yield put(loginFailed(error));
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initClientSystemParametersSaga(action) {
  const { payload } = action || {};
  try {
    let requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ClientSystemParamGetClientSystemParameter,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchClientSystemParameters(response.data));
    }
    storage.setItem("clientSystemParameters", JSON.stringify(response.data));
  } catch (error) {
    yield put(loginFailed(error));
  }
}
