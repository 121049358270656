import { all, call, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from "../../../config";
import {
    ManualDataloadDupsDataloadInfo,
    ManualDataloadDupsDupsInfo,
    ManualDataloadDupsUploadAP,
    ManualDataloadDupsUploadPH,
    ManualDataloadDupsUploadVoid,
    ManualDataloadDupsRunDataload,
    ManualDataloadDupsRunDups,
} from "../../../config/APINames";
import {
    INIT_DATALOAD_INFO,
    INIT_DUPS_INFO,
    INIT_INSERT_AP_DATA,
    INIT_INSERT_PH_DATA,
    INIT_INSERT_VOID_DATA,
    INIT_RUN_DATA_LOAD,
    INIT_RUN_DUPS,
} from "./actionTypes";
import {
} from "./actions";

export default function* watchLogin() {
    yield all([
        takeLatest(INIT_DATALOAD_INFO, initDataloadInfoSaga),
        takeLatest(INIT_DUPS_INFO, initDupsInfoSaga),
        takeLatest(INIT_INSERT_AP_DATA, initInsertAPDataSaga),
        takeLatest(INIT_INSERT_PH_DATA, initInsertPHDataSaga),
        takeLatest(INIT_INSERT_VOID_DATA, initInsertVoidDataSaga),
        takeLatest(INIT_RUN_DATA_LOAD, initRunDataloadSaga),
        takeLatest(INIT_RUN_DUPS, initRunDupsSaga),
    ]);
}

function* initDataloadInfoSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualDataloadDupsDataloadInfo,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    }
    catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function* initDupsInfoSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualDataloadDupsDupsInfo,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    }
    catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function* initInsertAPDataSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualDataloadDupsUploadAP,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    }
    catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function* initInsertPHDataSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualDataloadDupsUploadPH,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    }
    catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function* initInsertVoidDataSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualDataloadDupsUploadVoid,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    }
    catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function* initRunDataloadSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualDataloadDupsRunDataload,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    }
    catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function* initRunDupsSaga(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ManualDataloadDupsRunDups,
            requestDetails
        );
        if (response.data === Error_Msg) {
            generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
        }
        else if (response !== Error_Msg) {
            if (action.callback) action.callback(response);
        }
    }
    catch (error) {
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}
