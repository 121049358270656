import {
  INIT_FETCH_RUNNINGJOBS,
  FETCH_RUNNINGJOB_LIST,
  INIT_FETCH_KILLJOB,
  INIT_FETCH_RUNNINGJOB_DETAILS,
  INIT_FETCH_JOB_LOGS,
  FETCH_JOB_LOGS,
  INIT_FETCH_RUNNING_JOB_STATUS,
  FETCH_RUNNING_JOB_STATUS,
  INIT_FETCH_CLIENT_RUNNINGJOBS,
  FETCH_CLIENT_RUNNINGJOB_LIST,
  FETCH_JOB_LOGS_COMPLETE,
  APPLY_RUNNING_JOBS_INITAL_VALUE,
} from "./actionTypes";

export const initfetchRunningJobs = (payload, callback) => {
  return {
    type: INIT_FETCH_RUNNINGJOBS,
    payload,
    callback,
  };
};
export const initfetchClientRunningJobs = (payload, callback) => {
  return {
    type: INIT_FETCH_CLIENT_RUNNINGJOBS,
    payload,
    callback,
  };
};
export const fetchClientRunningJobList = (data) => {
  return {
    type: FETCH_CLIENT_RUNNINGJOB_LIST,
    data,
  };
};
export const fetchRunningJobList = (data) => {
  return {
    type: FETCH_RUNNINGJOB_LIST,
    data,
  };
};

export const initfetchKillJob = (payload) => {
  return {
    type: INIT_FETCH_KILLJOB,
    payload,
  };
};
export const initfetchRunningJobDetails = (payload, callback) => {
  return {
    type: INIT_FETCH_RUNNINGJOB_DETAILS,
    payload,
    callback,
  };
};
export const initfetchJobLogs = (payload, callback) => {
  return {
    type: INIT_FETCH_JOB_LOGS,
    payload,
    callback,
  };
};
export const fetchJobLogs = (data) => {
  return {
    type: FETCH_JOB_LOGS,
    data,
  };
};
export const fetchJobLogsComplete = (data) => {
  return {
    type: FETCH_JOB_LOGS_COMPLETE,
    data,
  };
};
export const initfetchRunningJobStatus = (payload, callback) => {
  return {
    type: INIT_FETCH_RUNNING_JOB_STATUS,
    payload,
    callback,
  };
};

export function fetchRunningSuspendState(data) {
  return {
    type: FETCH_RUNNING_JOB_STATUS,
    data,
  };
}
export const applyIntialValue = () => {
  return {
    type: APPLY_RUNNING_JOBS_INITAL_VALUE,
  };
};
