import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { generateNotification } from "../../../helper/generateNotification";
import {
    postRequestDetails,
    postNoCacheRequestDetails,
    nbcuTokenPostRequestDetails,
    nbcuClientInfoPostRequestDetails,
} from "../../../config/requestHeaders";
import {
    loginFailed,
    loginSuccess,
    handleLoginProcess,
    logOut,
    fetchapplicationName,
} from "./actions";
import { fetchClientSystemParameters } from "../../Layout/Header/store/actions";
import {
    GenerateOTP,
    GenerateToken,
    LoginLogoutSelectedLanguage,
    loginLogoutNonSSO,
    PasswordMangementGenerateOTP,
    PasswordManagementForgotPassword,
    ssocallbackauth,
    loginLogoutSSO,
    ApplicationName,
    ClientSystemParamGetClientSystemParameter,
    ClientMGetResponse,
    ClientMGetClientAuthenticationDetails,
    ClientMGetClientTokenDetails,
} from "../../../config/APINames";
import {
    INIT_LOGIN,
    VALIDATE_OTP,
    INIT_LOGIN_PROCESS,
    INIT_FORGOT_PASSWORD,
    HANDLE_FORGOT_PASSWORD,
    INIT_LOGOUT,
    SSO_CALLBACK_AUTH,
    INIT_APPLICATION_NAME,
    INIT_NBCU_BASIC_AUTH,
    INIT_NBCU_CLIENT_TOKEN_DETAILS,
} from "./actionTypes";
import {
    Error_Msg,
    AtcatAdminName,
    ProvideValidInfo,
    IncorrectCredentials,
    PasswordLimitExceed,
    OTPError,
    PasswordChangeMsg,
} from "../../../config";
import { storage } from "../../../config/storage";
export default function* watchLogin() {
    yield all([
        takeLatest(INIT_LOGIN, initLoginSaga),
        takeLatest(VALIDATE_OTP, initNonSsoOtpSaga),
        takeLatest(INIT_LOGIN_PROCESS, initLoginProcess),
        takeLatest(INIT_FORGOT_PASSWORD, initForgotPassword),
        takeLatest(HANDLE_FORGOT_PASSWORD, verifyForgotPassword),
        takeLatest(INIT_LOGOUT, logOutSaga),
        takeLatest(SSO_CALLBACK_AUTH, ssoCallbackAuthSaga),
        takeLatest(INIT_APPLICATION_NAME, getApplicationName),
        takeLatest(INIT_NBCU_BASIC_AUTH, initNBCUTokenSaga),
        takeLatest(INIT_NBCU_CLIENT_TOKEN_DETAILS, initNBCUClientTokenDetailsSaga),
    ]);
}

function* initLoginSaga(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = { ...postNoCacheRequestDetails };
        yield (requestDetails.data = {
            UserName: payload.userName,
            NewPassword: payload.password,
            ClientName: payload.clientName,
        });
        let response = yield call(axios, GenerateOTP, requestDetails);
        response = response.data;
        let responses = {
            message: response,
        };
        if (response === ProvideValidInfo || response === IncorrectCredentials) {
            responses = {
                message: response,
                flag: false,
                type: "danger",
            };
            callback(responses);
        }
        else if (response.ErrorMessage === IncorrectCredentials) {
            responses = {
                message: response.ErrorMessage,
                flag: false,
                type: "danger",
            };
            callback(responses);
        } else if (response !== Error_Msg) {
            responses = {
                message: response,
                flag: true,
                type: "success",
            };
            callback(responses);
        } else {
            responses = {
                message: response,
                flag: false,
                type: "danger",
            };
            callback(responses);
        }
    } catch (error) {
        yield put(loginFailed(error));
    }
}

function* initNonSsoOtpSaga(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = { ...postNoCacheRequestDetails };
        yield (requestDetails.data = {
            UserName: payload.userName,
            Password: payload.password,
            ClientName: payload.clientName,
            OTP: payload.otp,
        });
        let response = yield call(axios, GenerateToken, requestDetails);
        response = response.data;
        storage.setItem("access_token", response.accessToken);
        storage.setItem("refresh_token", response.refresh_token);
        storage.setItem("ExpiresAt", response.expires);
        const userRenewalTokenTime = getUserLoginTimeForToken();
        storage.setItem("UserRenewalTokenTime", userRenewalTokenTime);
        localStorage.setItem("Atcat", "10593");
        let data = {
            userName: payload.userName,
            password: payload.password,
            clientName: payload.clientName,
            otp: payload.otp,
        };
        if (response === IncorrectCredentials) {
            generateNotification(
                "atcat.incorrectCredentialsPleaseContactAtcatSupportTeam",
                "danger"
            );
        } else if (response === OTPError) {
            generateNotification(OTPError, "danger");
        } else yield put(handleLoginProcess(data, callback));
    } catch (error) {
        yield put(loginFailed(error));
    }
}

function* initLoginProcess(action) {
    const { payload } = action || {};
    try {
        let langugeRequestDetails = postRequestDetails();
        yield (langugeRequestDetails.data = {
            UName: payload.userName,
            SelectedLanguage: "",
            ClientName: payload.clientName,
        });

        let response = yield call(
            axios,
            LoginLogoutSelectedLanguage,
            langugeRequestDetails
        );
        response = response.data;
        response = response.split(",");
        const timezone = response[1];
        const availableLang = response[0];
        let nonSSORequestDetails = postRequestDetails();
        yield (nonSSORequestDetails.data = {
            UserName: payload.userName,
            Password: payload.password,
            ClientName: payload.clientName,
            OTP: payload.otp,
        });
        response = yield call(axios, loginLogoutNonSSO, nonSSORequestDetails);
        response = response.data;
        response = {
            ...response,
            userName: payload.userName,
            timezone: timezone,
            loginTime: new Date(response.LoginTime).toLocaleString(),
            availableLang: availableLang,
        };
        const data = generateJson(response);
        yield put(loginSuccess(data));
        if (payload.clientName.toUpperCase() !== AtcatAdminName) {
            let systemParameterRequestDetails = postRequestDetails();
            yield (systemParameterRequestDetails.data = {
                userName: payload.userName,
                clientName: payload.clientName,
            });
            response = yield call(
                axios,
                ClientSystemParamGetClientSystemParameter,
                systemParameterRequestDetails
            );
            if (response.data === Error_Msg) {
                generateNotification(
                    "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
                    "danger"
                );
            } else if (response !== Error_Msg) {
                yield put(fetchClientSystemParameters(response.data));
            }
            storage.setItem("clientSystemParameters", JSON.stringify(response.data));
        }
    } catch (error) {
        yield put(loginFailed(error));
    }
}
function* initForgotPassword(action) {
    const { payload, callback } = action || {};
    try {
        let requestDetails = { ...postNoCacheRequestDetails };
        yield (requestDetails.data = {
            ClientName: payload.clientName,
            UserName: payload.userName,
        });
        let response = yield call(
            axios,
            PasswordMangementGenerateOTP,
            requestDetails
        );
        response = response.data;
        if (
            response === ProvideValidInfo ||
            response === PasswordLimitExceed ||
            response === Error_Msg ||
            response === IncorrectCredentials
        ) {
            let modalFlag = {
                message: response,
                flag: false,
                type: "danger",
            };
            callback(modalFlag);
        } else if (response.ErrorMessage === IncorrectCredentials) {
            let modalFlag = {
                message: response.ErrorMessage,
                flag: false,
                type: "danger",
            };
            callback(modalFlag);
        } else {
            let modalFlag = {
                message: response,
                flag: true,
                type: "success",
            };
            callback(modalFlag);
        }
    } catch (error) {
        yield put(loginFailed(error));
    }
}

function* verifyForgotPassword(action) {
    const { payload, callback } = action || {};
    try {
        let requestDetails = { ...postNoCacheRequestDetails };
        yield (requestDetails.data = {
            ClientName: payload.clientName,
            UserName: payload.userName,
            OTP: payload.otp,
            NewPassword: payload.newPassword,
            ConfirmPassword: payload.confirmPassword,
        });
        let response = yield call(
            axios,
            PasswordManagementForgotPassword,
            requestDetails
        );
        response = response.data;
        if (response === PasswordChangeMsg) {
            let modalFlag = {
                message: response,
                flag: false,
                type: "success",
            };
            callback(modalFlag);
        } else if (
            response === OTPError ||
            response.ErrorMessage === IncorrectCredentials
        ) {
            let modalFlag = {
                message: response,
                flag: true,
                type: "danger",
            };
            callback(modalFlag);
        } else {
            let validation = [];
            for (let i = 0; i < response.length; i++) {
                validation.push("*" + response[i]);
            }
            let modalFlag = {
                message: validation.join(" ").trim(),
                flag: true,
                type: "danger",
            };
            callback(modalFlag);
        }
    } catch (error) {
        yield put(loginFailed(error));
    }
}
function* logOutSaga(action) {
    const { callback } = action || {};
    yield put(logOut());
    yield storage.removeItem("access_token");
    yield storage.removeItem("refresh_token");
    yield storage.removeItem("ExpiresAt");
    yield storage.removeItem("UserRenewalTokenTime");
    yield storage.removeItem("RenewTokenFlag");
    yield storage.removeItem("loginDetails");
    yield storage.removeItem("i18nextLng");
    yield storage.removeItem("currPath");
    yield storage.removeItem("activeModule");
    yield storage.removeItem("visited");
    yield localStorage.removeItem("Atcat");

    callback && callback();
}
function* ssoCallbackAuthSaga(action) {
    const { payload } = action || {};
    try {
        let ssoCallbackRequestDetails = postRequestDetails();
        yield (ssoCallbackRequestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ssocallbackauth,
            ssoCallbackRequestDetails
        );
        response = response.data;
        let access_token = response.accessToken;
        let refresh_token = response.refresh_token;
        let userName = response.UserName.split("@")[0];
        let clientName = response.ClientName;
        storage.setItem("access_token", access_token);
        storage.setItem("refresh_token", refresh_token);
        storage.setItem("ExpiresAt", response.expires);
        const userRenewalTokenTime = getUserLoginTimeForToken();
        storage.setItem("UserRenewalTokenTime", userRenewalTokenTime);
        localStorage.setItem("Atcat", "10593");
        let getSelectedLanguageRequestDetails = postRequestDetails();
        yield (getSelectedLanguageRequestDetails.data = {
            UName: userName,
            ClientName: clientName,
            SelectedLanguage: "",
        });
        response = yield call(
            axios,
            LoginLogoutSelectedLanguage,
            getSelectedLanguageRequestDetails
        );
        response = response.data;
        if (
            response !==
            "Request could not be processed at this time. Please contact support team."
        ) {
            let timezone = response.split(",")[1];
            let availableLang = response.split(",")[0];

            let ssoRequestDetails = postRequestDetails();
            yield (ssoRequestDetails.data = {
                ClientName: clientName,
                UserName: userName,
            });
            response = yield call(axios, loginLogoutSSO, ssoRequestDetails);
            response = response.data;
            if (response.Status !== "Fail") {
                let loginTime = response.LoginTime.split(".")[0];
                loginTime = new Date(loginTime).toLocaleString();
                response = {
                    ...response,
                    userName: userName,
                    timezone: timezone,
                    availableLang: availableLang,
                    loginTime: loginTime,
                };
                const data = generateJson(response);
                yield put(loginSuccess(data));
            } else {
                generateNotification(response.ErrorMessage, "danger");
            }
        } else {
            generateNotification(
                "atcat.incorrectCredentialsPleaseContactAtcatSupportTeam",
                "danger"
            );
        }
    } catch (error) {
        yield put(loginFailed(error));
    }
}

function* getApplicationName(action) {
    let applicationname = postRequestDetails();
    try {
        let response = yield call(axios, ApplicationName, applicationname);

        if (response && response.status === 200) {
            yield put(fetchapplicationName(response.data));
        }
    } catch (error) {
        yield put(loginFailed(error));
    }
}
function setUserRole(roleId, clientName) {
    let sourceDomain = 0;
    let role = "";
    if (clientName.toUpperCase() === AtcatAdminName) {
        sourceDomain = 0;
        switch (roleId) {
            case 1:
                role = "SuperUser";
                break;
            case 2:
                role = "ServiceUser";
                break;
            case 3:
                role = "ServiceReader";
                break;
            default:
                role = "";
                break;
        }
    } else {
        sourceDomain = 1;
        switch (roleId) {
            case 1:
                role = "ClientAdministrator";
                break;
            case 2:
                role = "Supervisor";
                break;
            case 3:
                role = "Auditor";
                break;
            case 4:
                role = "Reader";
                break;
            default:
                role = "";
                break;
        }
    }
    return { sourceDomain, role };
}
function generateJson(response) {
    const userDetails = setUserRole(response.RoleId, response.ClientName);
    const config = {
        loginstatus: true,
        sourceDomain: userDetails.sourceDomain,
        role: userDetails.role,
        userName: response.userName,
        timezone: response.timezone,
        availableLang: response.availableLang,
        clientName: response.ClientName,
        roleId: response.RoleId,
        userId: response.UserId,
        clientId: response.clientID,
        customClientId: response.customClientID,
        ipqcClientId: response.ipqcCustomClientID,
        logo: response.logo,
        tokenExpireTime: response.tokenExpireTime,
        loginTime: response.loginTime,
        name: response.Name,
        atcatVersion: response.IsAtcatLite,
        selectedLanguage: "en",
        clientCount: response.clientCount,
        serverTimeZone:response.serverTimeZone,
    };
    storage.setItem("loginDetails", JSON.stringify(config));
    return config;
}

function getUserLoginTimeForToken() {
    var today = new Date();
    var expireTime = parseInt(storage.getItem("ExpiresAt"));
    today.setMinutes(today.getMinutes() + (expireTime - 5));
    return today;
}

function* initNBCUTokenSaga(action) {
    const { payload, callback } = action || {};
    try {
        let nbcuTokenRequestDetail = nbcuTokenPostRequestDetails();
        yield (nbcuTokenRequestDetail.data = {
            ClientName: payload.clientName,
        });
        let response = yield call(
            axios,
            ClientMGetResponse,
            nbcuTokenRequestDetail
        );
        storage.setItem(
            "nbcuClientToken",
            JSON.stringify(response.data.accessToken)
        );
        let getNBCUClientInforRequestDetails = nbcuClientInfoPostRequestDetails();
        yield (getNBCUClientInforRequestDetails.data = {
            clientName: payload.clientName,
        });
        response = yield call(
            axios,
            ClientMGetClientAuthenticationDetails,
            getNBCUClientInforRequestDetails
        );
            callback(response.data);
    } catch (error) {
        yield put(loginFailed(error));
    }
}

function* initNBCUClientTokenDetailsSaga(action) {
    const { payload, callback } = action || {};
    try {
        let nbcuClientTokenRequestDetail = nbcuClientInfoPostRequestDetails();
        yield (nbcuClientTokenRequestDetail.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ClientMGetClientTokenDetails,
            nbcuClientTokenRequestDetail
        );
        callback(response.data);
    } catch (error) {
        yield put(loginFailed(error));
    }
}
