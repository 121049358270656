import {
  INIT_DATA_VALIDATION_REPORT_LIST,
  FETCH_DATA_VALIDATION_REPORT_LIST,
} from "./actionTypes";

export const initDataValidationReportList = (payload) => {
  return {
    type: INIT_DATA_VALIDATION_REPORT_LIST,
    payload,
  };
};
export const fetchDataValidationReportList = (data) => {
  return {
    type: FETCH_DATA_VALIDATION_REPORT_LIST,
    data,
  };
};
