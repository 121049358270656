import React, { Component } from "react";
import "react-notifications-component/dist/theme.css";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { storage } from "./config/storage";
import ReactNotification from "react-notifications-component";
import {
    loginSuccess,
    updateAuthDetails,
    ssoCallbackAuth,
    initNBCUClientTokenDetails,
} from "./containers/Login/store/actions";
import { storeAdminDetails } from "./containers/Layout/Header/store/actions";
import { fetchMobileReport } from "./containers/Layout/Header/store/actions";
import getReducerInjectors from "./redux-utils/reducerInjectors";
import getSagaInjectors from "./redux-utils/sagaInjectors";
import { reducerDesc, sagaDesc } from "./helper/ReducerSagaInjector";
import i18next from "i18next";
import Layout from "./components/Layout";
import { selectLoginStatus } from "./containers/Login/store/selectors";
import { PublicClientApplication } from "@azure/msal-browser";
import { config, loginRequest, MsalConfig } from "../src/config/msalConfig";

class App extends Component {
    reducerInjector = getReducerInjectors(this.props.store);
    sagaInjector = getSagaInjectors(this.props.store);
    state = {};

    componentDidMount() {
        const { injectReducer } = this.reducerInjector;
        const { injectSaga } = this.sagaInjector;
        const clientAuthenticationDetails = JSON.parse(storage.getItem("clientUserConfiguration"));
        const ssoLoginDetails = JSON.parse(storage.getItem("ssoLoginDetails"));
        reducerDesc.map(({ key, reducer }) => injectReducer(key, reducer));
        sagaDesc.map(({ key, saga }) => injectSaga(key, { saga }));
        this.props.updateAuthDetails({
            selectedLanguage: "EN",
            sourceDomain: 0,
            role: "",
            roleId: 0,
            atcatVersion: 0,
        });
        let sessionDetails = JSON.parse(storage.getItem("loginDetails"));
        if (sessionDetails !== null) {
            i18next.changeLanguage(sessionDetails.selectedLanguage);
            this.props.loginSuccess(sessionDetails);
        }
        let adminDetails = JSON.parse(storage.getItem("adminDetails"));
        if (adminDetails !== null) {
            this.props.storeAdminDetails(adminDetails);
        }
        const isMobileReportAvailable = storage.getItem("mobileReportAvailble");
        if (isMobileReportAvailable !== null &&
            isMobileReportAvailable !== undefined
        ) {
            this.props.fetchMobileReport(Boolean(isMobileReportAvailable));
        }
        if (ssoLoginDetails !== null) {
            if (clientAuthenticationDetails?.ProviderType?.toUpperCase() === "NBCU") {
                this.nbcuSSOFunction();
            } else if (clientAuthenticationDetails?.ProviderType?.toUpperCase() === "MSAL" || ssoLoginDetails?.SSOType === "Accenture User") {
                this.ssoFunction();
            }
        }
    }
    ssoFunction = () => {
        const clientAuthenticationDetails = JSON.parse(storage.getItem("clientUserConfiguration"));
        const ssoLoginDetails = JSON.parse(storage.getItem("ssoLoginDetails"));
        const selectConfig = ssoLoginDetails?.SSOType === "Accenture User" ? config : MsalConfig(clientAuthenticationDetails);
        const configMsal = new PublicClientApplication(selectConfig);
        configMsal
            .handleRedirectPromise()
            .then((response) => {
                if (response) {
                    const account = {
                        ...loginRequest,
                        account: response.account,
                    };
                    this.ssoAcquireToken(account);
                }
            })
            .catch((error) => { });
    };
    ssoAcquireToken = (request) => {
        const clientAuthenticationDetails = JSON.parse(storage.getItem("clientUserConfiguration"));
        const ssoLoginDetails = JSON.parse(storage.getItem("ssoLoginDetails"));
        const selectConfig = ssoLoginDetails?.SSOType === "Accenture User" ? config : MsalConfig(clientAuthenticationDetails);
        const configMsal = new PublicClientApplication(selectConfig);
        return configMsal
            .acquireTokenSilent(request)
            .then((loginResponse) => {
                if (loginResponse) {
                    let id_Token = loginResponse.idToken.replace(/ /g, "_");
                    let ssoLoginDetails = JSON.parse(storage.getItem("ssoLoginDetails"));
                    let payload = {
                        AccessToken: id_Token,
                        ClientName: ssoLoginDetails.clientName,
                        StartTime: "",
                        TokenType: "bearer",
                        userName: "",
                    };
                    this.props.ssoCallbackAuth(payload);
                    storage.removeItem("clientUserConfiguration");
                    return true;
                }
            })
            .catch((error) => {
                return configMsal.acquireTokenRedirect(request);
            });
    };
    nbcuSSOFunction = () => {
        let ssoLoginDetails = JSON.parse(storage.getItem("ssoLoginDetails"));
        let nbcuAccessToken = window.location.hash.split('access_token=')[1].split('&')[0];
        let payload = {
            accessToken: nbcuAccessToken,
            TokenType: "bearer",
            ClientName: ssoLoginDetails.clientName,
            StartTime: "",
            userName: "",
        };
        this.props.getNBCUClientTokenDetails(payload, (resp) => {
            let ssoCallBackPayload = {
                AccessToken: "",
                ClientName: ssoLoginDetails.clientName,
                StartTime: "",
                TokenType: "bearer",
                userName: resp.resultSet.username,
            };
            this.props.ssoCallbackAuth(ssoCallBackPayload);
            storage.removeItem("clientUserConfiguration");
        });
    };
    render() {
        return (
            <>
                <ReactNotification />
                <Layout
                    isAuthenticated={
                        process.env.REACT_APP_AUTH_STAT || this.props.isAuthenticated
                    }
                />
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isAuthenticated: selectLoginStatus(),
});
const mapDispatchToProps = (dispatch) => {
    return {
        loginSuccess: (data) => {
            dispatch(loginSuccess(data));
        },
        updateAuthDetails: (data) => dispatch(updateAuthDetails(data)),
        storeAdminDetails: (data) => {
            dispatch(storeAdminDetails(data));
        },
        ssoCallbackAuth: (payload) => {
            dispatch(ssoCallbackAuth(payload));
        },
        getNBCUClientTokenDetails: (payload, callback) => {
            dispatch(initNBCUClientTokenDetails(payload, callback));
        },
        fetchMobileReport: (data) => {
            dispatch(fetchMobileReport(data));
        },
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
