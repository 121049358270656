export const customStyles = {
  container: (base, state) => {
    const { customStyle } = state.selectProps;
    const { container } = customStyle || {};
    return {
      ...base,
      position: "relative",
      boxSizing: "border-box",
      width: "100%",
      height: "fit-content",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      ...container,
    };
  },
  control: (base, state) => {
    const { showError, isDisabled, visited, value, required, customStyle } =
      state.selectProps;
    const { control } = customStyle || {};
    return {
      ...base,
      borderStyle: "solid",
      borderWidth: `0.09375rem`,
      backgroundColor: isDisabled ? "rgb(239, 242, 245)" : "",
      minHeight: "2em",
      borderRadius: ".5em",
      cursor: isDisabled ? "not-allowed" : "pointer",
      "&:hover": {
        borderColor: "rgb(0, 65, 240)",
        boxShadow: "0rem 0rem 0.375rem rgb(102 187 255/ 0.5)",
      },
      borderColor: state.isFocused
        ? "rgb(0, 65, 240)"
        : showError || (visited && !value && required)
        ? "rgb(213, 0, 47)"
        : "rgb(223, 227, 232)",
      boxShadow: state.isFocused
        ? "0rem 0rem 0.375rem rgb(102 187 255/ 0.5);"
        : "none",
      ...control,
    };
  },
  valueContainer: (base, state) => {
    const { customStyle } = state.selectProps;
    const { valueContainer } = customStyle || {};
    return {
      ...base,
      ...valueContainer,
    };
  },
  singleValue: (base, state) => ({
    ...base,
    fontSize: "0.875rem",
    color: state.selectProps.isDisabled
      ? "rgb(181,192,202)"
      : "rgb(21, 24, 27)",
  }),

  input: (base) => ({
    ...base,
  }),
  indicatorSeparator: () => ({
    display: "none",
    backgroundColor: "transparent",
  }),

  dropdownIndicator: (base, state) => {
    const { customStyle } = state.selectProps;
    const { dropdownIndicator } = customStyle || {};
    return {
      ...base,
      padding: ".25em",
      color: state.isSelected
        ? "rgb(99, 115, 129)"
        : state.isFocused
        ? "rgb(99, 115, 129)"
        : "rgb(162, 185, 196)",
      ...dropdownIndicator,
    };
  },
  menu: (base, state) => {
    const { customStyle } = state.selectProps;
    const { menu } = customStyle || {};
    return {
      ...base,
      padding: "0.5rem",
      boxShadow: "0rem 0rem 0.375rem rgba(0, 0, 0, 0.15)",
      left: 0,
      zIndex: 3,
      margin: 0,
      ...menu,
    };
  },
  menuList: (base, state) => {
    const { customStyle } = state.selectProps;
    const { menuList } = customStyle || {};
    return {
      ...base,
      maxHeight: "10rem",
      ...menuList,
    };
  },

  option: (base, state) => ({
    ...base,
    borderRadius: "0.25em",
    backgroundColor: state.isSelected
      ? "rgb(244, 251, 255)"
      : state.isFocused
      ? "rgb(0, 65, 240)"
      : "rgb(255, 255, 255)",
    color: state.isSelected
      ? "rgb(0, 65, 240)"
      : state.isFocused
      ? "rgb(255, 255, 255)"
      : "black",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    fontFamily: "Graphik-Regular",
    fontSize: "0.875rem",
  }),
};
