import React, { useMemo, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { PAGE_SIZE_LIST } from "../../config/index";
import Select from "../SelectDropDowns/Select";
import Input from "../Inputs/Input";
import { ReactComponent as Back } from "../../assets/images/pagination/back.svg";
import { ReactComponent as First } from "../../assets/images/pagination/first.svg";
import { ReactComponent as Last } from "../../assets/images/pagination/last.svg";
import { ReactComponent as Next } from "../../assets/images/pagination/next.svg";
import customStyles from "./selectStyles";

const Pagination = (props) => {
  const { t } = useTranslation();
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    onPageSizeChange,
  } = props;
  const [pageNumber, setPageNumber] = useState("");
  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };
  let PageSizeDetails = [];
  for (let i = 0; i < PAGE_SIZE_LIST.length; i++) {
    PageSizeDetails.push({
      id: PAGE_SIZE_LIST[i],
      value: PAGE_SIZE_LIST[i],
    });
  }
  const usePagination = ({
    totalCount,
    pageSize,
    siblingCount,
    currentPage,
  }) => {
    const paginationRange = useMemo(() => {
      const totalPageCount = Math.ceil(totalCount / pageSize);

      const totalPageNumbers = siblingCount + 3;
      if (totalPageNumbers >= totalPageCount) {
        return range(1, totalPageCount);
      }

      const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
      const rightSiblingIndex = Math.min(
        currentPage + siblingCount,
        totalPageCount
      );

      const shouldShowLeftDots = leftSiblingIndex >= 2;
      const shouldShowRightDots = rightSiblingIndex <= totalPageCount - 2;

      if (!shouldShowLeftDots && shouldShowRightDots) {
        const leftItemCount = 2 + 2 * siblingCount;
        const leftRange = range(1, leftItemCount);

        return [...leftRange, "dots"];
      }

      if (shouldShowLeftDots && !shouldShowRightDots) {
        const rightItemCount = 2 + 2 * siblingCount;
        const rightRange = range(
          totalPageCount - rightItemCount + 1,
          totalPageCount
        );
        return ["dots", ...rightRange];
      }

      if (shouldShowLeftDots && shouldShowRightDots) {
        let middleRange = range(leftSiblingIndex, rightSiblingIndex);
        return ["dots", ...middleRange, "dots"];
      }
    }, [totalCount, pageSize, siblingCount, currentPage]);
    return paginationRange;
  };

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (!paginationRange || currentPage === 0 || totalCount === 0) {
    return null;
  }

  const totalPageCount = Math.ceil(totalCount / pageSize);

  const onNext = () => {
    currentPage < totalPageCount && onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    currentPage !== 1 && onPageChange(currentPage - 1);
  };

  const onClickDots = (isLeftDot) => {
    const computedPage = isLeftDot
      ? currentPage - 2 * siblingCount
      : currentPage + 2 * siblingCount;
    onPageChange(computedPage);
  };

  return (
    <div className="pagination">
      <ul range={paginationRange} className="pagination-items">
        <li>
          <button
            disabled={currentPage === 1}
            onClick={() => onPageChange(1)}
            className="icon-button"
            aria-label={t("atcat.goToFirstPage")}
          >
            <First aria-hidden="true" />
          </button>
        </li>
        <li className="previous-btn-item">
          <button
            disabled={currentPage === 1}
            onClick={onPrevious}
            className="icon-button"
            aria-label={t("atcat.goToPreviousPage")}
          >
            <Back aria-hidden="true" />
          </button>
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === "dots") {
            const isLeftDot = index < paginationRange.length - 1;
            return (
              <li key={index} onClick={() => onClickDots(isLeftDot)}>
                &#8230;
              </li>
            );
          }
          return (
            <li
              key={index}
              role="button"
              tabIndex={0}
              onClick={() => onPageChange(pageNumber)}
              className={`pages ${
                pageNumber === currentPage ? "active-page" : ""
              }`}
            >
              {pageNumber}
            </li>
          );
        })}
        <li className="next-btn-item">
          <button
            disabled={currentPage === totalPageCount}
            onClick={onNext}
            className="icon-button"
            aria-label={t("atcat.goToNextPage")}
          >
            <Next aria-hidden="true" />
          </button>
        </li>
        <li className="last-btn-item">
          <button
            disabled={currentPage === totalPageCount}
            onClick={() => onPageChange(totalPageCount)}
            className="icon-button"
            aria-label={t("atcat.goToLastPage")}
          >
            <Last aria-hidden="true" />
          </button>
        </li>
      </ul>
      <div className="page-size-wrapper">
        <div>{t("atcat.pageSize")}:</div>
        <Select
          options={PageSizeDetails}
          onChangeHandler={(sub) => {
            onPageSizeChange(sub.target.value);
          }}
          value={pageSize}
          styles={customStyles}
          isPagination={true}
        />
      </div>
      <div className="pagination-items">
        <div>{t("atcat.pageNo")}</div>
        <Input
          placeholder={currentPage}
          value={pageNumber}
          handleChange={(obj) => {
            if (
              parseInt(obj.target.value) > 0 &&
              parseInt(obj.target.value) <= totalPageCount &&
              obj.target.value !== ""
            ) {
              setPageNumber(obj.target.value);
            } else if (obj.target.value === "") {
              setPageNumber("");
            }
          }}
        />
        <div>
          {t("atcat.of")} {totalPageCount}
        </div>
        <button
          className="default-button"
          onClick={() => {
            onPageChange(parseInt(pageNumber));
            setPageNumber("");
          }}
          disabled={!pageNumber}
        >
          {t("Go")}
        </button>
      </div>
    </div>
  );
};

export default Pagination;
