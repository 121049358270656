import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { buildNotification } from "../../../config/notification";
import { store } from "react-notifications-component";
import { Error_Msg } from "../../../config";
import generateKey from "../../../config/generateKey";
import { generateNotification } from "../../../helper/generateNotification";
import {
    INIT_VENDOR_GROUP_LIST,
    INIT_VENDOR_GROUP_LIST_ROLE_BASE_ACTION,
    INIT_VENDOR_GROUP_DETAILS,
    INIT_VENDOR_GROUP_ASSIGN_VENDOR,
    INIT_VENDOR_GROUP_FILTER_DROPDOWN,
    INIT_UNASSIGN_VENDOR,
    INIT_DELETE_VENDOR_GROUP,
    INIT_SAVE_VENDOR_DETAILS,
    INIT_HANDLE_ASSIGN_VENDOR_TO_GROUP,
    INIT_CREATE_NEW_VENDOR_GROUP,
} from "./actionTypes";
import {
    ManageVendorGroupsListofVendorGroups,
    UserActionAuthenticationGetRoleBasedActions,
    ManageVendorGroupsDetailsofVendorGroup,
    ManageVendorGroupsFilterDropDown,
    ManageVendorGroupOnclickOfAssignVendors,
    ManageVendorGroupUnassignedVendorForVendorGroup,
    ManageVendorGroupDeleteVendorGroup,
    ManageVendorGroupSaveDetailsofVendorGroup,
    ManageVendorAssignVendorsToGroup,
    ManageVendorGroupsCreateVendorGroup,
} from "../../../config/APINames";
import {
    fetchVendorGroupList,
    fetchVendorRoleBasedAction,
    fetchVendorGroupDetails,
    fetchAssignVendorList,
    fetchVendorFilterDropDown,
} from "./actions";

export default function* watchVendorGroupList() {
    yield all([
        takeLatest(INIT_VENDOR_GROUP_LIST, initVendorList),
        takeLatest(INIT_VENDOR_GROUP_LIST_ROLE_BASE_ACTION, initVendorRoleAction),
        takeLatest(INIT_VENDOR_GROUP_DETAILS, initVendorGroupDetails),
        takeLatest(INIT_VENDOR_GROUP_ASSIGN_VENDOR, initAssignVendorList),
        takeLatest(INIT_VENDOR_GROUP_FILTER_DROPDOWN, initVendorFilterDropDown),
        takeLatest(INIT_UNASSIGN_VENDOR, initUnassignVendor),
        takeLatest(INIT_DELETE_VENDOR_GROUP, initDeleteVendorGroup),
        takeLatest(INIT_SAVE_VENDOR_DETAILS, initSaveVendorDetails),
        takeLatest(INIT_HANDLE_ASSIGN_VENDOR_TO_GROUP, initAssignVendorToGroup),
        takeLatest(INIT_CREATE_NEW_VENDOR_GROUP, initCreateNewVendorGroup),
    ]);
}
function* initVendorList(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ManageVendorGroupsListofVendorGroups,
            requestDetails
        );
        response = response.data;
        yield put(fetchVendorGroupList(response.resultSet));
    } catch (error) {
        const notification = buildNotification({
            message: generateKey(Error_Msg),
            type: "danger",
        });
        store.addNotification({
            ...notification,
        });
        yield put(fetchVendorGroupList(""));
    }
}
function* initVendorRoleAction(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            UserActionAuthenticationGetRoleBasedActions,
            requestDetails
        );
        response = response.data;
        yield put(fetchVendorRoleBasedAction(response.resultSet.actionNames));
    } catch (error) {
        const notification = buildNotification({
            message: generateKey(Error_Msg),
            type: "danger",
        });
        store.addNotification({
            ...notification,
        });
        yield put(fetchVendorRoleBasedAction(""));
    }
}

function* initVendorGroupDetails(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ManageVendorGroupsDetailsofVendorGroup,
            requestDetails
        );
        response = response.data;
        yield put(fetchVendorGroupDetails(response.resultSet));
        callback !== undefined && callback();
    } catch (error) {
        const notification = buildNotification({
            message: generateKey(Error_Msg),
            type: "danger",
        });
        store.addNotification({
            ...notification,
        });
        yield put(fetchVendorGroupDetails(""));
    }
}

function* initAssignVendorList(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ManageVendorGroupOnclickOfAssignVendors,
            requestDetails
        );
        response = response.data;
        yield put(fetchAssignVendorList(response.resultSet));
        if (callback !== undefined) {
            callback();
        }
    } catch (error) {
        const notification = buildNotification({
            message: generateKey(Error_Msg),
            type: "danger",
        });
        store.addNotification({
            ...notification,
        });
        yield put(fetchAssignVendorList([]));
    }
}

function* initVendorFilterDropDown(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ManageVendorGroupsFilterDropDown,
            requestDetails
        );
        response = response.data;
        yield put(fetchVendorFilterDropDown(response.resultSet));
    } catch (error) {
        const notification = buildNotification({
            message: generateKey(Error_Msg),
            type: "danger",
        });
        store.addNotification({
            ...notification,
        });
        yield put(fetchVendorFilterDropDown([]));
    }
}

function* initUnassignVendor(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ManageVendorGroupUnassignedVendorForVendorGroup,
            requestDetails
        );
        response = response.data;
        if (response.resultStatus === true) {
            const notification = buildNotification({
                message: generateKey(response.message),
                type: "success",
            });
            store.addNotification({
                ...notification,
            });
        } else {
            const notification = buildNotification({
                message: generateKey(response.message),
                type: "danger",
            });
            store.addNotification({
                ...notification,
            });
        }
        callback(response);
    } catch (error) {
        const notification = buildNotification({
            message: generateKey(Error_Msg),
            type: "danger",
        });
        store.addNotification({
            ...notification,
        });
    }
}

function* initDeleteVendorGroup(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ManageVendorGroupDeleteVendorGroup,
            requestDetails
        );
        response = response.data;
        if (response.resultStatus === true) {
            const notification = buildNotification({
                message: generateKey(response.message),
                type: "success",
            });
            store.addNotification({
                ...notification,
            });
            callback(response);
        } else {
            const notification = buildNotification({
                message: generateKey(response.message),
                type: "danger",
            });
            store.addNotification({
                ...notification,
            });
        }
    } catch (error) {
        const notification = buildNotification({
            message: generateKey(Error_Msg),
            type: "danger",
        });
        store.addNotification({
            ...notification,
        });
    }
}

function* initSaveVendorDetails(action) {
    const { payload } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ManageVendorGroupSaveDetailsofVendorGroup,
            requestDetails
        );
        response = response.data;
        if (response.resultStatus === true) {
            const notification = buildNotification({
                message: generateKey(response.message),
                type: "success",
            });
            store.addNotification({
                ...notification,
            });
        } else {
            const notification = buildNotification({
                message: generateKey(response.message),
                type: "danger",
            });
            store.addNotification({
                ...notification,
            });
        }
    } catch (error) {
        const notification = buildNotification({
            message: generateKey(Error_Msg),
            type: "danger",
        });
        store.addNotification({
            ...notification,
        });
    }
}

function* initAssignVendorToGroup(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ManageVendorAssignVendorsToGroup,
            requestDetails
        );
        response = response.data;
        if (response.resultStatus === true) {
            let responseMsg = response.message.split("number")[0];
            generateNotification(generateKey(responseMsg), "success");
        }
        else {
            const notification = buildNotification({
                message: generateKey(response.message),
                type: "danger",
            });
            store.addNotification({
                ...notification,
            });
        }
        callback(response);
    } catch (error) {
        const notification = buildNotification({
            message: generateKey(Error_Msg),
            type: "danger",
        });
        store.addNotification({
            ...notification,
        });
    }
}

function* initCreateNewVendorGroup(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        let response = yield call(
            axios,
            ManageVendorGroupsCreateVendorGroup,
            requestDetails
        );
        response = response.data;
        if (response.resultStatus === true) {
            const notification = buildNotification({
                message: generateKey(response.message),
                type: "success",
            });
            store.addNotification({
                ...notification,
            });
            callback(response);
        } else {
            const notification = buildNotification({
                message: generateKey(response.message),
                type: "danger",
            });
            store.addNotification({
                ...notification,
            });
        }
    } catch (error) {
        const notification = buildNotification({
            message: generateKey(Error_Msg),
            type: "danger",
        });
        store.addNotification({
            ...notification,
        });
    }
}
