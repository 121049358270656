export const INIT_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS =
  "INIT_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS";
export const FETCH_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS =
  "FETCH_IPQC_PRIORITIZATION_BASED_ACTIONS";
export const INIT_IPQC_PRIORITIZATION_FILTER_NAME =
  "INIT_IPQC_PRIORITIZATION_FILTER_NAME";
export const FETCH_IPQC_PRIORITIZATION_FILTER_NAME =
  "FETCH_IPQC_PRIORITIZATION_FILTER_NAME";
export const INIT_IPQC_PRIORITIZATION_EXPORT_FIELDS =
  "INIT_IPQC_PRIORITIZATION_EXPORT_FIELDS";
export const FETCH_IPQC_PRIORITIZATION_EXPORT_FIELDS =
  "FETCH_IPQC_PRIORITIZATION_EXPORT_FIELDS";
export const INIT_IPQC_PRIORITIZATION_HEADER_FIELDS =
  "INIT_IPQC_PRIORITIZATION_HEADER_FIELDS";
export const FETCH_IPQC_PRIORITIZATION_HEADER_FIELDS =
  "FETCH_IPQC_PRIORITIZATION_HEADER_FIELDS";
export const INIT_IPQC_PRIORITIZATION_DATA = "INIT_IPQC_PRIORITIZATION_DATA";
export const FETCH_IPQC_PRIORITIZATION_DATA = "FETCH_IPQC_PRIORITIZATION_DATA";
export const INIT_IPQC_PRIORITIZATION_PAGES_COUNT =
  "INIT_IPQC_PRIORITIZATION_PAGES_COUNT";
export const FETCH_IPQC_PRIORITIZATION_PAGES_COUNT =
  "FETCH_IPQC_PRIORITIZATION_PAGES_COUNT";
export const INIT_IPQC_PRIORITIZATION_FILTER_FIELDS =
  "INIT_IPQC_PRIORITIZATION_FILTER_FIELDS";
export const FETCH_IPQC_PRIORITIZATION_FILTER_FIELDS =
  "FETCH_IPQC_PRIORITIZATION_FILTER_FIELDS";
export const INIT_IPQC_PRIORITIZATION_ADD_FILTER =
  "INIT_IPQC_PRIORITIZATION_ADD_FILTER";
export const INIT_IPQC_PRIORITIZATION_DELETE_FILTER_OPTION =
  "INIT_IPQC_PRIORITIZATION_DELETE_FILTER_OPTION";
export const INIT_IPQC_PRIORITIZATION_RUN_FILTER =
  "INIT_IPQC_PRIORITIZATION_RUN_FILTER";
export const FETCH_IPQC_PRIORITIZATION_RUN_FILTER =
  "FETCH_IPQC_PRIORITIZATION_RUN_FILTER";
export const INIT_IPQC_PRIORITIZATION_SEARCH_RECORD =
  "INIT_IPQC_PRIORITIZATION_SEARCH_RECORD";
export const FETCH_IPQC_PRIORITIZATION_SEARCH_RECORD =
  "FETCH_IPQC_PRIORITIZATION_SEARCH_RECORD";
export const INIT_IPQC_PRIORITIZATION_RECORD_DETAILS =
  "INIT_IPQC_PRIORITIZATION_RECORD_DETAILS";
export const INIT_IPQC_PRIORITIZATION_SAVE_RECORD_DETAILS =
  "INIT_IPQC_PRIORITIZATION_SAVE_RECORD_DETAILS";
export const INIT_IPQC_PRIORITIZATION_EXPORT_RECORDS =
  "INIT_IPQC_PRIORITIZATION_EXPORT_RECORDS";
export const INIT_IPQC_PRIORITIZATION_EDIT_FILTER_DETAILS =
  "INIT_IPQC_PRIORITIZATION_EDIT_FILTER_DETAILS";
export const INIT_IPQC_PRIORITIZATION_SAVE_FILTER_DETAILS =
  "INIT_IPQC_PRIORITIZATION_SAVE_FILTER_DETAILS";
export const INIT_IPQC_PRIORITIZATION_PAGE_EXIT =
  "INIT_IPQC_PRIORITIZATION_PAGE_EXIT";
