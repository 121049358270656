import {
  INIT_DASHBOARD_DATA,
  FETCH_DASHBOARD_DATA,
  INIT_CLAIM_AGEING,
  FETCH_CLAIM_AGEING,
  INIT_DASHBOARD_AGEING,
  FETCH_DASHBOARD_AGEING,
  INIT_STARTDATE_ENDDATE_UI,
  FETCH_STARTDATE_ENDDATE_UI,
  INIT_SUPPLIER_DATA,
  FETCH_SUPPLIER_DATA,
  INIT_AUDITOR_LIST,
  FETCH_AUDITOR_LIST,
  INIT_SUPPLIER_CLAIM_PRODUCTIVITY,
  FETCH_SUPPLIER_CLAIM_PRODUCTIVITY,
  FETCH_CLAIM_AGEING_NOTIFICATION,
  FETCH_EXCEPTION_AGEING_NOTIFICATION,
  FETCH_SUPPLIER_DATA_BY_COUNT,
  FETCH_SUPPLIER_CLAIM_PRODUCTIVITY_BY_COUNT,
  ASSIGN_VARIABLE_INITAL_STATE,
} from "./actionTypes";

export const initDashboardData = (payload, callback) => {
  return {
    type: INIT_DASHBOARD_DATA,
    payload,
    callback,
  };
};

export const fetchDashboardData = (data) => {
  return {
    type: FETCH_DASHBOARD_DATA,
    data,
  };
};

export const initClaimAgeing = (payload, callback) => {
  return {
    type: INIT_CLAIM_AGEING,
    payload,
    callback,
  };
};

export const fetchClaimAgeing = (data) => {
  return {
    type: FETCH_CLAIM_AGEING,
    data,
  };
};

export const initDashboardAgeing = (payload, callback) => {
  return {
    type: INIT_DASHBOARD_AGEING,
    payload,
    callback,
  };
};

export const fetchDashboardAgeing = (data) => {
  return {
    type: FETCH_DASHBOARD_AGEING,
    data,
  };
};

export const initStartEndDateUI = (payload, callback) => {
  return {
    type: INIT_STARTDATE_ENDDATE_UI,
    payload,
    callback,
  };
};

export const fetchStartEndDateUI = (data) => {
  return {
    type: FETCH_STARTDATE_ENDDATE_UI,
    data,
  };
};

export const initSupplierData = (payload, callback) => {
  return {
    type: INIT_SUPPLIER_DATA,
    payload,
    callback,
  };
};

export const fetchSupplierData = (data) => {
  return {
    type: FETCH_SUPPLIER_DATA,
    data,
  };
};

export const initAuditorList = (payload, callback) => {
  return {
    type: INIT_AUDITOR_LIST,
    payload,
    callback,
  };
};

export const fetchAuditorList = (data) => {
  return {
    type: FETCH_AUDITOR_LIST,
    data,
  };
};

export const initSupplierProductivity = (payload, callback) => {
  return {
    type: INIT_SUPPLIER_CLAIM_PRODUCTIVITY,
    payload,
    callback,
  };
};

export const fetchSupplierProductivity = (data) => {
  return {
    type: FETCH_SUPPLIER_CLAIM_PRODUCTIVITY,
    data,
  };
};
export const fetchClaimAgeingNotification = (data) => {
  return {
    type: FETCH_CLAIM_AGEING_NOTIFICATION,
    data,
  };
};
export const fetchExceptionAgeingNotification = (data) => {
  return {
    type: FETCH_EXCEPTION_AGEING_NOTIFICATION,
    data,
  };
};
export const fetchSupplierDataByCount = (data) => {
  return {
    type: FETCH_SUPPLIER_DATA_BY_COUNT,
    data,
  };
};

export const fetchSupplierProductivityByCount = (data) => {
  return {
    type: FETCH_SUPPLIER_CLAIM_PRODUCTIVITY_BY_COUNT,
    data,
  };
};

export const assignInitalState = () => {
  return {
    type: ASSIGN_VARIABLE_INITAL_STATE,
  };
};
