export const INIT_GET_SYSTEM_PARAMETERS = "INIT_GET_SYSTEM_PARAMETERS";
export const FETCH_SYSTEM_PARAMETERS = "FETCH_SYSTEM_PARAMETERS";
export const INIT_GET_TOU_PS = "INIT_GET_TOU_PS";
export const SAVE_SYSTEM_PARAMETERS="SAVE_SYSTEM_PARAMETERS";
export const SAVE_TERMS_OF_USE="SAVE_TERMS_OF_USE";
export const SAVE_TERMS_OF_USE_COMPLETE="SAVE_TERMS_OF_USE_COMPLETE";
export const SAVE_TERMS_OF_USE_FAILED="SAVE_TERMS_OF_USE_FAILED";
export const SAVE_SYSTEM_PARAMETERS_COMPLETED="SAVE_SYSTEM_PARAMETERS_COMPLETED";
export const SAVE_SYSTEM_PARAMETERS_FAILED="SAVE_SYSTEM_PARAMETERS_FAILED";

export const SYSTEM_PARAMETER_FORMDATA="SYSTEM_PARAMETER_FORMDATA";
export const UPDATE_SYSTEM_PARAMETER_FORMDATA="UPDATE_SYSTEM_PARAMETER_FORMDATA";

export const TERMS_OF_USE_FORMDATA="TERMS_OF_USE_FORMDATA";
export const UPDATE_TERMS_OF_USE_FORMDATA="UPDATE_TERMS_OF_USE_FORMDATA";






