export const INIT_CLAIM_FILTER_FIELDS = "INIT_CLAIM_FILTER_FIELDS";
export const INIT_CLAIM_LIST = "INIT_CLAIM_LIST";
export const FETCH_CLAIM_LIST = "FETCH_CLAIM_LIST";
export const INIT_CLAIM_TOTAL_COUNT = "INIT_CLAIM_TOTAL_COUNT";
export const FETCH_CLAIM_TOTAL_COUNT = "FETCH_CLAIM_TOTAL_COUNT";
export const INIT_CLAIM_RECORDS_EXPORT = "INIT_CLAIM_RECORDS_EXPORT";
export const FETCH_CLAIM_RECORDS_EXPORT = "FETCH_CLAIM_RECORDS_EXPORT";
export const INIT_CLAIM_RECORDS_EXPORT_ALL = "INIT_CLAIM_RECORDS_EXPORT_ALL";
export const FETCH_CLAIM_RECORDS_EXPORT_ALL = "FETCH_CLAIM_RECORDS_EXPORT_ALL";
export const INIT_GET_DUPS_CLAIM_DETAILS = "INIT_GET_DUPS_CLAIM_DETAILS";
export const FETCH_DUPS_CLAIM_DETAILS = "FETCH_DUPS_CLAIM_DETAILS";

