import { fromJS } from "immutable";
import {
  FETCHING_CLIENTS_LIST,
  FETCH_CLIENTS_LIST,
  CLIENTS_LIST_FETCHING_COMPLETE,
  INIT_SAVE_CLIENT_DATA,
  CLIENT_DATA_SAVE_SUCCESS,
  CLIENT_DATA_SAVE_FAILED,
  FETCHING_CLIENT_BY_NAME,
  CLIENT_BY_NAME_FETCH_COMPLETE,
  FETCH_TOTAL_CLIENTS,
  FETCH_ACTIVE_CLIENTS,
  FETCH_INACTIVE_CLIENTS,
  INIT_DELETE_CLIENT,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAILED,
  INIT_CLIENT_ACTION,
  CLIENT_ACTION_SUCCESS,
  CLIENT_ACTION_FAILED,
  FETCH_FILTERED_TOTAL_CLIENTS,
  FETCHING_COUNTRIES,
  FETCH_COUNTRIES,
  COUNTRIES_FETCH_COMPLETE,
  FETCHING_UDL_DETAILS,
  FETCH_UDL_DETAILS,
  UDL_DETAILS_FETCH_COMPLETE,
  FETCHING_ATCAT_VERSION,
  FETCH_ATCAT_VERSION,
  ATCAT_VERSION_FETCH_COMPLETE,
  FETCHING_DEFAULT_VALUES,
  DEFAULT_VALUES_FETCH_COMPLETE,
  FETCHING_SAVED_REPORTS_LIST,
  SAVED_REPORTS_LIST_FETCHING_COMPLETE,
  INIT_UPLOAD_REPORTS,
  REPORTS_UPLOAD_SAVE_SUCCESS,
  REPORTS_UPLOAD_SAVE_FAILED,
} from "./actionTypes";

export const initialState = fromJS({
  isClientDetailsFetching: false,
  clientDetails: "",
  fetchingClientsList: "",
  clientsList: "",
  fetchingClientByName: false,
  savingClientData: false,
  totalClients: "",
  filteredTotalClients: "",
  activeClients: "",
  inActiveClients: "",
  deletingClient: false,
  activatingClient: false,
  deactivatingClient: false,
  fetchingCountries: false,
  countries: [],
  fetchingUDLDetails: false,
  UDLDetail: {},
  UDLTypeDetails: [],
  fetchingAtcatVersion: false,
  atcatVersionList: [],
  fetchingDefaultValues: false,
  fetchingSavedReportsList: "",
  uploadingReports: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_CLIENTS_LIST: {
      return state.set("fetchingClientsList", true);
    }

    case FETCH_CLIENTS_LIST: {
      return state.set("clientsList", action.data);
    }

    case CLIENTS_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingClientsList", false);
    }

    case FETCH_TOTAL_CLIENTS: {
      return state.set("totalClients", action.data);
    }

    case FETCH_FILTERED_TOTAL_CLIENTS: {
      return state.set("filteredTotalClients", action.data);
    }

    case FETCH_ACTIVE_CLIENTS: {
      return state.set("activeClients", action.data);
    }

    case FETCH_INACTIVE_CLIENTS: {
      return state.set("inActiveClients", action.data);
    }

    case INIT_DELETE_CLIENT: {
      return state.set("deletingClient", true);
    }

    case CLIENT_DELETE_SUCCESS: {
      return state.set("deletingClient", false);
    }

    case CLIENT_DELETE_FAILED: {
      return state.set("deletingClient", false);
    }

    case INIT_CLIENT_ACTION: {
      return state.set("clientActionOnGoing", true);
    }

    case CLIENT_ACTION_SUCCESS: {
      return state.set("clientActionOnGoing", false);
    }

    case CLIENT_ACTION_FAILED: {
      return state.set("clientActionOnGoing", false);
    }

    case FETCHING_COUNTRIES:
      return state.set("fetchingCountries", true);

    case FETCH_COUNTRIES:
      return state.set("countries", action.data);

    case COUNTRIES_FETCH_COMPLETE:
      return state.set("fetchingCountries", false);

    case FETCHING_UDL_DETAILS:
      return state.set("fetchingUDLDetails", true);

    case FETCH_UDL_DETAILS:
      return state
        .set("UDLDetail", action.data && action.data.udlDetail)
        .set("UDLTypeDetails", action.data && action.data.udlTypeDetail);

    case UDL_DETAILS_FETCH_COMPLETE:
      return state.set("fetchingUDLDetails", false);

    case FETCHING_ATCAT_VERSION:
      return state.set("fetchingAtcatVersion", true);

    case FETCH_ATCAT_VERSION:
      return state.set("atcatVersionList", action.data);

    case ATCAT_VERSION_FETCH_COMPLETE:
      return state.set("fetchingAtcatVersion", false);

    case FETCHING_DEFAULT_VALUES:
      return state.set("fetchingDefaultValues", true);

    case DEFAULT_VALUES_FETCH_COMPLETE:
      return state.set("fetchingDefaultValues", false);

    case INIT_SAVE_CLIENT_DATA: {
      return state.set("savingClientData", true);
    }

    case CLIENT_DATA_SAVE_SUCCESS:
    case CLIENT_DATA_SAVE_FAILED: {
      return state.set("savingClientData", false);
    }

    case FETCHING_CLIENT_BY_NAME: {
      return state.set("fetchingClientByName", true);
    }
    case CLIENT_BY_NAME_FETCH_COMPLETE: {
      return state.set("fetchingClientByName", false);
    }

    case FETCHING_SAVED_REPORTS_LIST: {
      return state.set("fetchingSavedReportsList", true);
    }

    case SAVED_REPORTS_LIST_FETCHING_COMPLETE: {
      return state.set("fetchingSavedReportsList", false);
    }

    case INIT_UPLOAD_REPORTS: {
      return state.set("uploadingReports", true);
    }

    case REPORTS_UPLOAD_SAVE_SUCCESS:
    case REPORTS_UPLOAD_SAVE_FAILED:
      return state.set("uploadingReports", false);

    default:
      return state;
  }
};

export default reducer;
