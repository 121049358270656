export const INIT_AI_ADHOC_JOB_LIST = "INIT_AI_ADHOC_JOB_LIST";
export const FETCH_AI_ADHOC_JOB_LIST = "FETCH_AI_ADHOC_JOB_LIST";
export const INIT_AI_ADHOC_JOB_DETAILS = "INIT_AI_ADHOC_JOB_DETAILS";
export const FETCH_AI_ADHOC_JOB_DETAILS = "FETCH_AI_ADHOC_JOB_DETAILS";
export const INIT_AI_ADHOC_RUN_DUPS = "INIT_AI_ADHOC_RUN_DUPS";
export const INIT_AI_ADHOC_EXPORT_EXCEPTIONS =
  "INIT_AI_ADHOC_EXPORT_EXCEPTIONS";
export const INIT_AI_ADHOC_EXCEL_VALIDATION = "INIT_AI_ADHOC_EXCEL_VALIDATION";
export const FETCH_AI_ADHOC_EXCEL_VALIDATION =
  "FETCH_AI_ADHOC_EXCEL_VALIDATION";
export const INIT_AI_ADHOC_INITAL_STATE = "INIT_AI_ADHOC_INITAL_STATE";
