import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/index.scss";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import "./i18n";
const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App history={history} store={store} />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
