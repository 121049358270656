import React from "react";
import { useTranslation } from "react-i18next";
import Arrow from "../../../SVG/Arrow";
import "../style.scss";

const Menu = (props) => {
  const { t } = useTranslation();
  const setNavigation = (routeKey) => {
    props.setRoute({ pathname: routeKey });
  };

  return (
    <ul className="nav-bar">
      {props.menuList.map((menu) => {
        const hasChildren = menu.children && menu.children.length;
        const isActive = props.activePath.indexOf(menu.key);

        return (
          <li
            tabIndex={0}
            key={menu.key}
            className={`nav-item ${isActive !== -1 ? `active` : ``}`}
            onKeyUp={
              hasChildren
                ? () => {}
                : (e) => {
                    if (e.key === "Enter") {
                      setNavigation(menu.key);
                    }
                  }
            }
            onClick={hasChildren ? () => {} : () => setNavigation(menu.key)}
          >
            {menu.parentKey === 0 ? (
              <button
                type="button"
                aria-label={`${menu.title} icon`}
                tabIndex={-1}
                className="nav-icon"
              >
                {menu.icon}
              </button>
            ) : null}
            <div
              tabIndex={-1}
              className={`nav-link  ${menu.parentKey === 0 ? `dropdown ` : ``}`}
            >
              <span tabIndex={-1}>
                {t(menu.title)}
                {hasChildren ? <Arrow /> : null}
              </span>
              {hasChildren ? (
                <Menu {...props} menuList={menu.children} />
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default Menu;
