import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Input = (props) => {
  const handleChange = (e) => {
    let value = e.target.value;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    props.handleChange && props.handleChange(e);
  };
  const handleBlur = (e) => {
    let value = e.target.value;
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    e.target.value = strippedHtml;
    props.handleBlur && props.handleBlur(e);
  };
  const inputclass = `default-input ${props.showError ? `error` : ``}`;
  return (
    <>
      <input
        className={inputclass}
        type={props.type}
        onClick={props.handleClick}
        onChange={handleChange}
        onFocus={props.handleFocus}
        onBlur={handleBlur}
        aria-label={props.ariaLabel || props.label}
        placeholder={props.placeholder}
        value={props.value}
        id={props.id}
        name={props.name}
        disabled={props.disabled}
        readOnly={props.readOnly}
        maxLength={props.inputLength || 100}
      />
    </>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.any,
  value: PropTypes.any,
  id: PropTypes.any,
  name: PropTypes.any,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleClick: PropTypes.func,
  handleFocus: PropTypes.func,
  ariaLabel: PropTypes.string,
};

export default Input;
