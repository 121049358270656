import React from "react";
import {
  LabeledCheckbox,
  LabeledSwitch,
  LabeledInput,
  LabeledSelect,
  LabeledTextarea,
  RadioButton,
  MultiSelect,
  DatePicker,
  Select,
  LabeledDualInput,
  DualRadioButton,
  DocumentUploader,
} from "..";
import DropdownInput from "../Inputs/DropdownInput"

const FormInput = (props) => {
  let inputElement = "";
  let selectedDate = new Date();
  switch (props.inputType) {
    case "labeledCheckbox": {
      inputElement = (
        <LabeledCheckbox
          {...props.attrs}
          handleCheck={props.onChangeHandler}
          handleClick={props.onClickHandler}
          checked={props.value}
        />
      );
      break;
    }

    case "radio": {
      inputElement = (
        <RadioButton
          checked={props.value}
          {...props.attrs}
          handleCheck={props.onChangeHandler}
        />
      );
      break;
    }

    case "dualRadio": {
      inputElement = (
        <DualRadioButton {...props.attrs} handleCheck={props.onChangeHandler} />
      );
      break;
    }

    case "datePicker": {
      inputElement = (
        <DatePicker
          {...props.attrs}
          dateValue={props.value}
          setDate={props.onChangeHandler}
          noFloating={props.noFloating}
          onFocusHandler={props.onFocusHandler}
          onClickHandler={props.onClickHandler}
          onBlurHandler={props.onBlurHandler}
          resetVisited={props.resetVisited}
          selectedDate={selectedDate}
          onChange={(date) => {
            this.setState({ selectedDate: date });
          }}
        />
      );
      break;
    }

    case "labeledInput": {
      const attrs = {
        ...props.attrs,
        handleChange: props.onChangeHandler,
        handleBlur: props.onBlurHandler,
        handleFocus: props.onFocusHandler,
        value: props.value || "",
        resetVisited: props.resetVisited,
      };
      inputElement = <LabeledInput {...attrs} />;
      break;
    }

    case "labeledSelect": {
      inputElement = (
        <LabeledSelect
          {...props.attrs}
          onChangeHandler={props.onChangeHandler}
          onBlurHandler={props.onBlurHandler}
          onFocus={props.onFocusHandler}
          value={props.value}
          isLoading={props.loading}
          inTable={props.inTable}
          resetVisited={props.resetVisited}
        />
      );
      break;
    }

    case "labeledTextarea": {
      inputElement = (
        <LabeledTextarea
          {...props.attrs}
          value={props.value}
          onChangeHandler={props.onChangeHandler}
          onBlurHandler={props.onBlurHandler}
          noFloating={props.noFloating}
          resetVisited={props.resetVisited}
        />
      );
      break;
    }

    case "labeledMultiSelect": {
      inputElement = (
        <MultiSelect
          {...props.attrs}
          value={props.value}
          onChangeHandler={props.onChangeHandler}
        />
      );
      break;
    }
    case "Select": {
      inputElement = <Select {...props.attrs} />;
      break;
    }

    case "labeledSwitch": {
      inputElement = (
        <LabeledSwitch
          {...props.attrs}
          handleCheck={props.onChangeHandler}
          checked={props.value}
        />
      );
      break;
    }
    case "labeledDualInput": {
      inputElement = (
        <LabeledDualInput
          {...props.attrs}
          handleChange={props.onChangeHandler}
          handleBlur={props.onBlurHandler}
        />
      );
      break;
    }
    case "documentUploader": {
      inputElement = (
        <DocumentUploader
          {...props.attrs}
          handleOnChange={props.onChangeHandler}
          handleBlur={props.onBlurHandler}
        />
      );
      break;
    }
    case "dropdownInput": {
      inputElement = (
        <DropdownInput
          {...props.attrs}
          
          handleChange={props.onChangeHandler}
          onChangeHandler={props.onChangeHandler}
          value={props.value}
          handleBlur={props.onBlurHandler}
        />
      );
      break;
    }

    default: {
      const attrs = {
        ...props.attrs,
        handleChange: props.onChangeHandler,
        handleBlur: props.onBlurHandler,
        handleFocus: props.onFocusHandler,
        value: props.value || "",
        resetVisited: props.resetVisited,
      };
      inputElement = <LabeledInput {...attrs} />;
      break;
    }
  }
  return inputElement;
};

export default FormInput;
