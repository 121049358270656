import { fromJS } from "immutable";

import { CURRENT_ROUTE_SET } from "./actionTypes";

export const initialState = fromJS({
  currentRoute: null,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_ROUTE_SET:
      return state.set("currentRoute", action.data);

    default:
      return state;
  }
};
export default reducer;
