import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { Error_Msg } from "../../../config";
import { postRequestDetails } from "../../../config/requestHeaders";
import {
  RunningJobsRunningJobsList,
  RunningJobsClientRunningJobsList,
  RunningJobsKillRunningJob,
  RunningJobsjobDetails,
  JobSchedularLogsForDupsAndDataload,
  RunningJobsCheckSuspendState,
} from "../../../config/APINames";
import {
  INIT_FETCH_RUNNINGJOBS,
  INIT_FETCH_KILLJOB,
  INIT_FETCH_RUNNINGJOB_DETAILS,
  INIT_FETCH_JOB_LOGS,
  INIT_FETCH_RUNNING_JOB_STATUS,
  INIT_FETCH_CLIENT_RUNNINGJOBS,
} from "./actionTypes";
import {
  fetchRunningJobList,
  fetchClientRunningJobList,
  fetchJobLogs,
  fetchRunningSuspendState,
  fetchJobLogsComplete,
} from "./actions";
import { generateNotification } from "../../../helper/generateNotification";
import generateKey from "../../../config/generateKey";

export default function* watchClientQueueSummary() {
  yield all([
    takeLatest(INIT_FETCH_RUNNINGJOBS, initGetRunningJobsSaga),
    takeLatest(INIT_FETCH_CLIENT_RUNNINGJOBS, initGetClientRunningJobsSaga),
    takeLatest(INIT_FETCH_KILLJOB, initKillingJobSaga),
    takeLatest(INIT_FETCH_RUNNINGJOB_DETAILS, initRunningJobDetailsSaga),
    takeLatest(INIT_FETCH_JOB_LOGS, initRunningJobLogsSaga),
    takeLatest(INIT_FETCH_RUNNING_JOB_STATUS, initRunningStatusSaga),
  ]);
}
function* initGetRunningJobsSaga(action) {
  const { payload, callback } = action || {};
  try {
    let url = RunningJobsRunningJobsList;
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      if (response.data.resultStatus === true) {
        yield put(fetchRunningJobList(response.data));
      } else {
        generateNotification(generateKey(response.data.message), "danger");
      }
      callback && callback();
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initGetClientRunningJobsSaga(action) {
  const { payload, callback } = action || {};

  try {
    let url = RunningJobsClientRunningJobsList;

    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, url, requestDetails);

    if (response && response.status === 200) {
      if (response.data.resultStatus === true) {
        yield put(fetchClientRunningJobList(response.data));
      }

      callback && callback();
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initKillingJobSaga(action) {
  const { payload } = action || {};
  try {
    let url = RunningJobsKillRunningJob;

    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, url, requestDetails);
    if (response && response.data.resultStatus === true) {
      generateNotification("atcat.killedJobSuccessfully", "success");
    } else {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    }
  } catch (error) {
    generateNotification(error, "danger");
  }
}
function* initRunningJobDetailsSaga(action) {
  const { payload, callback } = action || {};
  try {
    let url = RunningJobsjobDetails;

    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, url, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (response.data.resultStatus) {
        callback && callback(response.data.resultSet.Details);
      } else {
        generateNotification(generateKey(response.data.message), "danger");
      }
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initRunningJobLogsSaga(action) {
  const { payload,callback } = action || {};
  try {
    let url = JobSchedularLogsForDupsAndDataload;

    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      if (response.data && response.data.displayLogsAscendingByLogID) {
        yield put(fetchJobLogs(response.data));
      } 
        callback && callback(response.data)
        //generateNotification(generateKey(response.data), "danger");
      
      yield put(fetchJobLogsComplete());
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initRunningStatusSaga(action) {
  const { payload } = action || {};
  try {
    let url = RunningJobsCheckSuspendState;

    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      yield put(
        fetchRunningSuspendState(response.data.resultSet.queuestate[0])
      );
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
