import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectLogin = (state) => state.get("login", initialState);

export const selectLoginLoadingStatus = () =>
  createSelector(selectLogin, (VPState) => VPState.get("loginLoading"));

export const selectLoginStatus = () =>
  createSelector(selectLogin, (VPState) => VPState.get("loginStatus"));

export const selectAuthDetails = () =>
  createSelector(selectLogin, (VPState) => VPState.get("authDetails"));

export const selectAppName = () =>
  createSelector(selectLogin, (VPState) => VPState.get("applicationname"));
