import { fromJS } from "immutable";
import {
  FETCH_AI_ADHOC_JOB_DETAILS,
  FETCH_AI_ADHOC_JOB_LIST,
  INIT_AI_ADHOC_INITAL_STATE,
} from "./actionTypes";
export const initialState = fromJS({
  jobDetails: {},
  jobList: {},
});
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AI_ADHOC_JOB_DETAILS:
      return state.set("jobDetails", action.data);
    case FETCH_AI_ADHOC_JOB_LIST:
      return state.set("jobList", action.data);
    case INIT_AI_ADHOC_INITAL_STATE:
      return state.set("jobDetails", {}).set("jobList", {});
    default:
      return state;
  }
};

export default reducer;
