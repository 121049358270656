import {
  CLIENTS_LIST_FETCHING_COMPLETE,
  CLIENT_ACTION_FAILED,
  CLIENT_ACTION_SUCCESS,
  CLIENT_BY_NAME_FETCH_COMPLETE,
  CLIENT_DATA_SAVE_FAILED,
  CLIENT_DATA_SAVE_SUCCESS,
  CLIENT_DELETE_FAILED,
  CLIENT_DELETE_SUCCESS,
  FETCHING_CLIENTS_LIST,
  FETCHING_CLIENT_BY_NAME,
  FETCH_ACTIVE_CLIENTS,
  FETCH_CLIENTS_LIST,
  FETCH_FILTERED_TOTAL_CLIENTS,
  FETCH_INACTIVE_CLIENTS,
  FETCH_TOTAL_CLIENTS,
  INIT_CLIENT_ACTION,
  INIT_DELETE_CLIENT,
  INIT_GET_ACTIVE_CLIENTS,
  INIT_GET_CLIENTS_LIST,
  INIT_GET_CLIENT_BY_NAME,
  INIT_GET_INACTIVE_CLIENTS,
  INIT_GET_TOTAL_CLIENTS,
  INIT_SAVE_CLIENT_DATA,
  INIT_GET_COUNTRIES,
  FETCHING_COUNTRIES,
  FETCH_COUNTRIES,
  COUNTRIES_FETCH_COMPLETE,
  INIT_GET_UDL_DETAILS,
  FETCHING_UDL_DETAILS,
  UDL_DETAILS_FETCH_COMPLETE,
  FETCH_UDL_DETAILS,
  INIT_GET_ATCAT_VERSION,
  FETCHING_ATCAT_VERSION,
  FETCH_ATCAT_VERSION,
  ATCAT_VERSION_FETCH_COMPLETE,
  INIT_GET_DEFAULT_VALUES,
  FETCHING_DEFAULT_VALUES,
  DEFAULT_VALUES_FETCH_COMPLETE,
  INIT_GET_CLIENT_LANGUAGES,
  INIT_GET_SAVED_REPORTS_LIST,
  FETCHING_SAVED_REPORTS_LIST,
  SAVED_REPORTS_LIST_FETCHING_COMPLETE,
  INIT_UPLOAD_REPORTS,
  REPORTS_UPLOAD_SAVE_SUCCESS,
  REPORTS_UPLOAD_SAVE_FAILED,
} from "./actionTypes";

export function initGetClientsList(payload) {
  return {
    type: INIT_GET_CLIENTS_LIST,
    payload,
  };
}

export function fetchingClientsList() {
  return {
    type: FETCHING_CLIENTS_LIST,
  };
}

export function fetchClientsList(data) {
  return {
    type: FETCH_CLIENTS_LIST,
    data,
  };
}

export function clientsListFetchingComplete() {
  return {
    type: CLIENTS_LIST_FETCHING_COMPLETE,
  };
}

export function initGetTotalClients(payload) {
  return {
    type: INIT_GET_TOTAL_CLIENTS,
    payload,
  };
}

export function fetchTotalClients(data) {
  return {
    type: FETCH_TOTAL_CLIENTS,
    data,
  };
}

export function fetchFilteredTotalClients(data) {
  return {
    type: FETCH_FILTERED_TOTAL_CLIENTS,
    data,
  };
}

export function initGetActiveClients(payload) {
  return {
    type: INIT_GET_ACTIVE_CLIENTS,
    payload,
  };
}

export function fetchActiveClients(data) {
  return {
    type: FETCH_ACTIVE_CLIENTS,
    data,
  };
}

export function initGetInActiveClients(payload) {
  return {
    type: INIT_GET_INACTIVE_CLIENTS,
    payload,
  };
}

export function fetchInActiveClients(data) {
  return {
    type: FETCH_INACTIVE_CLIENTS,
    data,
  };
}

export const initGetCountries = () => {
  return {
    type: INIT_GET_COUNTRIES,
  };
};

export const countriesFetching = () => {
  return {
    type: FETCHING_COUNTRIES,
  };
};

export const fetchCountries = (data) => {
  return {
    type: FETCH_COUNTRIES,
    data,
  };
};

export const countriesFetchComplete = () => {
  return {
    type: COUNTRIES_FETCH_COMPLETE,
  };
};

export const initGetClientLanguages = (payload, callback) => {
  return {
    type: INIT_GET_CLIENT_LANGUAGES,
    payload,
    callback,
  };
};

export const initGetUDLDetails = (payload) => {
  return {
    type: INIT_GET_UDL_DETAILS,
    payload,
  };
};

export const udlDetailsFetching = () => {
  return {
    type: FETCHING_UDL_DETAILS,
  };
};

export const fetchUDLDetails = (data) => {
  return {
    type: FETCH_UDL_DETAILS,
    data,
  };
};

export const udlDetailsFetchComplete = () => {
  return {
    type: UDL_DETAILS_FETCH_COMPLETE,
  };
};

export const initGetAtcatVersion = () => {
  return {
    type: INIT_GET_ATCAT_VERSION,
  };
};

export const atcatVersionFetching = () => {
  return {
    type: FETCHING_ATCAT_VERSION,
  };
};

export const fetchAtcatVersion = (data) => {
  return {
    type: FETCH_ATCAT_VERSION,
    data,
  };
};

export const atcatVersionFetchComplete = () => {
  return {
    type: ATCAT_VERSION_FETCH_COMPLETE,
  };
};

export const initGetDefaultValues = (payload,callback) => {
  return {
    type: INIT_GET_DEFAULT_VALUES,
    payload,
    callback,
  };
};

export const defaultValuesFetching = () => {
  return {
    type: FETCHING_DEFAULT_VALUES,
  };
};

export const defaultValuesFetchComplete = () => {
  return {
    type: DEFAULT_VALUES_FETCH_COMPLETE,
  };
};

export const initGetClientByName = (payload, callback) => {
  return {
    type: INIT_GET_CLIENT_BY_NAME,
    payload,
    callback,
  };
};

export const fetchingClientByName = () => {
  return {
    type: FETCHING_CLIENT_BY_NAME,
  };
};

export const clientByNameFetchingComplete = () => {
  return {
    type: CLIENT_BY_NAME_FETCH_COMPLETE,
  };
};

export const initDeleteClient = (payload, callback) => {
  return {
    type: INIT_DELETE_CLIENT,
    payload,
    callback,
  };
};

export const clientDeleteSuccess = () => {
  return {
    type: CLIENT_DELETE_SUCCESS,
  };
};

export const clientDeleteFailed = () => {
  return {
    type: CLIENT_DELETE_FAILED,
  };
};

export const initClientAction = (payload, callback) => {
  return {
    type: INIT_CLIENT_ACTION,
    payload,
    callback,
  };
};

export const clientActionSuccess = () => {
  return {
    type: CLIENT_ACTION_SUCCESS,
  };
};

export const clientActionFailed = () => {
  return {
    type: CLIENT_ACTION_FAILED,
  };
};

export const initSaveClientData = (payload, callback) => {
  return {
    type: INIT_SAVE_CLIENT_DATA,
    payload,
    callback,
  };
};

export const clientDataSaveSuccess = () => {
  return {
    type: CLIENT_DATA_SAVE_SUCCESS,
  };
};

export const clientDataSaveFailed = () => {
  return {
    type: CLIENT_DATA_SAVE_FAILED,
  };
};

export function initGetSavedReportsList(payload, callback) {
  return {
    type: INIT_GET_SAVED_REPORTS_LIST,
    payload,
    callback,
  };
}

export function fetchingSavedReportsList() {
  return {
    type: FETCHING_SAVED_REPORTS_LIST,
  };
}

export function savedReportsListFetchingComplete() {
  return {
    type: SAVED_REPORTS_LIST_FETCHING_COMPLETE,
  };
}

export const initUploadReports = (payload) => {
  return {
    type: INIT_UPLOAD_REPORTS,
    payload,
  };
};

export const reportsUploadSaveSuccess = () => {
  return {
    type: REPORTS_UPLOAD_SAVE_SUCCESS,
  };
};

export const reportsUploadSaveFailed = () => {
  return {
    type: REPORTS_UPLOAD_SAVE_FAILED,
  };
};
