import { fromJS } from "immutable";
import {
  FETCHING_BULKRELEASE_GET_TIME,
  BULKRELEASE_GET_TIME_FETCHED,
  BULKRELEASE_GET_TIME_FETCH_COMPLETE,
  BULKRELEASE_GET_TIME_FETCH_FAIL,
  FETCHING_BULKRELEASE_SUBMIT_TIME,
  BULKRELEASE_SUBMIT_TIME_FETCHED,
  BULKRELEASE_SUBMIT_TIME_FETCH_COMPLETE,
  BULKRELEASE_SUBMIT_TIME_FETCH_FAIL,
} from "./actionTypes";

export const initialState = fromJS({
  fetchingGetTime: false,
  fetchingBulkReleaseSubmitTime: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_BULKRELEASE_GET_TIME: {
      return state.set("fetchingGetTime", true);
    }
    case BULKRELEASE_GET_TIME_FETCHED: {
      return state.set("bulkReleaseGetTime", action.data);
    }
    case BULKRELEASE_GET_TIME_FETCH_COMPLETE:
    case BULKRELEASE_GET_TIME_FETCH_FAIL: {
      return state.set("fetchingGetTime", false);
    }

    case FETCHING_BULKRELEASE_SUBMIT_TIME: {
      return state.set("fetchingBulkReleaseSubmitTime", true);
    }
    case BULKRELEASE_SUBMIT_TIME_FETCHED: {
      return state.set("bulkReleaseSubmitTime", action.data);
    }
    case BULKRELEASE_SUBMIT_TIME_FETCH_COMPLETE:
    case BULKRELEASE_SUBMIT_TIME_FETCH_FAIL: {
      return state.set("fetchingBulkReleaseSubmitTime", false);
    }

    default:
      return state;
  }
};

export default reducer;
