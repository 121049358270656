import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import {
  INIT_IPQC_PRIORITIZATION_DATA,
  INIT_IPQC_PRIORITIZATION_EXPORT_FIELDS,
  INIT_IPQC_PRIORITIZATION_FILTER_NAME,
  INIT_IPQC_PRIORITIZATION_HEADER_FIELDS,
  INIT_IPQC_PRIORITIZATION_PAGES_COUNT,
  INIT_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS,
  INIT_IPQC_PRIORITIZATION_RECORD_DETAILS,
  INIT_IPQC_PRIORITIZATION_RUN_FILTER,
  INIT_IPQC_PRIORITIZATION_FILTER_FIELDS,
  INIT_IPQC_PRIORITIZATION_EDIT_FILTER_DETAILS,
  INIT_IPQC_PRIORITIZATION_SAVE_FILTER_DETAILS,
  INIT_IPQC_PRIORITIZATION_DELETE_FILTER_OPTION,
  INIT_IPQC_PRIORITIZATION_EXPORT_RECORDS,
  INIT_IPQC_PRIORITIZATION_SAVE_RECORD_DETAILS,
} from "./actionTypes";
import {
  UserActionAuthenticationGetRoleBasedActions,
  IPQCFraudAndErrorFilterName,
  ReVisitedDupsUnattendedExportFieldsList,
  ReVisitedDupsUnattendedDupsExceptionListHeaders,
  IPQCFraudAndErrorDetectionData,
  IPQCFraudAndErrorCount,
  ReVisitedDupsUnattendedPost,
  IPQCFraudAndErrorRunFilterWithSort,
  IPQCFraudAndErrorFilterFields,
  SavedFilterEditFilterPageData,
  IPQCFraudAndErrorAddAdvanceFilter,
  SavedFilterDeleteAdvancedFilter,
  IPQCFraudAndErrorExportAllToExcel,
  IPQCSaveRecordDetails,
} from "../../../config/APINames";
import {
  fetchExportFields,
  fetchFilterName,
  fetchHeaderFieldsList,
  fetchPageCount,
  fetchRoleBasedActions,
  fetchTableData,
  fetchFilterFields,
} from "./actions";
import { generateNotification } from "../../../helper/generateNotification";
export default function* watchIPQCPrioritization() {
  yield all([
    takeLatest(INIT_IPQC_PRIORITIZATION_DATA, initFraudAndErrorRowData),
    takeLatest(INIT_IPQC_PRIORITIZATION_EXPORT_FIELDS, initExportedFields),
    takeLatest(INIT_IPQC_PRIORITIZATION_FILTER_NAME, initFilterName),
    takeLatest(INIT_IPQC_PRIORITIZATION_HEADER_FIELDS, initHeaderFields),
    takeLatest(INIT_IPQC_PRIORITIZATION_PAGES_COUNT, initPageCount),
    takeLatest(
      INIT_IPQC_PRIORITIZATION_ROLE_BASED_ACTIONS,
      initRoleBasedAction
    ),
    takeEvery(INIT_IPQC_PRIORITIZATION_RECORD_DETAILS, initRecordDetails),
    takeLatest(INIT_IPQC_PRIORITIZATION_RUN_FILTER, initRunFilter),
    takeLatest(INIT_IPQC_PRIORITIZATION_FILTER_FIELDS, initFilterFields),
    takeLatest(
      INIT_IPQC_PRIORITIZATION_EDIT_FILTER_DETAILS,
      initEditFilterDetails
    ),
    takeLatest(
      INIT_IPQC_PRIORITIZATION_SAVE_FILTER_DETAILS,
      initSaveFilterDetails
    ),
    takeLatest(INIT_IPQC_PRIORITIZATION_DELETE_FILTER_OPTION, initDeleteFilter),
    takeLatest(INIT_IPQC_PRIORITIZATION_EXPORT_RECORDS, initExportRecords),
    takeLatest(
      INIT_IPQC_PRIORITIZATION_SAVE_RECORD_DETAILS,
      initSaveRecordDetails
    ),
  ]);
}
function* initFraudAndErrorRowData(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      IPQCFraudAndErrorDetectionData,
      requestDetails
    );
    response = response.data;
    yield put(fetchTableData(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initExportedFields(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedExportFieldsList,
      requestDetails
    );
    response = response.data;
    yield put(fetchExportFields(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initFilterName(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      IPQCFraudAndErrorFilterName,
      requestDetails
    );
    response = response.data.length > 0 ? response.data : [];
    yield put(fetchFilterName(response));
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initHeaderFields(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedDupsExceptionListHeaders,
      requestDetails
    );
    response = response.data;
    yield put(fetchHeaderFieldsList(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initPageCount(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, IPQCFraudAndErrorCount, requestDetails);
    response = response.data;
    yield put(fetchPageCount(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initRoleBasedAction(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      UserActionAuthenticationGetRoleBasedActions,
      requestDetails
    );
    response =
      response.data.resultSet.actionNames !== undefined
        ? response.data.resultSet.actionNames
        : [];
    yield put(fetchRoleBasedActions(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initRecordDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      ReVisitedDupsUnattendedPost,
      requestDetails
    );
    response =
      payload.target === "search"
        ? response.data.details.RecordID !== 0
          ? [response.data.details]
          : []
        : payload.target === "recordSelect" && response.data.details;
    payload.target === "search" &&
      response.length === 0 &&
      generateNotification("No Record Found!", "danger");
    payload.target === "search" && response.length > 0 && callback(response);
    payload.target === "recordSelect" && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initRunFilter(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      IPQCFraudAndErrorRunFilterWithSort,
      requestDetails
    );
    response = response.data;
    yield put(fetchTableData(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initFilterFields(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      IPQCFraudAndErrorFilterFields,
      requestDetails
    );
    response.data ===
      "Request could not be processed at this time. Please contact support team." &&
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    response =
      response.data !==
        "Request could not be processed at this time. Please contact support team." &&
      response.data.length > 0
        ? response.data
        : [];
    yield put(fetchFilterFields(response));
    callback !== undefined && callback();
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initEditFilterDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      SavedFilterEditFilterPageData,
      requestDetails
    );
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initSaveFilterDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      IPQCFraudAndErrorAddAdvanceFilter,
      requestDetails
    );
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initDeleteFilter(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      SavedFilterDeleteAdvancedFilter,
      requestDetails
    );
    response = response.data;
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
function* initExportRecords(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      IPQCFraudAndErrorExportAllToExcel,
      requestDetails
    );
    response = response.data;
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initSaveRecordDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(axios, IPQCSaveRecordDetails, requestDetails);
    response = response.data;
    callback !== undefined && callback(response);
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
