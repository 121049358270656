import {
  INIT_EXCEPTION_FILTER_NAMES,
  INIT_EXCEPTION_LIST_HEADER_NAMES,
  FETCH_EXCEPTION_LIST_HEADER_NAMES,
  INIT_EXCEPTION_LIST,
  FETCH_EXCEPTION_LIST,
  INIT_EXCEPTION_FILTER_COLUMNS,
  FETCH_EXCEPTION_FILTER_COLUMNS,
  INIT_EXCEPTION_FILTER_DETAILS,
  INIT_SAVE_FILTER_EXCEPTION,
  INIT_EXCEPTION_TOTAL_COUNT,
  FETCH_EXCEPTION_TOTAL_COUNT,
  INIT_DELETE_FILTER_EXCEPTION,
  INIT_EXCEPTION_RECORDS_EXPORT,
  FETCH_EXCEPTION_RECORDS_EXPORT,
  INIT_EXCEPTION_RECORDS_EXPORT_ALL,
  FETCH_EXCEPTION_RECORDS_EXPORT_ALL,
  INIT_RELEASE_UNRELEASE_EXCEPTIONS,
  INIT_BULK_RELEASE_UDL_LIST,
  FETCH_BULK_RELEASE_UDL_LIST,
  INIT_MASS_RELEASE_WITH_COMMENTS,
  INITAL_EXCEPTION_DATA,
} from "./actionTypes";

export const initExceptionListFilterNames = (payload, callback) => {
  return {
    type: INIT_EXCEPTION_FILTER_NAMES,
    payload,
    callback,
  };
};

export const initExceptionListHeaderNames = (payload) => {
  return {
    type: INIT_EXCEPTION_LIST_HEADER_NAMES,
    payload,
  };
};

export const fetchExceptionListHeaderNames = (data) => {
  return {
    type: FETCH_EXCEPTION_LIST_HEADER_NAMES,
    data,
  };
};

export const initExceptionList = (payload) => {
  return {
    type: INIT_EXCEPTION_LIST,
    payload,
  };
};

export const fetchExceptionList = (data) => {
  return {
    type: FETCH_EXCEPTION_LIST,
    data,
  };
};

export const initExceptionFilterColumns = (payload) => {
  return {
    type: INIT_EXCEPTION_FILTER_COLUMNS,
    payload,
  };
};

export const fetchExceptionFilterColumns = (data) => {
  return {
    type: FETCH_EXCEPTION_FILTER_COLUMNS,
    data,
  };
};

export const initExceptionFilterDetails = (payload, callback) => {
  return {
    type: INIT_EXCEPTION_FILTER_DETAILS,
    payload,
    callback,
  };
};

export const initSaveExceptionFilter = (payload, callback) => {
  return {
    type: INIT_SAVE_FILTER_EXCEPTION,
    payload,
    callback,
  };
};

export const initExceptionTotalCount = (payload) => {
  return {
    type: INIT_EXCEPTION_TOTAL_COUNT,
    payload,
  };
};

export const fetchExceptionTotalCount = (data) => {
  return {
    type: FETCH_EXCEPTION_TOTAL_COUNT,
    data,
  };
};

export const initDeleteExceptionFilter = (payload, callback) => {
  return {
    type: INIT_DELETE_FILTER_EXCEPTION,
    payload,
    callback,
  };
};

export const initExceptionRecordsExport = (payload) => {
  return {
    type: INIT_EXCEPTION_RECORDS_EXPORT,
    payload,
  };
};

export const fetchExceptionRecordsExport = (data) => {
  return {
    type: FETCH_EXCEPTION_RECORDS_EXPORT,
    data,
  };
};

export const initExceptionRecordsExportAll = (payload) => {
  return {
    type: INIT_EXCEPTION_RECORDS_EXPORT_ALL,
    payload,
  };
};

export const fetchExceptionRecordsExportAll = (data) => {
  return {
    type: FETCH_EXCEPTION_RECORDS_EXPORT_ALL,
    data,
  };
};

export const initReleaseUnReleaseExceptions = (payload, callback) => {
  return {
    type: INIT_RELEASE_UNRELEASE_EXCEPTIONS,
    payload,
    callback,
  };
};

export const initBulkReleaseUdlList = (payload) => {
  return {
    type: INIT_BULK_RELEASE_UDL_LIST,
    payload,
  };
};

export const fetchBulkReleaseUdlList = (data) => {
  return {
    type: FETCH_BULK_RELEASE_UDL_LIST,
    data,
  };
};

export const initMassReleaseWithComments = (payload, callback) => {
  return {
    type: INIT_MASS_RELEASE_WITH_COMMENTS,
    payload,
    callback,
  };
};

export const intialExceptionData = () => {
  return {
    type: INITAL_EXCEPTION_DATA,
  };
};
