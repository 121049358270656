export const INIT_FETCH_RUNNINGJOBS = "INIT_FETCH_RUNNINGJOBS";
export const FETCH_RUNNINGJOB_LIST = "FETCH_RUNNINGJOB_LIST";
export const INIT_FETCH_KILLJOB = "INIT_FETCH_KILLJOB";
export const INIT_FETCH_RUNNINGJOB_DETAILS = "INIT_FETCH_RUNNINGJOB_DETAILS";
export const INIT_FETCH_JOB_LOGS = "INIT_FETCH_JOB_LOGS";
export const FETCH_JOB_LOGS_COMPLETE = "FETCH_JOB_LOGS_COMPLETE";
export const FETCH_JOB_LOGS = "FETCH_JOB_LOGS";
export const INIT_FETCH_RUNNING_JOB_STATUS = "INIT_FETCH_RUNNING_JOB_STATUS";
export const FETCH_RUNNING_JOB_STATUS = "FETCH_RUNNING_JOB_STATUS";
export const INIT_FETCH_CLIENT_RUNNINGJOBS = "INIT_FETCH_CLIENT_RUNNINGJOBS";
export const FETCH_CLIENT_RUNNINGJOB_LIST = "FETCH_CLIENT_RUNNINGJOB_LIST";
export const APPLY_RUNNING_JOBS_INITAL_VALUE =
  "APPLY_RUNNING_JOBS_INITAL_VALUE";
