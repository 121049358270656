export const INIT_GET_JOBS_LIST = "INIT_GET_JOBS_LIST";
export const FETCHING_JOBS_LIST = "FETCHING_JOBS_LIST";
export const FETCH_JOBS_LIST = "FETCH_JOBS_LIST";
export const JOBS_LIST_FETCHING_COMPLETE = "JOBS_LIST_FETCHING_COMPLETE";

export const INIT_DELETE_JOB = "INIT_DELETE_JOB";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILED = "DELETE_JOB_FAILED";

export const INIT_RUN_JOB = "INIT_RUN_JOB";
export const RUN_JOB_SUCCESS = "RUN_JOB_SUCCESS";
export const RUN_JOB_FAILED = "RUN_JOB_FAILED";

export const INIT_GET_LOGS_FOR_DUPS_AND_DATALOAD =
  "INIT_GET_LOGS_FOR_DUPS_AND_DATALOAD";
export const FETCHING_LOGS_FOR_DUPS_AND_DATALOAD =
  "FETCHING_LOGS_FOR_DUPS_AND_DATALOAD";
export const FETCH_LOGS_FOR_DUPS_AND_DATALOAD =
  "FETCH_LOGS_FOR_DUPS_AND_DATALOAD";
export const LOGS_FOR_DUPS_AND_DATALOAD_FETCHING_COMPLETE =
  "LOGS_FOR_DUPS_AND_DATALOAD_FETCHING_COMPLETE";

export const INIT_GET_JOB_DETAILS = "INIT_GET_JOB_DETAILS";
export const FETCHING_JOB_DETAILS = "FETCHING_JOB_DETAILS";
export const JOB_DETAILS_FETCH_COMPLETE = "JOB_DETAILS_FETCH_COMPLETE";

export const INIT_GET_SERVER_DATE_TIME = "INIT_GET_SERVER_DATE_TIME";
export const FETCHING_SERVER_DATE_TIME = "FETCHING_SERVER_DATE_TIME";
export const FETCH_SERVER_DATE_TIME = "FETCH_SERVER_DATE_TIME";
export const SERVER_DATE_TIME_FETCH_COMPLETE = "SERVER_DATE_TIME_FETCH_COMPLETE";

export const INIT_SAVE_ADD_JOB= "INIT_SAVE_ADD_JOB";
export const ADD_JOB_SAVE_SUCCESS = "ADD_JOB_SAVE_SUCCESS";
export const ADD_JOB_SAVE_FAILED = "ADD_JOB_SAVE_FAILED";


export const INIT_UPDATE_JOB_DETAILS= "INIT_UPDATE_JOB_DETAILS";
export const JOB_DETAILS_UPDATE_SUCCESS = "JOB_DETAILS_UPDATE_SUCCESS";
export const JOB_DETAILS_UPDATE_FAILED = " JOB_DETAILS_UPDATE_FAILED";