import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from "../../../config/index";
import {
  AdhocExcelValidation,
  IPQCUpload,
  IPQCReleaseAll,
  IPQCUploadFile,
} from "../../../config/APINames";
import {
  INIT_IPQC_BULKRELEASE_EXCEL_VALIDATION,
  INIT_IPQC_BULKRELEASE_UPLOAD,
  INIT_IPQC_BULKRELEASE_UPLOAD_FILE,
  INIT_IPQC_BULKRELEASE_RELEASE_ALL,
} from "./actionTypes";
import {
  fetchingIpqcBulkReleaseExcelValidation,
  fetchIpqcBulkReleaseExcelValidation,
  ipqcBulkReleaseExcelValidationComplete,
  ipqcBulkReleaseExcelValidationFail,
  fetchingIpqcBulkReleaseUpload,
  fetchIpqcBulkReleaseUpload,
  ipqcBulkReleaseUploadComplete,
  ipqcBulkReleaseUploadFail,
  fetchingIpqcBulkReleaseUploadFile,
  fetchIpqcBulkReleaseUploadFile,
  ipqcBulkReleaseUploadFileComplete,
  ipqcBulkReleaseUploadFileFail,
  fetchingIpqcBulkReleaseReleaseAll,
  fetchIpqcBulkReleaseReleaseAll,
  ipqcBulkReleaseReleaseAllComplete,
  ipqcBulkReleaseReleaseAllFail,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(
      INIT_IPQC_BULKRELEASE_EXCEL_VALIDATION,
      initBulkReleaseExcelValidationSaga
    ),
    takeLatest(INIT_IPQC_BULKRELEASE_UPLOAD, initBulkReleaseUploadSaga),
    takeLatest(
      INIT_IPQC_BULKRELEASE_UPLOAD_FILE,
      initBulkReleaseUploadFileSaga
    ),
    takeLatest(INIT_IPQC_BULKRELEASE_RELEASE_ALL, initBulkReleaseRelaseAllSaga),
  ]);
}

function* initBulkReleaseUploadSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingIpqcBulkReleaseUpload());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, IPQCUpload, requestDetails);
    if (response.data === Error_Msg) {
      callback && callback();
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchIpqcBulkReleaseUpload(response.data));
      callback && callback(response.data);
      yield put(ipqcBulkReleaseUploadComplete());
    } else {
      yield put(ipqcBulkReleaseUploadFail());
    }
  } catch (error) {
    yield put(ipqcBulkReleaseUploadFail());
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initBulkReleaseExcelValidationSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingIpqcBulkReleaseExcelValidation());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, AdhocExcelValidation, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(
        fetchIpqcBulkReleaseExcelValidation(response.data.resultStatus)
      );
      callback && callback(response.data);
      yield put(ipqcBulkReleaseExcelValidationComplete());
    } else {
      yield put(ipqcBulkReleaseExcelValidationFail());
    }
  } catch (error) {
    yield put(ipqcBulkReleaseExcelValidationFail());
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initBulkReleaseUploadFileSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingIpqcBulkReleaseUploadFile());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, IPQCUploadFile, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
      callback && callback();
    } else if (response !== Error_Msg) {
      yield put(fetchIpqcBulkReleaseUploadFile(response.data));
      callback && callback(response.data);
      yield put(ipqcBulkReleaseUploadFileComplete());
    } else {
      yield put(ipqcBulkReleaseUploadFileFail());
    }
  } catch (error) {
    yield put(ipqcBulkReleaseUploadFileFail());
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initBulkReleaseRelaseAllSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingIpqcBulkReleaseReleaseAll());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, IPQCReleaseAll, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchIpqcBulkReleaseReleaseAll(response.data));
      callback && callback(response.data);
      yield put(ipqcBulkReleaseReleaseAllComplete());
    } else {
      yield put(ipqcBulkReleaseReleaseAllFail());
    }
  } catch (error) {
    yield put(ipqcBulkReleaseReleaseAllFail());
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
