import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectHeader = (state) => state.get("header", initialState);

export const selectClientList = () =>
  createSelector(selectHeader, (VPState) => VPState.get("clientList"));

export const selectAdminDetails = () =>
  createSelector(selectHeader, (VPState) => VPState.get("adminDetails"));

export const selectClientSystemParameters = () =>
  createSelector(selectHeader, (VPState) =>
    VPState.get("clientSystemParameters")
  );

export const selectMobileReportUrl = () =>
  createSelector(selectHeader, (VPState) => VPState.get("mobileReportUrl"));
