import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { AtcatAdminName , Error_Msg } from "../../../config/index";
import {
  ChangePassword,
  PasswordManagementCheckPasswordConfiguration,
} from "../../../config/APINames";
import { loginFailed } from "../../Login/store/actions";
import { INIT_CHANGE_PASSWORD } from "./actionTypes";

export default function* watchLogin() {
  yield all([takeLatest(INIT_CHANGE_PASSWORD, initChangePassword)]);
}

function* initChangePassword(action) {
  const { payload = {}, callback } = action || {};
  const { clientName = "", role = "" } = payload;
  delete payload.role;
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });

    let url = ChangePassword;
    if (clientName.toUpperCase() !== AtcatAdminName && role !== "SuperUser")
      url = PasswordManagementCheckPasswordConfiguration;

    const response = yield call(axios, url, requestDetails);
    if (response && response.status === 200) {
      callback && callback(response.data);
    }
  } catch (error) {
    yield put(loginFailed(Error_Msg));
  }
}
