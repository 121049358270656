import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import exportToExcel from "../../../helper/exportToExcel";
import generateKey from "../../../config/generateKey";
import {
  DuplicateClaimFilterFields,
  DuplicateClaimAddAdvanceFilter,
  DuplicateClaimFilterName,
  DuplicateClaimListOfDuplicateClaim,
  DuplicateClaimDeleteAdvanceFilter,
  DuplicateClaimEditFilterPageData,
  DuplicateClaimExportToExcel,
  DuplicateClaimExportAllToExcel,
  RevisitedClaimsPost,
  UserManagementGetUserList,
  ManualClaimManualClaimHistory,
  ReVisitedClaimsSearchClaimId,
  RevisitedClaimsVendorLetters,
  ReVisitedClaimsPaybackRecords,
  RevisitedClaimsClaimRecords,
  ReVisitedClaimsVendorListDataFromException,
  RevisitedClaimsVendorFilterFields,
  RevisitedClaimsVendorListFromexceptionCount,
  RevisitedClaimsActionsOnClaimRecords,
  ReVisitedClaimsVendorListData,
  RevisitedClaimsVendorListCount,
  RevisitedClaimsGetOffsetRecords,
  ReVisitedClaimsDeleteOffset,
  ReVisitedClaimsAddOffset,
  ClientUDLGetUDLDetails,
  ReVisitedClaimsOffsetDetails,
  ReVisitedClaimsUpdateOffset,
  ReVisitedClaimsSaveClaimDetails,
  ReVisitedClaimsAddPayback,
    ReVisitedClaimsPaybackDetails,
    ReVisitedDupsUnattendedPrimusDFMURL,
} from "../../../config/APINames";
import { storage } from "../../../config/storage";
import {
  INIT_FETCH_CLAIM_FILTER_COL_NAMES,
  INIT_SAVE_CLAIM_FILTER,
  INIT_FETCH_CLAIM_FILTER_NAMES,
  INIT_DUPLICATE_CLAIM_LIST,
  INIT_DELETE_CLAIM_FILTER,
  INIT_FETCH_CLAIM_FILTER_DETAILS,
  INIT_GET_CLAIM_RECORDS_EXPORT,
  INIT_GET_CLAIM_RECORDS_EXPORT_ALL,
  INIT_GET_CLAIM_DETAILS,
  INIT_GET_USER_LIST,
  INIT_GET_MANUAL_CLAIMS_HISTORY,
  INIT_SEARCH_DUPLICATECLAIM_DETAILS,
  INIT_DUPLICATECLAIM_VENDOR_LETTER,
  INIT_DUPLICATECLAIM_PAYBACK_RECORDS,
  INIT_DUPLICATECLAIM_EXCEPTION_RECORDS,
  INIT_GET_VENDOR_LIST_FROM_EXCEPTION,
  INIT_GET_VENDOR_FILTER_FIELDS,
  INIT_GET_VENDOR_LIST_FROM_EXCEPTION_COUNT,
  INIT_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS,
  INIT_GET_VENDOR_LIST_DATA,
  INIT_GET_VENDOR_LIST_COUNT,
  INIT_GET_OFFSET_RECORDS,
  INIT_DELETE_OFFSET,
  INIT_ADD_OFFSET,
  INIT_GET_OFFSET_TYPE,
  INIT_GET_OFFSET_DETAILS,
  INIT_UPDATE_OFFSET,
  INIT_SAVE_CLAIM_DETAILS,
  INIT_ADD_PAYBACK,
    INIT_DUPLICATECLAIM_PAYBACK_DETAILS,
    INIT_GET_PRIMUS_DFM_URL_DETAILS,
} from "./actionTypes";
import { Error_Msg } from "../../../config/index";

import {
  fetchClaimColNames,
  fetchingClaimColNames,
  claimColNameFetchComplete,
  claimColNameFetchFailed,
  fetchDuplicateClaimsList,
  fetchingClaimRecodsExport,
  fetchClaimRecordsExport,
  claimRecordsExportFetchingComplete,
  fetchingClaimRecodsExportAll,
  fetchClaimRecordsExportAll,
  claimRecordsExportAllFetchingComplete,
  fetchClaimDetails,
  fetchUserList,
  fetchManualClaimsHistory,
  fetchSearchDuplicateClaimDetails,
  fetchDuplicateClaimVendorLetter,
  fetchDuplicateClaimPaybackRecords,
  fetchDuplicateClaimExceptionRecords,
  fetchVendorListFromException,
  fetchVendorFilterFields,
  fetchVendorListFromExceptionCount,
  fetchActionsDuplicateClaimExceptionRec,
  fetchVendorListData,
  fetchVendorListCount,
  fetchOffsetRecords,
  fetchOffsetType,
  fetchOffsetDetails,
  fetchDuplicateClaimPaybackDetails,
  fetchingClaimDetails,
  fetchingClaimDetailsComplete,
  fetchingClaimDetailsFail,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(INIT_SAVE_CLAIM_FILTER, initSaveClaimFilterSaga),
    takeLatest(INIT_FETCH_CLAIM_FILTER_COL_NAMES, initGetClaimColNamesSaga),
    takeLatest(INIT_FETCH_CLAIM_FILTER_NAMES, initGetClaimFilterNamesSaga),
    takeLatest(INIT_DUPLICATE_CLAIM_LIST, initGetDuplicateClaimsListSaga),
    takeLatest(INIT_DELETE_CLAIM_FILTER, initDeleteClaimFilterSaga),
    takeLatest(INIT_FETCH_CLAIM_FILTER_DETAILS, initGetClaimFilterDetailsSaga),
    takeLatest(INIT_GET_CLAIM_RECORDS_EXPORT, initGetClaimRecordsExportSaga),
    takeLatest(
      INIT_GET_CLAIM_RECORDS_EXPORT_ALL,
      initGetClaimRecordsExportAllSaga
    ),
    takeLatest(INIT_GET_CLAIM_DETAILS, initGetClaimDetailsSaga),
    takeLatest(INIT_GET_USER_LIST, initGetUserListSaga),
    takeLatest(INIT_GET_MANUAL_CLAIMS_HISTORY, initGetManualClaimsHistorySaga),
    takeLatest(
      INIT_SEARCH_DUPLICATECLAIM_DETAILS,
      initSearchDuplicateClaimDetailsSaga
    ),
    takeLatest(
      INIT_DUPLICATECLAIM_VENDOR_LETTER,
      initDuplicateClaimVendorLetterSaga
    ),
    takeLatest(
      INIT_DUPLICATECLAIM_PAYBACK_RECORDS,
      initDuplicateClaimPaybackRecordsSaga
    ),
    takeLatest(
      INIT_DUPLICATECLAIM_PAYBACK_DETAILS,
      initDuplicateClaimPaybackDetailsSaga
    ),
    takeLatest(INIT_ADD_PAYBACK, initAddPaybackSaga),
    takeLatest(
      INIT_DUPLICATECLAIM_EXCEPTION_RECORDS,
      initDuplicateClaimExceptionRecordsSaga
    ),
    takeLatest(
      INIT_GET_VENDOR_LIST_FROM_EXCEPTION,
      initGetVendorListFromExceptionSaga
    ),
    takeLatest(INIT_GET_VENDOR_FILTER_FIELDS, initGetVendorFilterFieldsSaga),
    takeLatest(
      INIT_GET_VENDOR_LIST_FROM_EXCEPTION_COUNT,
      initGetVendorListFromExceptionCountSaga
    ),
    takeLatest(
      INIT_ACTIONS_DUPLICATECLAIM_EXCEPTION_RECORDS,
      initActionDuplicateClaimExceptionRecSaga
    ),
    takeLatest(INIT_GET_VENDOR_LIST_DATA, initGetVendorListDataSaga),
    takeLatest(INIT_GET_VENDOR_LIST_COUNT, initGetVendorListCountSaga),
    takeLatest(INIT_GET_OFFSET_RECORDS, initGetOffsetRecordsSaga),
    takeLatest(INIT_DELETE_OFFSET, initDeleteOffsetSaga),
    takeLatest(INIT_ADD_OFFSET, initAddOffsetSaga),
    takeLatest(INIT_GET_OFFSET_TYPE, initGetOffsetTypeSaga),
    takeLatest(INIT_GET_OFFSET_DETAILS, initGetOffsetDetailsSaga),
    takeLatest(INIT_UPDATE_OFFSET, initUpdateOffsetSaga),
      takeLatest(INIT_SAVE_CLAIM_DETAILS, initSaveClaimDetailsSaga),
      takeLatest(INIT_GET_PRIMUS_DFM_URL_DETAILS, initGetPrimusDFMUrlSaga),
  ]);
}
function* initGetClaimRecordsExportSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName, clientId, selectedLanguage } =
    sessionDetails || {};
  yield put(fetchingClaimRecodsExport());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName: clientName.toLowerCase(),
      userName,
      ClientID: clientId,
      language: selectedLanguage,
    });
    const response = yield call(
      axios,
      DuplicateClaimExportToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchClaimRecordsExport(response.data));
      exportToExcel(
        response.data.claimListing,
        "DuplicateClaimsList",
        response.data.exportfileName
      );
    }

    yield put(claimRecordsExportFetchingComplete());
  } catch (error) {
    yield put(claimRecordsExportFetchingComplete());
  }
}

function* initGetClaimRecordsExportAllSaga(action) {
  const { payload } = action || {};
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { clientName, userName, clientId, selectedLanguage } =
    sessionDetails || {};
  yield put(fetchingClaimRecodsExportAll());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientname: clientName.toLowerCase(),
      userName,
      ClientID: clientId,
      language: selectedLanguage,
    });
    const response = yield call(
      axios,
      DuplicateClaimExportAllToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchClaimRecordsExportAll(response.data));
      exportToExcel(
        response.data.claimListing,
        "DuplicateClaimsList",
        response.data.exportfileName
      );
    }
    yield put(claimRecordsExportAllFetchingComplete());
  } catch (error) {
    yield put(claimRecordsExportAllFetchingComplete());
  }
}

function* initGetClaimFilterDetailsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, sourceDomain, clientName, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      sourceDomain,
      userId,
      userName,
    });
    const response = yield call(
      axios,
      DuplicateClaimEditFilterPageData,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetClaimColNamesSaga(action) {
  const { payload } = action || {};
  yield put(fetchingClaimColNames());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      DuplicateClaimFilterFields,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchClaimColNames(response.data));
      yield put(claimColNameFetchComplete());
    } else {
      yield put(claimColNameFetchFailed());
    }
  } catch (error) {
    yield put(claimColNameFetchFailed());
  }
}

function* initSaveClaimFilterSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      DuplicateClaimAddAdvanceFilter,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetClaimFilterNamesSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, sourceDomain, clientName, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userId,
      userName,
      sourceDomain,
      clientName,
    });
    const response = yield call(
      axios,
      DuplicateClaimFilterName,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetDuplicateClaimsListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      DuplicateClaimListOfDuplicateClaim,
      requestDetails
    );
    if (response && response.status === 200) {
      if (response.data.resultStatus === true) {
        yield put(fetchDuplicateClaimsList(response.data.resultSet));
      } else {
        yield put(fetchDuplicateClaimsList([]));
      }
    }
  } catch (error) {
    generateNotification(error, "danger");
  }
}

function* initDeleteClaimFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      userName,
      clientName,
    });
    const response = yield call(
      axios,
      DuplicateClaimDeleteAdvanceFilter,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetClaimDetailsSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingClaimDetails());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, RevisitedClaimsPost, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchClaimDetails(response.data));
      callback && callback(response.data);
      yield put(fetchingClaimDetailsComplete());
    } else {
      yield put(fetchingClaimDetailsFail());
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
    yield put(fetchingClaimDetailsFail());
  }
}

function* initGetUserListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      UserManagementGetUserList,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchUserList(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetManualClaimsHistorySaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManualClaimManualClaimHistory,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchManualClaimsHistory(response.data.resultSet));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initSearchDuplicateClaimDetailsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsSearchClaimId,
      requestDetails
    );
    if (response.data === Error_Msg) {
      const regMatch = /[a-z]/i.test(payload.CommandArgs);
      if (regMatch) {
        generateNotification(
          "atcat.enteredExceptionSetIdIsNotAvailable",
          "danger"
        );
      } else {
        generateNotification(
          "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
          "danger"
        );
      }
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response.data);
      yield put(fetchSearchDuplicateClaimDetails(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initDuplicateClaimVendorLetterSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RevisitedClaimsVendorLetters,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchDuplicateClaimVendorLetter(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initDuplicateClaimPaybackRecordsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsPaybackRecords,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);
      yield put(fetchDuplicateClaimPaybackRecords(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initDuplicateClaimPaybackDetailsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsPaybackDetails,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response);

      yield put(fetchDuplicateClaimPaybackDetails(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initDuplicateClaimExceptionRecordsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RevisitedClaimsClaimRecords,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchDuplicateClaimExceptionRecords(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initActionDuplicateClaimExceptionRecSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RevisitedClaimsActionsOnClaimRecords,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response.data);
      yield put(fetchActionsDuplicateClaimExceptionRec(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetVendorListFromExceptionSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsVendorListDataFromException,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchVendorListFromException(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}
function* initGetVendorFilterFieldsSaga(action) {
  const { payload,callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RevisitedClaimsVendorFilterFields,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchVendorFilterFields(response.data));
      if (callback) callback(response.data);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetVendorListFromExceptionCountSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RevisitedClaimsVendorListFromexceptionCount,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchVendorListFromExceptionCount(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetVendorListDataSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsVendorListData,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchVendorListData(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetVendorListCountSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RevisitedClaimsVendorListCount,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchVendorListCount(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetOffsetRecordsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      RevisitedClaimsGetOffsetRecords,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchOffsetRecords(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initDeleteOffsetSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsDeleteOffset,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback();
      generateNotification(generateKey(response.data), "success");
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initAddOffsetSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsAddOffset,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response.data);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initAddPaybackSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsAddPayback,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (response.data.resultStatus === true) {
        if (action.callback) action.callback();
        generateNotification(generateKey(response.data.message), "success");
      } else {
        generateNotification(
          generateKey(response.data.resultSet.validations[0]),
          "danger"
        );
      }
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetOffsetTypeSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, ClientUDLGetUDLDetails, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      yield put(fetchOffsetType(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initGetOffsetDetailsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsOffsetDetails,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response.data);
      yield put(fetchOffsetDetails(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initUpdateOffsetSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsUpdateOffset,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data !== Error_Msg) {
      if (action.callback) action.callback(response.data);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
    if (action.callback) action.callback(error);
  }
}

function* initSaveClaimDetailsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ReVisitedClaimsSaveClaimDetails,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response.data === "Claim details saved successfully") {
      generateNotification("atcat.claimDetailsSavedSuccessfully", "success");
    } else if (response.data !== Error_Msg) {
      generateNotification(generateKey(response.data), "danger");
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initGetPrimusDFMUrlSaga(action) {
    const { payload, callback } = action || {};
    try {
        const requestDetails = postRequestDetails();
        yield (requestDetails.data = {
            ...payload,
        });
        const response = yield call(
            axios,
            ReVisitedDupsUnattendedPrimusDFMURL,
            requestDetails
        );
        if (response.data !== Error_Msg) {
            callback && callback(response.data);
        } else generateNotification(generateKey(Error_Msg), "danger");
    } catch (error) {
        generateNotification(generateKey(Error_Msg), "danger");
    }
}
