import {
  INIT_VENDOR_GROUP_LIST,
  INIT_VENDOR_GROUP_LIST_ROLE_BASE_ACTION,
  FETCH_VENDOR_GROUP_LIST,
  FETCH_VENDOR_GROUP_LIST_ROLE_BASE_ACTION,
  APPLY_VARIABLE_INITAL_VALUE,
  INIT_VENDOR_GROUP_DETAILS,
  FETCH_VENDOR_GROUP_DETAILS,
  INIT_VENDOR_GROUP_ASSIGN_VENDOR,
  FETCH_VENDOR_GROUP_ASSIGN_VENDOR,
  INIT_VENDOR_GROUP_FILTER_DROPDOWN,
  FETCH_VENDOR_GROUP_FILTER_DROPDOWN,
  INIT_UNASSIGN_VENDOR,
  INIT_DELETE_VENDOR_GROUP,
  INIT_SAVE_VENDOR_DETAILS,
  INIT_HANDLE_ASSIGN_VENDOR_TO_GROUP,
  INIT_CREATE_NEW_VENDOR_GROUP,
} from "./actionTypes";

export const initVendorGroupList = (payload) => {
  return {
    type: INIT_VENDOR_GROUP_LIST,
    payload,
  };
};
export const initVendorRoleBaseAction = (payload) => {
  return {
    type: INIT_VENDOR_GROUP_LIST_ROLE_BASE_ACTION,
    payload,
  };
};

export const fetchVendorGroupList = (data) => {
  return {
    type: FETCH_VENDOR_GROUP_LIST,
    data,
  };
};

export const fetchVendorRoleBasedAction = (data) => {
  return {
    type: FETCH_VENDOR_GROUP_LIST_ROLE_BASE_ACTION,
    data,
  };
};

export const applyInitalValue = (data) => {
  return {
    type: APPLY_VARIABLE_INITAL_VALUE,
    data,
  };
};
export const initVendorGroupDetails = (payload, callback) => {
  return {
    type: INIT_VENDOR_GROUP_DETAILS,
    payload,
    callback,
  };
};
export const fetchVendorGroupDetails = (data) => {
  return {
    type: FETCH_VENDOR_GROUP_DETAILS,
    data,
  };
};
export const initVendorFilterDropDown = (payload) => {
  return {
    type: INIT_VENDOR_GROUP_FILTER_DROPDOWN,
    payload,
  };
};
export const fetchVendorFilterDropDown = (data) => {
  return {
    type: FETCH_VENDOR_GROUP_FILTER_DROPDOWN,
    data,
  };
};

export const initAssignVendorList = (payload, callback) => {
  return {
    type: INIT_VENDOR_GROUP_ASSIGN_VENDOR,
    payload,
    callback,
  };
};

export const initUnassignVendor = (payload, callback) => {
  return {
    type: INIT_UNASSIGN_VENDOR,
    payload,
    callback,
  };
};
export const initDeleteVendorGroup = (payload, callback) => {
  return {
    type: INIT_DELETE_VENDOR_GROUP,
      payload,
      callback
  };
};
export const initSaveVendorDetails = (payload) => {
  return {
    type: INIT_SAVE_VENDOR_DETAILS,
    payload,
  };
};
export const initHandleAssignVendorToGroup = (payload, callback) => {
  return {
    type: INIT_HANDLE_ASSIGN_VENDOR_TO_GROUP,
    payload,
    callback,
  };
};
export const initCreateNewVendorGroup = (payload, callback) => {
  return {
    type: INIT_CREATE_NEW_VENDOR_GROUP,
    payload,
    callback,
  };
};
export const fetchAssignVendorList = (data) => {
  return {
    type: FETCH_VENDOR_GROUP_ASSIGN_VENDOR,
    data,
  };
};
