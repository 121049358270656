import {
    INIT_CLAIM_FILTER_FIELDS,
    INIT_CLAIM_LIST,
    FETCH_CLAIM_LIST,
    INIT_CLAIM_TOTAL_COUNT,
    FETCH_CLAIM_TOTAL_COUNT,
    INIT_CLAIM_RECORDS_EXPORT,
    FETCH_CLAIM_RECORDS_EXPORT,
    INIT_CLAIM_RECORDS_EXPORT_ALL,
    FETCH_CLAIM_RECORDS_EXPORT_ALL,
    INIT_GET_DUPS_CLAIM_DETAILS,
    FETCH_DUPS_CLAIM_DETAILS
} from "./actionTypes";

export const initClaimFilterFields = (payload, callback) => {
    return {
        type: INIT_CLAIM_FILTER_FIELDS,
        payload,
        callback
    };
};

export const initClaimList = (payload) => {
    return {
        type: INIT_CLAIM_LIST,
        payload,
    };
};

export const fetchClaimList = (data) => {
    return {
        type: FETCH_CLAIM_LIST,
        data,
    };
};

export const initClaimTotalCount = (payload) => {
    return {
        type: INIT_CLAIM_TOTAL_COUNT,
        payload,
    };
};

export const fetchClaimTotalCount = (data) => {
    return {
        type: FETCH_CLAIM_TOTAL_COUNT,
        data,
    };
};

export const initClaimRecordsExport = (payload) => {
    return {
        type: INIT_CLAIM_RECORDS_EXPORT,
        payload,
    };
};

export const fetchClaimRecordsExport = (data) => {
    return {
        type: FETCH_CLAIM_RECORDS_EXPORT,
        data,
    };
};

export const initClaimRecordsExportAll = (payload) => {
    return {
        type: INIT_CLAIM_RECORDS_EXPORT_ALL,
        payload,
    };
};

export const fetchClaimRecordsExportAll = (data) => {
    return {
        type: FETCH_CLAIM_RECORDS_EXPORT_ALL,
        data,
    };
};

export const initGetDupsClaimDetails = (payload, callback) => {
    return {
        type: INIT_GET_DUPS_CLAIM_DETAILS,
        payload,
        callback,
    };
};

export const fetchDupsClaimDetails = (data) => {
    return {
        type: FETCH_DUPS_CLAIM_DETAILS,
        data,
    };
};

