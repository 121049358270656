import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip";
import Confirm from "./Confirm";

const Button = (props) => {
  let timeout = useRef(0);
  const [active, setActive] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const showTip = () => {
    timeout.current = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout.current);
    setActive(false);
  };

  const handleConfirmation = () => {
    setShowConfirmation(false);
    props.onConfirmation && props.onConfirmation();
  };

  const getBtnClass = () => {
    switch (props.btnType) {
      case "primary":
        return "primary-button";
      case "secondary":
        return "secondary-button";
      case "icon":
        return "icon-button";
      default:
        return "default-button";
    }
  };

  return (
    <>
      <button
        className={`${props.customClass ? props.customClass : getBtnClass()}`}
        ref={setReferenceElement}
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
        onClick={
          props.showConfirm
            ? () => setShowConfirmation(true)
            : props.handleClick
        }
        aria-label={props.hint || props.label}
        disabled={props.disabled}
      >
        {props.icon ? props.icon : props.label}
      </button>
      {props.hint && active && (
        <Tooltip triggerElem={referenceElement}>{props.hint}</Tooltip>
      )}
      {props.showConfirm && (
        <Confirm
          showConfirmation={showConfirmation}
          handleConfirmation={handleConfirmation}
          message={props.message}
          handleCancel={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

Button.propTypes = {
  btnType: PropTypes.oneOf(["default", "primary", "secondary", "icon"]),
  customClass: PropTypes.string,
  handleClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
};

export default Button;
