import { fromJS } from "immutable";
import {
    INIT_ADHOCDUPS_EXCEL_VALIDATION,
    FETCH_ADHOCDUPS_EXCEL_VALIDATION,
    INIT_SUBMIT_ADHOCDUPS,
    FETCH_SUBMIT_ADHOCDUPS,
    FETCH_ADHOC_DUPS_JOBS,
    FETCH_ADHOC_DUPS_JOBS_DETAILS,
    APPLY_INITAL_ADHOCDUPS_VALUES,
    FETCH_ADHOCDUPS_EXCEL_RECORD_COUNT,
} from "./actionTypes";

export const initialState = fromJS({
    fetchingAdhocDups: false,
    fileValidation: true,
    adhocDupsDetails: {},
    adhocJobs: {},
    adhocJobDetails: {},
    adhocRecordCount: 0,
});
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_ADHOCDUPS_EXCEL_VALIDATION: {
            return state.set("fetchingAdhocDups", true);
        }
        case FETCH_ADHOCDUPS_EXCEL_VALIDATION: {
            return state
                .set("fileValidation", action.data)
                .set("fetchingAdhocDups", false);
        }
        case INIT_SUBMIT_ADHOCDUPS: {
            return state.set("fetchingAdhocDups", true);
        }
        case FETCH_SUBMIT_ADHOCDUPS: {
            return state
                .set("adhocDupsDetails", action.data)
                .set("fetchingAdhocDups", false);
        }
        case FETCH_ADHOC_DUPS_JOBS: {
            return state.set("adhocJobs", action.data);
        }
        case FETCH_ADHOC_DUPS_JOBS_DETAILS: {
            return state.set("adhocJobDetails", action.data);
        }
        case APPLY_INITAL_ADHOCDUPS_VALUES: {
            return initialState;
        }
        case FETCH_ADHOCDUPS_EXCEL_RECORD_COUNT: {
            return state.set("adhocDupsRecordCount", action.data);
        }
        default:
            return state;
    }
};

export default reducer;
