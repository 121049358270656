import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";
import { customStyles } from "./style";

const Select = (props) => {
  const { t } = useTranslation();
  const [visited, setVisited] = useState(false);
  useEffect(() => {
    props.resetVisited && setVisited(false);
  }, [props.resetVisited]);
  const options = props.options
    ? props.options.map((option) => ({
        label: option.value,
        value: option.id,
      }))
    : [];

  const stringVal = props.value ? props.value.toString() : "";
  const value = options.find(
    (option) => option && option.value && option.value.toString() === stringVal
  );

  const handleOnChange = (selectedOption) => {
    let value = selectedOption ? selectedOption.value.toString() : "";
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    value = isNaN(Number(strippedHtml)) ? strippedHtml : Number(strippedHtml);

    props.onChangeHandler &&
      props.onChangeHandler({
        target: {
          value: selectedOption ? strippedHtml : "",
          id: props.id,
          name: props.name,
          text: selectedOption ? selectedOption.label : null,
          options: options,
          selectedIndex: options.findIndex((option) =>
            option.value === selectedOption ? strippedHtml : false
          ),
          required: props.required,
        },
      });
  };

  const handleOnBlur = (selectedOption) => {
    setVisited(true);
    let value = selectedOption.target.value.toString();
    const strippedHtml = value.replace(/<[^>]+>/g, "");
    value = isNaN(Number(strippedHtml)) ? strippedHtml : Number(strippedHtml);

    return (
      props.onBlurHandler &&
      props.onBlurHandler({
        type: "blur",
        target: {
          value,
          id: props.id,
          name: props.name,
          text: selectedOption.label,
          options: options,
          selectedIndex: options.findIndex((option) => option.value === value),
        },
      })
    );
  };

  return (
    <>
      <ReactSelect
        visited={visited}
        isClearable={props.isClearable || false}
        onInputChange={(value) =>
          props.onSelectInputType &&
          props.onSelectInputType({
            target: {
              value,
              name: props.name,
              id: props.id,
            },
          })
        }
        isDisabled={props.disabled || false}
        required={props.required}
        isSearchable={props.isSearchable || false}
        isLoading={props.isLoading}
        value={value || ""}
        placeholder={props.placeholder || `--${t("atcat.select")}--`}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        options={options}
        styles={{ ...customStyles, ...props.styles }}
        customStyle={props.customStyle}
        menuPlacement="auto"
        menuPortalTarget={props.inTable || props.isPagination ? document.body : false}
        noOptionsMessage={() => t("atcat.noOptions")}
        showError={props.showError}
        aria-label={props.label}
      />
    </>
  );
};

Select.propTypes = {
  customStyle: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.any,
  inTable: PropTypes.any,
  isPagination:PropTypes.any,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.any,
  isSearchable: PropTypes.bool,
  name: PropTypes.any,
  onBlurHandler: PropTypes.func,
  onChangeHandler: PropTypes.func,
  onSelectInputType: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.any,
  required: PropTypes.any,
  resetVisited: PropTypes.func,
  showError: PropTypes.any,
  styles: PropTypes.any,
  value: PropTypes.string,
  label: PropTypes.string,
};

export default Select;
