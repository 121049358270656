import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from "../../../config/index";
import {
  AdhocExcelValidation,
  BulkReleaseUpload,
  BulkReleaseFraudAndErrorUpload,
  BulkReleaseReleaseAll,
  BulkReleaseFraudErrorReleaseAll,
  BulkReleaseUploadFile,
  BulkReleaseFraudAndErrorUploadFile,
} from "../../../config/APINames";
import {
  INIT_BULKRELEASEAI_EXCEL_VALIDATION,
  INIT_BULKRELEASE_DUPS_UPLOAD,
  INIT_BULKRELEASE_DUPS_UPLOAD_FILE,
  INIT_BULKRELEASE_DUPS_RELEASE_ALL,
  INIT_BULKRELEASE_FNE_UPLOAD,
  INIT_BULKRELEASE_FNE_UPLOAD_FILE,
  INIT_BULKRELEASE_FNE_RELEASE_ALL,
} from "./actionTypes";
import {
  fetchingBulkReleaseExcelValidation,
  fetchBulkReleaseExcelValidation,
  bulkReleaseExcelValidationComplete,
  bulkReleaseExcelValidationFail,
  fetchingBulkReleaseUpload,
  fetchBulkReleaseUpload,
  bulkReleaseUploadComplete,
  bulkReleaseUploadFail,
  fetchingBulkReleaseUploadFile,
  fetchBulkReleaseUploadFile,
  bulkReleaseUploadFileComplete,
  bulkReleaseUploadFileFail,
  fetchingBulkReleaseReleaseAll,
  fetchBulkReleaseReleaseAll,
  bulkReleaseReleaseAllComplete,
  bulkReleaseReleaseAllFail,
  fetchingBulkReleaseFneUpload,
  fetchBulkReleaseFneUpload,
  bulkReleaseFneUploadComplete,
  bulkReleaseFneUploadFail,
  fetchingBulkReleaseFneUploadFile,
  fetchBulkReleaseFneUploadFile,
  bulkReleaseFneUploadFileComplete,
  bulkReleaseFneUploadFileFail,
  fetchingBulkReleaseFneReleaseAll,
  fetchBulkReleaseFneReleaseAll,
  bulkReleaseFneReleaseAllComplete,
  bulkReleaseFneReleaseAllFail,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(
      INIT_BULKRELEASEAI_EXCEL_VALIDATION,
      initBulkReleaseExcelValidationSaga
    ),
    takeLatest(INIT_BULKRELEASE_DUPS_UPLOAD, initBulkReleaseUploadSaga),
    takeLatest(INIT_BULKRELEASE_DUPS_UPLOAD_FILE, initBulkReleaseUploadFileSaga),
    takeLatest(INIT_BULKRELEASE_DUPS_RELEASE_ALL, initBulkReleaseRelaseAllSaga),
    takeLatest(INIT_BULKRELEASE_FNE_UPLOAD, initBulkReleaseFneUploadSaga),
    takeLatest(
      INIT_BULKRELEASE_FNE_UPLOAD_FILE,
      initBulkReleaseFneUploadFileSaga
    ),
    takeLatest(
      INIT_BULKRELEASE_FNE_RELEASE_ALL,
      initBulkReleaseFneRelaseAllSaga
    ),
  ]);
}

function* initBulkReleaseExcelValidationSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseExcelValidation());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, AdhocExcelValidation, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseExcelValidation(response.data.resultStatus));
      callback && callback(response.data);
      yield put(bulkReleaseExcelValidationComplete());
    } else {
      yield put(bulkReleaseExcelValidationFail());
    }
  } catch (error) {
    yield put(bulkReleaseExcelValidationFail());
  }
}

function* initBulkReleaseUploadSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseUpload());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, BulkReleaseUpload, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseUpload(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseUploadComplete());
    } else {
      yield put(bulkReleaseUploadFail());
    }
  } catch (error) {
    yield put(bulkReleaseUploadFail());
  }
}

function* initBulkReleaseUploadFileSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseUploadFile());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, BulkReleaseUploadFile, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseUploadFile(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseUploadFileComplete());
    } else {
      yield put(bulkReleaseUploadFileFail());
    }
  } catch (error) {
    yield put(bulkReleaseUploadFileFail());
  }
}

function* initBulkReleaseRelaseAllSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseReleaseAll());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, BulkReleaseReleaseAll, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseReleaseAll(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseReleaseAllComplete());
    } else {
      yield put(bulkReleaseReleaseAllFail());
    }
  } catch (error) {
    yield put(bulkReleaseReleaseAllFail());
  }
}

function* initBulkReleaseFneUploadSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseFneUpload());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      BulkReleaseFraudAndErrorUpload,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseFneUpload(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseFneUploadComplete());
    } else {
      yield put(bulkReleaseFneUploadFail());
    }
  } catch (error) {
    yield put(bulkReleaseFneUploadFail());
  }
}

function* initBulkReleaseFneUploadFileSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseFneUploadFile());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      BulkReleaseFraudAndErrorUploadFile,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseFneUploadFile(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseFneUploadFileComplete());
    } else {
      yield put(bulkReleaseFneUploadFileFail());
    }
  } catch (error) {
    yield put(bulkReleaseFneUploadFileFail());
  }
}

function* initBulkReleaseFneRelaseAllSaga(action) {
  const { payload, callback } = action || {};
  yield put(fetchingBulkReleaseFneReleaseAll());
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      BulkReleaseFraudErrorReleaseAll,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchBulkReleaseFneReleaseAll(response.data));
      callback && callback(response.data);
      yield put(bulkReleaseFneReleaseAllComplete());
    } else {
      yield put(bulkReleaseFneReleaseAllFail());
    }
  } catch (error) {
    yield put(bulkReleaseFneReleaseAllFail());
  }
}
