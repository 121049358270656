const User = (props) => {
  return (
    <svg viewBox="0 0 24 24" className="icons">
      <title>User Icon</title>
      <g stroke="none">
        <g transform="translate(-151.000000, -408.000000)">
          <path d="M162.625,408 C156.203125,408 151,413.203125 151,419.625 C151,426.046875 156.203125,431.25 162.625,431.25 C169.046875,431.25 174.25,426.046875 174.25,419.625 C174.25,413.203125 169.046875,408 162.625,408 Z M168.625,427.7625 C166.942188,429.004688 164.870313,429.75 162.625,429.75 C160.379688,429.75 158.307813,429.004688 156.625,427.7625 L156.625,427.125 C156.625,425.470313 157.970313,424.125 159.625,424.125 C160.145313,424.125 160.914063,424.659375 162.625,424.659375 C164.340625,424.659375 165.1,424.125 165.625,424.125 C167.279688,424.125 168.625,425.470313 168.625,427.125 L168.625,427.7625 Z M170.059375,426.473438 C169.740625,424.298438 167.889063,422.625 165.625,422.625 C164.664063,422.625 164.2,423.159375 162.625,423.159375 C161.05,423.159375 160.590625,422.625 159.625,422.625 C157.360938,422.625 155.509375,424.298438 155.190625,426.473438 C153.526563,424.66875 152.5,422.26875 152.5,419.625 C152.5,414.042188 157.042188,409.5 162.625,409.5 C168.207813,409.5 172.75,414.042188 172.75,419.625 C172.75,422.26875 171.723438,424.66875 170.059375,426.473438 Z M162.625,413.25 C160.346875,413.25 158.5,415.096875 158.5,417.375 C158.5,419.653125 160.346875,421.5 162.625,421.5 C164.903125,421.5 166.75,419.653125 166.75,417.375 C166.75,415.096875 164.903125,413.25 162.625,413.25 Z M162.625,420 C161.176563,420 160,418.823438 160,417.375 C160,415.926563 161.176563,414.75 162.625,414.75 C164.073438,414.75 165.25,415.926563 165.25,417.375 C165.25,418.823438 164.073438,420 162.625,420 Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default User;
