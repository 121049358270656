import React from "react";

const IPQC = (props) => {
  return (
    <svg
      className="icons"
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Switch-to-client" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="DataLoad-and-DUPS"
          transform="translate(-83.000000, -601.000000)"
        >
          <g
            id="Worfklow,-Status-&amp;-UI/01-Light/tachometer-alt-average-Copy-2"
            transform="translate(83.000000, 600.000000)"
          >
            <path
              d="M17.0339063,15.4982813 L14.533875,12.9985313 C15.0080625,12.3887813 15.3019688,11.6322188 15.3019688,10.8 C15.3019688,8.8117749 13.6901939,7.2 11.7019688,7.2 C9.71374365,7.2 8.10196875,8.8117749 8.10196875,10.8 C8.10196875,12.7882251 9.71374365,14.4 11.7019688,14.4 C12.5330625,14.4 13.2890625,14.1069375 13.8985313,13.633875 L16.3988438,16.1339063 C16.4410466,16.1761561 16.4983142,16.1998958 16.5580313,16.1998958 C16.6177483,16.1998958 16.6750159,16.1761561 16.7172188,16.1339063 L17.0353125,15.8158125 C17.1223493,15.7276347 17.1217206,15.5856847 17.0339063,15.4982813 L17.0339063,15.4982813 Z M11.7014063,13.5 C10.2102374,13.5 9.00140625,12.2911688 9.00140625,10.8 C9.00140625,9.30883118 10.2102374,8.1 11.7014063,8.1 C13.1925751,8.1 14.4014063,9.30883118 14.4014063,10.8 C14.3995468,12.2903979 13.1918042,13.4981405 11.7014063,13.5 L11.7014063,13.5 Z M10.35,15.3 L2.24999998,15.3 C2.00147185,15.3 1.79999998,15.0985282 1.79999998,14.85 L1.79999998,3.15253124 C1.79999998,2.9040031 2.00147185,2.70253123 2.24999998,2.70253123 L7.2,2.70253123 L7.2,5.62753124 C7.19947508,5.80671278 7.27042238,5.97870682 7.3971234,6.10540784 C7.52382442,6.23210886 7.69581846,6.30305616 7.875,6.30253124 L11.7,6.29999998 L11.7,5.51249999 C11.6994232,5.1538593 11.5568403,4.81004224 11.3034375,4.55624999 L8.94375,2.19656248 C8.69091089,1.94310969 8.3477524,1.8004642 7.98975,1.79999998 L2.24999998,1.79999998 C1.50445589,1.8024761 0.901079514,2.40698381 0.89999998,3.15253124 L0.89999998,14.85 C0.89999998,15.5955844 1.50441557,16.2 2.24999998,16.2 L10.35,16.2 C10.9235028,16.1986376 11.4331899,15.8341238 11.6198438,15.2918438 C11.2983745,15.2851907 10.9785457,15.2440806 10.6658438,15.1692188 C10.5820198,15.2528948 10.4684404,15.2999246 10.35,15.3 Z M8.1,2.71659373 C8.17890941,2.73610126 8.25091912,2.77697164 8.308125,2.83471873 L10.6678125,5.19412499 C10.7254919,5.25127096 10.7663552,5.32317086 10.7859375,5.40196874 L8.1,5.40196874 L8.1,2.71659373 Z"
              id="Files,-Folders-&amp;-Lists/01-Light/file-search"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default IPQC;
