import { all, call, put, takeLatest } from "redux-saga/effects";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import { Error_Msg } from '../../../config';
import axios from "../../../config/axiosCall";
import {
  INIT_GET_GRID_CATEGORY,
  INIT_GRID_CATEGORY_LISTING_LIST,
  INIT_GRID_DETAILS,
  INIT_SAVE_GRID_DETAILS,
} from "./actionsTypes";
import {
  fetchGridCategory,
  fetchGridListingList,
  fetchGridDetails,
} from "./actions";
import {
  ManageGridCategoryListing,
  ManageGridListing,
  ManageGridDetails,
  ManageGridUpdateGridDetails,
} from "../../../config/APINames";
import generateKey from '../../../config/generateKey';

export default function* watchGridActivity() {
  yield all([
    takeLatest(INIT_GET_GRID_CATEGORY, initGridCategory),
    takeLatest(INIT_GRID_CATEGORY_LISTING_LIST, initGridListing),
    takeLatest(INIT_GRID_DETAILS, initGridDetails),
    takeLatest(INIT_SAVE_GRID_DETAILS, initSaveGridDetails),
  ]);
}
function* initGridCategory(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageGridCategoryListing,
      requestDetails
    );
    if (
      response.data.resultStatus === true &&
      response.data.resultSet !== undefined
    ) {
      yield put(fetchGridCategory(response.data.resultSet.gridCategoryList));
    }
  } catch (error) {
    generateNotification(Error_Msg, "danger");
  }
}

function* initGridListing(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, ManageGridListing, requestDetails);
    if (response.data.resultSet !== undefined) {
      yield put(fetchGridListingList(response.data.resultSet.gridListing));
    }
  } catch (error) {
    generateNotification(Error_Msg, "danger");
  }
}

function* initGridDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, ManageGridDetails, requestDetails);
    if (response.data.resultSet !== undefined) {
      yield put(fetchGridDetails(response.data.resultSet));
      callback();
    }
  } catch (error) {
    generateNotification(Error_Msg, "danger");
  }
}
function* initSaveGridDetails(action) {
  const { payload, callback } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      ManageGridUpdateGridDetails,
      requestDetails
    );
    if ((response.data.resultSet = "Saved successfully")) {
      generateNotification(generateKey(response.data.resultSet), "success");
      callback();
    } else {
      generateNotification(generateKey(response.data.resultSet), "danger");
    }
  } catch (error) {
    generateNotification(Error_Msg, "danger");
  }
}
