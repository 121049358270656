import React, { Component } from "react";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import i18n from "i18next";
import { selectAuthDetails } from "../../Login/store/selectors";
import { updateAuthDetails } from "../../Login/store/actions";
import {
  selectClientList,
  selectAdminDetails,
  selectClientSystemParameters,
} from "./store/selectors";
import {
  initClientList,
  initSwitchClient,
  initClientSystemParameters,
} from "./store/actions";
import { initSetRoute } from "../../../store/actions";
import { storage } from "../../../config/storage";
import HeaderView from "../../../components/Layout/Header";
import { fetchMobileReport } from './store/actions';
import { LanguageList, redirectOAuthUri, redirectDNS } from "./config/index";
import { initLogOut } from "../../Login/store/actions";
import { Tenant, RedirectURL, AtcatAdminName } from "../../../config";
import { generateNotification } from "../../../helper/generateNotification";
import { initRenewToken } from "../../../containers/RenewToken/store/actions";

class Header extends Component {
  state = {
    language: "EN",
  };
  componentDidMount() {
    this.getRenewToken();
    const { availableLang, selectedLanguage, clientName, userName } =
      this.props.authDetails;
    if (availableLang.length > 0) {
      const availLang = availableLang.split(",");
      const assignLang =
        selectedLanguage === "en"
          ? availLang[0].toUpperCase()
          : selectedLanguage;
      this.setState({
        language: assignLang,
      });
      this.updateLanguage(assignLang);
    } else if (selectedLanguage !== undefined) {
      this.setState({
        language: selectedLanguage.toUpperCase(),
      });
    }
    clientName !== undefined &&
      clientName.toUpperCase() !== AtcatAdminName &&
      this.props.getClientSystemParameters({
        userName: userName,
        clientName: clientName,
      });
  }

  componentDidUpdate(_, prevState) {
    this.getRenewToken();
  }

  getRenewToken = () => {
    this.props.getRenewToken({
      UserName: this.props.authDetails.userName,
      ClientName: this.props.authDetails.clientName.toLowerCase(),
      accessToken: storage.getItem("access_token"),
      refreshToken: storage.getItem("refresh_token"),
    });
  };

  updateLanguage(lang) {
    this.getRenewToken();
    let authDetails = this.props.authDetails;
    authDetails.selectedLanguage = lang;
    this.setState({
      language: lang,
    });
    storage.setItem("loginDetails", JSON.stringify(authDetails));
    this.props.updateAuthDetails(authDetails);
    i18n.changeLanguage(lang);
  }
  generateLangageList() {
    let availableLanguages =
      this.props.authDetails.availableLang !== undefined
        ? this.props.authDetails.availableLang.split(",")
        : [];
    let list = [];
    LanguageList.forEach((item) => {
      availableLanguages.forEach((item2) => {
        if (item2.toUpperCase() === item.selector) {
          list.push(
            <li className="dropdown-item " key={item.selector}>
              <button
                onClick={() => this.updateLanguage(item.selector)}
              >{`${item.selector} - ${item.title}`}</button>
            </li>
          );
        }
      });
    });
    return list;
  }
  handleSwitchClient = (data) => {
    this.getRenewToken();
    const { clientName, option } = data;
    if (option === "Switch to Client") {
      if (clientName !== "") {
        this.props.initSwitchClient({
          clientName: clientName,
          password: "",
          userName: this.props.authDetails.userName,
        });
        this.props.getClientSystemParameters({
          userName: this.props.authDetails.userName,
          clientName: clientName,
        });
      } else {
        generateNotification("atcat.pleaseSelectClientToSwitch", "danger");
      }
    } else {
      storage.setItem("loginDetails", storage.getItem("adminDetails"));
      storage.removeItem('mobileReportAvailble');
      this.props.updateAuthDetails(this.props.adminDetails);
      this.props.initSetRoute("Home");
      this.props.fetchMobileReport(false);
    }
  };
  handleLogOut = () => {
    let redirectUri = "";
    const ssoLoginDetails =
      JSON.parse(storage.getItem("ssoLoginDetails")) || "NON-SSO";
    const clientAuthenticationDetails = JSON.parse(storage.getItem("clientAuthentication"));
    this.props.logout(() => {
      if (ssoLoginDetails.loginType === "SSO") {
        redirectUri = redirectDNS + Tenant + redirectOAuthUri + RedirectURL;
        if (ssoLoginDetails.SSOType === "Client User") {
          redirectUri = clientAuthenticationDetails?.RequestUrl + redirectOAuthUri + RedirectURL;
        }
      }
      storage.removeItem("clientAuthentication");
      storage.removeItem("ssoLoginDetails");
      window.open(redirectUri, "_self");
    });
  };
  render() {
    let list = this.generateLangageList();
    let value = {
      languageList: list,
      language: this.state.language,
    };
    let func = {
      changeLanuage: (lang) => this.updateLanguage(lang),
      handleSwitchClient: (data) => this.handleSwitchClient(data),
      handleLogOut: this.handleLogOut,
      fetchClientList: () => {
        this.props.initClientList({
          userName: this.props.authDetails.userName,
          clientName: AtcatAdminName.toLocaleLowerCase(),
          userId: this.props.authDetails.userId,
          role: this.props.authDetails.role,
        });
      },
    };
    return (
      <>
        <HeaderView {...value} {...func} {...this.props} />
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  authDetails: selectAuthDetails(),
  clientList: selectClientList(),
  adminDetails: selectAdminDetails(),
  clientSystemParameters: selectClientSystemParameters(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    updateAuthDetails: (data) => {
      dispatch(updateAuthDetails(data));
    },
    initClientList: (payload) => {
      dispatch(initClientList(payload));
    },
    initSwitchClient: (payload) => {
      dispatch(initSwitchClient(payload));
    },
    initSetRoute: (data) => {
      dispatch(initSetRoute({ pathname: data }));
    },
    logout: (callback) => {
      dispatch(initLogOut(callback));
    },
    getClientSystemParameters: (payload) => {
      dispatch(initClientSystemParameters(payload));
    },
    getRenewToken: (payload) => {
      dispatch(initRenewToken(payload));
    },
    fetchMobileReport: (data) => {
      dispatch(fetchMobileReport(data))
    }
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Header);
