import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { storage } from "../../../config/storage";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import exportToExcel from "../../../helper/exportToExcel";
import { Error_Msg } from "../../../config/index";
import {
  AIRevisitedDupsExceptionListHeader,
  AIRevisitedDupsExportToExcel,
  AIRevisitedDupsExportAllToExcel,
  AIRevisitedDupsExceptionFieldList,
  AIDupsCount,
  AIRevisitedDupsExceptionList,
  AIRevisitedDupsGetFilterName,
  AIRevisitedDupsDeleteAdvanceFilter,
  AIRevisitedUnattendedPost,
  AIRevisitedDupsMassReleaseWithComment,
  AIRevisitedDupsGetFilterFields,
  AIRevisitedDupsEditFilterPageData,
  AddAdvanceFilter,
} from "../../../config/APINames";
import {
  INIT_AI_EXCEPTION_LIST_HEADER_NAMES,
  INIT_AI_EXCEPTION_EXPORT_FIELD_LIST,
  INIT_AI_EXCEPTION_TOTAL_COUNT,
  INIT_AI_EXCEPTION_LIST,
  INIT_AI_EXCEPTION_FILTER_NAMES,
  INIT_DELETE_FILTER_AI_EXCEPTION,
  INIT_AI_EXCEPTION_RECORDS_EXPORT,
  INIT_AI_EXCEPTION_RECORDS_EXPORT_ALL,
  INIT_RELEASE_UNRELEASE_AI_EXCEPTIONS,
  INIT_AI_MASS_RELEASE_WITH_COMMENTS,
  INIT_AI_EXCEPTION_FILTER_COLUMNS,
  INIT_AI_EXCEPTION_FILTER_DETAILS,
  INIT_SAVE_FILTER_AI_EXCEPTION,
} from "./actionTypes";
import {
  fetchAIExceptionListHeaderNames,
  fetchAIExceptionExportFieldList,
  fetchAIExceptionTotalCount,
  fetchAIExceptionList,
  fetchAIExceptionRecordsExport,
  fetchAIExceptionRecordsExportAll,
  fetchAIExceptionFilterColumns,
} from "./actions";

export default function* watchLogin() {
  yield all([
    takeLatest(
      INIT_AI_EXCEPTION_FILTER_NAMES,
      initGetAIExceptionFilterNamesSaga
    ),
    takeLatest(
      INIT_AI_EXCEPTION_LIST_HEADER_NAMES,
      initGetAIExceptionListHeaderNamesSaga
    ),
    takeLatest(INIT_AI_EXCEPTION_LIST, initGetAIExceptionListSaga),
    takeLatest(
      INIT_AI_EXCEPTION_FILTER_COLUMNS,
      initAIExceptionFilterColumsSaga
    ),
    takeLatest(
      INIT_AI_EXCEPTION_FILTER_DETAILS,
      initAIExceptionFilterDetailsSaga
    ),
    takeLatest(INIT_SAVE_FILTER_AI_EXCEPTION, initSaveAIExceptionFilterSaga),
    takeLatest(INIT_AI_EXCEPTION_TOTAL_COUNT, initAIExceptionTotalCountSaga),
    takeLatest(
      INIT_DELETE_FILTER_AI_EXCEPTION,
      initDeleteAIExceptionFilterSaga
    ),
    takeEvery(
      INIT_RELEASE_UNRELEASE_AI_EXCEPTIONS,
      initReleaseUnReleaseAIExceptionsSaga
    ),
    takeLatest(
      INIT_AI_MASS_RELEASE_WITH_COMMENTS,
      initAIMassReleaseWithCommentsSaga
    ),
    takeLatest(
      INIT_AI_EXCEPTION_EXPORT_FIELD_LIST,
      initGetAIExceptionExportFieldListSaga
    ),
    takeLatest(
      INIT_AI_EXCEPTION_RECORDS_EXPORT,
      initAIExceptionRecordsExportSaga
    ),
    takeLatest(
      INIT_AI_EXCEPTION_RECORDS_EXPORT_ALL,
      initAIExceptionRecordsExportAllSaga
    ),
  ]);
}

function* initGetAIExceptionFilterNamesSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName, sourceDomain, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      userName,
      userId,
      sourceDomain,
    });
    const response = yield call(
      axios,
      AIRevisitedDupsGetFilterName,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initGetAIExceptionListHeaderNamesSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      AIRevisitedDupsExceptionListHeader,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchAIExceptionListHeaderNames(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initGetAIExceptionListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      AIRevisitedDupsExceptionList,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchAIExceptionList(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initAIExceptionFilterColumsSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      AIRevisitedDupsGetFilterFields,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchAIExceptionFilterColumns(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initAIExceptionFilterDetailsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName, sourceDomain, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      sourceDomain,
      userId,
      userName,
    });
    const response = yield call(
      axios,
      AIRevisitedDupsEditFilterPageData,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initSaveAIExceptionFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName, sourceDomain, userId } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      sourceDomain,
      userId,
      userName,
    });
    const response = yield call(axios, AddAdvanceFilter, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initAIExceptionTotalCountSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(axios, AIDupsCount, requestDetails);
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchAIExceptionTotalCount(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initDeleteAIExceptionFilterSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      clientName,
      userName,
    });
    const response = yield call(
      axios,
      AIRevisitedDupsDeleteAdvanceFilter,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      if (action.callback) action.callback(response);
    }
  } catch (error) {
    if (action.callback) action.callback(error);
  }
}

function* initAIExceptionRecordsExportSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      AIRevisitedDupsExportToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchAIExceptionRecordsExport(response.data));
      exportToExcel(
        response.data.exceptionListing,
        "ExceptionsListing",
        response.data.exportfileName
      );
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initAIExceptionRecordsExportAllSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      AIRevisitedDupsExportAllToExcel,
      requestDetails
    );
    if (response.data === Error_Msg) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchAIExceptionRecordsExportAll(response.data));
      exportToExcel(
        response.data.exceptionListing,
        "ExceptionsListing",
        response.data.exportfileName
      );
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initReleaseUnReleaseAIExceptionsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      ClientName: clientName,
      UserName: userName,
    });
    const response = yield call(
      axios,
      AIRevisitedUnattendedPost,
      requestDetails
    );
    if (response.data === Error_Msg) {
      const regMatch = /[a-z]/i.test(payload.CommandArgs);
      if (regMatch || payload.CommandArgs.length > 10) {
        if (action.callback) action.callback(response);
      } else {
        generateNotification(
          "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
          "danger"
        );
      }
    } else if (
      response.data === "Exception Sets Released Successfully" ||
      response.data === "Exception Set Unreleased Successfully" ||
      response.data.msg === "All records released successfully"
    ) {
      if (action.callback) action.callback(response);
    } else if (
      response.data !==
      "Request could not be processed at this time. Please contact support team."
    )
      if (action.callback) action.callback(response.data);
      else if (response.data.msg === "No valid exceptions exist")
        generateNotification("atcat.noValidExceptionsExist", "danger");
      else {
        generateNotification(response.data, "danger");
      }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initAIMassReleaseWithCommentsSaga(action) {
  const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
  const { userName, clientName } = sessionDetails || {};
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
      ClientName: clientName,
      UserName: userName,
    });
    const response = yield call(
      axios,
      AIRevisitedDupsMassReleaseWithComment,
      requestDetails
    );
    if (response.data === Error_Msg)
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    else if (
      response.data.msg === "Selected exceptions released successfully" ||
      response.data === "Exception Sets Released Successfully" ||
      response.data.msg === "All records released successfully"
    ) {
      if (action.callback) action.callback(response);
    } else {
      generateNotification(response.data.msg, "danger");
      if (action.callback) action.callback(false);
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}

function* initGetAIExceptionExportFieldListSaga(action) {
  const { payload } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      AIRevisitedDupsExceptionFieldList,
      requestDetails
    );
    if (response.status !== 200) {
      generateNotification(
        "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
        "danger"
      );
    } else if (response !== Error_Msg) {
      yield put(fetchAIExceptionExportFieldList(response.data));
    }
  } catch (error) {
    generateNotification(
      "atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam",
      "danger"
    );
  }
}
