import { all, call, takeEvery } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { generateNotification } from "../../../helper/generateNotification";
import {
    postRequestDetails,
} from "../../../config/requestHeaders";
import { } from "./actions";
import { LoginLogoutRenewToken } from "../../../config/APINames";
import { INIT_RENEW_TOKEN } from "./actionTypes";
import { Error_Msg } from "../../../config";
import { storage } from "../../../config/storage";
export default function* watchLogin() {
    yield all([
        takeEvery(INIT_RENEW_TOKEN, initRenewTokenSaga),
    ]);
}
function* initRenewTokenSaga(action) {
    const { payload } = action || {};
    try {
        const today = new Date();
        const userRenewalTokenTime = storage.getItem('UserRenewalTokenTime');
        const d1 = new Date(today);
        const d2 = new Date(userRenewalTokenTime);
        const renewTokenFlag = storage.getItem("RenewTokenFlag") == null || undefined ? 'false' : storage.getItem("RenewTokenFlag");
        const renewalTokenTimeInMin = new Date(userRenewalTokenTime).getTime();
        const userLoginTimeInMin = new Date(today).getTime();
        let diffMin = (renewalTokenTimeInMin - userLoginTimeInMin) / 1000;
        diffMin /= 60;
        let IsExpired = "false";
        if (diffMin <= 0)
            IsExpired = "true";
        if (d1.getTime() >= d2.getTime() && renewTokenFlag === 'false') {
            const sessionDetails = JSON.parse(storage.getItem("loginDetails")) || {};
            const requestDetails = postRequestDetails();
            yield (requestDetails.data = {
                UserName: payload.UserName,
                ClientName: sessionDetails.role === "SuperUser" || sessionDetails.role === "ServiceUser" || sessionDetails.role === "ServiceReader" ? "atcat" : payload.ClientName,
                accessToken: payload.accessToken,
                refreshToken: payload.refreshToken,
                IsExpired: IsExpired,
            });
            storage.setItem("RenewTokenFlag", "true");
            let response = yield call(axios, LoginLogoutRenewToken, requestDetails);
            if (response.data === Error_Msg) {
                generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
            }
            else {
                storage.setItem("access_token", response.data.accessToken);
                storage.setItem("refresh_token", response.data.refresh_token);
                storage.setItem("ExpiresAt", response.data.expires);
                const userRenewalTokenTime = getUserLoginTimeForToken();
                storage.setItem('UserRenewalTokenTime', userRenewalTokenTime);
                storage.setItem("RenewTokenFlag", "false");
            }
        }
        else
            return;
    }
    catch (error) {
        storage.setItem("RenewTokenFlag", "true");
        generateNotification("atcat.requestCouldNotBeProcessedAtThisTimePleaseContactSupportTeam", "danger");
    }
}

function getUserLoginTimeForToken() {
    const today = new Date();
    const expireTime = parseInt(storage.getItem('ExpiresAt'));
    today.setMinutes(today.getMinutes() + (expireTime - 5));
    return today;
}
