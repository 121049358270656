import React from "react";
import { routes } from "../routes/routes";
const LoginComponent = React.lazy(() => import("../containers/Login"));
const Home = React.lazy(() => import("../containers/Home"));
export const getComponentByPath = (pathname, authStatus) => {
  const defaultComponent = authStatus ? Home : LoginComponent;
  if (pathname && authStatus) {
    const route = routes.find((route) => route.key === pathname);
    if (route) return route.path;
  }

  return defaultComponent;
};
