import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "../../../config/axiosCall";
import { postRequestDetails } from "../../../config/requestHeaders";
import { generateNotification } from "../../../helper/generateNotification";
import generateKey from "../../../config/generateKey";
import {
  SystemParametersGetSystemParameters,
  SystemParametersGetToUandPSDetails,
  SystemParametersSaveSystemParams,
  SystemParametersSaveToUandPSDetails,
} from "../../../config/APINames";
import {
  saveTermsOfUseComplete,
  saveTermsOfUseFailed,
  saveSystemParametersComplete,
  saveSystemParametersFailed,
  getSystemParameterSuccess,
  getTermsOfUseSuccess,
} from "./actions";
import {
  INIT_GET_SYSTEM_PARAMETERS,
  INIT_GET_TOU_PS,
  SAVE_SYSTEM_PARAMETERS,
  SAVE_TERMS_OF_USE,
} from "./actionTypes";
export default function* watchLogin() {
  yield all([
    takeLatest(INIT_GET_SYSTEM_PARAMETERS, initGetSystemParameters),
    takeLatest(INIT_GET_TOU_PS, initGetToUandPS),
    takeLatest(SAVE_SYSTEM_PARAMETERS, initSaveSystemParameters),
    takeLatest(SAVE_TERMS_OF_USE, initSaveTermsOfUse),
  ]);
}

function* initGetSystemParameters(action) {
  const { payload = {} } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    let response = yield call(
      axios,
      SystemParametersGetSystemParameters,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(
        getSystemParameterSuccess(response.data.resultSet.systemParametersData)
      );
    }
  } catch (error) {
    generateNotification(error, "danger");
  }
}

function* initGetToUandPS(action) {
  const { payload = {} } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      SystemParametersGetToUandPSDetails,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(getTermsOfUseSuccess(response.data.resultSet));
    }
  } catch (error) {
    generateNotification(error, "danger");
  }
}

function* initSaveSystemParameters(action) {
  const { payload = {} } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      SystemParametersSaveSystemParams,
      requestDetails
    );
    if (response && response.status === 200) {
      const { data = [] } = response;
      let msg = data.message,
        type = "success";
      if (data.length) {
        type = "danger";
        msg = data.join(" ").trim();
      }
      generateNotification(generateKey(msg), type);

    }
    yield put(saveSystemParametersComplete(payload));
  } catch (error) {
    yield put(saveSystemParametersFailed());
  }
}

function* initSaveTermsOfUse(action) {
  const { payload = {} } = action || {};
  try {
    const requestDetails = postRequestDetails();
    yield (requestDetails.data = {
      ...payload,
    });
    const response = yield call(
      axios,
      SystemParametersSaveToUandPSDetails,
      requestDetails
    );
    if (response && response.status === 200) {
      yield put(saveTermsOfUseComplete(response.data.resultSet));
    }
  } catch (error) {
    yield put(saveTermsOfUseFailed());
  }
}
