import React from "react";

const HomeMenuIcon = (props) => {
  return (
    <svg
      className="icons"
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-27.000000, -92.000000)">
          <g id="Group-15" transform="translate(10.000000, 80.000000)">
            <g transform="translate(16.000000, 10.000000)">
              <path d="M18.7847222,9.06805555 L10.6996528,2.47083331 C10.290535,2.14085965 9.70668725,2.14085965 9.29756944,2.47083331 L1.21527776,9.06805555 C1.09656363,9.16480288 1.07854676,9.33938012 1.17499998,9.45833333 L1.52569442,9.88923611 C1.62202506,10.0073866 1.79557665,10.0258265 1.91458331,9.93055556 L3.33333332,8.77152777 L3.33333332,17.2090278 C3.33333332,17.5158527 3.58206401,17.7645834 3.88888887,17.7645834 L8.33333333,17.7645834 C8.64015819,17.7645834 8.88888889,17.5158527 8.88888889,17.2090278 L8.88888889,12.7645833 L11.1111111,12.775 L11.1111111,17.2222222 C11.1111111,17.5290471 11.3598418,17.7777778 11.6666667,17.7777778 L16.1111111,17.7663195 C16.417936,17.7663195 16.6666667,17.5175888 16.6666667,17.2107639 L16.6666667,8.77152777 L18.0854167,9.93055556 C18.2044242,10.0272442 18.3792677,10.0092159 18.4760417,9.89027778 L18.8267361,9.459375 C18.8731905,9.40196501 18.8948714,9.32841959 18.8869877,9.25499098 C18.8791041,9.18156236 18.842305,9.11429564 18.7847222,9.06805555 Z M15.5517361,16.6579861 L15.5552083,16.6579861 L12.221875,16.6684028 L12.221875,12.2180556 C12.2209284,11.9123001 11.9738065,11.6644036 11.6680556,11.6625 L8.33472222,11.653125 C8.18707915,11.6526636 8.045325,11.7109914 7.94076233,11.8152284 C7.83619967,11.9194653 7.77742983,12.0610368 7.77743055,12.2086806 L7.77743055,16.6579861 L4.44444443,16.6579861 L4.44444443,7.86493055 L10,3.33124998 L15.5555556,7.86493055 L15.5517361,16.6579861 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HomeMenuIcon;
