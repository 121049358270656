import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import FolderIcon from "../../SVG/FolderIcon";
import "./style.scss";

const FileUploader = (props) => {
  const { t } = useTranslation();
  const handleEnterHit = ({ key }) => {
    if (key === "Enter") hiddenFileInput.current.click();
  };
  const hiddenFileInput = useRef(null);
  const labelclass = `title  ${props.required ? `required` : ``}`;
  const wrapperclass = `uploader-wrapper file-uploader-wrapper ${
    props.disabled ? `disabled` : ``
  }  ${props.showError ? `error` : ``}`;
  const handleFileUpload = (data) => {
    props.handleOnChange(data);
    hiddenFileInput.current.value = null;
  };
  return (
    <div className={wrapperclass}>
      <div className="input-control">
        <label className={labelclass} htmlFor={props.id}>
          {props.label}
        </label>
        <input
          ref={hiddenFileInput}
          className="file-uploader"
          type="file"
          id={props.id}
          name={props.name}
          hidden
          onBlur={props.handleBlur}
          onChange={(data) => {
            handleFileUpload(data);
          }}
          accept={props.acceptableExt}
          disabled={props.disabled}
        />
        <label
          className={`label  ${props.disabled ? `disabled` : ``}`}
          tabIndex={0}
          onKeyPress={handleEnterHit}
          htmlFor={props.id}
        >
          {props.fileName ? props.fileName : t("atcat.chooseFile")}
        </label>
      </div>
      <div className="file-icon">
        <FolderIcon />
      </div>
    </div>
  );
};

export default FileUploader;
